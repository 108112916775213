function sortOnCreated (a, b) {
  return a.created.timestamp - b.created.timestamp
}

export default function getCommentsArrayFromObject (input) {
  if (!input) return []

  const commentsMap = new Map()

  // Extract values to map
  for (const [key, data] of input.entries()) {
    const commentId = key.endsWith(':replies') ? key.substr(0, key.indexOf(':replies')) : key
    const existing = commentsMap.get(commentId)

    if (key.endsWith(':replies')) {
      commentsMap.set(commentId, { ...existing, replies: data })
    } else {
      commentsMap.set(commentId, { ...existing, ...data })
    }
  }

  return Array.from(commentsMap.values()).sort(sortOnCreated)
}
