export default function countNewlines (str) {
  let count = 0
  let pos = 0
  const step = '\n'.length

  while (true) {
    pos = str.indexOf('\n', pos)

    if (pos >= 0) {
      count += 1
      pos += step
    } else {
      break
    }
  }

  return count
}
