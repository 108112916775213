import randomWithSeed from 'random-seed'

const avatars = [
  'bear.png', 'bear_2.png', 'beetle.png', 'bird.png', 'cat.png',
  'chicken.png', 'cow.png', 'dog.png', 'duck.png', 'fish.png',
  'fox.png', 'frog.png', 'hedgehog.png', 'hen.png', 'honeybee.png',
  'koala.png', 'ladybug.png', 'lion.png', 'monkey.png', 'mouse.png',
  'mouse_2.png', 'octopus.png', 'owl.png', 'panda.png', 'penguin.png',
  'pig.png', 'rabbit.png', 'snail.png', 'tiger.png', 'turtle.png',
  'unicorn.png', 'wolf.png'
]

export default (id) => {
  const avatarImage = avatars[randomWithSeed(id).intBetween(0, avatars.length - 1)]
  return `/images/avatars/${avatarImage}`
}
