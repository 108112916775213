import styled from 'styled-components'
import SmallTitle from 'components/SmallTitle'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import SvgIcon from 'components/SvgIcon'
import formatFullDate from 'utils/formatFullDate'
import getAnonymousUsername from 'utils/getAnonymousUsername'

export default function Info ({
  info,
  userDataId,
  showInfoBar,
  onToggleInfoBar
}) {
  return (
    <>
      <StatisticGrid>
        <StatisticItem>
          <StatisticItemData>{info.amountWords}</StatisticItemData>
          <StatisticItemTitle>Words</StatisticItemTitle>
        </StatisticItem>
        <StatisticItem>
          <StatisticItemData>{info.amountCharacters}</StatisticItemData>
          <StatisticItemTitle>Characters</StatisticItemTitle>
        </StatisticItem>
        <StatisticItem>
          <StatisticItemData>{info.readTime}</StatisticItemData>
          <StatisticItemTitle>Read time</StatisticItemTitle>
        </StatisticItem>
        <StatisticItem>
          <StatisticItemData>{info.amountParagraphs}</StatisticItemData>
          <StatisticItemTitle>Paragraphs</StatisticItemTitle>
        </StatisticItem>
      </StatisticGrid>

      <ShowInfoButton
        grey
        noShadow
        onClick={onToggleInfoBar}
        label={`${showInfoBar ? 'Hide' : 'Show'} info bar`}
        icon={<SvgIcon icon={showInfoBar ? 'private' : 'public'} />}
      />

      {info.created && (
        <Column>
          <SmallTitle>Creation date:</SmallTitle>
          <MediumTitle>{formatFullDate(info.created.timestamp == null ? info.created : info.created.timestamp)}</MediumTitle>
          {info.created.user && (
            <User>
              <By>by</By>
              <Avatar avatar={info.created.user.avatar} seed={info.created.user.userId} size={25} />
              <UserName>
                {info.created.user.name || getAnonymousUsername(info.created.user.userId)}
                {' '}
                {info.created.user.userId === userDataId && <You>(You)</You>}
              </UserName>
            </User>
          )}
        </Column>
      )}
      {info.latestUpdate && (
        <Column>
          <SmallTitle>Last edit:</SmallTitle>
          <MediumTitle>{formatFullDate(info.latestUpdate.timestamp == null ? info.latestUpdate : info.latestUpdate.timestamp)}</MediumTitle>
          {info.latestUpdate.user && (
            <User>
              <By>by</By>
              <Avatar avatar={info.latestUpdate.user.avatar} seed={info.latestUpdate.user.userId} size={25} />
              <UserName>
                {info.latestUpdate.user.name || getAnonymousUsername(info.latestUpdate.user.userId)}
                {' '}
                {info.latestUpdate.user.userId === userDataId && <You>(You)</You>}
              </UserName>
            </User>
          )}
        </Column>
      )}
    </>
  )
}

const StatisticGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .1rem;
  background: transparent;
  border: .1rem rgba(61, 79, 80, .18) solid;
  border-radius: 1rem;
  overflow: hidden;
  margin: 0 0 2rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2rem;
    left: 2rem;
    height: calc(100% - 4rem);
    width: calc(100% - 4rem);
    background: rgba(151, 151, 151, .2);
    z-index: -1;
  }
`
const StatisticItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 2.5rem 0.5rem;
  .darkMode & {
    background: #1D232B;
  }
`
const StatisticItemData = styled.p`
  font-weight: 500;
  font-size: 2rem;
`
const StatisticItemTitle = styled.p`
  font-size: 1.4rem;
  opacity: 0.5;
`
const ShowInfoButton = styled(Button)`
  margin: 0 0 2rem;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-top: .1rem solid rgba(151, 151, 151, .2);

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`
const MediumTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.95rem;
`
const User = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1.6rem;
`
const By = styled.span`
  opacity: 0.5;
  margin-right: 0.5rem;
`
const UserName = styled.span`
  margin-left: 0.5rem;
  font-weight: 500;
`
const You = styled.span`
  opacity: 0.5;
  font-weight: 400;
`
