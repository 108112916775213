import { useState, useEffect } from 'preact/hooks'
import { debounce } from 'throttle-debounce'

export default function useExtractCachedUserDataFromKluddDoc (kluddDoc) {
  const [cachedUserData, setCachedUserData] = useState(new Map())

  useEffect(() => {
    if (!kluddDoc || !kluddDoc.usersCachedDataType) return

    const map = kluddDoc.usersCachedDataType

    const updateData = debounce(1000, () => {
      const userData = new Map()

      map.forEach((value, userId) => userData.set(userId, value))

      setCachedUserData(userData)
    })

    map.observe(updateData)
    updateData()

    return () => {
      map.unobserve(updateData)
      updateData.cancel()
    }
  }, [kluddDoc])

  return cachedUserData
}
