import { useMemo } from 'preact/hooks'
import CrdtMap from 'crdtmap'

export default function useUserDoc (userId) {
  return useMemo(() => {
    const doc = CrdtMap()
    const documentsMapType = doc.getMap('documents')
    const favoritesType = doc.getMap('favorites')
    const metadataType = doc.getMap('metadata')

    return {
      doc,
      docId: userId, // Used to make sure we're using same document
      documentsMapType,
      favoritesType,
      metadataType
    }
  }, [userId]) // Get new document on userId update
}
