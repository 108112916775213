import styled from 'styled-components'
import BottomBar from 'components/BottomBar'
import SvgIcon from 'components/SvgIcon'

export default function InfoBottomBar ({ info, onToggle, ...props }) {
  return (
    <Bar {...props}>
      <InfoBarContent>
        <InfoBarItem><InfoBarTitle>Words:</InfoBarTitle> {info.amountWords}</InfoBarItem>
        <InfoBarItem><InfoBarTitle>Characters:</InfoBarTitle> {info.amountCharacters}</InfoBarItem>
        <InfoBarItem><InfoBarTitle>Read time:</InfoBarTitle> {info.readTime}</InfoBarItem>
        <InfoBarItem><InfoBarTitle>Paragraphs:</InfoBarTitle> {info.amountParagraphs}</InfoBarItem>
      </InfoBarContent>
      <InfoBarHideButton onClick={onToggle}>
        <SvgIcon icon='private' />
      </InfoBarHideButton>
    </Bar>
  )
}

const Bar = styled(BottomBar)`
  // > div { padding: 0; }
`
const InfoBarContent = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -1rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 1rem;
    width: 1px;
    background: rgba(151, 151, 151, 0.2);
  }
`
const InfoBarItem = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  white-space: nowrap;
  margin: 0 2rem 1rem 0;
`
const InfoBarHideButton = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  padding: 0 1.5rem;
  margin: -1rem -1.5rem -1rem 0;
  align-self: stretch;

  svg { display: block; }
`
const InfoBarTitle = styled.span`
  opacity: 0.5;
`
