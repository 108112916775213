import styled from 'styled-components'
import Checkbox from 'components/Checkbox'

export default function CheckboxWithLabel ({
  className,
  label,
  reverseOrder,
  icon,
  disabled,
  ...props
}) {
  if (reverseOrder) {
    return (
      <CheckboxWrapper className={className} disabled={disabled}>
        <CheckboxMessage
          checked={!!props.checked}
          reverseOrder
        >
          {icon && <Icon>{icon}</Icon>}
          {label}
        </CheckboxMessage>
        <Checkbox
          isWrappedByLabel
          disabled={disabled}
          {...props}
        />
      </CheckboxWrapper>
    )
  } else {
    return (
      <CheckboxWrapper className={className} disabled={disabled}>
        <Checkbox
          isWrappedByLabel
          disabled={disabled}
          {...props}
        />
        <CheckboxMessage
          checked={!!props.checked}
        >
          {icon && <Icon>{icon}</Icon>}
          {label}
        </CheckboxMessage>
      </CheckboxWrapper>
    )
  }
}

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  ${p => p.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'}
`
const CheckboxMessage = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  transition: opacity .2s ease;
  display: flex;
  align-items: center;
  ${p => !p.checked && 'opacity: 0.6;'}
  a { color: inherit; }

  ${p => p.reverseOrder
    ? 'padding-right: 1rem; margin-right: auto;'
    : 'margin-left: 1rem;'
  }
`
const Icon = styled.span`
  display: flex;
  margin-right: 1rem;
`
