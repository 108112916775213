import { useState, useMemo } from 'preact/hooks'
import styled from 'styled-components'
import Avatar from 'components/Avatar'
import formatClock from 'utils/formatClock'
import formatDate from 'utils/formatDate'

const MAX_DESCRIPTION = 65

export default function Snapshot ({
  id,
  autosave,
  restored,
  name,
  description,
  timestamp,
  userId,
  user,
  isSelected,
  isPreview,
  onClick,
  onContextMenu,
  showContextMenuOnClick,
  snapshot,
  hideAvatar,
  hideShowMore,
  ...props
}) {
  const [showAll, setShowAll] = useState(false)
  const time = useMemo(() => formatClock(timestamp) + ' ' + formatDate(timestamp, 'DAY'), [timestamp])
  const cappedDescription = useMemo(() => (
    description && !showAll && description.length >= MAX_DESCRIPTION
      ? description.substr(0, MAX_DESCRIPTION - 3) + '...'
      : description
  ), [description, showAll])
  const _onContextMenu = (ev) => {
    const rect = ev.target.getBoundingClientRect()
    const top = rect.bottom
    const left = rect.left + rect.width / 2

    onContextMenu(snapshot, top, left)
  }

  return (
    <Wrapper {...props}>
      <InnerLink isPreview={isPreview} isSelected={isSelected} onClick={showContextMenuOnClick ? _onContextMenu : onClick}>
        {!hideAvatar && (
          <AvatarWrapper>
            {
              !autosave && !!user
                ? (
                  <Avatar
                    avatar={user.avatar}
                    seed={userId}
                    size={40}
                  />
                  )
                : <AvatarDot />
            }
          </AvatarWrapper>
        )}

        <Texts>
          <Time>{time}</Time>
          {autosave && <AutosavedName>{name}</AutosavedName>}
          {!autosave && !!name && <Name hasDescription={!!description}>{restored ? `Restored: “${name}”` : `“${name}”`}</Name>}
          {!autosave && !!description && <Description>{cappedDescription}</Description>}
          {!autosave && !!description && description.length >= MAX_DESCRIPTION && !isPreview && !hideShowMore && <ShowAllLinkWhitespace />}
        </Texts>
      </InnerLink>

      {!autosave && !!description && description.length >= MAX_DESCRIPTION && !isPreview && !hideShowMore && (
        <ShowAllLink
          onClick={() => setShowAll(!showAll)}
          hideAvatar={!!hideAvatar}
        >{showAll ? 'Show less' : 'Show more'}
        </ShowAllLink>
      )}

      {!!onContextMenu && !showContextMenuOnClick && (
        <Dots onClick={_onContextMenu} isSelected={isSelected}>
          <svg width='18' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect class='contextBackground' width='18' height='8' rx='4' fill='currentColor' fill-opacity='.2' />
            <path fill-rule='evenodd' clip-rule='evenodd' d='M3.75 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.75 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' fill='currentColor' />
          </svg>
        </Dots>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.article`
  position: relative;
`
const InnerLink = styled.a`
  padding: 1rem;
  display: flex;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: background .2s ease;

  &:hover { background: rgba(241, 241, 241, 0.5); }
  .darkMode &:hover { background: rgba(12, 14, 17, 0.3); }
  // .darkMode &:hover { background: #181d23; }

  ${p => p.isSelected && `
    &, &:hover, .darkMode &:hover { background: rgba(110, 208, 255, 0.2); }
  `}
  ${p => p.isPreview && `
    &, &:hover { background: rgba(241, 241, 241, 0.5); }
    .darkMode &, .darkMode &:hover { background: #181d23; }
    padding: 2rem;
  `}
`
const Texts = styled.div`
  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }
  flex: 1;
`
const Name = styled.h3`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  ${p => p.hasDescription && 'margin-bottom: 1rem;'}
`
const AutosavedName = styled.h3`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
`
const Description = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  overflow: hidden;
  white-space: pre-line;
`
const Time = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 1rem;
`
const AvatarWrapper = styled.div`
  flex: none;
  width: 4rem;
  margin-right: 1rem;
`
const AvatarDot = styled.div`
  background: #ffffff;
  border: 0.3rem solid #8c9197;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: 1.1rem auto 0;

  .darkMode & {
    border-color: rgba(243, 249, 254, 0.4);
    background: #1d232b;
  }
`

// Used to take up space in link, but keep the actual link outside to avoid link in link
const ShowAllLinkWhitespace = styled.div`
  height: 2.6rem;
`
const ShowAllLink = styled.a`
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: rgba(61, 79, 80, 0.5);
  text-decoration: underline;
  text-decoration-color: rgba(61, 79, 80, 0.3);
  text-underline-offset: 0.2rem;
  display: block;

  position: absolute;
  bottom: 1rem;
  left: 6rem;
  ${p => p.hideAvatar && 'left: 1rem;'}

  .darkMode & {
    color: rgba(243, 249, 254, 0.5);
    text-decoration-color: rgba(243, 249, 254, 0.3);
  }
`
const Dots = styled.a`
  position: absolute;
  top: calc(50% - 0.9rem);
  right: 2rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 2;
  ${Wrapper}:hover & { opacity: 0.5; }
  .contextBackground { opacity: 0; transition: opacity 0.2s ease; }
  ${Wrapper}:hover &:hover, &:hover {
    .contextBackground { opacity: 1; }
    opacity: 1;
  }
  ${p => p.isSelected && 'opacity: 1;'}
`
