/* global fetch */
import { useState, useEffect, useRef } from 'preact/hooks'
import { logTrackingEvent } from 'helpers/tracking'
import { API_SERVER } from '@constants'
import { route } from 'preact-router'
import styled from 'styled-components'
import Loader from 'components/Loader'
import Metadata from 'components/Metadata'
import InformationWithIllustration from 'components/InformationWithIllustration'

export default function Invitation ({ docId, inviteCode, hasGotAuthState, userToken, matches }) {
  const [error, setError] = useState(false)
  const hasCalledFetchOnce = useRef(false)
  const ogImage = API_SERVER + '/ogimage/' + docId + '/' + inviteCode
  const isGooglePrerender = matches._escaped_fragment_ != null

  useEffect(() => {
    const isLoggedIn = !!userToken

    // userToken is either string or null once getIdToken is called, so undefined is prior to idToken check
    const hasDoneUserTokenCheck = userToken !== undefined

    // Extra check to make sure we don't fetch twice
    if (hasCalledFetchOnce.current) return

    // Don't fetch anything on prerender
    if (isGooglePrerender) return

    if (hasGotAuthState && hasDoneUserTokenCheck) {
      hasCalledFetchOnce.current = true

      if (isLoggedIn) {
        logTrackingEvent('Document invite redeemed', {
          documentId: docId,
          invitationCode: inviteCode
        })

        fetch(API_SERVER + '/consume-invite/' + docId + '/' + inviteCode, {
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken
          }
        })
          .then(r => {
            if (r.ok) {
              // User has been added to document so redirect to document
              route('/' + docId)
            } else {
              setError(true)
            }
          })
          .catch(err => {
            console.log('Error consuming invite', err)
            setError(true)
          })
      } else {
        // Validate invite code
        fetch(API_SERVER + '/validate-invite/' + docId + '/' + inviteCode)
          .then(r => {
            if (r.ok) {
              r.json()
                .then(metadata => {
                  // Redirect to login with invite code
                  route('/workspaces?returnUrl=/' + docId + '/' + inviteCode + '&wasInvitedToAuthor=' + encodeURIComponent(metadata.ownerName))
                })
            } else {
              setError(true)
            }
          })
          .catch(err => {
            console.log('Error consuming invite', err)
            setError(true)
          })
      }
    }
  }, [hasGotAuthState, userToken])

  if (error) {
    return (
      <View>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <InformationWithIllustration
          illustration={require('images/illustration-expired-link.svg').default}
          illustrationDarkMode={require('images/illustration-expired-link-darkmode.svg').default}
          illustrationBottomMargin='6%'
          imageAlt='Illustration of "Expired Link"'
          title='Sorry, your invitation has expired'
          text='Please ask the owner for a new one.'
          addPadding={false}
        />
      </View>
    )
  }

  if (isGooglePrerender) {
    return (
      <View>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <p>You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions.</p>
      </View>
    )
  }

  return (
    <View>
      <Metadata
        title='Kludd'
        description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
        image={ogImage}
      />
      <Loader />
    </View>
  )
}

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2rem;
  text-align: center;
  font-size: 1.4rem;
`
