import './index.css'
import * as preact from 'preact'
import * as preactHooks from 'preact/hooks'
import CodeMirror from 'codemirror'
import { useState, useCallback, useEffect, useMemo } from 'preact/hooks'
import Router from 'preact-router'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import * as bc from 'lib0/broadcastchannel'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import './polyfills'

import Home from 'routes/Home'
import Landing from 'routes/Landing'
import Slack from 'routes/Slack'
import Invitation from 'routes/Invitation'
import Editor from 'routes/Editor'
import ContentPage from 'routes/ContentPage'
import StoryPage from 'routes/StoryPage'
import Message from 'routes/Message'
import AuthMessage from 'routes/AuthMessage'
import Components from 'routes/Components'
import SvgIcons from 'routes/SvgIcons'

import PluginDataContext from 'contexts/PluginDataContext'

import DocsSidebar from 'components/DocsSidebar'
import MyProfileModal from 'components/MyProfileModal'
import MoveKluddsModal from 'components/MoveKluddsModal'
import DeleteKluddModal from 'components/DeleteKluddModal'
import TypographySettingsModal from 'components/TypographySettingsModal'
import EmailAuthModal from 'components/EmailAuthModal'
import ContextMenu from 'components/ContextMenu'
import CookieCompliance from 'components/CookieCompliance'
import BottomBar from 'components/BottomBar'
import SvgIcon from 'components/SvgIcon'
import SplashScreen from './SplashScreen'

import useViewportHeightCssVariable from 'hooks/useViewportHeightCssVariable'
import usePlugins from 'hooks/usePlugins'
import useActivatePluginsFromUrl from 'hooks/useActivatePluginsFromUrl'
import useOldUserDoc from 'hooks/useOldUserDoc'
import useUserDoc from 'hooks/useUserDoc'
import useCrdtMapIndexeddbProvider from 'hooks/useCrdtMapIndexeddbProvider'
import useCrdtMapWebsocketProvider from 'hooks/useCrdtMapWebsocketProvider'
import useExtractUserDocs from 'hooks/useExtractUserDocs'
import useExtractUserAcceptedTerms from 'hooks/useExtractUserAcceptedTerms'
import useHandleFirstTimeSignIn from 'hooks/useHandleFirstTimeSignIn'
import useAuth, { SHARED_AUTH_BC_CHANNEL } from 'hooks/useAuth'
import useKluddDocContextMenu from 'hooks/useKluddDocContextMenu'
import useRememberModes from 'hooks/useRememberModes'
import useEditorSettings from 'hooks/useEditorSettings'
import useDeviceHasMouse from 'hooks/useDeviceHasMouse'
import useHideUiWhileWriting from 'hooks/useHideUiWhileWriting'
import useClearOldIndexedDB from 'hooks/useClearOldIndexedDB'
import useOverlayControls from 'hooks/useOverlayControls'

import { loadGoogleTagManager } from 'helpers/tracking'

import goToNewDoc from 'utils/goToNewDoc'
import isMacLike from 'utils/isMacLike'

// Only include Sentry in production to avoid messing with console.log tracing.
// Should only be relevant in production anyway.
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://15ca7c22e0a042f6bc472ec6c05c7c8a@o1254854.ingest.sentry.io/6512413',
    integrations: [new BrowserTracing()],

    environment: process.env.BRANCH === 'draft' ? 'draft' : process.env.BRANCH === 'master' ? 'production' : 'feature-branch',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
  })
}

const NON_APP_PAGE_URLS = ['/', '/slack', '/stories/andreas-roman', '/__components', '__svg-icons']
const CONTENT_PAGE_URLS = ['/terms', '/privacy', '/cookies', '/subprocessors']

// Make some packages globally available, to be used in plugins
if (typeof window !== 'undefined') {
  window.codemirror = CodeMirror
  window.h = preact.h
  window.preact = preact
  window.preact_hooks = preactHooks

  loadGoogleTagManager()
}

export default function App () {
  const [darkMode, setDarkMode] = useState(false)
  const [focusMode, setFocusMode] = useState(false)
  const [minimalistMode, setMinimalistMode] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [_sidebarOpen, setSidebarOpen, onSidebarBlur] = useOverlayControls(true)
  const [myProfileOpen, setMyProfileOpen] = useState(false)
  const [typographyModalOpen, setTypographyModalOpen, onTypographyModalBlur] = useOverlayControls(false)
  const [aboutModalOpen, setAboutModalOpen, onAboutModalBlur] = useOverlayControls(false)
  const [showCookieBar, setShowCookieBar] = useState(false)
  const [hideCookieBar, setHideCookieBar] = useState(false)

  // Close certain overlays when others are opened
  useEffect(() => { if (aboutModalOpen) { setTypographyModalOpen(false) } }, [aboutModalOpen])
  useEffect(() => { if (typographyModalOpen) { setAboutModalOpen(false) } }, [typographyModalOpen])

  // Hash used to trigger rerender in sidebar (online/offline checks can't be used in normal state so need to be triggered manually)
  const [sidebarRefreshHash, setSidebarRefreshHash] = useState(1)
  const updateSidebarRefreshHash = useCallback(() => {
    setSidebarRefreshHash(h => h + 1)
  }, [])

  const {
    user,
    userEmailVerified,
    userId,
    userDataId,
    providerId,
    userToken,
    userData,
    isLoggingIn,
    login,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    logout,
    updateProfile,
    uploadAvatarImage,
    hasGotAuthState,
    generateNewIdToken,
    justSignedIn,
    emailAuthModalOpen,
    setEmailAuthModalOpen,
    applyActionCode,
    reloadUser
  } = useAuth()

  useClearOldIndexedDB()

  // Used to determine if route is app, i.e. show sidebar, dark mode, etc.
  const [isOnContentPage, setIsOnContentPage] = useState(false)
  const deviceHasMouse = useDeviceHasMouse()
  const { hideUiWhileWriting, setCodeMirror: hideUiWhileWritingSetCodeMirror } = useHideUiWhileWriting(deviceHasMouse, 10, !minimalistMode)
  const [isInApp, setIsInApp] = useState(false)
  const [hideSidebar, setHideSidebar] = useState(false)
  const [isOnWorkspacePage, setIsOnWorkspacePage] = useState(false)
  const userDoc = useUserDoc(userId)
  const docs = useExtractUserDocs(userDoc)
  const [hasAcceptedToS, acceptToS, acceptToSForUserId] = useExtractUserAcceptedTerms(userDoc)
  const [docContextMenu, onDocContextMenu, closeDocContextMenu, memoryHiddenDocuments, removeMemoryHiddenDocument, memoryLeftDocuments, removeMemoryLeftDocument, undoLeaveDocument, removeUndoLeaveDocument, tempDocs, onDeleteKludd, removeOnDeleteKludd, memoryDeletedDocuments, removeMemoryDeletedDocument, undoDeleteDocument, removeUndoDeleteDocument] = useKluddDocContextMenu(userId, userDoc, userData, generateNewIdToken)
  const [editorSettings, setEditorSettings] = useEditorSettings()

  const [activatedPlugins, availableExports, activatePlugin, deactivatePlugin] = usePlugins(isInApp)
  const [pluginData, setPluginData] = useState({})
  const pluginDataContext = useMemo(() => ({ pluginData, setPluginData }), [pluginData, setPluginData])
  useActivatePluginsFromUrl(activatePlugin, deactivatePlugin)

  useOldUserDoc(userId, userDoc, userToken)

  const [isUserSynced] = useCrdtMapWebsocketProvider(userDoc, 'kludd_user:' + userId, userId, userToken, null, generateNewIdToken, true)

  const [isSigningIn, setIsSigningIn, anonymousDocuments, setAnonymousDocuments] = useHandleFirstTimeSignIn(userData, userToken, hasAcceptedToS, justSignedIn, isUserSynced, userEmailVerified)

  const [userDocIsSynced] = useCrdtMapIndexeddbProvider(userDoc, 'kludd_user:' + userId, userId, true)
  useViewportHeightCssVariable()
  useRememberModes(darkMode, setDarkMode, focusMode, setFocusMode, minimalistMode, setMinimalistMode, isInApp)

  // Checks both actual firebase login and if user doc is synced, in order to wait for ToS check
  const _isLoggingIn = isLoggingIn || (justSignedIn && !isUserSynced)
  const sidebarOpen = _sidebarOpen && !hideSidebar && !_isLoggingIn
  const _bigSidebarOpen = aboutModalOpen && !_isLoggingIn

  const onRouterChange = useCallback((e) => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    const url = e.url.split('?')[0]

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 650 // Uses same as sidebarOpen padding

    setIsInApp(!NON_APP_PAGE_URLS.includes(url) && !e.url.includes('?mode=resetPassword'))
    setIsOnContentPage(CONTENT_PAGE_URLS.includes(url))
    setIsOnWorkspacePage(url === '/workspaces')

    if (isMobile) {
      setSidebarOpen(false)
    } else {
      // Open sidebar if signed out and on workspaces, since no workspace is shown then
      if (url === '/workspaces' && !userToken) {
        setSidebarOpen(true)
      }
    }
  }, [userToken])

  const onAcceptCookies = () => {
    setHideCookieBar(true)
    // Remove cookie bar when transition is done.
    setTimeout(() => setShowCookieBar(false), 201)
  }

  useEffect(() => {
    let cookieCompliance = {}
    try { cookieCompliance = JSON.parse(Cookies.get('cookieCompliance')) } catch (_) {}
    setShowCookieBar(!cookieCompliance['necessary-cookies'])
  }, [])

  // Keyboard shortcuts
  useEffect(() => {
    if (isInApp) {
      const onKey = (ev) => {
        const cmdKey = isMacLike() ? ev.metaKey : ev.ctrlKey

        if (cmdKey && ev.key === 'k') {
          ev.preventDefault()
          ev.stopPropagation()

          goToNewDoc(docs.length === 0, userData, userDoc)
        }
      }

      window.addEventListener('keydown', onKey)
      return () => window.removeEventListener('keydown', onKey)
    }
  }, [isInApp, docs, userData, userDoc])

  if (typeof window === 'undefined' && process.env.NODE_ENV !== 'development') {
    return (
      <PluginDataContext.Provider value={pluginDataContext}>
        <div id='app'>
          <SplashScreen />
        </div>
      </PluginDataContext.Provider>
    )
  }

  return (
    <PluginDataContext.Provider value={pluginDataContext}>
      <div id='app'>
        {process.env.NODE_ENV !== 'development' && <SplashScreen canHide />}
        {/* SplashScreen needs to be top to prevent rehydrate issues */}

        <Router onChange={onRouterChange}>
          <Home
            path='/workspaces'
            userEmailVerified={userEmailVerified}
            isLoggedIn={!!userToken && !_isLoggingIn}
            isLoggingIn={_isLoggingIn}
            login={login}
            sidebarOpen={sidebarOpen}
            docs={docs}
            onDocContextMenu={onDocContextMenu}
            sidebarRefreshHash={sidebarRefreshHash}
            userData={userData}
            userDoc={userDoc}
            setMyProfileOpen={setMyProfileOpen}
            setHideSidebar={setHideSidebar}
            tempDocs={tempDocs}
            setShowCookieBar={setShowCookieBar}
          />

          <Landing
            path='/'
            isLoggedIn={!!userToken}
            docs={docs}
            userData={userData}
            userDoc={userDoc}
          />

          <Slack
            path='/slack'
            isLoggedIn={!!userToken}
            docs={docs}
            userData={userData}
            userDoc={userDoc}
          />

          <StoryPage
            path='/stories/andreas-roman'
            isLoggedIn={!!userToken}
            docs={docs}
            userData={userData}
            userDoc={userDoc}
          />

          <Message
            path='/message/awaiting-verification'
            sidebarOpen={sidebarOpen}
            image={{
              src: require('images/illustration-awaiting-verification.svg').default,
              width: 411,
              height: 306
            }}
            title='Almost there, awaiting verification!'
            text={'We’ve sent you an email to verify your account.\nIf you can’t find it make sure to check your spam folder.'}
          />

          <AuthMessage
            path='/auth'
            sidebarOpen={sidebarOpen}
            applyActionCode={applyActionCode}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            reloadUser={reloadUser}
          />

          <ContentPage path='/terms' title='Terms of Service' sidebarOpen={sidebarOpen} />
          <ContentPage path='/privacy' title='Privacy Policy' sidebarOpen={sidebarOpen} />
          <ContentPage path='/cookies' title='Cookie Policy' sidebarOpen={sidebarOpen} />
          <ContentPage path='/subprocessors' title='Kludd Subprocessors' sidebarOpen={sidebarOpen} />
          <Components path='/__components' title='Components' />
          <SvgIcons path='/__svg-icons' title='SvgIcons' />

          {/* Handle old and new invite formats */}
          <Invitation
            path='/:docId/:inviteCode'
            userToken={userToken}
            hasGotAuthState={hasGotAuthState}
          />
          <Invitation
            path='/invite/:docId/:inviteCode'
            userToken={userToken}
            hasGotAuthState={hasGotAuthState}
          />

          <Editor
            path='/:docId'
            userId={userId}
            userEmailVerified={userEmailVerified}
            userDataId={userDataId}
            userDoc={userDoc}
            userToken={userToken}
            userData={userData}
            sidebarOpen={sidebarOpen}
            darkMode={darkMode}
            // setDarkMode={setDarkMode}
            focusMode={focusMode}
            // setFocusMode={setFocusMode}
            updateSidebarRefreshHash={updateSidebarRefreshHash}
            editorSettings={editorSettings}
            memoryHiddenDocuments={memoryHiddenDocuments}
            removeMemoryHiddenDocument={removeMemoryHiddenDocument}
            memoryLeftDocuments={memoryLeftDocuments}
            removeMemoryLeftDocument={removeMemoryLeftDocument}
            memoryDeletedDocuments={memoryDeletedDocuments}
            removeMemoryDeletedDocument={removeMemoryDeletedDocument}
            generateNewIdToken={generateNewIdToken}
            typographyModalOpen={typographyModalOpen}
            setTypographyModalOpen={setTypographyModalOpen}
            onTypographyModalBlur={onTypographyModalBlur}
            aboutModalOpen={aboutModalOpen}
            setAboutModalOpen={setAboutModalOpen}
            onAboutModalBlur={onAboutModalBlur}
            setSidebarOpen={setSidebarOpen}
            onSidebarBlur={onSidebarBlur}
            hideUiWhileWriting={hideUiWhileWriting}
            hideUiWhileWritingSetCodeMirror={hideUiWhileWritingSetCodeMirror}
            docs={docs}
            userDocIsSynced={userDocIsSynced}
            availableExports={availableExports}
            activatePlugin={activatePlugin}
            deactivatePlugin={deactivatePlugin}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Router>

        <PositionedDocsSidebar
          docs={docs}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
          visible={isInApp && !hideSidebar && !_isLoggingIn}
          hideUiWhileWriting={hideUiWhileWriting}
          userData={userData}
          userDoc={userDoc}
          isLoggedIn={!!userToken}
          onDocContextMenu={onDocContextMenu}
          sidebarRefreshHash={sidebarRefreshHash}
          showLoginSection={!userToken && !isOnWorkspacePage}
          isBehindModal={typographyModalOpen || aboutModalOpen}
          tempDocs={tempDocs}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        <DeleteKluddModal
          open={!!onDeleteKludd}
          onClose={removeOnDeleteKludd}
          onDeleteKludd={onDeleteKludd}
        />

        <MoveKluddsModal
          open={isSigningIn === 'anonymousOnly' && userEmailVerified}
          onClose={() => {
            setIsSigningIn(false)
            setAnonymousDocuments([]) // clear anon docs so we don't get modal twice if we don't import anon docs

            // Broadcast clearJustSignedIn so all tabs (including this one) closes import modals
            bc.publish(SHARED_AUTH_BC_CHANNEL, 'clearJustSignedIn')
          }}
          anonymousDocuments={anonymousDocuments}
          generateNewIdToken={generateNewIdToken}
          userDoc={userDoc}
        />

        {!isOnContentPage && ( // hidden here to show terms when signing in
          <MyProfileModal
            user={user}
            userData={userData}
            providerId={providerId}
            open={myProfileOpen || isSigningIn === true}
            onClose={(success) => {
              setMyProfileOpen(false)
              setIsSigningIn(false)
              setAnonymousDocuments([]) // clear anon docs so we don't get modal twice if we don't import anon docs

              // Broadcast clearJustSignedIn so all tabs (including this one) closes import modals
              bc.publish(SHARED_AUTH_BC_CHANNEL, 'clearJustSignedIn')

              if (success !== true && isSigningIn) {
                logout()
              }
            }}
            logout={logout}
            updateProfile={updateProfile}
            uploadAvatarImage={uploadAvatarImage}
            isSigningIn={isSigningIn}
            anonymousDocuments={anonymousDocuments}
            hasAcceptedToS={hasAcceptedToS}
            acceptToS={acceptToS}
            generateNewIdToken={generateNewIdToken}
            userDoc={userDoc}
          />
        )}

        <PositionedTypographySettingsModal
          userData={userData}
          open={typographyModalOpen}
          onClose={() => setTypographyModalOpen(false)}
          editorSettings={editorSettings}
          setEditorSettings={setEditorSettings}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          minimalistMode={minimalistMode}
          setMinimalistMode={setMinimalistMode}
        />

        <EmailAuthModal
          open={emailAuthModalOpen}
          onClose={() => setEmailAuthModalOpen(false)}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          updateProfile={updateProfile}
          uploadAvatarImage={uploadAvatarImage}
          acceptToSForUserId={acceptToSForUserId}
        />

        {docContextMenu && (
          <ContextMenu
            top={docContextMenu.top}
            left={docContextMenu.left}
            options={docContextMenu.options}
            title={docContextMenu.title}
            context={docContextMenu.context}
            onClose={closeDocContextMenu}
          />
        )}

        {showCookieBar && (
          <CookieCompliance hide={hideCookieBar} onAcceptCookies={onAcceptCookies} />
        )}

        {activatedPlugins.map(([id, PluginComponent]) => (
          <PluginComponent key={id} />
        ))}

        <BottomBar
          show={!!undoDeleteDocument}
          sidebarOpen={sidebarOpen || typographyModalOpen}
          bigSidebarOpen={_bigSidebarOpen}
          icon={<SvgIcon icon='hand-waving' />}
          options={[{ label: 'Undo', onClick: undoDeleteDocument }]}
          closeAction={removeUndoDeleteDocument}
        >
          <p>You <strong>deleted</strong> a kludd</p>
        </BottomBar>

        <BottomBar
          show={!!undoLeaveDocument}
          sidebarOpen={sidebarOpen || typographyModalOpen}
          bigSidebarOpen={_bigSidebarOpen}
          icon={<SvgIcon icon='hand-waving' />}
          options={[{ label: 'Undo', onClick: undoLeaveDocument }]}
          closeAction={removeUndoLeaveDocument}
        >
          <p>You <strong>left</strong> a kludd</p>
        </BottomBar>
      </div>
    </PluginDataContext.Provider>
  )
}

const PositionedDocsSidebar = styled(DocsSidebar)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
  @media (min-width: 650px) {
    padding: 2rem 0 2rem 2rem;
    width: 26rem;
  }
  @media (min-width: 1000px) {
    padding: 3rem 0 3rem 3rem;
    width: 27rem;
  }
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10;
`
const PositionedTypographySettingsModal = styled(TypographySettingsModal)`
  z-index: 11;
  position: absolute;
  inset: 0;
  width: 100%;
  @media (min-width: 650px) {
    top: 3rem;
    left: 3rem;
    bottom: 3rem;
    width: 24rem;
  }
`
