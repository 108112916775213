// Remove weird characters on paste. E.g. Figma adds a \u2028 for newlines.
// Intended to be used as listener to CodeMirror's .on('beforeChange')
export default function handleRemovingWeirdCharactersBeforeCodeMirrorChange (cm, change) {
  if (change.origin === 'paste') {
    let somethingWasReplaced = false

    const newText = change.text.reduce((result, line) => {
      const splitLine = line.split('\u2028')

      if (splitLine.length > 1) {
        somethingWasReplaced = true
      }

      return result.concat(splitLine)
    }, [])

    if (somethingWasReplaced) {
      change.update(null, null, newText)
    }
  }
}
