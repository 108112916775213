import addHttpToUrlIfMissing from 'utils/addHttpToUrlIfMissing'

const createBookmarkAnchor = (url) => {
  // Container with a relative positioning in the text flow...
  const container = document.createElement('span')
  container.className = 'floatingAnchorContainer'

  // ...so that we can absolute position/float the anchor link itself.
  const anchor = document.createElement('a')
  anchor.href = addHttpToUrlIfMissing(url)
  anchor.className = 'floatingAnchor'
  anchor.target = '_blank'
  anchor.rel = 'noopener'

  // The icon
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttribute('d', 'M2.25 5A2.75 2.75 0 015 2.25h1.75a.75.75 0 010 1.5H5c-.69 0-1.25.56-1.25 1.25v8c0 .69.56 1.25 1.25 1.25h8c.69 0 1.25-.56 1.25-1.25v-1.75a.75.75 0 011.5 0V13A2.75 2.75 0 0113 15.75H5A2.75 2.75 0 012.25 13V5zm8.25-1.25a.75.75 0 010-1.5H15a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V4.81L9.53 9.53a.75.75 0 01-1.06-1.06l4.72-4.72H10.5z')

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('fill', 'currentColor')
  svg.setAttribute('viewBox', '0 0 18 18')

  svg.appendChild(path)
  anchor.appendChild(svg)
  container.appendChild(anchor)

  return container
}

let bookmark

/**
 * Will insert an anchor link if the the cursor is on a link type span.
 * @param {object} cm CodeMirror instance.
 */
export const updateFloatingAnchorOnCursorActivity = (cm) => {
  const cursor = cm.getCursor()
  const tokenType = cm.getTokenTypeAt(cursor)

  // Remove any previous floating anchor/mark if there are any (perhaps look into a caching solution if performance turns out to be a problem)
  if (bookmark) {
    bookmark.clear()
  }

  if (tokenType === 'link') {
    const linkTokenSpan = getTokenSpanByType(cm, 'link')
    const url = cm.getRange({ line: cursor.line, ch: linkTokenSpan.from }, { line: cursor.line, ch: linkTokenSpan.to })
    const anchorLink = createBookmarkAnchor(url)
    bookmark = cm.doc.setBookmark(
      {
        line: cursor.line,
        ch: linkTokenSpan.to
      }, {
        widget: anchorLink
      }
    )
  }
}

/**
 * This will step through all tokens before and after the current cursor position and determine when a series of tokens start/stop.
 * @param {object} cm CodeMirror instance.
 * @param {string} type Token type to look for.
 */
const getTokenSpanByType = (cm, type) => {
  const cursor = cm.getCursor()
  const line = cm.getLine(cursor.line)
  let currentCh = cursor.ch

  // Find the start
  let from
  while (from === undefined) {
    const prevChType = cm.getTokenTypeAt({ line: cursor.line, ch: currentCh })
    if (prevChType !== type || currentCh === 0) { // either we wound it or we reached the SOL
      from = currentCh
    } else {
      currentCh--
    }
  }

  // Reset iterator
  currentCh = cursor.ch

  // Find the end
  let to
  while (to === undefined) {
    const nextChType = cm.getTokenTypeAt({ line: cursor.line, ch: currentCh })

    if (nextChType !== type) {
      // Found it!
      to = currentCh - 1
    } else if (currentCh === line.length) {
      // Reached EOL
      to = currentCh
    } else {
      currentCh++
    }
  }

  return {
    from,
    to
  }
}
