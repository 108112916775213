import { useState, useCallback } from 'preact/hooks'
import styled from 'styled-components'
import DocCardWithOfflineCheck from 'components/DocCardWithOfflineCheck'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import SvgIcon from 'components/SvgIcon'
import EmptyViewNotice from 'components/EmptyViewNotice'
import goToNewDoc from 'utils/goToNewDoc'
import shouldShowDot from 'utils/shouldShowDot'
import useSortedDocs from 'hooks/useSortedDocs'
import useSearchFilteredDocs from 'hooks/useSearchFilteredDocs'
import BigRadioButtons from './BigRadioButtons'
import { useFavoriteFilteredDocs, useFavoriteToggle } from '../hooks/useFavoriteDocs'

// const exampleDocument = {
//   preview: 'Testdokument text\nny rad\nny rad\nnyare rad\nännu en rad\nosv',
//   latestUpdateTimestamp: 0
// }

const LIST_ALL = 'all'
const LIST_FAVORITES = 'favorites'

export default function DocsSidebar ({
  open,
  visible,
  hideUiWhileWriting,
  setOpen,
  isBehindModal,
  docs,
  tempDocs,
  onDocContextMenu,
  userData,
  isLoggedIn,
  sidebarRefreshHash,
  showLoginSection,
  userDoc,
  searchQuery,
  setSearchQuery,
  ...props
}) {
  const [useHeaderShadow, setUseHeaderShadow] = useState(false)
  const [list, setList] = useState(LIST_ALL)
  const sortedDocs = useSortedDocs(docs, tempDocs)
  const [favoriteKeys, favoriteDocs] = useFavoriteFilteredDocs(sortedDocs, userDoc)
  const filteredDocs = useSearchFilteredDocs(list === LIST_ALL ? sortedDocs : favoriteDocs, searchQuery)
  const onFavorite = useFavoriteToggle(userDoc)

  const onScroll = useCallback((el) => {
    if (!el || !el.target) return
    const { scrollTop } = el.target
    setUseHeaderShadow(scrollTop > 10)
  }, [setUseHeaderShadow])

  const onFly = useCallback(() => setOpen(true), [setOpen])

  return (
    <View {...props} open={open} visible={visible}>
      <Fly
        onClick={onFly}
        visible={!open && !hideUiWhileWriting}
      >
        <SvgIcon icon='sidebar-out' />
      </Fly>
      <ActionButton showLoginSection={showLoginSection} onClick={() => goToNewDoc(sortedDocs.length === 0, userData, userDoc)}>
        <SvgIcon icon='add' />
      </ActionButton>
      <Inner isBehindModal={isBehindModal} onScroll={onScroll}>
        <Header shadow={useHeaderShadow}>
          {userData && (
            <AvatarButton href='/workspaces'>
              <Avatar avatar={userData.avatar} seed={userData.userId} />
            </AvatarButton>
          )}
          <SearchInput
            type='search'
            placeholder='Search'
            value={searchQuery}
            onInput={e => setSearchQuery(e.currentTarget.value)}
          />
          <Close onClick={() => setOpen(false)}>
            <SvgIcon icon='arrow-back' />
          </Close>
        </Header>
        <Body showLoginSection={showLoginSection}>
          {/*
          <MarginedDocCard
            href='/abc'
            doc={exampleDocument}
            onContextMenu={onDocContextMenu}
            {...exampleDocument}
          />
          <MarginedDocCard
            href='/abc2'
            doc={exampleDocument}
            onContextMenu={onDocContextMenu}
            {...exampleDocument}
            isTemp
            tempMessage={{
              title: '* Kludd hidden *',
              subtitle: 'You can find it in your <a href="/">workspace</a>'
            }}
          />
          <MarginedDocCard
            href='/abc2'
            doc={exampleDocument}
            onContextMenu={onDocContextMenu}
            {...exampleDocument}
            isTemp
            isRemoved
            tempMessage={{
              title: '* Kludd hidden *',
              subtitle: 'You can find it in your <a href="/">workspace</a>'
            }}
          />
          */}
          <ListSwitch
            value={list}
            onChange={setList}
            options={[
              { value: LIST_ALL, label: 'All kludds' },
              { value: LIST_FAVORITES, label: 'Favorites' }
            ]}
          />

          <DocGrid>
            {filteredDocs
              .map(([key, doc]) => (
                <MarginedDocCard
                  key={key}
                  href={`/${key}`}
                  doc={{ key, ...doc }}
                  onContextMenu={onDocContextMenu}
                  showDot={isLoggedIn && shouldShowDot(doc)}
                  sidebarRefreshHash={sidebarRefreshHash}
                  onFavorite={favoriteKeys.has(key) && (() => onFavorite(key))}
                  searchQuery={searchQuery}
                  {...doc}
                />
              ))}
          </DocGrid>

          {list === LIST_FAVORITES && filteredDocs.length === 0 && (
            <EmptyViewNotice
              icon='favorite'
              iconColor='#ffb82e'
              message={<p>You don't have any favorites saved yet.</p>}
            />
          )}
        </Body>
        {showLoginSection && (
          <LoginSection>
            <LoginText>Sign in in order to save your kludds to your account.</LoginText>
            <LoginButton
              icon={<SvgIcon icon='sign-in' />}
              tighterIconMargin
              href='/workspaces'
              label='Sign in'
            />
          </LoginSection>
        )}
      </Inner>
    </View>
  )
}

const View = styled.div`
  position: relative;
  transition: transform .2s ease, opacity .5s ease, visibility .5s ease;
  ${p => !p.open && 'transform: translateX(-100%);'}
  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}
  @media print { display: none; }
`
// -webkit-mask-image is overflow fix for Safari bug
const Inner = styled.div`
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: background .3s ease, color .3s ease, transform .3s ease;
  background: white;
  color: #3d4f50;
  .darkMode & { background: #1d232b; color: #f3f9fe; }
  height: 100%;
  border-radius: 2rem;
  position: relative;
  padding: 0 2rem 2rem;
  box-shadow: 0 0 3rem rgba(61, 79, 80, 0.05);
  transform-origin: left;
  ${p => p.isBehindModal && 'transform: translateX(-1rem) scale(0.95);'}
`
const Header = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  padding: 2rem;
  margin: 0 -2rem 2rem;
  box-shadow: none;
  color: inherit;
  height: 7.1rem;
  position: sticky;
  top: 0;
  z-index: 3;
  background: inherit;

  &:after {
    content: '';
    height: 0.1rem;
    transition: background .3s ease;
    background: rgba(61, 79, 80, 0.1);
    position: absolute;
    bottom: 0;
    left: 2rem;
    right: 2rem;
  }
  .darkMode &:after { background: rgba(255, 255, 255, 0.1); }

  ${p => p.shadow && `
    &:after, .darkMode &:after { background: transparent; }
    box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
    .darkMode & { box-shadow: 0 -0.4rem 2rem rgba(255, 255, 255, 0.1); }
  `}
`
// const HomeSpace = styled.a`
//   text-decoration: none;
//   color: inherit;
//   opacity: 0.5;
//   font-size: 1.2rem;
//   line-height: 1.4rem;
//   display: inline-flex;
//   align-items: center;
//
//   > span { text-decoration: underline; margin-right: 0.5rem; }
//   > svg { transform: translateY(0.1rem); }
// `
const Close = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  align-self: stretch;
  margin-right: -2rem;
  padding-right: 2rem;

  > svg { display: block }
`
const AvatarButton = styled.a`
  display: block;
  position: relative;
  margin-right: 1rem;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    border-radius: 50%;
    transition: opacity .2s ease;
    background: rgba(255, 255, 255, 0.8) no-repeat center;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 5C1.75 3.75736 2.75736 2.75 4 2.75H14C15.2426 2.75 16.25 3.75736 16.25 5V11C16.25 12.2426 15.2426 13.25 14 13.25H4C2.75736 13.25 1.75 12.2426 1.75 11V5Z' fill='%233d4f50' fill-opacity='0.05' stroke='%233d4f50' stroke-width='1.5'/%3E%3Cpath d='M14.25 15.75C14.25 15.75 11.2306 13.875 9 13.875C6.76939 13.875 3.75 15.75 3.75 15.75' stroke='%233d4f50' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    opacity: 0;
  }
  .darkMode &:after {
    background-color: rgba(0, 0, 0, 0.8);
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 5C1.75 3.75736 2.75736 2.75 4 2.75H14C15.2426 2.75 16.25 3.75736 16.25 5V11C16.25 12.2426 15.2426 13.25 14 13.25H4C2.75736 13.25 1.75 12.2426 1.75 11V5Z' fill='%23fff' fill-opacity='0.05' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath d='M14.25 15.75C14.25 15.75 11.2306 13.875 9 13.875C6.76939 13.875 3.75 15.75 3.75 15.75' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after { opacity: 1; }
`
const Body = styled.div`
  padding-bottom: ${p => p.showLoginSection ? '14.5rem' : '4rem'}; // 14.5 is the max-height of a DocCard.
`
const MarginedDocCard = styled(DocCardWithOfflineCheck)`
  @media (min-width: 650px) {
    &:not(:last-child){ margin-bottom: 2rem; }
  }  
`
const Fly = styled.a`
  position: absolute;
  left: calc(100% + 2rem);
  top: 2rem;
  @media (min-width: 1000px) {
    left: calc(100% + 3rem);
    top: 3rem;
  }
  transition: background .3s ease, opacity .3s ease, visibility .3s ease, transform .3s ease;
  background: white;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  .darkMode & { background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg { display: block }

  ${p => !p.visible && 'opacity: 0; visibility: hidden; transform: translateX(0rem);'}

  @media (max-width: 649px) {
    left: calc(100% + 1.5rem);
    top: 1.5rem;
  }
`
const ActionButton = styled.a`
  flex: none;
  display: flex;
  background: #3d4f50;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  color: white;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.4rem;
  > svg { margin-right: 0.1rem; }
  transition: background .3s ease, color .3s ease;
  .darkMode & { background: #f3f9fe; color: #181d23; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }

  position: absolute;
  z-index: 1;
  bottom: 3.5rem;
  ${p => p.showLoginSection && 'bottom: 15.7rem;'}
  right: 1.5rem;
  @media (min-width: 1000px) {
    bottom: 4.5rem;
    ${p => p.showLoginSection && 'bottom: 16.7rem;'}
  }
`
const SearchInput = styled.input`
  min-width: 0;
  font-size: 1.2rem;
  flex: 1;
  margin-right: 0.7rem;
  appearance: none;
  border: 1px solid white;
  background: white;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%233d4f50' fill-opacity='0.2' stroke='%233d4f50' stroke-width='1.5'/%3E%3Cpath d='M12.5 12.5L15 15' stroke='%233d4f50' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 0.6rem;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  color: #3d4f50;
  height: 3rem;
  border-radius: 5rem;
  padding: 0 1rem 0 2.9rem;
  transition: background .3s ease, color .3s ease, box-shadow .3s ease, border .3s ease;

  // 16px on touch device to avoid zoom-in on iOS
  @media (-moz-touch-enabled:1), (pointer:coarse) {
    font-size: 1.6rem;
  }

  &:focus {
    outline: none;
    border-color: rgba(61, 79, 80, 0.2);
  }

  ::placeholder,
  ::-webkit-input-placeholder { color: inherit; opacity: 0.4; }
  :-ms-input-placeholder { color: inherit; opacity: 0.4; }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  .darkMode & {
    border-color: #242b33;
    background-color: #242b33;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%23fff' fill-opacity='0.2' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath d='M12.5 12.5L15 15' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    color: #f3f9fe;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);

    &:focus {
      border-color: rgba(243, 249, 254, 0.1);
    }
  }
`
const LoginSection = styled.div`
  padding: 2rem;
  margin: auto -2rem -2rem;
  background: inherit;
  position: sticky;
  bottom: -2rem;
  box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 3;

  .darkMode & {
    box-shadow: 0 -0.4rem 2rem rgba(0, 0, 0, 0.15);
  }
`
const LoginText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: center;
  margin: 0 auto 1rem;
  max-width: 17rem;
`
const LoginButton = styled(Button)``

const ListSwitch = styled(BigRadioButtons)`
  margin-bottom: 2rem;
`
const DocGrid = styled.div`
  @media (max-width: 649px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-gap: 1.6rem;
  }
`
