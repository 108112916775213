import styled from 'styled-components'
import KeyboardKey from 'components/KeyboardKey'
import isMacLike from 'utils/isMacLike'
import { CONTACT_MAIL } from '@constants'

export default function FeedbackMessage (props) {
  return (
    <Wrapper {...props}>
      <Inner>
        <Paragraph hideOnMobile>
          You can start a new kludd by pressing

          <Shortcut>
            {
              isMacLike()
                ? <KeyboardKey square>⌘</KeyboardKey>
                : <KeyboardKey>Ctrl</KeyboardKey>
            }
            <PlusIcon width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10 5.82353H5.86275V10H4.11765V5.82353H0V4.19608H4.11765V0H5.86275V4.19608H10V5.82353Z' fill='currentColor' />
            </PlusIcon>
            <KeyboardKey square>K</KeyboardKey>
          </Shortcut>
        </Paragraph>

        <Title>We are continuously improving Kludd!</Title>

        <Paragraph>
          Hang out with us on{' '}
          <a href='https://reddit.com/r/kludd' target='_blank' rel='noreferrer'>
            reddit.com/r/kludd
          </a>{' '}
          and let us know what you like (or don’t) or what you might be missing.
          You can also contact us at{' '}
          <a href={`mailto:${CONTACT_MAIL}`}>{CONTACT_MAIL}</a>.
        </Paragraph>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.section``
const Inner = styled.div`
  text-align: center;
  padding: 3rem;
  position: relative;

  &:before {
    content: '';
    background: currentColor;
    display: block;
    height: 0.1rem;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`
const Paragraph = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  opacity: 0.8;
  max-width: 49rem;
  margin: 0 auto;

  > a { color: #366668; }

  &:not(:last-child) { margin-bottom: 2.5rem; }
  ${p => p.hideOnMobile && '@media (max-width: 649px) { display: none; }'}
`
const Shortcut = styled.span`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  margin-left: 1rem;
`
const PlusIcon = styled.svg`
  margin: 0 1rem;
`
const Title = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 1rem;
  opacity: 0.8;
`
