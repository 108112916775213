import styled, { css } from 'styled-components'

export default ({ label, type, className, title, ...props }) => {
  const Element = type ? Button : LinkButton
  const classNames = [
    className,
    props.disabled && 'is-disabled'
  ]
    .filter(v => v)
    .join(' ')

  return (
    <Element
      type={type}
      title={title || (typeof label === 'string' ? label : undefined)}
      className={classNames}
      {...props}
    >
      {label}
    </Element>
  )
}

const sharedStyle = css`
  appearance: none;
  background: transparent;
  border: none;

  color: inherit;
  font: inherit;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 500;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    right: 0;
    height: 0.1rem;
    background: currentColor;
    opacity: 0.2;
    transition: opacity .2s ease;
  }

  &:hover:after {
    opacity: 0.8;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`

const LinkButton = styled.a`
  ${sharedStyle}
`

const Button = styled.button`
  ${sharedStyle}
`
