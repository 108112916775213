import styled from 'styled-components'
import { useRef, useEffect, useState, useCallback } from 'preact/hooks'
import { throttle } from 'throttle-debounce'
import { TITLE_FONT_FAMILY } from '@constants'
import getIndexInLoopingArray from 'utils/getIndexInLoopingArray'

const GridItem = ({ image, title, text, cta, ...props }) => (
  <Item {...props}>
    <Image src={image} />
    <Title>{title}</Title>
    <Text>{text}</Text>
    <Cta href={cta.href} target='_blank' rel='noreferrer'>{cta.label}</Cta>
  </Item>
)

export default function StorySampleGrid ({
  items,
  ...props
}) {
  const [selected, setSelected] = useState(0)
  const [desktop, setDesktop] = useState(false)
  const scrollRef = useRef()
  const innerRef = useRef()

  // Determine selected index on mobile scroll
  useEffect(() => {
    if (desktop) return

    const el = scrollRef.current
    const inner = innerRef.current

    const onScroll = throttle(100, () => {
      const refX = el.offsetWidth / 2 + el.scrollLeft
      let closestI = 0
      let closestDist = Infinity

      for (let i = 0; i < inner.children.length; i++) {
        const child = inner.children[i]
        const x = child.offsetLeft + child.offsetWidth / 2
        const dist = Math.abs(refX - x)

        if (dist < closestDist) {
          closestI = i
          closestDist = dist
        }
      }

      setSelected(closestI)
    })

    onScroll()

    el.addEventListener('scroll', onScroll)
    return () => el.removeEventListener('scroll', onScroll)
  }, [desktop])

  // Determine desktop or mobile view
  useEffect(() => {
    const onResize = throttle(100, () => {
      if (!desktop && window.innerWidth >= 1000) {
        setDesktop(true)
      } else if (desktop && window.innerWidth < 1000) {
        setDesktop(false)
      }
    })

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('scroll', onResize)
  }, [desktop])

  // Desktop callbacks
  const onPrev = useCallback(() => setSelected(s => s - 1), [])
  const onNext = useCallback(() => setSelected(s => s + 1), [])

  if (desktop) {
    // Desktop view is handle by js
    const indexes = Array.from({ length: 5 }, (_, i) => selected + i - 2)

    return (
      <DesktopView {...props}>
        <DesktopInner
          style={{
            transform: `translateX(calc(${selected * -50}vw))`
          }}
        >
          {indexes.map(index => (
            <GridItem
              key={index}
              selected={index === selected}
              style={{
                left: (25 + 50 * index) + 'vw'
              }}
              {...items[getIndexInLoopingArray(items.length, index)]}
            />
          ))}
        </DesktopInner>

        <ArrowLeft onClick={onPrev}>
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.25 14.625L5.625 9L11.25 3.375' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
        </ArrowLeft>

        <ArrowRight onClick={onNext}>
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6.75 3.375L12.375 9L6.75 14.625' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
        </ArrowRight>
      </DesktopView>
    )
  }

  // Mobile view is scrollable
  return (
    <View ref={scrollRef} {...props}>
      <Inner ref={innerRef}>
        {items.map((item, i) => (
          <GridItem
            key={i}
            selected={selected === i}
            {...item}
          />
        ))}
      </Inner>
    </View>
  )
}

const View = styled.div`
  border-top: 0.1rem solid #e1dede;
  overflow: auto;
  scroll-snap-type: x proximity;
`
const DesktopView = styled.div`
  border-top: 0.1rem solid #e1dede;
  overflow: hidden;
  position: relative;
`
const Inner = styled.div`
  display: flex;

  &:before, &:after {
    content: '';
    display: block;
    flex: none;
    width: 3rem;
  }

  @media (min-width: 1000px) {
    &:before { width: 25vw; }
    &:after { width: calc(25vw - 0.1rem); } // calculate without border
  }
`
const DesktopInner = styled.div`
  transition: transform .3s ease;
  position: relative;
  height: 57rem;

  > article {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
  }
`
const Item = styled.article`
  scroll-snap-align: center;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  border-left: 0.1rem solid #e1dede;
  transition: opacity .2s ease;

  @media (max-width: 999px) {
    &:last-child { border-right: 0.1rem solid #e1dede; }
  }

  width: calc(100vw - 6rem);
  @media (min-width: 1000px) {
    width: 50vw;
    padding: 5rem 4.8rem;
  }

  ${p => !p.selected && 'opacity: 0.4;'}
`
const Image = styled.img`
  max-width: 11.2rem;
  height: auto;
  box-shadow: -0.5rem 0.7rem 1rem rgba(61, 79, 80, 0.29);
  display: block;
  margin-bottom: 4rem;
`
const Title = styled.h3`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 3rem;
  margin-bottom: 1rem;
`
const Text = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  opacity: 0.6;
  max-width: 52rem;
  margin-bottom: 4rem;
`
const Cta = styled.a`
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #366668;
`
const Arrow = styled.a`
  color: #3d4f50;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 7.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg { display: block; }
`
const ArrowLeft = styled(Arrow)`left: 25vw;`
const ArrowRight = styled(Arrow)`right: 25vw;`
