import styled from 'styled-components'

export default ({ customStyle, style, className, items, shorterFirstRow, topBorderDesktop = true, darkGrid, darkerGrid, lightGrid, twoGridMobile }) => (
  <Grid
    topBorderDesktop={topBorderDesktop}
    shorterFirstRow={shorterFirstRow}
    twoGridMobile={twoGridMobile}
    dark={darkGrid}
    darker={darkerGrid}
    style={style}
    className={className}
    customStyle={customStyle}
  >
    {items.map((item, i) => (
      <GridChild
        key={`grid-${i}`}
        style={item && item.style}
        dark={darkGrid}
        darker={darkerGrid}
        light={lightGrid}
        desktopOnly={item && item.desktopOnly}
        mobileOnly={item && item.mobileOnly}
        column={item && item.column}
        row={item && item.row}
        centered={item && item.centered}
        right={item && item.right}
        bottom={item && item.bottom}
        mobileOrder={item && item.mobileOrder}
        empty={item ? (item.keepOnMobile ? false : !item.content) : true}
      >
        {item && item.content}
      </GridChild>
    ))}
  </Grid>
)

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background: ${p => (p.dark || p.darker) ? '#161B22' : '#E1DEDE'};

  ${p => p.twoGridMobile && `
    @media (max-width: 999px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: calc(50vw);
      grid-gap: .1rem;
    }
  `}

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: ${p => p.shorterFirstRow ? '15vw 25vw' : '25vw'};
    grid-gap: .1rem;
    ${p => p.topBorderDesktop && `border-top: .1rem solid ${(p.dark || p.darker) ? '#161B22' : '#E1DEDE'};`}
  }

  ${p => p.customStyle}
`

const GridChild = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  background: ${p => p.dark ? '#303840' : p.darker ? '#22282F' : p.light ? '#f4f4f4' : '#F8F8F9'};
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 19rem;
  ${p => p.empty && 'display: none;'}
  ${p => p.column && `grid-column: span ${p.column};`}
  ${p => p.row && `grid-row: span ${p.row};`}
  ${p => p.centered && `
    justify-content: center;
    align-items: center;
  `}
  ${p => p.right && 'justify-content: flex-end;'}
  ${p => p.bottom && 'align-items: flex-end;'}

  @media (max-width: 999px) {
    border-top: .1rem solid ${p => (p.dark || p.darker) ? '#161B22' : '#E1DEDE'};
    ${p => p.mobileOrder && `order: ${p.mobileOrder};`}
  }

  @media (min-width: 1000px) {
    ${p => p.empty && 'display: block;'}
  }

  ${p => p.desktopOnly && '@media (max-width: 999px) { opacity: 0,1; display: none; }'}
  ${p => p.mobileOnly && '@media (min-width: 1000px) { display: none; }'}
`
