import { useState, useEffect, useCallback } from 'preact/hooks'

export default function useExtractUserAcceptedTerms (userDoc) {
  const [hasAcceptedToS, _setHasAcceptedToS] = useState(false)
  const [futureUserIdsToAccept, setFutureUserIdsToAccept] = useState([])

  useEffect(() => {
    const onUpdate = () => {
      _setHasAcceptedToS(!!userDoc.metadataType.get('acceptedToS'))
    }

    onUpdate()
    userDoc.doc.on('update', onUpdate)
    userDoc.doc.on('snapshot', onUpdate)

    return () => {
      userDoc.doc.off('update', onUpdate)
      userDoc.doc.off('snapshot', onUpdate)
    }
  }, [userDoc])

  const acceptToS = useCallback(() => {
    const timestamp = Date.now()

    userDoc.metadataType.set('acceptedToS', timestamp)
  }, [userDoc])

  // Accept for specific user id, to be able to accept before the userdoc exists (e.g. when signing up with email, to avoid race conditions)
  const acceptToSForUserId = useCallback((userId) => {
    setFutureUserIdsToAccept(ids => ids.concat(userId))
  }, [])

  useEffect(() => {
    const userId = userDoc.docId

    // If current userDoc exists in future userIds, we can accept and remove from array
    if (futureUserIdsToAccept.includes(userId)) {
      acceptToS()
      setFutureUserIdsToAccept(ids => ids.filter(id => id !== userId))
    }
  }, [userDoc, futureUserIdsToAccept])

  return [hasAcceptedToS, acceptToS, acceptToSForUserId]
}
