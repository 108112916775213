import { useEffect, useState } from 'preact/hooks'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import Modal from 'components/Modal'
import takeOwnershipOfDocuments from 'utils/takeOwnershipOfDocuments'

export default ({
  providerId,
  open,
  onClose,
  logout,
  updateProfile,
  uploadAvatarImage,
  hasAcceptedToS,
  acceptToS,
  anonymousDocuments,
  generateNewIdToken,
  userDoc,
  ...props
}) => {
  const [takeAnonymousDocuments, setTakeAnonymousDocuments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // Reset form on open
    if (open) {
      setTakeAnonymousDocuments(false)
    }
  }, [open])

  const onSubmit = (ev) => {
    ev.preventDefault()

    // Just close if submitting without taking documents
    if (!takeAnonymousDocuments) {
      onClose()
      return
    }

    // Everything async except preventDefault
    ;(async function () {
      setIsLoading(true)

      if (anonymousDocuments && takeAnonymousDocuments) {
        await takeOwnershipOfDocuments(anonymousDocuments, generateNewIdToken, userDoc)
      }

      onClose()
      setIsLoading(false)
    }())
  }

  return (
    <Modal
      title='Psst... new kludds found!'
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      includeCancelButton={false}
      innerStyle='padding: 3rem;'
      maxWidth='50.5rem'
      submitButton={{
        label: takeAnonymousDocuments ? 'Continue' : 'Continue anyway',
        loading: isLoading
      }}
      {...props}
    >
      <CheckboxWithLabel
        checked={takeAnonymousDocuments}
        onChecked={setTakeAnonymousDocuments}
        label={`Add kludds created without logging in to my account (we found ${anonymousDocuments?.length || 0})`}
      />
    </Modal>
  )
}
