import { useCallback, useMemo } from 'preact/hooks'

/**
 * Use this hook to retrieve the favorite docs as well as an index of all the favorite docs.
 */
export function useFavoriteFilteredDocs (docs, userDoc) {
  const filteredDocs = useMemo(() => {
    const favoriteKeys = new Set()

    // Loop through old way of reading favorites in case any old ones are left, so we can convert to new way
    userDoc.documentsMapType.forEach((element, key) => {
      if (element.isFavorite) {
        // Add to new favorites type
        userDoc.favoritesType.set(key, true)

        // Remove from old favorites type
        userDoc.documentsMapType.set(key, { ...element, isFavorite: false })
      }
    })

    // Add new type of favorites
    userDoc.favoritesType.forEach((isFavorite, key) => {
      if (isFavorite) favoriteKeys.add(key)
    })

    const favoriteDocs = docs.filter((doc) => favoriteKeys.has(doc[0]))

    return [favoriteKeys, favoriteDocs]
  }, [docs, userDoc])

  return filteredDocs
}

/**
 * Use this hook to toggle the favorite of a document.
 */
export function useFavoriteToggle (userDoc) {
  return useCallback((documentKey) => {
    const isFavorite = !!userDoc.favoritesType.get(documentKey)

    if (isFavorite) {
      userDoc.favoritesType.remove(documentKey)
    } else {
      userDoc.favoritesType.set(documentKey, true)
    }
  }, [userDoc])
}
