import styled from 'styled-components'
import thumb from 'images/thumb.svg'
import thumbDark from 'images/thumb-dark.svg'

export default function Slider ({
  min,
  max,
  step,
  value,
  onValue,
  ...props
}) {
  const amountSteps = (max - min) / step + 1
  const activeStep = (value - min) / step

  return (
    <Wrapper {...props}>
      <Input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={e => onValue(parseInt(e.currentTarget.value))}
      />
      <ValueHolder>
        <Value
          style={{ left: `${(activeStep / (amountSteps - 1)) * 100}%` }}
        >
          {value}
        </Value>
      </ValueHolder>
      <Steps>
        {Array.from({ length: amountSteps }).map((_, i) => (
          <Step active={i === activeStep} key={i} />
        ))}
      </Steps>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Input = styled.input`
  display: block;
  appearance: none;
  width: 100%;
  padding: 1.5rem 0 2.8rem;
  cursor: pointer;
  background: inherit;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 3.4rem;
    width: 3rem;
    border: none;
    background: url('${thumb}');
    .darkMode & { background: url('${thumbDark}'); }
    cursor: pointer;
    margin-top: ${0.2 - 3.4 / 2}rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }
  &::-moz-range-thumb {
    height: 3.4rem;
    width: 3rem;
    border: none;
    background: url('${thumb}');
    .darkMode & { background: url('${thumbDark}'); }
    cursor: pointer;
  }
  &::-ms-thumb {
    height: 3.4rem;
    width: 3rem;
    border: none;
    background: url('${thumb}');
    .darkMode & { background: url('${thumbDark}'); }
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.4rem;
    cursor: pointer;
    background: rgba(61, 79, 80, 0.2);
    border: none;
    border-radius: 0.4rem;
  }
  &:focus::-webkit-slider-runnable-track {
    background: rgba(61, 79, 80, 0.4);
  }
  &::-moz-range-track {
    width: 100%;
    height: 0.4rem;
    cursor: pointer;
    background: rgba(61, 79, 80, 0.2);
    border: none;
    border-radius: 0.4rem;
  }
  &::-ms-track {
    width: 100%;
    height: 0.4rem;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #2a6495;
    border: none;
    border-radius: 0.8rem;
  }
  &:focus::-ms-fill-lower {
    background: rgba(61, 79, 80, 0.2);
  }
  &::-ms-fill-upper {
    background: rgba(61, 79, 80, 0.2);
    border: none;
    border-radius: 0.8rem;
  }
  &:focus::-ms-fill-upper {
    background: rgba(61, 79, 80, 0.4);
  }
`
const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  margin-top: -0.4rem;
  padding: 0 1.3rem 0;
`
const Step = styled.div`
  flex: none;
  background: currentColor;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  transition: opacity .2s ease, transform .2s ease;
  ${p => !p.active && 'opacity: 0.6; transform: translateY(-0.5rem);'}
`
const ValueHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 3rem;
  pointer-events: none;
`
const Value = styled.p`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  padding-top: 0.7rem;
`
