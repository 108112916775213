const headerRegex = /^(#{1,6}) /
const blankLineRegex = /^\s*$/
const whitespaceRegex = /\s/

const isBlankLine = (line) => (
  !line || line.match(blankLineRegex)
)

export default function getMarkdownFormatStopStart (lines) {
  const state = {
    strong: false,
    em: false,
    del: false
  }
  const starts = { strong: [], em: [], del: [] }
  const stops = { strong: [], em: [], del: [] }

  const canOpen = (ch, before, after) => {
    const leftFlanking = !/\s/.test(after) && after !== ''
    return leftFlanking && before !== '\\'
  }

  const canClose = (ch, before, after) => {
    const rightFlanking = !/\s/.test(before) && before !== ''
    return (rightFlanking || after === '*') && before !== '\\'
  }

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]

    // First check for headers and blank lines
    if (
      headerRegex.test(line) ||
      isBlankLine(line)
    ) {
      if (state.strong) {
        stops.strong.push({ line: i - 1, ch: lines[i - 1].length })
      }
      if (state.em) {
        stops.em.push({ line: i - 1, ch: lines[i - 1].length })
      }
      if (state.del) {
        stops.del.push({ line: i - 1, ch: lines[i - 1].length })
      }

      state.strong = false
      state.em = false
      state.del = false
      continue
    }

    // Then check for rest
    let lastCh

    for (let j = 0; j < line.length; j++) {
      const ch = line.charAt(j)

      // Emphasis https://fountain.io/syntax#section-emphasis
      // See http://spec.commonmark.org/0.27/#emphasis-and-strong-emphasis
      if (lastCh !== '\\') {
        if (ch === '*' || ch === '_') {
          let len = 1

          // Special handling of closing italic + bold if both are active
          if (state.em && state.strong) {
            if (state.em > state.strong) {
              while (len < 1 && line.charAt(j + len) === ch) len++
            } else if (state.strong > state.em) {
              while (len < 2 && line.charAt(j + len) === ch) len++
            } else {
              while (len < 3 && line.charAt(j + len) === ch) len++
            }
          } else {
            while (len < 3 && line.charAt(j + len) === ch) len++
          }
          j += len - 1

          const nextCh = line.charAt(j + 1)

          // Special rules for handling toggling of strong/em when empty next line
          if (!state.strong && canOpen(ch, lastCh, nextCh) && len === 3 && nextCh === ch) {
            // Four **** means start then stop for strong
            starts.strong.push({ line: i, ch: j - 2 })
            stops.strong.push({ line: i, ch: j })
            j += 1
            continue
          } else if (len === 2 && !state.strong && (!nextCh || whitespaceRegex.test(nextCh)) && (!lastCh || whitespaceRegex.test(lastCh))) {
            // Two ** means start then stop for em
            starts.em.push({ line: i, ch: j - 1 })
            stops.em.push({ line: i, ch: j })
            continue
          }

          if (len === 1 || len === 3) {
            if (
              // Check if can close italic, and same char as opening italic
              (state.em && ch === state.emChar && canClose(ch, lastCh, nextCh)) ||
              // Or if we wan't to open an italic
              (!state.em && canOpen(ch, lastCh, nextCh, line, j, 1))
            ) {
              state.em = state.em ? false : j + 1
              state.emChar = state.em ? ch : undefined

              if (state.em) {
                starts.em.push({ line: i, ch: j - len + 1 })
              } else {
                stops.em.push({ line: i, ch: j })
              }
            }
          }

          if (len >= 2) {
            if (
              // Check if can close bold, and same char as opening bold
              (state.strong && ch === state.strongChar && canClose(ch, lastCh, nextCh)) ||
              // Or if we wan't to open an bold
              (!state.strong && canOpen(ch, lastCh, nextCh, line, j, 2))
            ) {
              state.strong = state.strong ? false : j + 1
              state.strongChar = state.strong ? ch : undefined

              if (state.strong) {
                starts.strong.push({ line: i, ch: j - 1 })
              } else {
                stops.strong.push({ line: i, ch: j - len + 1 })
              }
            }
          }
        } else if (ch === '~' && line.charAt(j + 1) === '~') {
          j += 1

          if (!state.del) {
            const nextCh = line.charAt(j + 1)
            if (nextCh && !/\s/.test(nextCh)) {
              state.del = true
              starts.del.push({ line: i, ch: j - 1 })
            }
          } else {
            state.del = false
            stops.del.push({ line: i, ch: j - 1 })
          }
        }
      }

      lastCh = ch
    }
  }

  // Close remaining open formats
  const i = lines.length
  if (state.strong) {
    stops.strong.push({ line: i - 1, ch: lines[i - 1].length })
  }
  if (state.em) {
    stops.em.push({ line: i - 1, ch: lines[i - 1].length })
  }
  if (state.del) {
    stops.del.push({ line: i - 1, ch: lines[i - 1].length })
  }

  return { starts, stops }
}
