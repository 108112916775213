import { useEffect } from 'preact/hooks'

const getOrCreateMetaTag = (name) => {
  let element = document.querySelector(`meta[name="${name}"]`)
  if (!element) {
    element = document.createElement('meta')
    element.setAttribute('name', name)
    document.getElementsByTagName('head')[0].appendChild(element)
  }
  return element
}

export default ({ title, description, image }) => {
  useEffect(() => {
    if (description != null) {
      const tags = [getOrCreateMetaTag('description'), getOrCreateMetaTag('og:description')]
      tags.forEach(t => t.setAttribute('content', description))
      return () => {
        tags.forEach(t => t.remove())
      }
    }
  }, [description])

  useEffect(() => {
    if (title != null) {
      const ogTitle = getOrCreateMetaTag('og:title')
      const titleElement = document.querySelector('title')
      ogTitle.setAttribute('content', title)
      titleElement.innerText = title
      document.title = title
      return () => {
        ogTitle.setAttribute('content', 'Kludd')
        titleElement.innerText = 'Kludd'
        document.title = 'Kludd'
      }
    }
  }, [title])

  useEffect(() => {
    if (image != null) {
      const tag = getOrCreateMetaTag('og:image')
      tag.setAttribute('content', image)
      return () => tag.remove()
    }
  }, [image])

  return <></>
}
