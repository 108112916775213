import { useState, useLayoutEffect } from 'preact/hooks'
import * as Y from 'yjs'
import YKluddMetadataMap from 'helpers/YKluddMetadataMap'

export default function useKluddYDoc (docId) {
  const [kluddDoc, setKluddDoc] = useState(null)

  useLayoutEffect(() => {
    const doc = new Y.Doc()
    const contentType = doc.getText('content')
    const metadataType = doc.get('metadata', YKluddMetadataMap)
    const commentsType = doc.getMap('comments')
    const pluginsType = doc.getMap('plugins')
    const usersCachedDataType = doc.getMap('usersCachedData')

    setKluddDoc({
      doc,
      docId,
      contentType,
      metadataType,
      commentsType,
      pluginsType,
      usersCachedDataType
    })

    return () => doc.destroy()
  }, [docId])

  return kluddDoc
}
