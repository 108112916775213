const MIN_IN_MS = 60000
const HOUR_IN_MS = 60 * MIN_IN_MS
const DAY_IN_MS = 24 * HOUR_IN_MS
const WEEK_IN_MS = 7 * DAY_IN_MS

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default (date, format) => {
  if (!date) return 'unknown date'

  date = new Date(date)

  if (format === 'YYYYMMDD') {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  }

  const now = new Date()
  const diff = now - date

  if (format === 'DAY') {
    if (diff < DAY_IN_MS) {
      return 'Today'
    } else if (diff < DAY_IN_MS * 2) {
      return 'Yesterday'
    }
  } else {
    if (diff < MIN_IN_MS) {
      return 'Just now'
    }

    if (diff < HOUR_IN_MS) {
      const minutes = Math.floor(diff / MIN_IN_MS)
      if (minutes === 1) return '1 min ago'
      return `${minutes} mins ago`
    }

    if (diff < DAY_IN_MS) {
      const hours = Math.floor(diff / HOUR_IN_MS)
      if (hours === 1) return '1 hour ago'
      return `${hours} hours ago`
    }
  }

  if (diff < WEEK_IN_MS) {
    const days = Math.floor(diff / DAY_IN_MS)
    if (days === 1) return '1 day ago'
    return `${days} days ago`
  }

  if (date.getFullYear() !== now.getFullYear()) {
    return `${months[date.getMonth()]} ${date.getDate()} - ${date.getFullYear()}`
  }

  return `${months[date.getMonth()]} ${date.getDate()}`
}
