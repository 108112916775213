import { TITLE_FONT_FAMILY } from '@constants'
import styled from 'styled-components'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Grid from 'components/Grid'
import Button from 'components/Button'
import SvgIcon from 'components/SvgIcon'
import SlackPreview from 'components/SlackPreview'

export default function Slack ({ isLoggedIn, docs, userData, userDoc }) {
  return (
    <View>
      <Header
        isLoggedIn={isLoggedIn}
        docs={docs}
        userData={userData}
        userDoc={userDoc}
      />

      <Grid
        topBorderDesktop={false}
        items={[null,
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner>
                <TitleIcon>
                  <SvgIcon icon='slack-color' width={48} />
                </TitleIcon>
                <HeroTitle>
                  <small>Introducing</small>
                  Kludd <em>+</em> Slack
                </HeroTitle>
                <ActionButton
                  label='Add to Slack'
                  href='https://futurememories.slack.com/apps/A02GLEG6PQR-kludd'
                  target='_blank'
                  rel='noreferrer'
                />
              </GridChildInner>
            )
          },
          null, null, null
        ]}
      />

      <InfiniteGrid
        items={[null,
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner>
                <Title><em>/</em>kludd</Title>
                <Text>Invite your team or a friend to a Kludd from Slack. Just type <strong>/kludd</strong> to start collaborating.</Text>
                <SlackPreview />
              </GridChildInner>
            )
          },
          null, null, null
        ]}
      />

      <Footer light />
    </View>
  )
}

const View = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 999px) {
    margin-top: -.1rem;
  }
`

const InfiniteGrid = styled(Grid)`
  grid-auto-rows: auto !important;
  grid-template-rows: 25vw auto !important;
`

const GridChildInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 3rem;
  min-height: 37.5rem;

  @media (min-width: 1000px) {
    padding: 12rem 3rem;
  }
`

const HeroTitle = styled.h1`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 4rem;
  line-height: 4.8rem;
  color: #3d4f50;
  font-weight: 600;

  small {
    display: block;
    font-weight: 400;
  }

  em {
    font-style: normal;
    font-weight: 400;
    opacity: 0.4;
  }

  @media (min-width: 1000px) {
    font-size: 6rem;
    line-height: 7rem;
  }
`

const ActionButton = styled(Button)`
  font-weight: 500;
  padding: 1.1rem 2.5rem;
  margin-top: 4rem;
  min-width: 14.7rem;
`

const Title = styled.h2`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 600;
  color: #3d4f50;

  em {
    font-style: normal;
    opacity: 0.4;
  }

  @media (min-width: 1000px) {
    font-size: 4rem;
    line-height: 5.4rem;
  }
`

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(61, 79, 80, 0.6);
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 43rem;

  strong { color: rgba(61, 79, 80, 1); font-weight: 600; }

  @media (min-width: 1000px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
`

const TitleIcon = styled.div`
  border: 1px solid #e1dede;
  border-radius: 1.5rem;
  padding: 0.9rem;
  color: #3d4f50;
  margin-bottom: 2rem;

  svg { display: block; }
`
