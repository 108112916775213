import { useState, useEffect, useCallback } from 'preact/hooks'
import styled from 'styled-components'
import firebase from 'firebase/app'
import * as bc from 'lib0/broadcastchannel'
import InformationWithIllustration from 'components/InformationWithIllustration'
import Loader from 'components/Loader'
import SvgIcon from 'components/SvgIcon'
import TextInput from 'components/TextInput'
import Label from 'components/Label'
import Button from 'components/Button'
import Message from 'routes/Message'
import { SHARED_AUTH_BC_CHANNEL } from 'hooks/useAuth'

// https://firebase.google.com/docs/auth/custom-email-handler
export default function AuthMessage ({ mode, oobCode, applyActionCode, signInWithEmailAndPassword, reloadUser, ...props }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [formError, setFormError] = useState(false)
  const [accountEmail, setAccountEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const hideSidebar = mode === 'resetPassword'

  const handleResetPasswordSubmit = useCallback((ev) => {
    ev.preventDefault()
    setLoading(true)

    firebase.auth().confirmPasswordReset(oobCode, newPassword).then((resp) => {
      setFormSubmitted(true)
      setLoading(false)

      // Password reset has been confirmed and new password updated.
      signInWithEmailAndPassword(accountEmail, newPassword)
    }).catch((error) => {
      // Error occurred during confirmation. The code might have expired or the password is too weak.
      console.error(error)
      setFormError(error.message)
      setLoading(false)
    })
  }, [oobCode, accountEmail, newPassword, signInWithEmailAndPassword])

  useEffect(() => {
    switch (mode) {
      case 'resetPassword': {
        firebase.auth().verifyPasswordResetCode(oobCode)
          .then((email) => {
            setLoading(false)
            setAccountEmail(email)
          })
          .catch((error) => {
            // Code is invalid or expired. Ask the user to verify their email address again.
            console.error(error)
            setError(error.message)
            setLoading(false)
          })
        break
      }
      // case 'recoverEmail':
      //   handleRecoverEmail(auth, actionCode, lang);
      //   break;
      case 'verifyEmail': {
        // handleVerifyEmail(auth, actionCode, continueUrl, lang)
        // Try to apply the email verification code.
        applyActionCode(oobCode)
          .then(() => {
            // Email address has been verified.
            setError(false)
            setLoading(false)

            // Broadcast reloadUser so all tabs (including this one) reloads user with new emailVerified value
            bc.publish(SHARED_AUTH_BC_CHANNEL, 'reloadUser')

            // Sometimes BroadcastChannel doesn't update this tab for some reason, so make sure we update locally
            reloadUser()
          })
          .catch((error) => {
            // Code is invalid or expired. Ask the user to verify their email address again.
            console.error(error)
            setError(error.message)
            setLoading(false)
          })
        break
      }
      default: {
        setError(true)
        setLoading(false)
      }
    }
  }, [mode, oobCode])

  if (loading) {
    return (
      <View hideSidebar={hideSidebar} loading {...props}>
        <Loader />
      </View>
    )
  }

  if (!error && mode === 'verifyEmail') {
    return (
      <Message
        hideSidebar={hideSidebar}
        image={{
          src: require('images/illustration-awaiting-verification.svg').default,
          width: 411,
          height: 306
        }}
        title='Account verified!'
        text='Happy to see that you are you! You can now reach your kludds from any device and collaborate with others, for example through inviting them to private kludds.'
        actionButton={{
          href: '/workspaces',
          label: 'Open my workspace',
          icon: <SvgIcon icon='workspace' />
        }}
        {...props}
      />
    )
  }

  if (!error && mode === 'resetPassword') {
    if (formSubmitted) {
      return (
        <View hideSidebar={hideSidebar} {...props}>
          <LogoLink href='/'>
            <img src={require('images/logo.svg').default} width={115} height={37} />
          </LogoLink>

          <Form>
            <FormTitle>Reset your password</FormTitle>

            <FormMessage>{'Your password has been reset\nGo to your workspace or close this tab'}</FormMessage>

            <FormFooter>
              <SubmitButton label='Show my workspace' href='/workspaces' icon={<SvgIcon icon='workspace' />} />
            </FormFooter>
          </Form>
        </View>
      )
    }

    return (
      <View hideSidebar={hideSidebar} {...props}>
        <LogoLink href='/'>
          <img src={require('images/logo.svg').default} width={115} height={37} />
        </LogoLink>

        <Form onSubmit={handleResetPasswordSubmit}>
          <FormTitle>Reset your password</FormTitle>

          <Label>Email address:</Label>
          <FormValue>{accountEmail}</FormValue>

          <SecondaryLabel for='email-auth-new-password'>Password:</SecondaryLabel>
          <TextInput
            required
            value={newPassword}
            onChange={ev => setNewPassword(ev.currentTarget.value)}
            type='password'
            name='password'
            id='email-auth-new-password'
            autoComplete='new-password'
          />

          <FormFooter>
            <SubmitButton type='submit' label='Save' />
            {!!formError && <ErrorText>{formError}</ErrorText>}
          </FormFooter>
        </Form>
      </View>
    )
  }

  return (
    <View hideSidebar={hideSidebar} {...props}>
      {!!error && typeof error === 'string'
        ? (
          <InformationWithIllustration
            title='Oops, something went wrong'
            text={error}
          />
          )
        : (
          <InformationWithIllustration
            title='This page does not exist'
            text='But not all those who wander are lost.'
          />
          )}
    </View>
  )
}

const View = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
  flex-direction: column;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  ${p => p.loading && 'display: flex; align-items: center; justify-content: center;'}

  transition: padding .2s ease;
  @media (min-width: 650px) {
    padding-left: ${p => p.sidebarOpen && !p.hideSidebar ? '26rem' : '0rem'};
  }
  @media (min-width: 1000px) {
    padding-left: ${p => p.sidebarOpen && !p.hideSidebar ? '27rem' : '0rem'};
  }
`
const Form = styled.form`
  padding: 0 4rem 4rem;
  max-width: 50.5rem;
  width: 100%;
  background: white;
  color: #3d4f50;
  border-radius: 2rem;
  box-shadow: 0 0.4rem 4rem rgba(61, 79, 80, 0.1);
  margin: 0 auto auto;

  .darkMode & {
    color: #f3f9fe;
    background: #1d232b;
    box-shadow: 0 0.4rem 4rem rgba(0, 0, 0, 0.1);
  }
`
const FormTitle = styled.h1`
  padding: 2rem;
  margin: 0 -4rem 4rem;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.1);
  text-align: center;

  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2rem;

  @media screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`
const FormFooter = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  margin-top: 3rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SecondaryLabel = styled(Label)`margin-top: 2rem;`
const SubmitButton = styled(Button)`width: 100%; max-width: 20rem;`
const LogoLink = styled.a`
  margin: auto auto 3rem;
  img { display: block; }
`
const FormValue = styled.p`
  font-size: 1.3rem;
  line-height: 1.6rem;
`
const FormMessage = styled(FormValue)`
  white-space: pre-wrap;
  text-align: center;
`
const ErrorText = styled.p`
  font-size: 1.4rem;
  margin: 1rem 0 0 0;
  text-align: center;
  color: red;
`
