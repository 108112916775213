import { useState, useLayoutEffect, useEffect, useCallback, useMemo } from 'preact/hooks'
import SetCodeMirrorMode from 'components/SetCodeMirrorMode'
import Modal from 'components/Modal'
import { PLUGINS, EXPORTS } from '@constants'

const loadedPlugins = new Set()

function loadPlugin (pluginId) {
  const plugin = PLUGINS[pluginId] || EXPORTS[pluginId]

  if (plugin && plugin.url) {
    const script = document.createElement('script')
    script.src = plugin.url
    script.async = true
    document.body.appendChild(script)
  }

  // Remember being loaded
  loadedPlugins.add(pluginId)
}

export default function usePlugins (isInApp) {
  const [activatedPluginIds, setActivatedPluginIds] = useState(new Set())
  const [availablePlugins, setAvailablePlugins] = useState({})
  const [availableExports, setAvailableExports] = useState({})

  useEffect(() => {
    // Only load when in app to avoid loading on landing page
    if (!isInApp) return

    for (const key of Object.keys(EXPORTS)) {
      // Only load once
      if (!loadedPlugins.has(key)) {
        // Load script
        loadPlugin(key)
      }
    }

    // Load activated plugins that is not loaded yet
    for (const pluginId of activatedPluginIds) {
      // Only load once
      if (!loadedPlugins.has(pluginId)) {
        // Load script
        loadPlugin(pluginId)
      }
    }
  }, [activatedPluginIds, isInApp])

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return
    if (!window.Kludd) window.Kludd = {}

    window.Kludd.definePlugin = (id, component) => {
      console.log('Define plugin:', id)
      setAvailablePlugins(ps => ({ ...ps, [id]: component }))
    }

    window.Kludd.defineExport = (id, action) => {
      console.log('Define export:', id)
      setAvailableExports(ps => ({ ...ps, [id]: action }))
    }

    window.Kludd.SetCodeMirrorMode = SetCodeMirrorMode
    window.Kludd.Modal = Modal
  }, [])

  const activatePlugin = useCallback((id) => {
    console.log('Activate plugin:', id)
    setActivatedPluginIds(ps => new Set(ps.add(id)))
  }, [])

  const deactivatePlugin = useCallback((id) => {
    console.log('Deactivate plugin:', id)
    setActivatedPluginIds(ps => { ps.delete(id); return new Set(ps) })
  }, [])

  const activatedPlugins = useMemo(() => (
    // Find plugins that are activated
    Array.from(activatedPluginIds.values())

      // If a plugin exists both as plugin and export, always enable for now
      .concat(Object.keys(availableExports))

      // Filter only available loaded plugins
      .filter(id => availablePlugins[id])
      .map(id => [id, availablePlugins[id]])
  ), [availablePlugins, activatedPluginIds])

  return [activatedPlugins, availableExports, activatePlugin, deactivatePlugin]
}
