import styled from 'styled-components'

export default function FontButton ({ fontFamily, fontExtraCss, fontName, previewWeight, active, className, ...props }) {
  return (
    <Button className={[className, active && 'is-active'].filter(v => v).join(' ')} {...props}>
      <FontFamily family={fontFamily} fontExtraCss={fontExtraCss} active={active} previewWeight={previewWeight || 400}>Aa</FontFamily>
      <FontName active={active}>{fontName}</FontName>
    </Button>
  )
}

const Button = styled.a`
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  height: 6.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #3d4f50;
  background: #f8f8f9;
  border: 1px dashed rgba(61, 79, 80, 0.2);
  transition: color .2s ease, background .2s ease, border .2s ease;

  .darkMode & { background: #1d232b; color: #5b5f63; border-color: rgba(61, 79, 80, 0.6); }

  &.is-active { color: white; background: #3d4f50; border: 1px solid rgba(61, 79, 80, 0.6); }
  .darkMode &.is-active { background: #181d23; color: #f8f8f9; }
`
const FontFamily = styled.p`
  font-size: 2.4rem;
  font-family: ${p => p.family};
  font-weight: ${p => p.previewWeight};
  ${p => p.fontExtraCss || ''}
  height: 2.5rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  transition: opacity .2s ease;
  ${p => !p.active && 'opacity: 0.6;'}

  // Trick from https://stackoverflow.com/a/26304590 to align at baseline
  &:after {
    content: "";
    height: 100%;
    display: inline-block;
  }
`
const FontName = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
  opacity: 0.8;
  transition: opacity .2s ease;
  ${p => !p.active && 'opacity: 0.6;'}
`
