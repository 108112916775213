import { useState, useLayoutEffect } from 'preact/hooks'
import { throttle } from 'throttle-debounce'

export default function useExtractUserDocs (userDoc) {
  const [docs, setDocs] = useState([])

  useLayoutEffect(() => {
    // throttle just in case we get a lot of updates at once
    const onUpdate = throttle(100, () => {
      setDocs(Array.from(userDoc.documentsMapType.entries()))
    })

    onUpdate()
    userDoc.doc.on('update', onUpdate)
    userDoc.doc.on('snapshot', onUpdate)

    return () => {
      userDoc.doc.off('update', onUpdate)
      userDoc.doc.off('snapshot', onUpdate)
    }
  }, [userDoc])

  return docs
}
