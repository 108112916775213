import CodeMirror from 'codemirror'
import { debounce } from 'throttle-debounce'
import { useDebounce } from 'use-debounce'
import { useEffect, useState } from 'preact/hooks'
import findIndicesOf from 'utils/findIndicesOf'

// Highlight searched word in editor
export default function useShowSearchQueryInEditor (searchQuery, codeMirrorBinding) {
  const [debouncedQuery] = useDebounce(searchQuery, 500)
  const [showSearchInEditor, setShowSearchInEditor] = useState(false)

  // Re-show on query change
  useEffect(() => {
    setShowSearchInEditor(true)
  }, [searchQuery])

  useEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm || !debouncedQuery || !showSearchInEditor) return

    const markers = []
    const codeMirror = codeMirrorBinding.cm
    let isFirstWebsocketChange = true

    const addMarkers = debounce(500, () => {
      // Remove old markers
      markers.forEach(marker => marker.clear())

      // Find all instances of searched term
      const lines = codeMirror.getValue().split(codeMirror.lineSeparator())

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        const indices = findIndicesOf(debouncedQuery, line)

        if (indices.length > 0) {
          for (const index of indices) {
            const fromPos = new CodeMirror.Pos(i, index)
            const toPos = new CodeMirror.Pos(i, index + debouncedQuery.length)
            const marker = codeMirror.markText(fromPos, toPos, { className: 'search-marker' })

            markers.push(marker)
          }
        }
      }
    })

    // Remove search highlights on any user input to avoid marker rerenders causing performance issues
    const onChange = (_, ev) => {
      const isLocalUserChange = ev.origin.startsWith('+') // +input, +delete
      const isWebsocketChange = ev.origin === 'prosemirror-binding'

      if (
        // Hide on first user input
        isLocalUserChange ||
        // First websocket change is from server while we can assume subsequent are from remote clients, i.e. inputs that we want to hide search markers for
        (isWebsocketChange && !isFirstWebsocketChange)
      ) {
        setShowSearchInEditor(false)
      } else {
        // If update from somewhere else, e.g. indexeddb/websocket, we want to update markers
        addMarkers()
      }

      if (isWebsocketChange) {
        isFirstWebsocketChange = false
      }
    }
    codeMirror.on('change', onChange)

    addMarkers()

    return () => {
      addMarkers.cancel()
      codeMirror.off('change', onChange)
      markers.forEach(marker => marker.clear())
    }
  }, [debouncedQuery, codeMirrorBinding, showSearchInEditor])
}
