import styled from 'styled-components'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Metadata from 'components/Metadata'
import StorySampleGrid from 'components/StorySampleGrid'
import { TITLE_FONT_FAMILY } from '@constants'

export default function StoryPage ({
  path,
  isLoggedIn,
  docs,
  userData,
  userDoc
}) {
  const data = content[path]

  return (
    <View>
      <Metadata title={data.name} />

      <Header
        isLoggedIn={isLoggedIn}
        docs={docs}
        userData={userData}
        userDoc={userDoc}
        alwaysVisible
        titleComponent={
          <KluddStories src={require('images/kludd-stories.svg').default} />
        }
      />

      <FirstSection>
        <NameAndTitle>
          <div>
            <Name>{data.name}</Name>
            <Title>{data.title}</Title>
          </div>
        </NameAndTitle>
        <Preamble>
          <PreambleText>{data.preamble}</PreambleText>
        </Preamble>
      </FirstSection>

      <HeroImage src={data.heroImage} />

      {data.sections.map((section, i) => (
        <Section key={i} hasImage={!!section.image}>
          {section.imageLocation === 'left' && (
            <SectionImage src={section.image} />
          )}
          <SectionText hasImage={!!section.image}>
            {section.date && <SectionDate>{section.date}</SectionDate>}
            <SectionTitle>{section.title}</SectionTitle>
            {section.text.split('\n\n').map((p, j) => (
              <SectionParagraph key={j}>{p}</SectionParagraph>
            ))}
          </SectionText>
          {section.imageLocation === 'right' && (
            <SectionImage src={section.image} />
          )}
        </Section>
      ))}

      {data.sampleGrid && (
        <Section>
          <SectionText>
            <SampleTitle>Selected works</SampleTitle>
          </SectionText>
        </Section>
      )}

      {data.sampleGrid && (
        <StorySampleGrid
          items={data.sampleGrid}
        />
      )}

      <Footer light />
    </View>
  )
}

const View = styled.div`
  color: #3d4f50;
`
const KluddStories = styled.img`
  width: 4.6rem;
  height: 3.2rem;
  display: block;
`
const FirstSection = styled.div`
  display: flex;
  min-height: 36rem;
  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`
const NameAndTitle = styled.div`
  flex: 1;
  border-bottom: 1px solid #e1dede;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 3rem;

  @media (min-width: 1000px) {
    border-right: 1px solid #e1dede;
    border-bottom: none;
  }
`
const Name = styled.h1`
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  font-size: 7rem;
  line-height: 9rem;
`
const Title = styled.p`
  font-size: 2rem;
  line-height: 3.2rem;
  opacity: 0.6;

  > a {
    color: inherit;
    text-underline-offset: 0.5rem;
    text-decoration-color: rgba(61, 79, 80, 0.2);
    text-decoration-thickness: 0.2rem;
  }
`
const Preamble = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 3rem;
`
const PreambleText = styled.p`
  font-size: 2rem;
  line-height: 3.2rem;
  max-width: 48.9rem;
`
const HeroImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
`
const Section = styled.section`
  border-top: 0.1rem solid #e1dede;

  ${p => !p.hasImage && `
  `}
  ${p => p.hasImage && `
    display: grid;
    align-items: center;

    @media (min-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
  `}
`
const SectionDate = styled.p`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  opacity: 0.3;
  margin-bottom: 5rem;
`
const SectionText = styled.div`
  padding: 4rem 3rem;
  min-width: 0;

  ${p => !p.hasImage && `
    max-width: 98rem;
    margin: 0 auto;
    border-right: 0.1rem solid #e1dede;
    border-left: 0.1rem solid #e1dede;
  `}

  @media (max-width: 999px) {
    order: 2;
  }

  @media (min-width: 1000px) {
    padding: 10rem;
  }
`
const SectionTitle = styled.h2`
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  font-size: 4rem;
  line-height: 5.4rem;
  margin-bottom: 3rem;
`
const SectionParagraph = styled.p`
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0.01rem;

  &:not(:last-child) { margin-bottom: 3rem; }
`
const SectionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 999px) {
    order: 1;
  }
`
const SampleTitle = styled.h3`
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
`

const content = {
  '/stories/andreas-roman': {
    name: 'Andreas Roman',
    title: <>Novelist and content producer for <a href='https://www.sleepcycle.com/' target='_blank' rel='noreferrer'>Sleep Cycle</a></>,
    preamble: "I grew up with stories and consoles. As a kid, I spun my tales for friends and family, sometimes from words typed on Dad's old Remington, sometimes through some game I'd made with friends on a Commodore 64. To this day, I'm still that kid, blending story's craft with technology.",
    heroImage: require('images/andreas-roman-1.jpg').default,
    sections: [
      {
        date: '2021 / 03 / 06 — 4 min read',
        title: 'Why I write',
        text: "I love words. They're all equal to me. If it's comforting ones on sleep, if it's a novel on video games and redemption, if it's a campaign for a sports brand or a love story about fortunes lost and green fur balls. There's always a chance you'll reach someone and make a positive difference in their lives.\n\nWhen I was younger, I thought I told stories because I wanted to entertain. While that's always true to some extent, I've learned now, that was never the case. I'm telling you stuff because I want to help you find comfort and insight, a way to frame things that'll help you feel less alone. You can look at a story, and think - oh, that's me. That's how I feel. At least, I'm not the only one now."
      },
      {
        imageLocation: 'left',
        image: require('images/andreas-roman-2.jpg').default,
        title: 'How I write',
        text: "Stories become larger than life when you involve your peers. Editors. Producers. Directors. Actors. Publishers. Fellow writers. Friends and readers. To get the work out there and nurture it together. When you're all in the same room, energised by each other's power of assocation.\n\nWith Kludd, we're all contributing. Typing, editing, cutting and pasting at a furious pace. In real time. Separately. Someone leaves, someone enters. Gone is the awkward block from tedious log-ins, interface confusions and unstable syncs. It's only the craft, the story and the work. Together with your friends."
      },
      {
        imageLocation: 'right',
        image: require('images/andreas-roman-3.jpg').default,
        title: 'My process',
        text: "The first words of anything go into Kludd. That's about the only thing I know for sure, when I start writing. Sometimes, I finish the draft before I share. Sometimes, I get nowhere and invite others to help. Or, it's editing on a piece that's done and needs rewrites. But the words stay in Kludd until it's time to frame it, say for print, production or launch. And when I'm the editor or producer, I use Kludd to help whoever's telling the story, to get to where they need to be. I really have no linear way of working, even the smallest slices of text never follow the same template. "
      }
    ],
    sampleGrid: [
      {
        image: require('images/andreas-roman-4.jpg').default,
        title: 'Större än störst',
        text: "The Greatest Game of All is my tenth novel, a story about love and friendship in the age of video games, and coming of age when you're facing the world alone. It is the greatest story I've ever told and my finest achievement to date.",
        cta: { label: 'Read the book', href: 'https://www.adlibris.com/se/bok/storre-an-storst-9789177993247' }
      },
      {
        image: require('images/andreas-roman-5.jpg').default,
        title: 'Leos’s Fortune',
        text: "Leo's Fortune was always special to me, a story about love lost and a lonely man's journey to earn it back. The team won an Apple Design award for Leo, which is about the finest recognition one can get for such a thing.",
        cta: { label: 'Get the app', href: 'http://www.leosfortune.com/' }
      },
      {
        image: require('images/andreas-roman-6.jpg').default,
        title: 'Lighthouse',
        text: "I was in a dark place for some time, and wrote the Lighthouse to find my way out. When the full impact of Alexander Skarsgård's gentle but powerful performance hit me, it felt like I'd finally found my way back home again.",
        cta: { label: 'Listen to the story', href: 'https://www.sleepcycle.com/sleep-stories/skarsgard-sleep-story/' }
      }
    ]
  }
}
