import { useCallback, useState } from 'preact/hooks'
import styled from 'styled-components'
import ButtonComponent from 'components/Button'
import SvgIcon from 'components/SvgIcon'
import confetti from 'canvas-confetti'

export default function CopyTextButton ({
  text,
  label,
  copiedLabel,
  inverted,
  textLink,
  withConfetti,
  ...props
}) {
  const [copied, setCopied] = useState(false)
  const onCopyText = useCallback((event) => {
    if (text && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
      setCopied(true)

      if (withConfetti) {
        // To get the button's position and place the confetti
        const buttonElement = event.currentTarget
        const buttonPosition = buttonElement.getBoundingClientRect()

        // x and y has to be a number between 0 and 1
        const x = buttonPosition.right / window.innerWidth
        const y = buttonPosition.top / window.innerHeight
        confetti({
          particleCount: 60,
          angle: 70, // angle of emission (degrees) where 90 is straight up
          spread: 40, // The higher the number, the more spread out the confetti will be
          ticks: 100, // The lower the number, the faster it will disappear
          drift: 0.1, // How much it will drift to the left (negative) and right (positive)
          origin: { x: x, y: y },
          startVelocity: 20,
          colors: ['#fff935', '#366668', '#ff6498', '#ffab35', '#46b7ed', '#fff']
        })
      }
    }
  }, [text, withConfetti])

  return (
    <Button
      label={
        <FlexContainer>
          <Label
            inverted={inverted}
            small={props.small}
            textLink={textLink}
          >
            {copied ? copiedLabel : label}
          </Label>
          {!withConfetti && !textLink && (
            <Icon inverted={inverted} small={props.small}>
              <SvgIcon icon={copied ? 'check' : 'link'} />
            </Icon>
          )}
          {withConfetti && copied && <Confetti>🎉</Confetti>}
          {withConfetti && !copied &&
            <Icon inverted={inverted} small={props.small}>
              <SvgIcon icon='link' />
            </Icon>}
        </FlexContainer>
      }
      onClick={onCopyText}
      white={inverted}
      textLink={textLink}
      {...props}
    />
  )
}

const Button = styled(ButtonComponent)`
  ${p => !p.small && 'padding-left: 2rem; padding-right: 2rem;'}
`
const Label = styled.span`
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.3);
  text-underline-offset: 0.5rem;

  ${p => p.inverted && 'color: #366668; text-decoration-color: rgba(54, 102, 104, 0.3);'}
  ${p => p.small && 'font-size: 1.4rem; text-underline-offset: 0.3rem;'}
  ${p => p.textLink && 'font: inherit; text-decoration: none;'}
`
const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: white;
  color: #366668;
  margin: -0.4rem -1.5rem -0.4rem 2rem;
  ${p => p.inverted && 'color: white; background: #366668;'}
  ${p => p.small && 'margin: -0.2rem -0.6rem -0.2rem 1rem; width: 2.2rem; height: 2.2rem;'}
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`
const Confetti = styled(Icon)``
