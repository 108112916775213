import { useMemo } from 'preact/hooks'
import styled from 'styled-components'
import { TITLE_FONT_FAMILY, CONTACT_MAIL } from '@constants'
import Grid from 'components/Grid'

const links = [
  {
    label: 'Kludd',
    items: [
      { label: 'Contact', href: `mailto:${CONTACT_MAIL}` },
      { label: 'Terms of Service', href: '/terms' },
      { label: 'Privacy Policy', href: '/privacy' }
    ]
  },
  {
    label: 'Social',
    items: [
      { label: 'r/Kludd', href: 'https://www.reddit.com/r/Kludd/', target: '_blank', rel: 'noreferrer' },
      { empty: true },
      { empty: true }
    ]
  }
]

export default ({ light, ...props }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <Grid
      darkGrid={!light}
      lightGrid={light}
      items={[
        {
          column: 2,
          centered: true,
          content: (
            <Section>
              <Logo width='23' height='37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fill={light ? '#3d4f50' : '#f8f8f9'} d='M0 36.28h4.75V.03H0zM4.75 17.29v4.75H22v-4.75z' />
                <path fill={light ? '#3d4f50' : '#f8f8f9'} d='M1.77 16.91l3.35 3.36L22.04 3.36 18.68 0z' />
              </Logo>
              <Text>
                Kludd is a service crafted by <Link underline href='https://www.futurememories.se/'>Future Memories</Link>.{'\n'}
                A digital design and tech studio based in Gothenburg, Sweden.
              </Text>
              <Copyright>© {currentYear} Future Memories, Sweden. All rights Reserved.</Copyright>
            </Section>
          )
        },
        {
          desktopOnly: true,
          centered: true,
          content: (
            <Section small>
              <Title light={light}>{links[0].label}</Title>
              {links[0].items.map(({ label, empty, ...link }) => (
                empty ? <EmptyNavLink /> : <NavLink {...link}>{label}</NavLink>
              ))}
            </Section>
          )
        },
        {
          desktopOnly: true,
          centered: true,
          content: (
            <Section small>
              <Title light={light}>{links[1].label}</Title>
              {links[1].items.map(({ label, empty, ...link }) => (
                empty ? <EmptyNavLink /> : <NavLink {...link}>{label}</NavLink>
              ))}
            </Section>
          )
        },
        {
          mobileOnly: true,
          content: (
            <MobileRowSection small>
              <MobileRowChild small>
                <Title light={light}>{links[0].label}</Title>
                {links[0].items.map(({ label, empty, ...link }) => (
                  empty ? <EmptyNavLink /> : <NavLink {...link}>{label}</NavLink>
                ))}
              </MobileRowChild>
              <MobileRowChild>
                <Title light={light}>{links[1].label}</Title>
                {links[1].items.map(({ label, empty, ...link }) => (
                  empty ? <EmptyNavLink /> : <NavLink {...link}>{label}</NavLink>
                ))}
              </MobileRowChild>
            </MobileRowSection>
          )
        }
      ]}
      {...props}
    />
  )
}

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  color: #80858A;
  width: 100%;

  @media (max-width: 999px) {
    padding: 3rem;
  }

  @media (min-width: 1000px) {
    ${p => p.small
      ? 'max-width: 13rem;'
      : 'padding-left: 12%;'
    }
  }
`

const MobileRowSection = styled(Section)`
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 5rem);
  margin: -2.5rem;
`

const MobileRowChild = styled.div`
  margin: 2.5rem;
`

const Text = styled.p`
  display: block;
  color: inherit;
  font-size: 1.4rem;
  line-height: 2.4rem;
  white-space: pre-line;
`

const Title = styled.h3`
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  font-size: 2rem;
  display: block;
  color: ${p => p.light ? 'rgba(61, 79, 80, 0.8)' : '#d0d2d4'};
  margin-bottom: 2rem;
`

const Copyright = styled(Text)`
  margin-top: 3rem;
  @media (min-width: 1000px) {
    margin-top: 10%;
  }
`

const Link = styled.a`
  color: inherit;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-decoration: ${p => p.underline ? 'underline' : 'none'};
`

const NavLink = styled(Link)`
  display: block;
  @media (min-width: 1000px) {
    display: inline-block;
  }
  &:not(:last-child) {
    margin-bottom: .7rem
  }
`

const EmptyNavLink = styled.span`
  display: block;
  width: .1rem;
  height: 2.4rem;
  &:not(:last-child) {
    margin-bottom: .6rem
  }
`

const Logo = styled.svg`
  margin-bottom: 2rem;
  opacity: .8;
`
