export const SOCKET_SERVER = (
  process.env.NODE_ENV === 'development'
    ? 'ws://localhost:1235'
    : 'wss://apialpha.kludd.co'
)

export const API_SERVER = (
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:1235'
    : 'https://apialpha.kludd.co'
)

export const TITLE_FONT_FAMILY = 'Recoleta'
export const BASE_FONT_FAMILY = '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
export const MONOSPACE_FONT_FAMILY = '"IBM Plex Mono",monospace'
export const MONOSPACE_FONT_EXTRA_CSS = "font-feature-settings: 'ss04' on, 'ss02' on; letter-spacing: 0.02rem;"
export const COURIER_PRIME_FONT = '"Courier Prime",monospace'

export const FONT_FAMILY_CHOICES = [
  { id: 'default', fontFamily: MONOSPACE_FONT_FAMILY, fontExtraCss: MONOSPACE_FONT_EXTRA_CSS, fontName: 'Plex', previewWeight: 500 },
  { id: 'alt', fontFamily: BASE_FONT_FAMILY, fontName: 'Inter', previewWeight: 500 },
  { id: 'courier', fontFamily: COURIER_PRIME_FONT, fontName: 'Courier', previewWeight: 400 }
]

export const WEBSOCKET_AUTH_NO_READ = 4000
export const WEBSOCKET_AUTH_INVALID_TOKEN = 4001
export const WEBSOCKET_AUTH_PERMISSIONS_CHANGED = 4002
export const WEBSOCKET_AUTH_DELETED_DOCUMENT = 4003
export const MESSAGE_PERMISSIONS = 5
export const MESSAGE_SYNCED_WITH_DATABASE = 6
export const MESSAGE_SNAPSHOTS = 7
export const MESSAGE_SNAPSHOTS_DIFF = 8
export const MESSAGE_SNAPSHOTS_MESSAGE = 9
export const ANONYMOUS_USER_ID = '__anonymous__'

const DARK_FOREGROUND = '#000'
const LIGHT_FOREGROUND = '#fff'
export const USER_COLORS = [
  { background: '#43c2ff', foreground: DARK_FOREGROUND },
  { background: '#ffd66e', foreground: DARK_FOREGROUND },
  { background: '#ffb82e', foreground: DARK_FOREGROUND },
  { background: '#9c6eff', foreground: LIGHT_FOREGROUND },
  { background: '#864dff', foreground: LIGHT_FOREGROUND },
  { background: '#fc6eff', foreground: DARK_FOREGROUND },
  { background: '#e068e2', foreground: LIGHT_FOREGROUND },
  { background: '#eb4747', foreground: LIGHT_FOREGROUND },
  { background: '#e85757', foreground: LIGHT_FOREGROUND },
  { background: '#48d166', foreground: DARK_FOREGROUND },
  { background: '#59ce5e', foreground: DARK_FOREGROUND },
  { background: '#208aed', foreground: LIGHT_FOREGROUND },
  { background: '#3da2eb', foreground: LIGHT_FOREGROUND },
  { background: '#ff8642', foreground: DARK_FOREGROUND },
  { background: '#f4692d', foreground: LIGHT_FOREGROUND },
  { background: '#00bdbd', foreground: LIGHT_FOREGROUND },
  { background: '#33cbcb', foreground: DARK_FOREGROUND },
  { background: '#57a777', foreground: LIGHT_FOREGROUND },
  { background: '#34af65', foreground: LIGHT_FOREGROUND },
  { background: '#d77bb8', foreground: LIGHT_FOREGROUND },
  { background: '#d65aa4', foreground: LIGHT_FOREGROUND }
]

export const PLUGINS = {
  fountain: {
    url: '/plugins/fountain.js',
    icon: '/plugins/fountain.svg',
    label: 'Screenwriting mode',
    beta: true
  }
}

export const EXPORTS = {
  pdf: {
    url: '/plugins/pdf.js',
    icon: '/plugins/pdf.svg',
    label: 'PDF document (.pdf)'
  },
  docx: {
    url: '/plugins/docx.js',
    icon: '/plugins/docx.svg',
    label: 'Microsoft Word (.docx)'
  },
  txt: {
    url: '/plugins/txt.js',
    icon: '/plugins/txt.svg',
    label: 'Plain text (.txt)'
  }
}
export const CONTACT_MAIL = 'hello@kludd.co'

// Used for debugging, simulate user id returned from auth to be this id
// Needs to be updated on server as well
export const SIMULATE_USER_ID = ''
