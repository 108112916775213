import { clearDocument } from 'y-indexeddb'

// Triggered when client finds a deleted kludd remotely
// Then call this method to remove deleted kludd locally
export default function removeDeletedKludd (userDoc, docId) {
  // We have deleted document so remove from user docs
  userDoc.documentsMapType.delete(docId)

  // Remove document from indexeddb
  clearDocument('kludd:' + docId)
}
