import { getLowestRangePos, getHighestRangePos } from 'helpers/CustomRange'
import getMarkdownFormatStopStart from 'utils/getMarkdownFormatStopStart'

const mergeFormatsIntoBlocks = (formats) => {
  const blocks = { strong: [], em: [], del: [] }
  const _formats = JSON.parse(JSON.stringify(formats))

  while (_formats.starts.strong.length > 0) {
    const start = _formats.starts.strong.shift()
    const stop = _formats.stops.strong.shift()

    blocks.strong.push({ start, stop })
  }

  while (_formats.starts.em.length > 0) {
    const start = _formats.starts.em.shift()
    const stop = _formats.stops.em.shift()

    blocks.em.push({ start, stop })
  }

  while (_formats.starts.del.length > 0) {
    const start = _formats.starts.del.shift()
    const stop = _formats.stops.del.shift()

    blocks.del.push({ start, stop })
  }

  return blocks
}

const isPosBeforeOrEqual = (pos, other) => (pos.line === other.line ? pos.ch <= other.ch : pos.line < other.line)
const isPosAfterOrEqual = (pos, other) => (pos.line === other.line ? pos.ch >= other.ch : pos.line > other.line)
const isPosEqual = (a, b) => (a.line === b.line && a.ch === b.ch)

const findBlock = (pos, formats) => {
  for (const block of formats) {
    const posIsAfterStart = isPosAfterOrEqual(pos, block.start)
    const posIsBeforeStop = isPosBeforeOrEqual(pos, block.stop)

    if (posIsAfterStart && posIsBeforeStop) {
      return block
    }
  }
}

const getTextBetween = (from, to, lines) => {
  if (from.line === to.line) {
    return lines[from.line].substring(from.ch, to.ch)
  }
  let result = lines[from.line].substr(from.ch)
  for (let line = from.line + 1; line < to.line; line++) {
    result += '\n' + lines[line]
  }
  result += '\n' + lines[to.line].substr(0, to.ch)
  return result
}

const addCharsToPos = (pos, amount) => ({ line: pos.line, ch: pos.ch + amount })
const addLinesToPos = (pos, amount) => ({ line: pos.line + amount, ch: pos.ch })

const loopText = (lines, from, to, cb) => {
  const selectedText = getTextBetween(from, to, lines)
  const pos = { line: from.line, ch: from.ch }

  for (let i = 0; i < selectedText.length; i++) {
    const char = selectedText.charAt(i)
    const nextChar = selectedText.charAt(i + 1)

    if (cb(char, pos, nextChar)) {
      // Break on return true to allow early break
      return
    }

    // Update pos position based on character, to be able to compare with codemirror position
    if (char === '\n') {
      pos.line += 1
      pos.ch = 0
    } else {
      pos.ch += 1
    }
  }
}

const loopTextBackwards = (lines, from, to, cb) => {
  const selectedText = getTextBetween(from, to, lines)
  const pos = { line: to.line, ch: to.ch }

  for (let i = selectedText.length - 1; i >= 0; i--) {
    const char = selectedText.charAt(i)
    const nextChar = selectedText.charAt(i - 1)

    if (cb(char, pos, nextChar)) {
      // Break on return true to allow early break
      return
    }

    // Update pos position based on character, to be able to compare with codemirror position
    if (char === '\n') {
      pos.line -= 1

      // Find last char of line by finding start or next newline
      let count = 0
      for (let j = i - 1; j >= 0; j--) {
        const compareChar = selectedText.charAt(j)
        if (!compareChar || compareChar === '\n') {
          break
        }
        count += 1
      }

      pos.ch = count
    } else {
      pos.ch -= 1
    }
  }
}

const whitespaceRegex = /\s/

export default function calculateKeyboardToggleResult (lines, symbol, head, anchor, symbolsMap, lineNumberOffset = 0) {
  // Normalize head/anchor so order doesn't matter
  let from = addLinesToPos(getLowestRangePos(head, anchor), -lineNumberOffset)
  let to = addLinesToPos(getHighestRangePos(head, anchor), -lineNumberOffset)
  const type = symbolsMap[symbol]
  const formats = getMarkdownFormatStopStart(lines)
  const blocks = mergeFormatsIntoBlocks(formats)

  // Move FROM forward to avoid any whitespace/newline
  loopText(lines, from, to, (char, pos) => {
    if (!whitespaceRegex.test(char) && char !== '\n') {
      from = pos
      return true
    }
  })

  // Move TO backward to avoid any whitespace/newline
  loopTextBackwards(lines, from, to, (char, pos) => {
    if (!whitespaceRegex.test(char) && char !== '\n') {
      to = pos
      return true
    }
  })

  const fromBlock = findBlock(from, blocks[type])
  const toBlock = findBlock(to, blocks[type])
  let mode = 'REMOVE'
  let addedChars = 0
  let addedCharsOnLastLine = 0
  let charsOnLastLine = 0
  const blocksOfFormat = blocks[type]

  // Handle single position differently to selection
  if (isPosEqual(from, to)) {
    mode = fromBlock ? 'REMOVE' : 'ADD'

    // Deletion with single position deletes outside selection
    if (mode === 'REMOVE') {
      const replacement = getTextBetween(addCharsToPos(fromBlock.start, symbol.length), fromBlock.stop, lines)
      return {
        replacement,
        from: addLinesToPos(fromBlock.start, lineNumberOffset),
        to: addLinesToPos(addCharsToPos(fromBlock.stop, symbol.length), lineNumberOffset),
        addedChars: symbol.length * -2,
        addedCharsOnLastLine: symbol.length * -2
      }
    } else {
      return {
        replacement: symbol + symbol,
        from: addLinesToPos(from, lineNumberOffset),
        to: addLinesToPos(from, lineNumberOffset),
        addedChars: symbol.length * 2,
        addedCharsOnLastLine: symbol.length * 2
      }
    }
  } else {
    // If ANY character is not of format, go into add format mode. Else go into remove format mode.
    // let mode = 'REMOVE'
    loopText(lines, from, to, (char, pos) => {
      const posHasFormat = !!blocksOfFormat.find(b => (
        isPosAfterOrEqual(pos, b.start) &&
        isPosBeforeOrEqual(pos, addCharsToPos(b.stop, symbol.length - 1))
      ))
      if (char !== '\n' && !posHasFormat) {
        // Break early when we found one character without format, it means we're adding
        mode = 'ADD'
        return true
      }
    })
  }

  let replacement = ''
  let nextStartOfLineShouldAddFormatSymbols = false

  // Remove all format symbols inside selection
  loopText(lines, from, to, (char, pos, nextChar) => {
    // Check if character position is start/end of block and if so, don't include. Otherwise, include.
    let includeChar = true
    for (const block of blocksOfFormat) {
      if (isPosEqual(pos, addCharsToPos(block.start, 0)) || isPosEqual(pos, addCharsToPos(block.start, symbol.length - 1))) {
        includeChar = false
        break
      }
      if (isPosEqual(pos, addCharsToPos(block.stop, 0)) || isPosEqual(pos, addCharsToPos(block.stop, symbol.length - 1))) {
        includeChar = false
        break
      }
    }

    // Handle adding with newlines
    if (mode === 'ADD') {
      if (nextStartOfLineShouldAddFormatSymbols && char !== '\n') {
        replacement += symbol
        addedChars += symbol.length
        addedCharsOnLastLine += symbol.length
        nextStartOfLineShouldAddFormatSymbols = false
      }
    }

    if (includeChar) {
      replacement += char
      charsOnLastLine += 1
    } else {
      addedChars -= 1
      addedCharsOnLastLine -= 1
    }

    // Handle adding with newlines
    if (mode === 'ADD') {
      if (nextChar === '\n') {
        if (char !== '\n') {
          replacement += symbol
          addedChars += symbol.length
        }
        nextStartOfLineShouldAddFormatSymbols = true
      }
    }

    if (char === '\n') {
      charsOnLastLine = 0
      addedCharsOnLastLine = 0
    }
  })

  if (mode === 'ADD') {
    // Add symbol at start of selection in case FROM is not inside format block
    if (!fromBlock) {
      addedChars += symbol.length
      if (from.line === to.line) {
        addedCharsOnLastLine += symbol.length
      }
      replacement = symbol + replacement
    }

    // Add symbol at end of selection in case TO is not inside format block
    // Also make sure last line is not just empty line
    if (!toBlock && charsOnLastLine > 0) {
      addedChars += symbol.length
      addedCharsOnLastLine += symbol.length
      replacement = replacement + symbol
    }
  } else if (mode === 'REMOVE') {
    // Add symbol at start of selection in case FROM is inside format block (but not at EXACT start)
    if (fromBlock && !isPosEqual(from, fromBlock.start)) {
      addedChars += symbol.length
      addedCharsOnLastLine += symbol.length
      replacement = symbol + replacement
    }

    // Add symbol at end of selection in case TO is inside format block (but not at EXACT stop)
    if (toBlock && !isPosEqual(to, toBlock.stop)) {
      addedChars += symbol.length
      addedCharsOnLastLine += symbol.length
      replacement = replacement + symbol
    }
  }

  return {
    replacement,
    from: addLinesToPos(from, lineNumberOffset),
    to: addLinesToPos(to, lineNumberOffset),
    addedChars,
    addedCharsOnLastLine
  }
}
