import { useEffect, useState, useRef } from 'preact/hooks'
import { throttle } from 'throttle-debounce'
import { BASE_FONT_FAMILY } from '@constants'
import styled from 'styled-components'
import Avatar from 'components/Avatar'
import SvgIcon from 'components/SvgIcon'

const MINUTE_IN_MS = 60000

const User = ({ user, first, isOffline, canShowJustJoined, hasShowedJustJoinedMap, scrollToUserCursor }) => {
  const [showJustJoined, setShowJustJoined] = useState(false)

  useEffect(() => {
    if (!user.me) {
      let timeoutId

      if (canShowJustJoined.current) {
        const lastShowTimestamp = hasShowedJustJoinedMap.current.get(user.userId) || 0
        const msSinceLastShow = Date.now() - lastShowTimestamp

        // Wait one minute before showing "just joined" again, since user left
        if (msSinceLastShow > MINUTE_IN_MS) {
          setShowJustJoined(true)
          timeoutId = setTimeout(() => setShowJustJoined(false), 2000)
        }
      }

      return () => {
        // Update timestamp when removed
        hasShowedJustJoinedMap.current.set(user.userId, Date.now())

        // clear timeout to prevent unmounted component state update
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [])

  return (
    <AvatarHolderLink isOffline={isOffline} onClick={() => scrollToUserCursor(user.userId)}>
      <AvatarImage
        avatar={user.avatar}
        alternativeAvatar={user.anonymousAvatar}
        color={user.color}
        seed={user.userId}
      />
      <Name
        unknown={!user.name}
        style={{
          backgroundColor: user.color,
          color: user.foregroundColor
        }}
      >
        {user.name || '(Unknown name)'}
        {user.me ? ' (You)' : ''}
      </Name>
      <JustJoined visible={showJustJoined}>{user.name} just joined!</JustJoined>
    </AvatarHolderLink>
  )
}

const OfflineAvatar = (props) => (
  <AvatarHolder {...props}>
    <OfflineAvatarImage>
      <SvgIcon icon='offline' />
    </OfflineAvatarImage>
    <Message>
      <MessageTitle>No internet? No problem!</MessageTitle>
      <MessageText>Keep working as if nothing happened, it will sync when you are online again 🤘</MessageText>
    </Message>
  </AvatarHolder>
)

export default ({ users, isOffline, docId, scrollToUserCursor, onShare, maxUsersAtViewportWidth }) => {
  // Wait for a second before showing "just joined" messages
  // To avoid "just joined" immediately when it's me joining
  const canShowJustJoined = useRef(false)
  // Remember when showing "just joined" for specific users, to avoid spamming it
  const hasShowedJustJoinedMap = useRef(new Map())
  const [avatars, setAvatars] = useState([])

  useEffect(() => {
    const updateAvatars = throttle(100, () => {
      let maxAvatars = 6

      if (maxUsersAtViewportWidth) {
        // Find highest viewport
        const viewport = Object.entries(maxUsersAtViewportWidth)
          .reduce((res, cur) => {
            const a = parseInt(cur[0])
            const b = parseInt(res[0])
            if (a > b && window.innerWidth >= a) {
              return cur
            }
            return res
          })
        maxAvatars = viewport[1]
      }

      // Filter duplicate users
      const filtered = (users || [])
        .reduce((result, user) => (
          result.find(u => u.userId === user.userId)
            ? result
            : result.concat(user)
        ), [])

      if (filtered.length >= 15) {
        setAvatars([{ label: filtered.length, icon: 'users' }])
        return
      }

      if (filtered.length > maxAvatars) {
        setAvatars(
          filtered
            .slice(0, maxAvatars - 1)
            .concat({ label: '+' + (filtered.length - (maxAvatars - 1)) })
        )
        return
      }

      setAvatars(filtered)
    })

    updateAvatars()
    window.addEventListener('resize', updateAvatars)
    return () => window.removeEventListener('resize', updateAvatars)
  }, [users, maxUsersAtViewportWidth])

  useEffect(() => {
    hasShowedJustJoinedMap.current = new Map() // clear map on each new doc
    canShowJustJoined.current = false

    const timeoutId = setTimeout(() => { canShowJustJoined.current = true }, 2500)

    // Clear timeout on unmount in case user quickly switches between documents
    return () => clearTimeout(timeoutId)
  }, [docId]) // Reset timer on every doc change

  return (
    <Avatars>
      {!!onShare && (
        <ShareButtonWrapper>
          <ShareButton onClick={onShare}>
            <SvgIcon icon='add-user' />
          </ShareButton>
        </ShareButtonWrapper>
      )}
      {avatars.map((u, i) => (
        u.label
          ? (
            <Label hasIcon={!!u.icon}>
              {!!u.icon && <SvgIcon icon={u.icon} />}
              {u.label}
            </Label>
            )
          : (
            <User
              key={u.userId}
              user={u}
              canShowJustJoined={canShowJustJoined}
              hasShowedJustJoinedMap={hasShowedJustJoinedMap}
              isOffline={isOffline}
              scrollToUserCursor={scrollToUserCursor}
            />
            )
      ))}
      {isOffline && <OfflineAvatar first={avatars.length === 0} />}
    </Avatars>
  )
}

const Avatars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 649px) {
    flex-direction: row-reverse;
  }

  @media (-moz-touch-enabled:1), (pointer:coarse) {
    pointer-events: none;
  }
`
const AvatarHolder = styled.div`
  position: relative;
  ${p => !p.first && `
    margin-top: -0.5rem;
    @media (max-width: 649px) {
      margin-top: 0;
      margin-right: -1rem;
    }
  `}
  ${p => p.isOffline && 'opacity: 0.5;'}
`
const AvatarHolderLink = styled.a`
  display: block;
  position: relative;
  &:not(:first-of-type) {
    margin-top: -0.5rem;
    @media (max-width: 649px) { margin-top: 0; margin-right: -1rem; }
  }
  ${p => p.isOffline && 'opacity: 0.5;'}
`
const AvatarImage = styled(Avatar)`
  &:hover + p {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`
const Name = styled.p`
  position: absolute;
  top: calc(50% - 0.7rem);
  right: 3.5rem;
  font: normal 400 1rem/1.2rem ${BASE_FONT_FAMILY};
  padding: 0.1rem 0.2rem;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateX(1rem);
  white-space: nowrap;
  ${p => p.unknown && 'font-style: italic;'}
`
const JustJoined = styled.p`
  position: absolute;
  top: 0;
  right: 4rem;
  white-space: nowrap;
  background: white;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.4rem 2rem rgba(61, 79, 80, 0.15);
  opacity: 0;
  visibility: hidden;
  transform: translateX(1rem);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  ${p => p.visible && 'opacity: 1; visibility: visible; transform: none;'}
`
const Message = styled.div`
  position: absolute;
  top: 50%;
  right: 5rem;
  background: white;
  color: #3d4f50;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 0.6rem;
  box-shadow: 0 0.4rem 2rem rgba(61, 79, 80, 0.15);
  width: 100vw;
  max-width: 23rem;
  opacity: 0;
  visibility: hidden;
  transform: translate(1rem, -50%);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  ${p => p.unknown && 'color: rgba(0, 0, 0, 0.2); font-style: italic;'}

  &:after {
    content: '';
    background: white;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: calc(50% - 0.5rem);
    right: -0.5rem;
    transform: rotate(45deg);
    border-radius: 0.2rem;
  }
`
const MessageTitle = styled.p`
  font-size: 1.6rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.2);
`
const MessageText = styled.p`font-size: 1.4rem;`
const OfflineAvatarImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  background: white;
  transition: background .3s ease;
  .darkMode & { background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }

  &:hover + p {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  &:hover + div {
    opacity: 1;
    visibility: visible;
    transform: translate(0, -50%);
  }
`
const ShareButtonWrapper = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 649px) {
    margin-bottom: 0;
    margin-left: 1.5rem;
  }

  @media (-moz-touch-enabled:1), (pointer:coarse) {
    pointer-events: auto;
  }
`
const ShareButton = styled.button`
  border: none;
  appearance: none;
  display: block;
  padding: 0.6rem;
  border-radius: 50%;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  cursor: pointer;
  color: #3d4f50;
  background: white;
  transition: background .3s ease, color .3s ease;
  .darkMode & { color: white; background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  svg { display: block; }
`
const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
  width: 3rem;
  height: 3rem;
  border: 2px solid rgba(155, 160, 166, 0.4);
  border-radius: 1.5rem;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  background: white;
  transition: background .3s ease;
  .darkMode & { background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  margin-top: -0.5rem;
  position: relative;

  @media (max-width: 649px) {
    margin-top: 0;
    margin-right: -1rem;
  }

  > svg { display: block; margin-right: 0.5rem; }

  ${p => p.hasIcon && `
    width: auto;
    margin-top: 0;
    border: none;
    padding: 0 0.8rem;

    @media (max-width: 649px) {
      margin-right: 0;
    }
  `}
`
