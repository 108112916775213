import randomWithSeed from 'random-seed'

const firstNames = [
  'Unknown',
  'Nameless',
  'Unnamed',
  'Unsigned',
  'Incognito',
  'Hidden',
  'Masked',
  'Secret',
  'Unidentified',
  'Disguised',
  'Undercover',
  'Camouflaged',
  'Mysterious',
  'Cryptic',
  'Concealed',
  'Undetected',
  'Unexposed',
  'Shady',
  'Cloaked'
]

const lastNames = [
  'Poet',
  'Novelist',
  'Lyricist',
  'Writer',
  'Biographer',
  'Blogger',
  'Journalist',
  'Essayist',
  'Screenwriter',
  'Scribe',
  'Playwright',
  'Copywriter',
  'Speechwriter',
  'Librettist',
  'Editor',
  'Ghostwriter',
  'Humorist'
]

export default (id) => {
  const firstName = firstNames[randomWithSeed(id).intBetween(0, firstNames.length - 1)]
  const lastName = lastNames[randomWithSeed(id + 'last').intBetween(0, lastNames.length - 1)]
  return `${firstName} ${lastName}`
}
