import styled from 'styled-components'
import { useState, useEffect } from 'preact/hooks'
import Loader from 'components/Loader'
import Button from 'components/Button'
import BigRadioButtons from 'components/BigRadioButtons'
import TextInput from 'components/TextInput'
import EmptyAvatar from 'components/EmptyAvatar'
import CopyTextButton from 'components/CopyTextButton'
import Checkbox from 'components/Checkbox'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import Label from 'components/Label'
import DocCard from 'components/DocCard'
import ContextMenu from 'components/ContextMenu'
import Comment from 'components/Comment'
import CommentPreview from 'components/CommentPreview'
import SvgIcon from 'components/SvgIcon'
import EmptyViewNotice from 'components/EmptyViewNotice'
import AvatarList from 'components/AvatarList'
import ActionWarning from 'components/ActionWarning'

import getRandomUserColor from 'utils/getRandomUserColor'

export default function Components () {
  const [darkMode, setDarkMode] = useState(false)
  const [checked, setChecked] = useState(false)
  const [view, setView] = useState('comments')

  useEffect(() => {
    document.documentElement.classList.toggle('darkMode', darkMode)
  }, [darkMode])

  return (
    <View>
      <C><CheckboxWithLabel label='Toggle dark mode' checked={darkMode} onChecked={setDarkMode} /></C>
      <C><Loader /></C>
      <C><Button label='Label' /></C>
      <C><Button label='Label' loading /></C>
      <C><Button label='Label' white /></C>
      <C><Button label='Label' white loading /></C>
      <C><Button label='Label' black /></C>
      <C><Button label='Label' black loading /></C>
      <C><Button label='Label' icon={<SvgIcon icon='snapshot-create' />} /></C>
      <C><Button label='Label' white icon={<SvgIcon icon='snapshot-create' />} /></C>
      <C><Button label='Label' black icon={<SvgIcon icon='snapshot-create' />} /></C>
      <C><Button label='Text link' textLink /></C>
      <C><Button label='Read Andreas story' textLinkBig /></C>
      <C width={295 + 40} white><BigRadioButtons
        fadeNotSelected
        name='view'
        value={view}
        onChange={setView}
        hideLabels
        options={[
          { value: 'comments', label: 'Comments', icon: <SvgIcon icon='comments' /> },
          { value: 'history', label: 'History', icon: <SvgIcon icon='history' /> },
          { value: 'info', label: 'Info', icon: <SvgIcon icon='information' /> },
          { value: 'plugins', label: 'Plugins', icon: <SvgIcon icon='plugin' /> }
        ]}
                                />
      </C>
      <C><Checkbox checked={checked} onChecked={setChecked} /></C>
      <C><Checkbox displayAsBoolean checked={checked} onChecked={setChecked} /></C>
      <C><CheckboxWithLabel label='Checkbox with label' checked={checked} onChecked={setChecked} /></C>
      <C><CheckboxWithLabel label='Checkbox with label' displayAsBoolean checked={checked} onChecked={setChecked} /></C>
      <C><CheckboxWithLabel label='Checkbox with label in reverse order' reverseOrder checked={checked} onChecked={setChecked} /></C>
      <C><CheckboxWithLabel label='Checkbox with label in reverse order' reverseOrder displayAsBoolean checked={checked} onChecked={setChecked} /></C>
      <C><CheckboxWithLabel label='Checkbox with label in reverse order' reverseOrder displayAsBoolean icon={<SvgIcon icon='information' />} checked={checked} onChecked={setChecked} /></C>
      <C>
        <CheckboxWithLabel
          checked={checked}
          onChecked={setChecked}
          label={<span>I accept the Kludd’s <a href='/privacy' target='_blank'>privacy policy</a> and <a href='/terms' target='_blank'>terms of service</a></span>}
        />
      </C>
      <C><Label>Label:</Label></C>
      <C><TextInput placeholder='Singleline input...' required /></C>
      <C><TextInput multiline placeholder='Multiline input...' rows={3} required /></C>
      <C><EmptyAvatar /></C>
      <C>
        <CopyTextButton
          text='Text to copy'
          label='Invite link'
          copiedLabel='Link copied!'
          withConfetti
        />
      </C>
      <C>
        <CopyTextButton
          text='Text to copy'
          label='Invite link'
          copiedLabel='Link copied!'
          small
        />
      </C>
      <C>
        <CopyTextButton
          text='Text to copy'
          label='Invite link'
          copiedLabel='Link copied!'
          inverted
        />
      </C>
      <C>
        <CopyTextButton
          text='Text to copy'
          label='Invite link'
          copiedLabel='Link copied!'
          inverted
          small
        />
      </C>
      <C>
        <CopyTextButton
          text='Text to copy'
          label='Invite link'
          copiedLabel='Link copied!'
          textLink
        />
      </C>
      <C>
        <DocCard
          href='/'
          preview='Lorem ipsum dolor'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
        />
      </C>
      <C>
        <DocCard
          preview='Lorem ipsum dolor'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          filled
        />
      </C>
      <C>
        <DocCard
          href='/'
          preview='Show search query: Nisl eget molestie vitae vel quis. Nisl egetna nullam quam pharetra laoreet ornare ut enim. Sit aliquam vitae eu elementum varius facilisis... nisl eget'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          searchQuery='nisl eget'
        />
      </C>
      <C>
        <DocCard
          preview='Show search query: Nisl eget molestie vitae vel quis. Nisl egenta nullam quam pharetra laoreet ornare ut enim. Sit aliquam vitae eu elementum varius facilisis...'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          filled
          searchQuery='nisl eget'
        />
      </C>
      <C>
        <DocCard
          preview='Lorem ipsum dolor'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          loading
        />
      </C>
      <C>
        <DocCard
          preview='Lorem ipsum dolor'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          loading
          filled
        />
      </C>
      <C>
        <DocCard
          preview='Symbol always visible'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
          symbolAlwaysVisible
        />
      </C>
      <C>
        <DocCard
          preview='Symbol always visible (filled)'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          filled
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
          symbolAlwaysVisible
        />
      </C>
      <C>
        <DocCard
          preview='Symbol always visible (match)'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          forceMatches
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
          symbolAlwaysVisible
        />
      </C>
      <C>
        <DocCard
          preview='Symbol visible on hover'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
        />
      </C>
      <C>
        <DocCard
          preview='Symbol visible on hover (filled)'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          filled
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
        />
      </C>
      <C>
        <DocCard
          preview='Symbol visible on hover (match)'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          forceMatches
          symbol='not-synced'
          symbolText='Kludd was created offline and will sync when online.'
        />
      </C>
      <C>
        <DocCard
          preview='Show temp message'
          latestUpdateTimestamp={new Date(2020, 1, 1)}
          isTemp
          tempMessage={{
            title: '* Kludd hidden *',
            subtitle: 'You can find it in your <a href="/workspaces">workspace</a>'
          }}
          onContextMenu={() => {}}
        />
      </C>
      <C>
        <ContextMenu
          options={[
            { icon: 'duplicate', label: 'Duplicate' },
            { divider: true },
            { icon: 'leave', label: 'Leave', loading: true },
            { icon: 'delete', label: 'Delete', deleteDocumentOption: true }
          ]}
        />
      </C>
      <C width={295}>
        <Comment
          message='Varius feugiat eu elementum tempus, aliquet. Ac iaculis nisi, viverra sed midae...'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
        />
      </C>
      <C width={295}>
        <Comment
          message='Comment with URL: https://google.com/abc123/iequrhqiuweh/iqewruhqeoieqw/qieenqwe'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          onSelect={() => console.log('onSelect comment')}
        />
      </C>
      <C width={295}>
        <Comment
          message='Selected comment with URL: https://google.com/abc123/iequrhqiuweh/iqewruhqeoieqw/qieenqwe'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          isSelected
          onSelect={() => console.log('onSelect comment')}
          replies={[
            {
              message: 'Here\'s a reply with a URL: https://google.com/abc123/iequrhqiuweh/iqewruhqeoieqw/qieenqwe',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            },
            {
              message: 'Here\'s a reply with a short URL: https://google.com/',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            }
          ]}
        />
      </C>
      <C width={295}>
        <Comment
          message='Comment with replies'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          replies={[
            {
              message: 'Here\'s a reply',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            }
          ]}
        />
      </C>
      <C width={295}>
        <Comment
          message='Resolved comment'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          resolved={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 4, 9, 15) }}
        />
      </C>
      <C width={295}>
        <Comment
          message='Resolved comment with replies'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          resolved={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 4, 10, 12) }}
          replies={[
            {
              message: 'Here\'s a reply',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            }
          ]}
        />
      </C>
      <C width={295}>
        <Comment
          readOnly
          message='readOnly comment. väldigtlångtordsombryterkonstigtochjadetbryterväldigtkonstigtfördethärärettovanligtlångtord. Lite ord efter det långa.'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
        />
      </C>
      <C width={295}>
        <Comment
          readOnly
          message='Resolved readOnly comment'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          resolved={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 4, 10, 12) }}
        />
      </C>
      <C width={295}>
        <Comment
          readOnly
          message='readOnly comment with reply'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          replies={[
            {
              message: 'Here\'s a reply',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            }
          ]}
        />
      </C>
      <C width={295}>
        <Comment
          readOnly
          message='Resolved readOnly comment with reply'
          selectedText='The sail was patched with flour sac lorem ipsum dolor sit amet.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
          resolved={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 4, 10, 12) }}
          replies={[
            {
              message: 'Here\'s a reply',
              created: { user: { userId: '1234', name: 'User Name' }, timestamp: new Date(2021, 1, 2) }
            }
          ]}
        />
      </C>
      <C>
        <CommentPreview
          message='Varius feugiat eu elementum tempus, aliquet. Ac iaculis nisi, viverra sed midae. Varius feugiat eu elementum tempus, aliquet. Ac iaculis nisi, viverra sed midae.'
          created={{ user: { userId: '123', name: 'User Name' }, timestamp: new Date(2021, 1, 1) }}
        />
      </C>
      <C>
        <EmptyViewNotice
          icon='favorite'
          iconColor='#ffb82e'
          message={<p>You <strong>don't</strong> have any favorites saved yet.</p>}
        />
      </C>
      <C>
        <AvatarList
          // Show max 6 users
          users={Array.from({ length: 6 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
        />
      </C>
      <C>
        <AvatarList
          // Should show '+9'
          users={Array.from({ length: 5 + 9 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
        />
      </C>
      <C>
        <AvatarList
          // Should show '1263'
          users={Array.from({ length: 1263 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
        />
      </C>
      <C>
        <AvatarList
          users={[
            { userId: 'a', avatar: 'https://i.pravatar.cc/40', color: getRandomUserColor().background }
          ]}
          isOffline
        />
      </C>
      <C>
        <AvatarList
          // Show max 6 users
          users={Array.from({ length: 6 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
          onShare={() => {}}
        />
      </C>
      <C>
        <AvatarList
          // Should show '+9'
          users={Array.from({ length: 5 + 9 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
          onShare={() => {}}
        />
      </C>
      <C>
        <AvatarList
          // Should show '1263'
          users={Array.from({ length: 1263 }, (_, i) => ({
            userId: 'userId' + i,
            avatar: 'https://i.pravatar.cc/' + (40 + i),
            color: getRandomUserColor('userId' + i).background
          }))}
          onShare={() => {}}
        />
      </C>
      <C>
        <AvatarList
          users={[
            { userId: 'a', avatar: 'https://i.pravatar.cc/40', color: getRandomUserColor().background }
          ]}
          isOffline
          onShare={() => {}}
        />
      </C>
      <C>
        <ActionWarning
          message={<><strong>You must verify</strong> your account within 12 hours!</>}
          submessage='You can select which kludds to add to your account upon verification'
          actionLabel='Resend verification'
          actionCalledLabel='New email sent!'
        />
      </C>
    </View>
  )
}

const View = styled.div`
  padding: 4rem;
  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }
`
const C = styled.div`
  margin-bottom: 2rem;
  ${p => p.width ? `width: ${p.width}px;` : ''}
  ${p => p.white && `
    background: white;
    padding: 2rem;
    .darkMode & { background: #1d232b; }
  `}
`
