import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'

export default ({ icon, iconColor, message }) => (
  <Wrapper>
    <IconWrapper iconColor={iconColor}>
      <SvgIcon icon={icon} />
    </IconWrapper>
    <Message>{message}</Message>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(241, 241, 241, 0.5);
  border-radius: 1.5rem;
  padding: 2rem;
  height: 20.5rem;

  .darkMode & { background: rgba(24, 29, 35, 0.5); }
`
const IconWrapper = styled.div`
  color: ${p => p.iconColor};
  background: white;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  margin: 0 auto 1.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .darkMode & {
    background: #1d232b;
    box-shadow: 0 0.4rem 4rem rgba(0, 0, 0, 0.15);
  }

  svg { display: block; }
`
const Message = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: center;
  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }
`
