// Check if there is more recent user data in usersCachedData (e.g. avatar or name may have been updated since comment was stored)
// Fallback to stored user on comment
export default function getUserFromCache (fallbackUser, usersCachedData) {
  if (!usersCachedData || !fallbackUser || !fallbackUser.userId) return fallbackUser
  const cached = usersCachedData.get(fallbackUser.userId)
  if (cached) {
    return { ...cached, userId: fallbackUser.userId }
  }
  return fallbackUser
}
