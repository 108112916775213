/* global fetch alert */
import { useState, useCallback, useRef } from 'preact/hooks'
import canCloneDocument from 'utils/canCloneDocument'
import cloneDocument from 'utils/cloneDocument'
import removeDeletedKludd from 'utils/removeDeletedKludd'
import { API_SERVER, ANONYMOUS_USER_ID } from '@constants'
import { clearDocument } from 'y-indexeddb'
import { useFavoriteToggle } from './useFavoriteDocs'

export default function useKluddDocContextMenu (userId, userDoc, userData, generateNewIdToken) {
  const [contextMenu, setContextMenu] = useState(null)
  const [tempDocs, setTempDocs] = useState([])
  const [memoryHiddenDocuments, setMemoryHiddenDocuments] = useState({})
  const [memoryLeftDocuments, setMemoryLeftDocuments] = useState({})
  const [memoryDeletedDocuments, setMemoryDeletedDocuments] = useState({})
  const [undoLeaveDocument, setUndoLeaveDocument] = useState(null)
  const [undoDeleteDocument, setUndoDeleteDocument] = useState(null)
  const [onDeleteKludd, setOnDeleteKludd] = useState(null)
  const currentDocId = useRef()

  const removeMemoryHiddenDocument = useCallback((docId) => {
    setMemoryHiddenDocuments(ds => {
      const obj = { ...ds }
      delete obj[docId]
      return obj
    })
  }, [])

  const removeMemoryLeftDocument = useCallback((docId) => {
    setMemoryLeftDocuments(ds => {
      const obj = { ...ds }
      delete obj[docId]
      return obj
    })
  }, [])

  const removeMemoryDeletedDocument = useCallback((docId) => {
    setMemoryDeletedDocuments(ds => {
      const obj = { ...ds }
      delete obj[docId]
      return obj
    })
  }, [])

  const removeUndoLeaveDocument = useCallback(() => {
    setUndoLeaveDocument(null)
  }, [])

  const removeOnDeleteKludd = useCallback(() => {
    setOnDeleteKludd(null)
  }, [])
  const removeUndoDeleteDocument = useCallback(() => {
    setUndoDeleteDocument(null)
  }, [])

  const fetchDocumentPermissions = useCallback((docId) => (
    generateNewIdToken(false, true)
      .then(userToken => (
        fetch(API_SERVER + '/document-permissions/' + docId, {
          headers: {
            Authorization: 'Bearer ' + userToken
          }
        })
          .then(r => r.json())
          .catch(err => console.log('Error fetching document permissions', err))
      ))
  ), [generateNewIdToken])

  const deleteDocument = useCallback((docId) => {
    // Show modal asking to confirm delete
    setOnDeleteKludd(() => () => {
      const existing = userDoc.documentsMapType.get(docId) || {}

      // Add to tempDocs to hide
      setTempDocs(tds => tds.concat([[
        docId,
        {
          ...existing,
          isHidden: true
        }
      ]]))

      // Remember deleted document id in memory to allow frontend to show deleted message
      setMemoryDeletedDocuments(ds => ({ ...ds, [docId]: true }))

      const timer = setTimeout(() => {
        setUndoDeleteDocument(null)

        generateNewIdToken(false, true)
          .then(userToken => (
            fetch(API_SERVER + '/delete-document/' + docId, {
              method: 'DELETE',
              headers: {
                Authorization: 'Bearer ' + userToken
              }
            })
              .then(r => {
                if (r.ok) {
                  removeDeletedKludd(userDoc, docId)
                } else {
                  throw new Error(r.status + ' - ' + r.statusText)
                }
              })
              .catch((err) => {
                alert('Oops, something went wrong.')
                console.log('Error deleting document', err)
              })
              .then(() => {
                setOnDeleteKludd(null)

                // Remove hidden temp doc when done
                setTempDocs(tds => tds.filter(d => d[0] !== docId))
              })
          ))
      }, 5000)

      // Undo will deactivate timer
      setUndoDeleteDocument(() => () => {
        clearTimeout(timer)
        setUndoDeleteDocument(null)

        // Remove from memory deleted
        removeMemoryDeletedDocument(docId)

        // Remove hidden temp doc when done
        setTempDocs(tds => tds.filter(d => d[0] !== docId))
      })
    })
  }, [])

  const leaveDocument = useCallback((docId) => {
    const existing = userDoc.documentsMapType.get(docId) || {}

    // Add to tempDocs to hide
    setTempDocs(tds => tds.concat([[
      docId,
      {
        ...existing,
        isHidden: true
      }
    ]]))

    // Remember left document id in memory to allow frontend to show left message
    setMemoryLeftDocuments(ds => ({ ...ds, [docId]: true }))

    // Generate id token to make sure we get valid token
    generateNewIdToken(false, true)
      .then(userToken => {
        const timer = setTimeout(() => {
          setUndoLeaveDocument(null)

          fetch(API_SERVER + '/leave-document/' + docId, {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => {
              // If 404, i.e. user not found in document, we can safely remove from user docs
              if (r.ok || r.status === 404) {
                // We have left document so remove from user docs
                userDoc.documentsMapType.delete(docId)

                // Remove document from indexeddb
                clearDocument('kludd:' + docId)
              } else {
                throw new Error(r.status + ' - ' + r.statusText)
              }
            })
            .catch((err) => {
              alert('Oops, something went wrong.')
              console.log('Error leaving document', err)
            })
            .then(() => {
              // Remove hidden temp doc when done
              setTempDocs(tds => tds.filter(d => d[0] !== docId))
            })
        }, 5000)

        // Undo will deactivate timer
        setUndoLeaveDocument(() => () => {
          clearTimeout(timer)
          setUndoLeaveDocument(null)

          // Remove from memory left
          removeMemoryLeftDocument(docId)

          // Remove hidden temp doc when done
          setTempDocs(tds => tds.filter(d => d[0] !== docId))
        })
      })
  }, [userId, userDoc])

  // Leave locally only, so no backend calls (used for leaving inaccessible documents, i.e. user already removed from document on backend)
  const leaveDocumentLocally = useCallback((docId) => {
    const existing = userDoc.documentsMapType.get(docId) || {}

    // Add to tempDocs to hide
    setTempDocs(tds => tds.concat([[
      docId,
      {
        ...existing,
        isHidden: true
      }
    ]]))

    // Remember left document id in memory to allow frontend to show left message
    setMemoryLeftDocuments(ds => ({ ...ds, [docId]: true }))

    const timer = setTimeout(() => {
      setUndoLeaveDocument(null)

      // We have left document so remove from user docs
      userDoc.documentsMapType.delete(docId)

      // Remove document from indexeddb
      clearDocument('kludd:' + docId)

      // Remove hidden temp doc when done
      setTempDocs(tds => tds.filter(d => d[0] !== docId))
    }, 5000)

    // Undo will deactivate timer
    setUndoLeaveDocument(() => () => {
      clearTimeout(timer)
      setUndoLeaveDocument(null)

      // Remove from memory left
      removeMemoryLeftDocument(docId)

      // Remove hidden temp doc when done
      setTempDocs(tds => tds.filter(d => d[0] !== docId))
    })
  }, [userId, userDoc])

  const hideDocument = useCallback((docId) => {
    const existing = userDoc.documentsMapType.get(docId) || {}

    // Add to tempDocs to animate hidden
    setTempDocs(tds => tds.concat([[
      docId,
      {
        ...existing,
        isTemp: true,
        tempMessage: {
          title: '* Kludd hidden *',
          subtitle: 'You can find it in your <a href="/workspaces">workspace</a>'
        }
      }
    ]]))

    // Animate to temp message
    setTimeout(() => {
      // Animate, fade out
      setTempDocs(tds => tds.map(d => (
        d[0] === docId
          ? [d[0], { ...d[1], isRemoved: true }]
          : d
      )))

      // Remove completely
      setTimeout(() => {
        setTempDocs(tds => tds.filter(d => d[0] !== docId))
      }, 300)
    }, 5000)

    // Remember hidden document id in memory to allow frontend to show hidden message
    setMemoryHiddenDocuments(ds => ({ ...ds, [docId]: true }))

    // Remove document from user docs bookmark
    if (userId === ANONYMOUS_USER_ID) {
      // Remove when signed out
      userDoc.documentsMapType.delete(docId)
    } else {
      // Just set a isHidden flag if signed in, to be able to show in workspaces
      const data = {
        ...existing,
        isHidden: true
      }

      userDoc.documentsMapType.set(docId, data)
    }
  }, [userId, userDoc])

  const favoriteDocument = useFavoriteToggle(userDoc)

  const onContextMenu = useCallback(async (doc, top, left) => {
    currentDocId.current = doc.key

    // canCloneDocument only checks if document is technically valid to clone, i.e. data is stored locally. But if user has lost access they may still have the document locally but shouldn't have access to it, so make sure they don't have access
    const _canCloneDocument = doc.hasAccess !== false && await canCloneDocument(userId, doc.key)
    const _canLeaveDocument = userId !== ANONYMOUS_USER_ID // Only logged in users can leave
    const _canDeleteDocument = userId !== ANONYMOUS_USER_ID // Only logged in users can delete
    const _canFavoriteDocument = userId !== ANONYMOUS_USER_ID // Only logged in users can favorite a document

    const options = [
      {
        icon: 'duplicate',
        label: 'Duplicate',
        action: () => cloneDocument(userId, doc.key, userData),
        disabled: !_canCloneDocument
      },
      _canFavoriteDocument && {
        icon: 'favorite',
        label: 'Favorite',
        action: () => favoriteDocument(doc.key)
      },
      { divider: true },
      _canLeaveDocument
        ? {
            leaveDocumentOption: true,
            icon: 'leave',
            label: 'Leave',
            action: () => leaveDocument(doc.key),
            loading: true
          }
        : {
            icon: 'private',
            label: 'Hide',
            action: () => hideDocument(doc.key)
          },
      _canDeleteDocument && {
        deleteDocumentOption: true,
        icon: 'delete',
        label: 'Delete',
        action: () => deleteDocument(doc.key),
        loading: true
      }
    ].filter(o => o)

    if (options.length > 0) {
      setContextMenu({
        title: 'Manage kludd',
        doc,
        top,
        left,
        options
      })

      // If leave document option is available, fetch permissions to check if it should be enabled
      if (_canLeaveDocument) {
        fetchDocumentPermissions(doc.key)
          .then(data => {
            // Make sure this permission check is still for the current doc id
            if (data && data.docId === currentDocId.current) {
              // Only filter menu options if menu is available (could be null if closed before call is completed
              if (data.errorCode === 'USER_NOT_IN_DOCUMENT') {
                setContextMenu(menu => (
                  menu
                    ? {
                        ...menu,
                        options: menu.options.map(o => (
                          o.leaveDocumentOption
                            ? {
                                ...o,
                                loading: false,
                                disabled: false,
                                // Remove document from userdoc instead of callback
                                action: () => leaveDocumentLocally(data.docId)
                              }
                            : o.deleteDocumentOption
                              ? { ...o, loading: false, disabled: !data.canDelete, disabledMessage: data.cannotDeleteMessage }
                              : o
                        ))
                      }
                    : null
                ))
              } else {
                setContextMenu(menu => (
                  menu
                    ? {
                        ...menu,
                        options: menu.options.map(o => (
                          o.leaveDocumentOption
                            ? { ...o, loading: false, disabled: !data.canLeave, disabledMessage: data.cannotLeaveMessage }
                            : o.deleteDocumentOption
                              ? { ...o, loading: false, disabled: !data.canDelete, disabledMessage: data.cannotDeleteMessage }
                              : o
                        ))
                      }
                    : null
                ))
              }
            }
          })
      }
    }
  }, [userId, deleteDocument, leaveDocument, fetchDocumentPermissions])

  const close = useCallback(() => {
    setContextMenu(null)
  }, [])

  return [contextMenu, onContextMenu, close, memoryHiddenDocuments, removeMemoryHiddenDocument, memoryLeftDocuments, removeMemoryLeftDocument, undoLeaveDocument, removeUndoLeaveDocument, tempDocs, onDeleteKludd, removeOnDeleteKludd, memoryDeletedDocuments, removeMemoryDeletedDocument, undoDeleteDocument, removeUndoDeleteDocument]
}
