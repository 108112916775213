import styled from 'styled-components'
import preventDefaultEvent from 'utils/preventDefaultEvent'
import preventLabelClickForCheckboxesToPreventFocus from 'utils/preventLabelClickForCheckboxesToPreventFocus'

export default function Checkbox ({ checked, onChecked, displayAsBoolean, isWrappedByLabel, disabled, ...props }) {
  const WrapperElement = isWrappedByLabel ? WrapperNoLabel : Wrapper

  return (
    <WrapperElement
      onClick={isWrappedByLabel ? undefined : preventLabelClickForCheckboxesToPreventFocus}
      onMouseDown={preventDefaultEvent}
      disabled={disabled}
      {...props}
    >
      <Input
        type='checkbox'
        checked={checked}
        onChange={ev => onChecked && !disabled && onChecked(ev.currentTarget.checked)}
        onMouseDown={preventDefaultEvent}
        disabled={disabled}
      />
      {displayAsBoolean
        ? (
          <BooleanWrapper checked={checked}>
            <BooleanButton />
          </BooleanWrapper>
          )
        : (
          <Box>
            <Check width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M11.3667 4.18874L5.05703 10.4984L2.6543 8.0957' stroke='#6ED0FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </Check>
          </Box>
          )}
    </WrapperElement>
  )
}

const Wrapper = styled.label`
  position: relative;
  ${p => p.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'}
`
const WrapperNoLabel = styled.span`
  position: relative;
  ${p => p.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'}
`
const Input = styled.input`
  ${p => p.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:checked + span > svg {
    opacity: 1;
  }

  &:focus + span {
    box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
    .darkMode & { box-shadow: 0 0.2rem 1rem rgba(255, 255, 255, 0.15); }
  }
`
const BooleanWrapper = styled.span`
  position: relative;
  display: block;
  width: 5rem;
  height: 2.8rem;
  padding: .2rem;
  background: #F1F1F1;
  border-radius: 6rem;
  transition: background .2s ease;
  .darkMode & { background: #2F3641; }

  ${p => p.checked && `
    background: #3d4f50;
    span {
      right: 0.2rem;
      background: #fff;
      box-shadow: unset;
    }
    .darkMode & {
      background: #fff;
      span { background: #2F3641; }
    }
  `}
`

const BooleanButton = styled.span`
  position: absolute;
  right: calc(100% - 2.6rem);
  display: block;
  height: 2.4rem;
  width: 2.4rem;
  background: rgba(61, 79, 80, 0.5);
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  transition: background .2s ease, box-shadow .2s ease, right .3s ease-in-out;

  .darkMode & {
    background: white;
  }
`
const Box = styled.span`
  display: block;
  background: white;
  border: 0.2rem solid rgba(61, 79, 80, 0.2);
  border-radius: 0.6rem;
  padding: 0.1rem;
  transition: background .2s ease, border .2s ease;

  .darkMode & {
    border-color: rgba(255, 255, 255, 0.2);
    background: #181d23;
  }
`
const Check = styled.svg`
  display: block;
  opacity: 0;
  transition: opacity .2s ease;
`
