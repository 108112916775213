// Overrides Y.Map for some custom logic
import * as Y from 'yjs'

const getUserMetadataFromUserData = (userData) => {
  if (!userData || !userData.userId) return null

  const metadata = { userId: userData.userId }

  if (userData.avatar) {
    metadata.avatar = userData.avatar
  }

  if (!userData.isAnonymous) {
    metadata.name = userData.name
  }

  return metadata
}

const extractTimestamp = (timestampOrObject) => (
  timestampOrObject.timestamp != null
    ? timestampOrObject.timestamp
    : timestampOrObject
)

export default class YKluddMetadataMap extends Y.Map {
  setCreatedTimestampToNow (userData) {
    return this.setCreatedTimestamp(Date.now(), userData)
  }

  setLatestUpdateTimestampToNow (userData) {
    return this.setLatestUpdateTimestamp(Date.now(), userData)
  }

  setCreatedTimestamp (timestamp, userData) {
    // Only set new created timestamp if earlier than existing
    const existingTimestamp = this.get('created')
    if (existingTimestamp && existingTimestamp < timestamp) {
      return existingTimestamp
    }

    const user = getUserMetadataFromUserData(userData)
    this.set('created', user ? { user, timestamp } : timestamp)
    return timestamp
  }

  setLatestUpdateTimestamp (timestamp, userData) {
    const user = getUserMetadataFromUserData(userData)
    const clientId = this.doc.clientID
    this.set('updated:' + clientId, user ? { user, timestamp } : timestamp)
    return timestamp
  }

  getCreatedTimestamp (includeUserDataIfAvailable) {
    const created = this.get('created')

    if (created) {
      return includeUserDataIfAvailable ? created : extractTimestamp(created)
    }

    return undefined
  }

  // Finds latest update and removes older
  getLatestUpdateTimestamp (includeUserDataIfAvailable, ignoreRemovingOld) {
    // Find latest timestamp
    const latest = Array.from(this.entries())
      .reduce((res, [key, timestampOrObject]) => {
        const timestamp = extractTimestamp(timestampOrObject)

        if (key.startsWith('updated:')) {
          if (!res || timestamp > extractTimestamp(res[1])) {
            return [key, timestampOrObject]
          }
        }
        return res
      }, null)

    if (latest) {
      // Older timestamps are no longer needed, so remove them
      if (!ignoreRemovingOld) {
        this.removeEveryUpdateTimestampExcept(latest[0])
      }

      return includeUserDataIfAvailable ? latest[1] : extractTimestamp(latest[1])
    }

    return undefined
  }

  // removeEveryCreatedTimestampExcept (timestampKey) {
  //   for (const key of this.keys()) {
  //     if (key !== timestampKey && key.startsWith('created:')) {
  //       this.delete(key)
  //     }
  //   }
  // }

  removeEveryUpdateTimestampExcept (timestampKey) {
    for (const key of this.keys()) {
      if (key !== timestampKey && key.startsWith('updated:')) {
        this.delete(key)
      }
    }
  }
}
