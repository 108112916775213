import { useEffect, useState } from 'preact/hooks'
import { debounce } from 'throttle-debounce'
import cleanTitleFromFormatting from 'utils/cleanTitleFromFormatting'

export default function useGetTitleFromKluddYDoc (kluddDoc) {
  const [title, setTitle] = useState('Kludd')

  useEffect(() => {
    if (kluddDoc) {
      const updateData = debounce(500, (data) => {
        const firstNewline = data.indexOf('\n')
        if (firstNewline > -1) {
          setTitle(cleanTitleFromFormatting(data.substr(0, firstNewline)) || 'Kludd')
        } else {
          setTitle(cleanTitleFromFormatting(data) || 'Kludd')
        }
      })

      const onUpdate = (ev) => {
        updateData(ev.currentTarget.toString())
      }

      kluddDoc.contentType.observe(onUpdate)

      return () => kluddDoc.contentType.unobserve(onUpdate)
    }
  }, [kluddDoc])

  return title
}
