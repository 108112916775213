// Mimic native Range
export default function CustomRange (head, anchor) {
  return {
    empty: () => !anchor || (head.line === anchor.line && head.ch === anchor.ch && head.sticky === anchor.sticky),
    head,
    anchor,
    from: () => getLowestRangePos(head, anchor || head),
    to: () => getHighestRangePos(head, anchor || head)
  }
}

export const getLowestRangePos = (a, b) => (
  a.line === b.line && a.ch === b.ch
    ? (a.sticky === 'before' ? a : b)
    : a.line === b.line
      ? (a.ch < b.ch ? a : b)
      : (a.line < b.line ? a : b)
)
export const getHighestRangePos = (a, b) => (
  getLowestRangePos(a, b) === a ? b : a
)
