import styled, { keyframes } from 'styled-components'

export default ({ icon }) => (
  <Wrapper>
    <Pulse animationDelay={(2 / 3) * 0} />
    <Pulse animationDelay={(2 / 3) * 1} />
    <Pulse animationDelay={(2 / 3) * 2} />
    <Circle>
      {icon}
    </Circle>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
const pulseAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 0.1;
    transform: scale(0.4);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`
const Pulse = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3d4f50;
  border-radius: 50%;
  animation: ${pulseAnimation} 2s ease-out infinite;
  animation-delay: ${p => p.animationDelay}s;
  .darkMode & { border-color: #f3f9fe; }
`
/* const Pulse1 = styled(Pulse)`
  width: 6rem;
  height: 6rem;
  opacity: 0.05;
`
const Pulse2 = styled(Pulse)`
  width: 8rem;
  height: 8rem;
  opacity: 0.03;
`
const Pulse3 = styled(Pulse)`
  width: 10rem;
  height: 10rem;
  opacity: 0.02;
` */
const Circle = styled.div`
  color: #3d4f50;
  background: white;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .darkMode & {
    color: #f3f9fe;
    background: #22282f;
    box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  }
`
