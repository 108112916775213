import styled, { css } from 'styled-components'
import { useMemo } from 'preact/hooks'
import LoaderComponent from 'components/Loader'
import { TITLE_FONT_FAMILY } from '@constants'

export default ({
  orange,
  red,
  white,
  black,
  grey,
  outline,
  noShadow,
  icon,
  label,
  type,
  className,
  loading,
  small,
  title,
  textLink,
  textLinkMedium,
  textLinkBig,
  ...props
}) => {
  const Element = type ? Button : LinkButton
  const classNames = useMemo(() => (
    [
      className,
      white && 'is-white',
      red && 'is-red',
      orange && 'is-orange',
      black && 'is-black',
      grey && 'is-grey',
      noShadow && 'no-shadow',
      outline && 'is-outline',
      props.disabled && 'is-disabled',
      loading && 'is-loading',
      textLink && 'is-text-link',
      textLinkMedium && 'is-text-link is-text-link-medium',
      textLinkBig && 'is-text-link is-text-link-big'
    ]
      .filter(v => v)
      .join(' ')
  ),
  [className, white, red, orange, black, grey, noShadow, outline, props.disabled, loading]
  )

  return (
    <Element
      type={type}
      title={title || (typeof label === 'string' ? label : undefined)}
      className={classNames}
      small={small}
      {...props}
    >
      {icon}
      {label}
      {loading && (
        <LoaderWrapper>
          <Loader secondsBeforeRotating={0} />
        </LoaderWrapper>
      )}
    </Element>
  )
}

const sharedStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 1.3rem;
  line-height: 1.8rem;
  background: #366668;
  border: 0.2rem solid #366668;
  color: white;
  padding: 1.1rem 1.9rem 1.2rem;
  border-radius: 6rem;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  transition: opacity .2s ease;

  & > * {
    pointer-events: none;
  }

  &:not(.no-shadow) {
    box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  }

  &.is-orange {
    background: #f59169;
    border-color: #f59169;
  }

  &.is-red {
    background: #ff6e6e;
    border-color: #ff6e6e;
  }

  &.is-white {
    background: #fff;
    border-color: #fff;
    color: #3d4f50;
  }

  &.is-black {
    background: #3d4f50;
    border-color: #3d4f50;
    color: #fff;
  }

  &.is-grey {
    background: #f8f8f9;
    border-color: #f8f8f9;
    color: #3d4f50;
  }

  &.is-outline {
    background: inherit;
    border-width: 0.1rem;
    border-color: #3d4f50;
    color: #3d4f50;
    box-shadow: none;
  }

  &.is-white.is-outline {
    border-color: rgba(61, 79, 80, 0.2);
  }

  &.is-disabled, &.is-loading {
    pointer-events: none;
    background: #f8f8f9;
    border-color: rgba(61, 79, 80, 0.05);
    color: rgba(61, 79, 80, 0.6);
    box-shadow: none;
  }

  &.is-loading { color: rgba(61, 79, 80, 0.2); }

  ${p => p.small && 'padding: 0.4rem 0.8rem;'}

  &:hover { box-shadow: 0 0.2rem 1rem rgba(54, 102, 104, 0.75); }
  &.is-orange:hover { box-shadow: 0 0.2rem 1rem rgba(245, 145, 105, 0.75); }
  &.is-red:hover { box-shadow: 0 0.2rem 1rem rgba(255, 110, 110, 0.75); }
  &.is-white:hover, &.is-outline:hover, &.is-grey:hover { box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15); }
  &.is-black:hover { box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.35); }

  &.is-text-link {
    background: transparent;
    border: none;
    padding: 0;
    box-shadow: none;
    color: #366668;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-decoration: underline;
    text-decoration-color: rgba(54, 102, 104, 0.3);
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.1rem;
  }
  &.is-text-link.is-text-link-medium {
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-underline-offset: 0.3rem;
    text-decoration-color: rgba(61, 79, 80, 0.3);
    color: #3d4f50;
  }
  &.is-text-link.is-text-link-big {
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    text-underline-offset: 0.7rem;
  }

  .darkMode & {
    &.is-white {
      background: #1d232b;
      border-color: #1d232b;
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
      color: #fff;
    }
    &.is-grey {
      background: #3d4f50;
      border-color: #3d4f50;
      color: #fff;
    }
    &.is-outline {
      border-color: #f8f8f9;
      color: #f8f8f9;
    }
    &.is-disabled, &.is-loading {
      border-color: rgba(243, 249, 254, 0.05);
      color: rgba(243, 249, 254, 0.6);
      background: #181d23;
    }
    &.is-loading { color: rgba(243, 249, 254, 0.2); }
  }

  transition: background .2s ease, border .2s ease, color .2s ease, box-shadow .2s ease;

  > svg { margin-right: 1rem; }
  ${p => p.tighterIconMargin && '> svg { margin-right: 0.5rem; }'}
`

const LinkButton = styled.a`
  ${sharedStyle}
`

const Button = styled.button`
  ${sharedStyle}
`

const LoaderWrapper = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(61, 79, 80, 1);
  .darkMode & { color: rgba(243, 249, 254, 1); }
}
`
const Loader = styled(LoaderComponent)`
  width: 4rem;
`
