import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'

export default function AddDocCard (props) {
  return (
    <Card {...props}>
      <Circle>
        <SvgIcon icon='add' />
      </Circle>
    </Card>
  )
}

const Card = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  border: 0.1rem dashed rgba(61, 79, 80, 0.2);
  border-radius: 0.6rem;

  .darkMode & {
    border-color: rgba(61, 79, 80, 0.6);
  }
`
const Circle = styled.div`
  background: white;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  padding: 1.1rem;
  border-radius: 50%;

  > svg { display: block; }

  .darkMode & {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    background: rgba(34, 40, 47, 0.85);
  }
`
