export default function preventLabelClickForCheckboxesToPreventFocus (event) {
  // Inspired by: https://stackoverflow.com/a/48359043/511949
  if (event.target.nodeName.toUpperCase() !== 'INPUT') {
    event.preventDefault()

    // Find input element
    const inputElement = event.target.closest('input') || event.target.parentElement.querySelector('input') || event.target.parentElement.parentElement.querySelector('input')
    if (inputElement) inputElement.click()
  }
}
