import { useEffect, useState } from 'preact/hooks'
import { IndexeddbPersistence } from 'y-indexeddb'

export default function useIndexeddbProvider (doc, roomName, docId, isValid) {
  const [isSynced, setIsSynced] = useState(false)

  useEffect(() => {
    setIsSynced(false)

    if (doc && isValid) {
      // If docId is provided, compare with document docId, to not bind to wrong document
      if (docId && docId !== doc.docId) return

      const provider = new IndexeddbPersistence(roomName, doc.doc)
      provider.on('synced', () => setIsSynced(true))

      return () => provider.destroy()
    }
  }, [doc, roomName, isValid])

  return [isSynced]
}
