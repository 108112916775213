import { useState, useEffect } from 'preact/hooks'

// useMemo but debounced value if value is true. If value is false, it's set immediately.
export default function useDebounceIfTrue (value, time) {
  const [debouncedValue, setDebouncedValue] = useState()

  useEffect(() => {
    if (value) {
      const timer = setTimeout(() => {
        setDebouncedValue(value)
      }, time)

      return () => clearTimeout(timer)
    } else {
      setDebouncedValue(value)
    }
  }, [value, time])

  return debouncedValue
}
