/* global requestAnimationFrame */
import CodeMirror from 'codemirror'

export const startMarkActiveLine = (codemirror, onCursorCoords) => {
  let activeLineMark
  let activityFromUserAction = false
  let cursorActivityHandledFromChange = false

  CodeMirror.on(codemirror.display.scroller, 'touchstart', () => {
    activityFromUserAction = true
  })
  CodeMirror.on(codemirror.display.scroller, 'touchend', () => {
    activityFromUserAction = false
  })
  CodeMirror.on(codemirror.display.scroller, 'touchcancel', () => {
    activityFromUserAction = false
  })
  codemirror.on('mousedown', () => {
    activityFromUserAction = true
    requestAnimationFrame(() => { activityFromUserAction = false })
  })
  codemirror.on('keydown', () => {
    activityFromUserAction = true
    requestAnimationFrame(() => { activityFromUserAction = false })
  })

  const onCursorActivity = (instance) => {
    const cursor = instance.getCursor('head')
    const line = instance.doc.getLine(cursor.line)
    const coords = getCursorCoords(instance)

    if (coords) {
      onCursorCoords(coords, false, activityFromUserAction)
    }
    activityFromUserAction = false

    if (activeLineMark) activeLineMark.clear()

    activeLineMark = instance.doc.markText(
      { line: cursor.line, ch: 0 },
      { line: cursor.line, ch: line.length },
      { className: 'active-line' }
    )
  }

  codemirror.on('change', (instance) => {
    // For some actions we need to handle cursorActivity from change (e.g. when remote user edits line above our cursor making it so long it breaks row we'll only get cursorActivity from ENTER and not from regular characters)

    // This part is to make sure we don't trigger onCursorActivity twice
    cursorActivityHandledFromChange = true
    requestAnimationFrame(() => {
      onCursorActivity(instance) // Run onCursorActivity in rAF aswell to make sure change has rendered
      cursorActivityHandledFromChange = false
    })
  })

  codemirror.on('cursorActivity', (instance) => {
    // If on change event handled activity, no need to call twice
    if (!cursorActivityHandledFromChange) onCursorActivity(instance)
  })
}

export const getCursorCoords = (instance) => {
  // Find if cursor is at end of selection or at start of selection
  try {
    const headCursor = instance.getCursor('head')
    const toCursor = instance.getCursor('to')
    const cursorIsAtStartOfSelection = !(headCursor.line === toCursor.line && headCursor.ch === toCursor.ch)
    const coords = instance.cursorCoords(cursorIsAtStartOfSelection, 'local')

    return coords
  } catch (_) {
    // Some Android browsers sometimes throw error (https://sentry.io/organizations/future-memories/issues/3377330418/). The text in editor is probably weird somehow so just let user retry.
    return undefined
  }
}

export const getCursor = (instance) => {
  const cursor = instance.getCursor('head')
  return { line: cursor.line, ch: cursor.ch }
}
