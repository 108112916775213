import { useState, useEffect } from 'preact/hooks'

export default function useDeviceHasMouse () {
  const [deviceHasMouse, setDeviceHasMouse] = useState(undefined)

  useEffect(() => {
    // No need to check again
    if (deviceHasMouse != null) return

    const isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))

    if (isTouchDevice) {
      setDeviceHasMouse(false)
      return
    }

    const onMouseMove = () => {
      setDeviceHasMouse(true)
    }

    window.addEventListener('mousemove', onMouseMove)
    return () => window.removeEventListener('mousemove', onMouseMove)
  }, [deviceHasMouse])

  return !!deviceHasMouse
}
