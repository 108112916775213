import styled from 'styled-components'
import Loader from 'components/Loader'

export default function EditorLoading ({ visible, sidebarOpen, bigSidebarOpen }) {
  return (
    <Wrapper sidebarOpen={sidebarOpen} bigSidebarOpen={bigSidebarOpen} visible={visible}>
      <Loader secondsBeforeRotating={1} />
      <LoadingText>Loading</LoadingText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #f8f8f9;
  color: #3d4f50;
  .darkMode & { background: #181d23; color: #f3f9fe; }

  transition: left .2s ease, opacity .3s ease, visibility .3s ease;
  @media (min-width: 650px) {
    left: ${p => p.bigSidebarOpen ? '36.5rem' : p.sidebarOpen ? '27rem' : '0rem'};
  }

  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}
`
const LoadingText = styled.p`
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: 0.1rem;
  opacity: 0.3;

  color: black;
  .darkMode & { color: #f3f9fe; }
`
