import { useMemo } from 'preact/hooks'

export default function useSearchFilteredDocs (docs, searchQuery) {
  const filteredDocs = useMemo(() => {
    if (searchQuery) {
      const query = searchQuery.toLowerCase()

      return (
        docs
          .filter(([key, doc]) => (
            doc.preview && doc.preview.toLowerCase().includes(query)
          ))
      )
    }

    return docs
  }, [docs, searchQuery])

  return filteredDocs
}
