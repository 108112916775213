export default function formatSeconds (time) {
  const hours = ~~(time / 3600)
  const minutes = ~~((time % 3600) / 60)
  const seconds = ~~time % 60

  let formattedTime = ''
  if (hours > 0) formattedTime += hours + 'h '
  if (minutes > 0) formattedTime += minutes + 'm '
  if (seconds > 0) formattedTime += seconds + 's'

  if (!formattedTime) return '0s'

  return formattedTime.trim()
}
