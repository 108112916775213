import { useEffect } from 'preact/hooks'

export default function useCloseOnEsc (onClose, isOpen) {
  useEffect(() => {
    if (isOpen && onClose) {
      const onKey = (ev) => {
        if (ev.key === 'Escape') {
          onClose()
        }
      }
      window.addEventListener('keydown', onKey)
      return () => window.removeEventListener('keydown', onKey)
    }
  }, [onClose, isOpen])
}
