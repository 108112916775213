import { useEffect, useState } from 'preact/hooks'
import { FONT_FAMILY_CHOICES } from '@constants'
import Cookies from 'js-cookie'

export const defaultValues = {
  fontFamilyId: FONT_FAMILY_CHOICES[0].id,
  fontSize: 18,
  lineHeight: 30,
  lineWidth: 780,
  paragraphSpacing: 0
}

export default function useEditorSettings () {
  const [editorSettings, setEditorSettings] = useState(defaultValues)

  useEffect(() => {
    // Load settings from cookies, fallback to default values
    try {
      setEditorSettings(JSON.parse(Cookies.get('editorSettings')))
    } catch (_) {
      setEditorSettings(defaultValues)
    }
  }, [])

  // Save editor settings
  useEffect(() => {
    Cookies.set('editorSettings', JSON.stringify(editorSettings), { expires: 365 })
  }, [editorSettings])

  return [editorSettings, setEditorSettings]
}
