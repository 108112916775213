import { useRef, useState, useMemo, useEffect } from 'preact/hooks'
import DocCard from 'components/DocCard'
import getDocumentExistsOffline from 'utils/getDocumentExistsOffline'
import getDocumentOnlyExistsOffline from 'utils/getDocumentOnlyExistsOffline'

export default function DocCardWithOfflineCheck (props) {
  const hasCheckedOnce = useRef(false)
  const [onlyAvailableOnline, setOnlyAvailableOnline] = useState(false) // not synced FROM server
  const [onlyAvailableOffline, setOnlyAvailableOffline] = useState(false) // not synced TO server

  const symbolProps = useMemo(() => (
    props.doc.hasAccess === false
      ? {
          symbol: 'locked',
          symbolText: 'You no longer have access to this kludd.',
          symbolAlwaysVisible: true,
          hideMetadata: true
        }
      : onlyAvailableOnline
        ? {
            symbol: 'sync',
            symbolText: 'You need to be online to open this kludd.',
            symbolAlwaysVisible: false
          }
        : onlyAvailableOffline
          ? {
              symbol: 'not-synced',
              symbolText: 'Kludd was created offline and will sync when online.',
              symbolAlwaysVisible: true
            }
          : {}
  ), [onlyAvailableOnline, onlyAvailableOffline, props.doc.hasAccess])

  useEffect(() => {
    // No need to check if it's false, it will never be true again
    const needsToCheckOffline = !hasCheckedOnce.current || onlyAvailableOnline
    const needsToCheckOnline = !hasCheckedOnce.current || onlyAvailableOffline

    if (needsToCheckOffline) {
      getDocumentExistsOffline(props.doc.key)
        // If it doesn't exist offline, it's only available online
        .then(exists => setOnlyAvailableOnline(!exists))
    }

    if (needsToCheckOnline) {
      getDocumentOnlyExistsOffline(props.doc.key)
        .then(onlyOffline => setOnlyAvailableOffline(onlyOffline))
    }

    hasCheckedOnce.current = true
  }, [props.doc.key, props.doc.latestReadTimestamp, props.sidebarRefreshHash])

  return (
    <DocCard
      {...props}
      {...symbolProps}
    />
  )
}
