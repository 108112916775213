export default (date) => {
  if (!date) return 'unknown time'

  date = new Date(date)

  return (
    (date.getHours() + '').padStart(2, '0') + ':' +
    (date.getMinutes() + '').padStart(2, '0')
  )
}
