import { useEffect, useContext } from 'preact/hooks'
import PluginDataContext from 'contexts/PluginDataContext'

export default function SetCodeMirrorMode ({ mode }) {
  const { setPluginData } = useContext(PluginDataContext)

  useEffect(() => {
    setPluginData(d => ({ ...d, codeMirrorMode: mode }))
    return () => setPluginData(d => ({ ...d, codeMirrorMode: null }))
  }, [mode])

  return null
}
