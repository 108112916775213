import styled from 'styled-components'

export default function Toast ({ icon, message, visible, ...props }) {
  return (
    <Wrapper visible={visible !== false} {...props}>
      {icon && <Icon>{icon}</Icon>}
      <Message>{message}</Message>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  color: #3d4f50;
  background: #fff;
  box-shadow: 0 0.4rem 2rem rgba(61, 79, 80, 0.15);
  border-radius: 1rem;
  transition: background .2s ease, color .2s ease, box-shadow .2s ease, opacity .3s ease, visibility .3s ease, transform .3s ease;

  ${p => !p.visible && 'opacity: 0; visibility: hidden; transform: translateY(1rem);'}

  .darkMode & {
    background: #1d232b;
    color: #fff;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.2);
  }
`
const Icon = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  flex: none;
  border-radius: 50%;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  .darkMode & { box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2); }
`
const Message = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
`
