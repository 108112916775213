/* global CustomEvent */
import { useCallback, useEffect, useState } from 'preact/hooks'
import { isMobile } from 'react-device-detect'

export default function useOverlayControls (initialValue) {
  const [overlayState, setOverlayState] = useState(initialValue)

  const onOverlayBlur = useCallback(() => {
    if (overlayState && isMobile) setOverlayState(false)
  }, [overlayState])

  // Send event when modal is opened or closed
  useEffect(() => {
    if (typeof window === 'undefined' || typeof CustomEvent === 'undefined') return

    const modalToggledStart = new CustomEvent('modal-toggled-start', { overlayState })
    const modalToggledEnd = new CustomEvent('modal-toggled-end', { overlayState })
    const transitionDuration = 300 + 10

    window.dispatchEvent(modalToggledStart)
    setTimeout(() => window.dispatchEvent(modalToggledEnd), transitionDuration)
  }, [overlayState])

  return [overlayState, setOverlayState, onOverlayBlur]
}
