import CrdtMap from 'crdtmap'
import { createIndexedDBHandler } from 'crdtmap-indexeddb'
import { ANONYMOUS_USER_ID } from '@constants'

export async function getAnonymousUserDoc () {
  const doc = CrdtMap()
  const persistence = createIndexedDBHandler('kludd_user:' + ANONYMOUS_USER_ID, doc)

  await persistence.whenSynced

  return { doc, persistence }
}

export default async function getAnonymousDocuments () {
  const { doc, persistence } = await getAnonymousUserDoc()
  const documentsMapType = doc.getMap('documents')

  persistence.destroy()

  // [[key, {data}]]
  return Array.from(documentsMapType.entries())
}
