import { useMemo, useState, useEffect, useCallback } from 'preact/hooks'
import { throttle } from 'throttle-debounce'
import * as Y from 'yjs'
import cuid from 'cuid'
import getCommentsArrayFromObject from 'utils/getCommentsArrayFromObject'

const USE_EXAMPLE_COMMENTS = false

export default function useKluddComments (kluddDoc, includeResolvedComments, codeMirror, userData) {
  const [unfilteredComments, setUnfilteredComments] = useState([])
  const [includeResolvedFrom, setIncludeResolvedFrom] = useState(0)
  const [selectedComment, setSelectedComment] = useState()
  const [userSelection, setUserSelection] = useState()

  // Load/extract comments from kludd doc
  useEffect(() => {
    if (!kluddDoc) {
      setUnfilteredComments([])
      return
    }

    const extractComments = throttle(100, () => {
      if (USE_EXAMPLE_COMMENTS) {
        // Generate example comments
        if (kluddDoc.contentType.length > 0) {
          const amountOfExampleComments = 100

          setUnfilteredComments(
            Array.from({ length: amountOfExampleComments }, () => ({
              anchor: Y.createRelativePositionFromTypeIndex(kluddDoc.contentType, Math.random() * (kluddDoc.contentType.length - 1)),
              head: Y.createRelativePositionFromTypeIndex(kluddDoc.contentType, Math.random() * (kluddDoc.contentType.length - 1)),
              created: { timestamp: Date.now(), user: { name: 'Shady Humorist', userId: 'ckv279cu1000w3e6aqkgsvasa' } },
              id: cuid(),
              message: '',
              selectedText: ''
            }))
          )
        } else {
          setUnfilteredComments([])
        }
      } else {
        // Convert comments map to array
        setUnfilteredComments(
          getCommentsArrayFromObject(kluddDoc.commentsType)
        )
      }
    })

    extractComments()
    kluddDoc.commentsType.observe(extractComments)

    return () => {
      extractComments.cancel()
      kluddDoc.commentsType.unobserve(extractComments)
      setUnfilteredComments([])
    }
  }, [kluddDoc])

  // Update "Add comment" icon when selection changes
  useEffect(() => {
    if (!codeMirror) return

    const onCursor = (a, b, c) => {
      const anchor = codeMirror.getCursor('anchor')
      const head = codeMirror.getCursor('head')
      const isSelection = anchor.line !== head.line || anchor.ch !== head.ch

      if (isSelection) {
        setUserSelection({
          anchor,
          head,
          selectedText: codeMirror.getSelection()
        })
      } else {
        setUserSelection(undefined)
      }
    }

    codeMirror.on('cursorActivity', onCursor)
    return () => codeMirror.off('cursorActivity', onCursor)
  }, [codeMirror])

  const comments = useMemo(() => (
    (
      includeResolvedComments
        ? unfilteredComments
        : unfilteredComments
          .filter(c => (
            !c.resolved || // Include unresolved
            (
              includeResolvedFrom > 0 &&
              c.resolved.timestamp >= includeResolvedFrom && // And resolved after timestamp...
              c.resolved.user.userId !== userData.userId // ...AND make sure they're not resolved by current user since we want to hide those immediately
            )
          ))
    )
  ), [unfilteredComments, includeResolvedComments, includeResolvedFrom, userData])

  const onResolve = useCallback((id) => {
    const comment = kluddDoc.commentsType.get(id)
    const resolved = {
      user: {
        userId: userData.userId,
        avatar: userData.avatar,
        name: userData.name
      },
      timestamp: Date.now()
    }

    // Update comment in kludd to resolved
    if (comment) {
      kluddDoc.commentsType.set(id, {
        ...comment,
        resolved
      })
    }
  }, [kluddDoc, userData])

  const onUnresolve = useCallback((id) => {
    // Remove "resolved" part of comment
    const comment = kluddDoc.commentsType.get(id)

    if (comment) {
      const { resolved, ...commentWithoutResolved } = comment
      kluddDoc.commentsType.set(id, commentWithoutResolved)
    }
  }, [kluddDoc, userData])

  const onReply = useCallback((commentId, message) => {
    const reply = {
      created: {
        user: {
          userId: userData.userId,
          avatar: userData.avatar,
          name: userData.name
        },
        timestamp: Date.now()
      },
      message
    }

    // Update comment in kludd with reply
    const replies = (kluddDoc.commentsType.get(commentId + ':replies') || [])
    kluddDoc.commentsType.set(commentId + ':replies', replies.concat(reply))
  }, [kluddDoc, userData])

  const onAddComment = useCallback((data) => {
    const id = cuid()
    const comment = { id, ...data }

    kluddDoc.commentsType.set(id, comment)
    setSelectedComment(id)
  }, [kluddDoc])

  return [
    comments,
    selectedComment,
    setSelectedComment,
    userSelection,
    onResolve,
    onUnresolve,
    onReply,
    onAddComment,
    setIncludeResolvedFrom,
    unfilteredComments
  ]
}
