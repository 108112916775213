import styled from 'styled-components'

export default ({ children, className, rightAligned, large, style, ...props }) => (
  <Wrapper className={className} style={style} disabled={!!props.disabled}>
    <Select rightAligned={rightAligned} large={large} {...props}>
      {children}
    </Select>
    <Arrow width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.558 6.442L4.5 7.5 9 12l4.5-4.5-1.057-1.058L9 9.877 5.558 6.442z' fill='currentColor' />
    </Arrow>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  ${p => p.disabled && 'opacity: 0.5; pointer-events: none;'}
`
const Select = styled.select`
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0 1.8rem 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 1.2rem;
  cursor: inherit;
  line-height: 1.8rem;
  transition: color .2s ease;

  ${p => p.rightAligned && 'direction: rtl; option { direction: ltr; }'}
  ${p => p.large && 'font-size: 1.4rem; line-height: 2rem;'}
`
const Arrow = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`
