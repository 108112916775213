/* global location fetch */
import { useContext, useState, useRef, useMemo, useEffect, useCallback } from 'preact/hooks'
import { route } from 'preact-router'
import styled, { keyframes } from 'styled-components'
import Cookies from 'js-cookie'
import firebase from 'firebase/app'
import { useDebouncedCallback } from 'use-debounce'

import { WEBSOCKET_AUTH_DELETED_DOCUMENT, WEBSOCKET_AUTH_INVALID_TOKEN, WEBSOCKET_AUTH_NO_READ, API_SERVER } from '@constants'

import isValidDocId from 'utils/isValidDocId'
import preventDefaultEvent from 'utils/preventDefaultEvent'
import removeDeletedKludd from 'utils/removeDeletedKludd'

import EditorComponent from 'components/Editor'
import AvatarList from 'components/AvatarList'
import ShareModal from 'components/ShareModal'
import CreateSnapshotModal from 'components/CreateSnapshotModal'
import AboutSidebar from 'components/AboutSidebar'
import BottomBar from 'components/BottomBar'
import InfoBottomBar from 'components/InfoBottomBar'
import SvgIcon from 'components/SvgIcon'
import Metadata from 'components/Metadata'
import EditorLoading from 'components/EditorLoading'
import PageTitleIcon from 'components/PageTitleIcon'
import FeedbackMessage from 'components/FeedbackMessage'
import InformationWithIllustration from 'components/InformationWithIllustration'
import ActionWarning from 'components/ActionWarning'
import CommentPreview from 'components/CommentPreview'

import PluginDataContext from 'contexts/PluginDataContext'

import useKluddYDoc from 'hooks/useKluddYDoc'
import useGetTitleFromKluddYDoc from 'hooks/useGetTitleFromKluddYDoc'
import useWebsocketProvider from 'hooks/useWebsocketProvider'
import useCodeMirrorBinding from 'hooks/useCodeMirrorBinding'
import useRemoteCursors from 'hooks/useRemoteCursors'
import useIndexeddbProvider from 'hooks/useIndexeddbProvider'
import useCustomUndoManager from 'hooks/useCustomUndoManager'
import useRememberOpenedKluddDocument from 'hooks/useRememberOpenedKluddDocument'
import useIsOnline from 'hooks/useIsOnline'
import useScrollToUserCursor from 'hooks/useScrollToUserCursor'
import useShareModal from 'hooks/useShareModal'
import useIsSyncedWithServer from 'hooks/useIsSyncedWithServer'
import useExtractKluddDocInfo from 'hooks/useExtractKluddDocInfo'
import useExtractCachedUserDataFromKluddDoc from 'hooks/useExtractCachedUserDataFromKluddDoc'
import useKluddComments from 'hooks/useKluddComments'
import useKluddPlugins from 'hooks/useKluddPlugins'
import useReadAccess from 'hooks/useReadAccess'
import useShowSearchQueryInEditor from 'hooks/useShowSearchQueryInEditor'
import useDebounceIfTrue from 'hooks/useDebounceIfTrue'
import useExtractSnapshotDiffsIntoCodeMirror from 'hooks/useExtractSnapshotDiffsIntoCodeMirror'

import { logTrackingEvent } from 'helpers/tracking'
import * as Sentry from '@sentry/browser'

export default function Editor ({
  docId,
  userId,
  userEmailVerified,
  userDataId,
  userDoc,
  userToken,
  userData,
  sidebarOpen,
  onSidebarBlur,
  darkMode,
  focusMode,
  matches,
  updateSidebarRefreshHash,
  editorSettings,
  memoryHiddenDocuments,
  removeMemoryHiddenDocument,
  memoryLeftDocuments,
  removeMemoryLeftDocument,
  memoryDeletedDocuments,
  removeMemoryDeletedDocument,
  generateNewIdToken,
  typographyModalOpen,
  setTypographyModalOpen,
  onTypographyModalBlur,
  aboutModalOpen,
  setAboutModalOpen,
  onAboutModalBlur,
  setSidebarOpen,
  hideUiWhileWriting,
  hideUiWhileWritingSetCodeMirror,
  focus,
  docs,
  userDocIsSynced,
  availableExports,
  activatePlugin,
  deactivatePlugin,
  searchQuery,
  setSearchQuery
}) {
  const roomName = 'kludd:' + docId
  const focusOnMount = focus != null // ?focus in url
  const isValid = isValidDocId(docId)
  const kluddDoc = useKluddYDoc(docId)
  const kluddDocTitle = useGetTitleFromKluddYDoc(kluddDoc)
  const kluddInfo = useExtractKluddDocInfo(kluddDoc)
  const usersCachedData = useExtractCachedUserDataFromKluddDoc(kluddDoc)
  const [includeResolvedComments, setIncludeResolvedComments] = useState(false)
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false)
  const [codeMirror, setCodeMirror] = useState()
  // const [snapshotDiffs, setSnapshotDiffs] = useState()
  const [snapshots, setSnapshots] = useState()
  const [snapshotData, setSnapshotData] = useState()
  const [onSnapshotDiffsCodeMirror, justAddedSnapshot] = useExtractSnapshotDiffsIntoCodeMirror(snapshotData, kluddDoc, docId, snapshots)
  const [comments, selectedComment, setSelectedComment, userSelection, onResolveComment, onUnresolveComment, onReplyToComment, onAddComment, setIncludeResolvedCommentsFrom, unfilteredComments] = useKluddComments(kluddDoc, includeResolvedComments, codeMirror, userData)
  const [activatedPlugins, onSetKluddPluginActive, availableExportsBinded] = useKluddPlugins(kluddDoc, codeMirror, activatePlugin, deactivatePlugin, availableExports, kluddDocTitle)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [createSnapshotModalOpen, setCreateSnapshotModalOpen] = useState(false)
  const [showInfoBar, setShowInfoBar] = useState(false)
  const [websocketProvider, isSocketConnected, users, latestClientIdForUserId, authRejectionCode, permissions, serverSaysWereSynced, snapshotMethods] = useWebsocketProvider(kluddDoc, roomName, docId, userToken, userData, generateNewIdToken, undefined, undefined, setSnapshots, setSnapshotData)
  const debouncedServerSaysWereSynced = useDebounceIfTrue(serverSaysWereSynced, 200) // debounce to avoid flash of "error"
  const codeMirrorBinding = useCodeMirrorBinding(docId, kluddDoc, codeMirror, websocketProvider)
  const { onUndo, onRedo } = useCustomUndoManager(kluddDoc, codeMirror, codeMirrorBinding)
  const isOnline = useIsOnline()
  const editorMethodsRef = useRef()
  const [scrollToUserCursor] = useScrollToUserCursor(userDataId, latestClientIdForUserId, codeMirror, editorMethodsRef, kluddDoc, websocketProvider)
  const [isShareModalLoading, isShareModalError, shareModalMetadata, shareModalCallbacks, fetchMetadata] = useShareModal(shareModalOpen, docId, generateNewIdToken)
  const readOnly = !permissions.includes('w')
  // can share if write permission and not public kludd and is user of document
  const canEditSharePermissions = permissions.includes('w') && permissions.includes('u') && !permissions.includes('p') && authRejectionCode === 0
  const canEditOrViewSharePermissions = (permissions.includes('r') || canEditSharePermissions) && authRejectionCode === 0
  const ogImage = API_SERVER + '/ogimage/' + docId
  const isSyncedWithServer = useIsSyncedWithServer(kluddDoc, docId, websocketProvider)
  const isLoading = (!isSyncedWithServer || authRejectionCode === WEBSOCKET_AUTH_INVALID_TOKEN || !userDocIsSynced) && authRejectionCode !== WEBSOCKET_AUTH_NO_READ
  const isOffline = (!isOnline || !isSocketConnected)
  const isHidden = !!(memoryHiddenDocuments && memoryHiddenDocuments[docId])
  const userJustLeft = !!(memoryLeftDocuments && memoryLeftDocuments[docId])
  const userJustDeleted = !!(memoryDeletedDocuments && memoryDeletedDocuments[docId])
  const onEditorFocus = useCallback(() => {
    onSidebarBlur()
    onTypographyModalBlur()
    onAboutModalBlur()
  }, [onSidebarBlur, onTypographyModalBlur, onAboutModalBlur])
  const { pluginData } = useContext(PluginDataContext)
  const _sidebarOpen = sidebarOpen || typographyModalOpen
  const _bigSidebarOpen = aboutModalOpen

  // Only allow logged in users to write snapshots, to prevent spam
  const canWriteSnapshots = !readOnly && !!userId

  const [selectedCommentCss, hoveredCommentId] = useRemoteCursors(websocketProvider && websocketProvider.awareness, codeMirrorBinding, comments, selectedComment, userData, permissions, userSelection, setAboutModalOpen, usersCachedData)

  // Find comment based on hovered comment id
  const hoveredComment = useMemo(() => (
    comments.find(c => c.id === hoveredCommentId)
  ), [comments, hoveredCommentId])

  // When we get a hovered comment, figure out position of comment preview
  const hoveredCommentPreviewProps = useMemo(() => {
    if (!hoveredComment) return undefined

    const dot = document.querySelector(`[data-comment-id="${hoveredComment.id}"]`)
    const line = dot?.parentElement?.parentElement
    const bounds = line?.getBoundingClientRect()

    if (!bounds) return undefined

    const marginToText = 10
    const maxPossibleHeightOfCommentPreview = 135
    const alignToTopOfComment = bounds.top > maxPossibleHeightOfCommentPreview + marginToText

    if (alignToTopOfComment) {
      return {
        style: {
          top: (bounds.top - marginToText) + 'px',
          left: bounds.left + 'px',
          transform: 'translateY(-100%)'
        }
      }
    }

    // This means we're close to top of viewport so preview doesn't fit
    return {
      style: {
        top: bounds.top + 'px',
        left: bounds.left + 'px'
      }
    }
  }, [hoveredComment])

  useShowSearchQueryInEditor(searchQuery, codeMirrorBinding)

  // Check if has read access
  const hasReadAccess = useReadAccess(isSocketConnected, authRejectionCode, docId, docs)

  // Debounce removing kludd to make sure we're using latest docId
  const onAuthRejectionDeleted = useDebouncedCallback(() => {
    if (authRejectionCode === WEBSOCKET_AUTH_DELETED_DOCUMENT) {
      // Remove local kludd if it's deleted
      removeDeletedKludd(userDoc, docId)
    }
  }, 300)
  useEffect(onAuthRejectionDeleted, [authRejectionCode, userDoc, docId])

  useEffect(() => {
    if (shareModalOpen) {
      // Refetch metadata if my permissions changed (probably due to someone else updating it)
      // This way I won't get all updates but atleast when my permission changed so I can see if someone made me owner
      // No need to fetch new permissions if no write access, share modal will be closed anyway. And new metadata fetched when share modal is opened.
      if (permissions && permissions.includes('w')) {
        fetchMetadata()
      }
    }
  }, [permissions])

  // Deselect comment if about modal closes
  useEffect(() => {
    if (!aboutModalOpen) setSelectedComment()
  }, [aboutModalOpen, setSelectedComment])

  useEffect(() => {
    hideUiWhileWritingSetCodeMirror(codeMirror)
    return () => hideUiWhileWritingSetCodeMirror(null)
  }, [codeMirror])

  useIndexeddbProvider(kluddDoc, roomName, docId, isValid)
  useRememberOpenedKluddDocument(docId, kluddDoc, userDoc, userData, codeMirrorBinding, memoryLeftDocuments, memoryDeletedDocuments, hasReadAccess)

  const onToggleInfoBar = useCallback(() => {
    setShowInfoBar(v => !v)
  }, [])

  // If ?focus is set, redirect from it
  useEffect(() => {
    if (focusOnMount && typeof URLSearchParams !== 'undefined' && typeof location !== 'undefined') {
      // Timeout to allow Editor component to get that it should focus before redirecting away
      setTimeout(() => {
        const params = new URLSearchParams(location.search)
        params.delete('focus')
        route('/' + docId + (Array.from(params).length > 0 ? '?' + params.toString() : ''))
      }, 100)
    }
  }, [focusOnMount])

  // Log opened doc event, but debounce to have higher change of getting correct permissions
  // Could be called twice if server permissions are slow to fetch
  const hasDocIdChanged = useRef(true)
  useEffect(() => { hasDocIdChanged.current = true }, [docId])
  const logOpenedDoc = useDebouncedCallback(
    (docId, permissions, isSocketConnected) => {
      // Only track new events if docId has changed
      if (hasDocIdChanged.current) {
        logTrackingEvent('Document opened', {
          documentId: docId,
          owner: isSocketConnected ? (permissions.includes('o') ? 'YES' : 'NO') : 'UNKNOWN_BECAUSE_OFFLINE'
        })
        hasDocIdChanged.current = false
      }
    },
    1000
  )
  useEffect(() => { logOpenedDoc(docId, permissions, isSocketConnected) }, [docId, permissions, isSocketConnected])

  // Close share modal if no longer access to it
  useEffect(() => {
    if (!canEditOrViewSharePermissions) {
      setShareModalOpen(false)
    }
  }, [canEditOrViewSharePermissions])

  // Remove hidden message when changing route, so that if we change back to this document from workspaces it won't be hidden
  useEffect(() => () => {
    removeMemoryHiddenDocument(docId)
    removeMemoryLeftDocument(docId)
    setSnapshotData(null)
  }, [docId])

  // Hide feedback message when writing
  useEffect(() => {
    if (kluddDoc && codeMirrorBinding && showFeedbackMessage) {
      const onUpdate = (ev) => {
        // If length of document is larger than 0, hide feedback message
        if (ev.currentTarget.length > 0) {
          setShowFeedbackMessage(false)
        }
      }

      onUpdate({ currentTarget: kluddDoc.contentType })

      kluddDoc.contentType.observe(onUpdate)
      return () => kluddDoc.contentType.unobserve(onUpdate)
    }
  }, [kluddDoc, codeMirrorBinding, showFeedbackMessage])

  // Show feedback message when switching document
  useEffect(() => {
    setShowFeedbackMessage(true)
  }, [docId])

  // Remove onlyExistsOffline:kludd:docId cookie, if it exists, once we're online and connected to server
  useEffect(() => {
    if (!isOffline) {
      Cookies.remove('onlyExistsOffline:kludd:' + docId)
      updateSidebarRefreshHash()
    }
  }, [isOffline, docId])

  // CSS to hide [data-clientid] for all clientids that is not latest
  const hideCursorsCss = useMemo(() => (
    users
      .map(user => {
        const latestClientId = latestClientIdForUserId && latestClientIdForUserId[user.userId]
        // Hide cursor if user has got a latestClientId and it's not this clientId
        if (latestClientId && latestClientId != user.clientId) { // eslint-disable-line
          return `.remote-caret[data-clientid="${user.clientId}"] { display: none; }`
        }
        return ''
      })
      .join('')
  ), [users, latestClientIdForUserId])

  // Capture "We're sorry"-error by recreating the render steps to get to that message and then sending Sentry event
  useEffect(() => {
    if (userJustLeft) { return }
    if (userJustDeleted || authRejectionCode === WEBSOCKET_AUTH_DELETED_DOCUMENT) { return }
    if (isLoading && debouncedServerSaysWereSynced && userDocIsSynced) {
      Sentry.captureMessage("We're sorry. Something unexpected happened.")
    }
  }, [userJustLeft, userJustDeleted, authRejectionCode, isLoading, debouncedServerSaysWereSynced, userDocIsSynced])

  if (userJustLeft) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <InformationWithIllustration
          illustration={require('images/illustration-not-found.svg').default}
          illustrationDarkMode={require('images/illustration-not-found-darkmode.svg').default}
          imageAlt='Illustration of "Left kludd"'
          title='You have left this kludd'
          text='If this was a mistake, ask the kludd owner for a link.'
        />
      </View>
    )
  }

  if (userJustDeleted || authRejectionCode === WEBSOCKET_AUTH_DELETED_DOCUMENT) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <InformationWithIllustration
          illustration={require('images/illustration-not-found.svg').default}
          illustrationDarkMode={require('images/illustration-not-found-darkmode.svg').default}
          imageAlt='Illustration of "Document Deleted"'
          title='Kludd deleted'
          text={'This kludd has been deleted by the\nowner and has been removed.'}
        />
      </View>
    )
  }

  // Edge case where server has loaded, and sent, data to client but client still thinks it's loading (due to missing created/updated metadata).
  // This indicates that data is corrupt somewhere so show a generic error.
  // Document could be synced prior to user doc so make sure we check that user doc is synced to indexeddb
  if (isLoading && debouncedServerSaysWereSynced && userDocIsSynced) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <InformationWithIllustration
          title="We're sorry"
          text='Something unexpected happened'
        />
      </View>
    )
  }

  if (!hasReadAccess) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <InformationWithIllustration
          illustration={require('images/illustration-access-denied.svg').default}
          illustrationDarkMode={require('images/illustration-access-denied-darkmode.svg').default}
          imageAlt='Illustration of "Access Denied"'
          title='Hold your horses'
          text={'You do not have access to this document.\nAsk the owner for an invitation.'}
        />
        <EditorLoading
          sidebarOpen={_sidebarOpen}
          bigSidebarOpen={_bigSidebarOpen}
          visible={isLoading}
        />
      </View>
    )
  }

  if (!isValid) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <InformationWithIllustration
          illustration={require('images/illustration-not-found.svg').default}
          illustrationDarkMode={require('images/illustration-not-found-darkmode.svg').default}
          imageAlt='Illustration of "Not Found"'
          title='This kludd does not exist'
          text='But not all those who wander are lost.'
        />
      </View>
    )
  }

  const isGooglePrerender = matches._escaped_fragment_ != null
  if (isGooglePrerender) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Metadata
          title='Kludd'
          description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
          image={ogImage}
        />
        <ErrorMessage>You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions.</ErrorMessage>
      </View>
    )
  }

  if (isHidden) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <ErrorMessage>
          <PageTitleIcon icon={<SvgIcon icon='private' />} />
          {
            userToken
              ? <span>This kludd is now hidden.<br />You can find it in <a href='/workspaces'>your workspace</a>.</span>
              : <span>This kludd is now hidden.</span>
          }
        </ErrorMessage>
      </View>
    )
  }

  return (
    <View
      sidebarOpen={_sidebarOpen}
      bigSidebarOpen={_bigSidebarOpen}
    >
      <style>{hideCursorsCss}</style>
      <style>{selectedCommentCss}</style>

      <Metadata
        title={kluddDocTitle}
        description="You have been invited by someone who appreciates your ideas, to share a Kludd with them. It's collaborative writing, without distractions."
        image={ogImage}
      />

      <EditorComponent
        docId={docId}
        onCodeMirror={setCodeMirror}
        onUndo={onUndo}
        onRedo={onRedo}
        darkMode={darkMode}
        focusMode={focusMode}
        editorMethodsRef={editorMethodsRef}
        onFocus={onEditorFocus}
        focusOnMount={focusOnMount}
        sidebarOpen={_sidebarOpen}
        bigSidebarOpen={_bigSidebarOpen}
        codeMirrorMode={pluginData.codeMirrorMode}
        readOnly={readOnly || (snapshotData != null && snapshotData.snapshot != null)}
        isHidden={snapshotData != null && snapshotData.snapshot != null}
        {...editorSettings}
      />

      {(snapshotData != null && snapshotData.snapshot != null) && (
        <EditorComponent
          onCodeMirror={onSnapshotDiffsCodeMirror}
          darkMode={darkMode}
          readOnly
          sidebarOpen={_sidebarOpen}
          bigSidebarOpen={_bigSidebarOpen}
          codeMirrorMode={pluginData.codeMirrorMode}
          {...editorSettings}
        />
      )}

      <EditorLoading
        sidebarOpen={_sidebarOpen}
        bigSidebarOpen={_bigSidebarOpen}
        visible={isLoading}
      />

      <ActionsWrapper>
        <TopRightInfo visible={!hideUiWhileWriting}>
          {!isLoading && (
            <TopRightTop>
              <InfoButton onClick={() => setAboutModalOpen(v => !v)}>
                <SvgIcon icon='information-2' />
              </InfoButton>
            </TopRightTop>
          )}

          <AvatarList
            users={users}
            maxUsersAtViewportWidth={{
              0: 4,
              348: 6
            }}
            docId={docId}
            isOffline={isOffline}
            scrollToUserCursor={scrollToUserCursor}
            onShare={canEditOrViewSharePermissions ? () => setShareModalOpen(true) : null}
          />
        </TopRightInfo>

        <TypographySettingsButton
          onMouseDown={preventDefaultEvent}
          onClick={() => setTypographyModalOpen(o => !o)}
          visible={!hideUiWhileWriting}
        >
          <SvgIcon icon='type-settings' />
        </TypographySettingsButton>
      </ActionsWrapper>

      <CreateSnapshotModal
        open={!!createSnapshotModalOpen}
        predefinedData={createSnapshotModalOpen}
        onClose={() => setCreateSnapshotModalOpen(false)}
        snapshotMethods={snapshotMethods}
        snapshotData={snapshotData}
      />

      <ShareModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        isLoading={isShareModalLoading}
        isLoggedIn={!!userToken}
        isError={isShareModalError}
        metadata={shareModalMetadata}
        callbacks={shareModalCallbacks}
        userId={userId}
        docId={docId}
        canEdit={canEditSharePermissions}
        userPermissions={permissions}
        usersCachedData={usersCachedData}
      />

      <PositionedFeedbackMessage
        visible={showFeedbackMessage}
        sidebarOpen={_sidebarOpen}
        bigSidebarOpen={_bigSidebarOpen}
      />

      <PositionedAboutSidebar
        readOnly={readOnly}
        openWithView={aboutModalOpen}
        onClose={() => setAboutModalOpen(false)}
        info={kluddInfo}
        usersCachedData={usersCachedData}
        userDataId={userDataId}
        showInfoBar={showInfoBar}
        onToggleInfoBar={onToggleInfoBar}
        canWriteSnapshots={canWriteSnapshots}
        snapshots={snapshots}
        snapshotMethods={snapshotMethods}
        snapshotData={snapshotData}
        setSnapshotData={setSnapshotData}
        setCreateSnapshotModalOpen={setCreateSnapshotModalOpen}
        comments={comments}
        unfilteredComments={unfilteredComments}
        selectedComment={selectedComment}
        setSelectedComment={setSelectedComment}
        includeResolvedComments={includeResolvedComments}
        setIncludeResolvedComments={setIncludeResolvedComments}
        setIncludeResolvedCommentsFrom={setIncludeResolvedCommentsFrom}
        onResolveComment={onResolveComment}
        onUnresolveComment={onUnresolveComment}
        onReplyToComment={onReplyToComment}
        onAddComment={onAddComment}
        availableExports={availableExportsBinded}
        activatedPlugins={activatedPlugins}
        onSetPluginActive={onSetKluddPluginActive}
        editorMethodsRef={editorMethodsRef}
        codeMirrorBinding={codeMirrorBinding}
        isOffline={isOffline}
        userDoc={userDoc}
        docId={docId}
      />

      {kluddInfo && (
        <InfoBottomBar
          show={showInfoBar}
          sidebarOpen={_sidebarOpen}
          bigSidebarOpen={_bigSidebarOpen}
          onToggle={onToggleInfoBar}
          info={kluddInfo}
        />
      )}

      <BottomBar
        show={snapshotData != null && snapshotData.snapshot != null}
        sidebarOpen={_sidebarOpen}
        bigSidebarOpen={_bigSidebarOpen}
        icon={<SvgIcon icon='binoculars' />}
        options={canWriteSnapshots ? [{ label: 'Restore', onClick: () => snapshotMethods.restoreSnapshot(snapshotData.snapshot.id) }] : undefined}
        closeAction={() => setSnapshotData(null)}
        concernsFullPage
      >
        <p><strong>Viewing:</strong> “{snapshotData && snapshotData.snapshot ? snapshotData.snapshot.name : ''}”</p>
      </BottomBar>

      <BottomBar
        show={!!justAddedSnapshot}
        sidebarOpen={_sidebarOpen}
        bigSidebarOpen={_bigSidebarOpen}
        icon={<SvgIcon icon='history' />}
      >
        <p>“{justAddedSnapshot && justAddedSnapshot.name}” saved to <a onClick={() => setAboutModalOpen('history')}><strong>Version history.</strong></a></p>
      </BottomBar>

      {!userEmailVerified && (
        <VerifyEmailActionWarning
          message={<><strong>You must verify</strong> your account within 12 hours!</>}
          submessage='You can select which kludds to add to your account upon verification'
          actionLabel='Resend verification'
          actionCalledLabel='New email sent!'
          actionCallback={() => {
            const email = firebase.auth().currentUser?.email
            if (email) {
              return fetch(API_SERVER + '/send-email-verification', {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: { 'Content-Type': 'application/json' }
              })
            }
          }}
        />
      )}

      {!!hoveredCommentPreviewProps && (
        <PositionedCommentPreview
          usersCachedData={usersCachedData}
          {...hoveredComment}
          {...hoveredCommentPreviewProps}
        />
      )}
    </View>
  )
}

const View = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  transition: padding .2s ease;
  @media (min-width: 650px) {
    padding-left: ${p => p.bigSidebarOpen ? '35.5rem' : p.sidebarOpen ? '26rem' : '0rem'};
  }
  @media (min-width: 1000px) {
    padding-left: ${p => p.bigSidebarOpen ? '36.5rem' : p.sidebarOpen ? '27rem' : '0rem'};
  }
`
const ErrorMessage = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 2rem;
  line-height: 2.6rem;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  a { color: inherit; }
`
const ActionsWrapper = styled.div`
  @media (max-width: 649px) {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 0.4rem 2rem rgba(61, 79, 80, 0.15);
    .darkMode & { background: #22282f; box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15); }
  }
`
const TopRightInfo = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  @media (min-width: 1000px) {
    top: 3rem;
    right: 3rem;
  }
  z-index: 10;
  transition: opacity .5s ease, visibility .5s ease;
  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}

  @media (max-width: 649px) {
    position: static;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 1.5rem 6rem; // give space for TypographySettingsButton
  }
`
const TopRightTop = styled.div`
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
  .darkMode & { border-bottom-color: rgba(217, 217, 217, 0.2); }

  @media (max-width: 649px) {
    border-bottom: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      height: 2rem;
      width: 0.1rem;
      background: #d9d9d9;
      display: block;
      margin: 0 1.5rem;
    }
    .darkMode &:before { background: rgba(217, 217, 217, 0.2); }
  }
`
const TypographySettingsButton = styled.a`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  @media (min-width: 1000px) {
    bottom: 3rem;
    right: 3rem;
  }
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  background: white;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  .darkMode & { background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  border-radius: 50%;
  transition: opacity .5s ease, visibility .5s ease, background .3s ease, color .3s ease;
  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}

  @media (max-width: 649px) {
    top: 1.5rem;
    right: 1.5rem;
  }
`
const PositionedFeedbackMessage = styled(FeedbackMessage)`
  position: fixed;
  bottom: 0;
  left: 10rem;
  right: 10rem;
  padding-left: ${p => p.bigSidebarOpen ? '36.5rem' : p.sidebarOpen ? '27rem' : '0rem'};
  z-index: 2;
  background: #f8f8f9;
  .darkMode & { background: #181d23; }

  @media (max-width: 999px) { left: 4rem; right: 4rem; }
  @media (max-width: 649px) { display: none; }

  transition: opacity .5s .2s ease, visibility .5s .2s ease, transform .5s .2s ease;
  ${p => !p.visible && 'opacity: 0; visibility: hidden; transform: translateY(1rem); transition-delay: 0s;'}
`
const InfoButton = styled.button`
  border: none;
  appearance: none;
  display: block;
  padding: 0.6rem;
  border-radius: 50%;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  cursor: pointer;
  color: #3d4f50;
  background: white;
  transition: background .3s ease, color .3s ease;
  .darkMode & { color: white; background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  svg { display: block; }
`
const PositionedAboutSidebar = styled(AboutSidebar)`
  z-index: 12;
  position: absolute;
  inset: 0;
  @media (min-width:650px){
   top: 3rem;
   left: 3rem;
   bottom: 3rem;
   width: 33.5rem;
 }
`
const VerifyEmailActionWarning = styled(ActionWarning)`
  position: absolute;
  top: 3rem;
  right: 7rem;
  @media screen and (max-width: 650px) { display: none; }
`
const showCommentPreview = keyframes`from { opacity: 0; }`
const PositionedCommentPreview = styled(CommentPreview)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  animation: ${showCommentPreview} 0.2s ease;
`
