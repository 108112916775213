/* global fetch */
import { useState, useEffect, useMemo, useCallback } from 'preact/hooks'
import { API_SERVER } from '@constants'

export default function useShareModal (modalVisible, docId, generateNewIdToken) {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [metadata, setMetadata] = useState({})

  const callbacks = useMemo(() => ({
    onSaveMetadata: (metadata) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId, {
            method: 'PATCH',
            body: JSON.stringify(metadata),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.json())
            .then(setMetadata)
            .catch(err => {
              console.log('Error fetching document metadata', err)
              setIsError(true)
            })
        ))
    ),
    onUpdateUser: (userId, data) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId + '/users/' + userId, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.json())
            .then(setMetadata)
            .catch(err => {
              console.log('Error fetching document metadata', err)
              setIsError(true)
            })
        ))
    ),
    onUpdateInvitedUser: (inviteCode, data) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId + '/invites/' + inviteCode, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.json())
            .then(setMetadata)
            .catch(err => {
              console.log('Error fetching document metadata', err)
              setIsError(true)
            })
        ))
    ),
    onDeleteInvitedUser: (inviteCode) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId + '/invites/' + inviteCode, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.json())
            .then(setMetadata)
            .catch(err => {
              console.log('Error fetching document metadata', err)
              setIsError(true)
            })
        ))
    ),
    onDeleteUser: (userId) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId + '/users/' + userId, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.json())
            .then(setMetadata)
            .catch(err => {
              console.log('Error fetching document metadata', err)
              setIsError(true)
            })
        ))
    ),
    onInviteUser: (permissions, name, email) => (
      generateNewIdToken(false, true)
        .then(userToken => (
          fetch(API_SERVER + '/document-metadata/' + docId + '/invite', {
            method: 'POST',
            body: JSON.stringify({
              displayName: name,
              email,
              permissions
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userToken
            }
          })
            .then(r => r.text())
            .catch(err => {
              console.log('Error inviting user', err)
              setIsError(true)
            })
        ))
    )
  }), [docId])

  const fetchMetadata = useCallback(() => {
    generateNewIdToken(false, true)
      .then(userToken => (
        fetch(API_SERVER + '/document-metadata/' + docId, {
          headers: {
            Authorization: 'Bearer ' + userToken
          }
        })
          .then(r => r.json())
          .then(setMetadata)
          .catch(err => {
            console.log('Error fetching document metadata', err)
            setIsError(true)
          })
          .then(() => setIsLoading(false))
      ))
  }, [docId, generateNewIdToken])

  // Load share modal data
  useEffect(() => {
    if (modalVisible) {
      setIsError(false)
      setIsLoading(true)

      fetchMetadata()
    }
  }, [modalVisible, fetchMetadata])

  return [isLoading, isError, metadata, callbacks, fetchMetadata]
}
