import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { useEffect, useState, useCallback, useRef } from 'preact/hooks'
import { route } from 'preact-router'
import Cookies from 'js-cookie'
import cuid from 'cuid'
import * as bc from 'lib0/broadcastchannel'
import getRandomUserColor from 'utils/getRandomUserColor'
import getAnonymousUsername from 'utils/getAnonymousUsername'
import getAnonymousAvatar from 'utils/getAnonymousAvatar'
import getProviderName from 'utils/getProviderName'
import useOverlayControls from 'hooks/useOverlayControls'
import { updateTrackingUserId, logTrackingEvent } from 'helpers/tracking'
import { ANONYMOUS_USER_ID, SIMULATE_USER_ID } from '@constants'

firebase.initializeApp({
  apiKey: 'AIzaSyDMZeQs8xlV-lAues8uCn6GUm6iMxvXzEA',
  authDomain: 'auth.kludd.co',
  databaseURL: 'https://kluddkluddkluddkludd.firebaseio.com',
  projectId: 'kluddkluddkluddkludd',
  storageBucket: 'kluddkluddkluddkludd.appspot.com',
  messagingSenderId: '921456163645',
  appId: '1:921456163645:web:31cc7d6b8a90e38e056354'
})

export const SHARED_AUTH_BC_CHANNEL = 'kluddAuthBc'

const getMyAnonymousUserSeed = () => {
  let seed = Cookies.get('myAnonymousUserSeed')

  if (!seed) {
    seed = cuid()
    Cookies.set('myAnonymousUserSeed', seed, { expires: 365 })
  }

  return seed
}

export const getIsLoggingIn = () => (
  Cookies.get('isLoggingIn') === 'true'
)

const getUserFromFirebaseUser = (u, idToken) => (
  u
    ? {
        idToken,
        id: SIMULATE_USER_ID || u.uid,
        name: u.displayName,
        email: u.email,
        avatar: u.photoURL,
        providerId: (u.providerData && u.providerData.length > 0 && u.providerData[0].providerId) || null,
        emailVerified: u.emailVerified
      }
    : null
)

export default function useAuth () {
  const [user, setUser] = useState()
  const [userData, setUserData] = useState()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [justSignedIn, setJustSignedIn] = useState(false)
  const isGeneratingIdToken = useRef(false)
  const [hasGotAuthState, setHasGotAuthState] = useState(false) // Used to determine if we've got any state from Firebase
  const [emailAuthModalOpen, setEmailAuthModalOpen] = useOverlayControls(false)

  const userId = (user && user.id) || ANONYMOUS_USER_ID
  const userDataId = (userData && userData.userId)
  const userToken = user && user.idToken
  const providerId = user && user.providerId
  const userEmailVerified = user ? !!user.emailVerified : true // If no user, pretend email is verified to avoid warnings

  const handleSignIn = useCallback((result) => {
    if (result && result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
      const method = getProviderName(result.user && result.user.providerData && result.user.providerData.length > 0 && result.user.providerData[0].providerId)
      logTrackingEvent('Signup completed', { method })
    }

    // Just signed in
    if (result && result.user) {
      setJustSignedIn(true)
    }

    if (getIsLoggingIn()) {
      Cookies.remove('isLoggingIn')
      setIsLoggingIn(false)

      // User is set in onAuthStateChanged, but if no user, make sure we update to refresh preact component
      if (!result || !result.user) {
        setUserData(null)
      }
    }

    return result
  }, [])

  const login = useCallback((providerId) => {
    let provider

    switch (providerId) {
      case 'email': {
        setEmailAuthModalOpen(true)
        return
      }
      case 'google': {
        provider = new firebase.auth.GoogleAuthProvider()
        provider.setCustomParameters({ prompt: 'select_account' })
        break
      }
      case 'apple': {
        provider = new firebase.auth.OAuthProvider('apple.com')
        break
      }
    }

    if (provider) {
      Cookies.set('isLoggingIn', 'true')

      setIsLoggingIn(true)

      firebase.auth().signInWithPopup(provider)
        .then(handleSignIn)
        .catch(err => {
          console.log('Error opening popup to provider.', err)
          Cookies.remove('isLoggingIn')
          setIsLoggingIn(false)
        })
    }
  }, [])

  const signInWithEmailAndPassword = useCallback((email, password) => (
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(handleSignIn)
  ), [])

  const createUserWithEmailAndPassword = useCallback((email, password) => (
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(handleSignIn)
  ), [])

  const applyActionCode = useCallback((oobCode) => (
    firebase.auth().applyActionCode(oobCode)
      .then(() => handleSignIn({ user: true }))
  ), [])

  const logout = useCallback(() => {
    // Redirect to homepage here to avoid anonymous user getting current open doc stored
    route('/workspaces')

    return firebase.auth().signOut()
      .then(() => {
        setJustSignedIn(false)

        if (userId) {
          // TODO: Find documents to remove locally (to prevent unauthorized data viewing)
          // return Promise.all([getUserDocsWithoutObserve(userId), getUserDocsWithoutObserve()])
          //   .then(([myDocs, anonymousDocs]) => {
          //     myDocs = new Map(myDocs)
          //     anonymousDocs = new Map(anonymousDocs)

          //     // Find docs that only logged in user has, and are not also available for anonymous
          //     for (const [key] of myDocs) {
          //       const availableForAnonymous = anonymousDocs.has(key)
          //       console.log(key, availableForAnonymous)
          //       if (!availableForAnonymous) {
          //         // Remove local doc
          //         clearDocument('kludd:' + key)
          //       }
          //     }
          //   })
        }
      })
  }, [userId])

  const getIdToken = useCallback(async (forceRefresh) => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      isGeneratingIdToken.current = false
      return null
    }

    let idToken = null
    isGeneratingIdToken.current = true
    try { idToken = await firebase.auth().currentUser.getIdToken(forceRefresh) } catch (_) {}
    isGeneratingIdToken.current = false

    return idToken
  }, [])

  const updateUserOnAuthChange = useCallback(async (u) => {
    try {
      // Only generate new idToken if existing id token has expired
      // To prevent state refresh (which causes websocket to reconnect with new token)
      const idToken = await getIdToken(/* forceRefresh */ false)
      const user = getUserFromFirebaseUser(u, idToken)

      updateTrackingUserId(user ? user.id : null)

      Cookies.set('user', JSON.stringify(user), { expires: 365 })

      setUser(user)
    } catch (err) {
      console.log('Auth error', err)
    } finally {
      // Set auth state after user is updated (setUser) to make sure routes listening to hasGotAuthState has latest user
      setHasGotAuthState(true)
    }
  }, [])

  // Generate new id token, usually used when id token is old
  // If shouldUpdateState is false, only updates cookie and returns
  // Returns new id token
  const generateNewIdToken = useCallback(async (shouldUpdateState, ignoreCurrentlyGeneratingCheck) => {
    // Prevent generating id tokens multiple times if called simultaneously
    if (isGeneratingIdToken.current && !ignoreCurrentlyGeneratingCheck) { return null }

    const currentUser = firebase.auth().currentUser
    if (!currentUser) return null

    const forceRefresh = !!shouldUpdateState
    const idToken = await getIdToken(forceRefresh)
    const user = getUserFromFirebaseUser(currentUser, idToken)

    Cookies.set('user', JSON.stringify(user), { expires: 365 })

    if (shouldUpdateState) {
      setUser(user)
    }

    return idToken
  }, [])

  const updateProfile = useCallback(({ displayName, photoURL }) => {
    const user = firebase.auth().currentUser

    if (user) {
      return user.updateProfile({ displayName, photoURL })
        .then(() => updateUserOnAuthChange(user))
        .catch(err => console.log('Error updating profile', err))
    }

    return Promise.resolve()
  }, [])

  const uploadAvatarImage = useCallback(async (file/* :File */) => {
    const user = firebase.auth().currentUser

    if (user && user.uid) {
      const fileName = file.type === 'image/png' ? 'avatar.png' : file.type === 'image/jpeg' ? 'avatar.jpg' : null
      if (fileName) {
        const storageRef = firebase.storage().ref()
        const avatarRef = storageRef.child(`userAvatars/${user.uid}/${fileName}`)

        return avatarRef.put(file)
          .then((snapshot) => snapshot.ref.getDownloadURL())
      }
    }

    return null
  }, [])

  const reloadUser = useCallback(() => {
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.reload()
        .then(() => updateUserOnAuthChange(firebase.auth().currentUser))
    } else {
      updateUserOnAuthChange(null)
    }
  }, [updateUserOnAuthChange])

  useEffect(() => {
    // Use BroadcastChannel to allow reload of user across tabs. This is used e.g. when verifying email because it opens in a new tab.
    const handleBcMessage = (message) => {
      switch (message) {
        case 'reloadUser': {
          reloadUser()
          break
        }
        case 'clearJustSignedIn': {
          setJustSignedIn(false)
          break
        }
      }
    }

    bc.subscribe(SHARED_AUTH_BC_CHANNEL, handleBcMessage)
    return () => bc.unsubscribe(SHARED_AUTH_BC_CHANNEL, handleBcMessage)
  }, [reloadUser])

  useEffect(() => {
    // Check if we have user in cookies, to show logged in user faster
    try {
      const user = JSON.parse(Cookies.get('user')) || undefined
      if (user) {
        setUser(user)
      }
    } catch (_) {}

    // Get if currently logging in
    setIsLoggingIn(getIsLoggingIn())

    // Setup firebase listeners
    firebase.auth().onAuthStateChanged(updateUserOnAuthChange)
  }, [])

  useEffect(() => {
    // Generate random color based on cookie-stored seed if not logged in
    const seed = (user && user.id) || getMyAnonymousUserSeed()
    const color = getRandomUserColor(seed)

    if (user) {
      setUserData({
        userId: user.id,
        color: color.background,
        foregroundColor: color.foreground,
        name: user.name,
        avatar: user.avatar
      })
    } else {
      setUserData({
        userId: seed,
        color: color.background,
        foregroundColor: color.foreground,
        name: getAnonymousUsername(seed),
        anonymousAvatar: getAnonymousAvatar(seed),
        isAnonymous: true
      })
    }
  }, [user])

  return {
    user,
    userId,
    userDataId,
    userToken,
    userData,
    isLoggingIn,
    providerId,
    userEmailVerified,
    login,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    logout,
    updateProfile,
    uploadAvatarImage,
    hasGotAuthState,
    generateNewIdToken,
    justSignedIn,
    emailAuthModalOpen,
    setEmailAuthModalOpen,
    applyActionCode,
    reloadUser
  }
}
