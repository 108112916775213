/* global requestAnimationFrame */
import { useRef, useState, useEffect, useCallback } from 'preact/hooks'
import styled from 'styled-components'
import BigRadioButtons from 'components/BigRadioButtons'
import SvgIcon from 'components/SvgIcon'
import Comments from 'components/AboutSidebar/Comments'
import History from 'components/AboutSidebar/History'
import Info from 'components/AboutSidebar/Info'
import Plugins from 'components/AboutSidebar/Plugins'
import ContextMenu from 'components/ContextMenu'
import useCloseOnEsc from 'hooks/useCloseOnEsc'
import useSnapshotContextMenu from 'hooks/useSnapshotContextMenu'

export default function AboutSidebar ({
  readOnly,
  openWithView,
  onClose,
  info,
  usersCachedData,
  userDataId,
  showInfoBar,
  onToggleInfoBar,
  canWriteSnapshots,
  snapshots,
  snapshotMethods,
  snapshotData,
  setSnapshotData,
  setCreateSnapshotModalOpen,
  comments,
  unfilteredComments,
  selectedComment,
  setSelectedComment,
  includeResolvedComments,
  setIncludeResolvedComments,
  setIncludeResolvedCommentsFrom,
  onResolveComment,
  onUnresolveComment,
  onReplyToComment,
  onAddComment,
  availableExports,
  activatedPlugins,
  onSetPluginActive,
  editorMethodsRef,
  codeMirrorBinding,
  isOffline,
  userDoc,
  docId,
  ...props
}) {
  const baseRef = useRef()
  const [view, setView] = useState('info')
  const [useHeaderShadow, setUseHeaderShadow] = useState(false)
  const [addCommentData, setAddCommentData] = useState(null)
  const [snapshotContextMenu, onSnapshotContextMenu, closeSnapshotContextMenu] = useSnapshotContextMenu(snapshotMethods, usersCachedData, setCreateSnapshotModalOpen, snapshotData, setSnapshotData, canWriteSnapshots, onClose)

  // Used to allow opening with specific view
  useEffect(() => {
    setAddCommentData(null)

    if (openWithView && typeof openWithView === 'string') {
      setView(openWithView)
    } else if (openWithView && openWithView.view) {
      setView(openWithView.view)

      if (openWithView.addComment) {
        setSelectedComment(null)
        setAddCommentData(openWithView.addComment)
      }

      if (openWithView.selectedComment) {
        setSelectedComment(openWithView.selectedComment)
      }

      // Scroll to selected comment when opened from outside modal
      if (openWithView.addComment || openWithView.selectedComment) {
        // Scroll in next frame to allow DOM to be updated
        requestAnimationFrame(() => {
          const selectedCommentDom = baseRef.current.querySelector('.comment-selected')
          if (selectedCommentDom) {
            baseRef.current.scroll({
              top: selectedCommentDom.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          }
        })
      }
    }
  }, [openWithView, setSelectedComment])

  // When selecting comment, cancel adding comment
  useEffect(() => {
    if (selectedComment) setAddCommentData(null)
  }, [selectedComment])

  useCloseOnEsc(onClose, !!openWithView)

  useEffect(() => {
    if (view !== 'comments' || !openWithView) {
      // Clear resolved comments when leaving <Comments> part of <AboutSidebar>
      setIncludeResolvedCommentsFrom(0)
    } else if (view === 'comments') {
      // Set timestamp to include when opening comments
      setIncludeResolvedCommentsFrom(Date.now())
    }
  }, [view, openWithView])

  const onScroll = useCallback((el) => {
    if (!el || !el.target) return
    const { scrollTop } = el.target
    setUseHeaderShadow(scrollTop > 10)
  }, [])

  return (
    <Wrapper
      {...props}
      open={!!openWithView}
    >
      <Modal
        ref={baseRef}
        onScroll={onScroll}
        hideOnMobile={!!snapshotContextMenu} // Should hide modal on mobile if context menu is open
      >
        <ModalHeader shadow={useHeaderShadow}>
          <ModalTitle>About this kludd</ModalTitle>

          <CloseButton onClick={onClose}>
            <SvgIcon icon='exit' />
          </CloseButton>
        </ModalHeader>

        <ModalInner>
          <ToggleViewButtons
            fadeNotSelected
            name='view'
            value={view}
            onChange={setView}
            hideLabels
            options={[
              { value: 'comments', label: 'Comments', icon: <SvgIcon icon='comments' /> },
              { value: 'history', label: 'History', icon: <SvgIcon icon='history' /> },
              { value: 'info', label: 'Info', icon: <SvgIcon icon='information' /> },
              { value: 'plugins', label: 'Plugins', icon: <SvgIcon icon='plugin' /> }
            ]}
          />

          {view === 'comments' && (
            <Comments
              comments={comments}
              unfilteredComments={unfilteredComments}
              selectedComment={selectedComment}
              setSelectedComment={setSelectedComment}
              includeResolvedComments={includeResolvedComments}
              setIncludeResolvedComments={setIncludeResolvedComments}
              setIncludeResolvedCommentsFrom={setIncludeResolvedCommentsFrom}
              onResolveComment={onResolveComment}
              onUnresolveComment={onUnresolveComment}
              onReplyToComment={onReplyToComment}
              onAddComment={message => {
                onAddComment(message)
                setAddCommentData(null)
              }}
              onCancelAddComment={() => setAddCommentData(null)}
              addCommentData={addCommentData}
              editorMethodsRef={editorMethodsRef}
              codeMirrorBinding={codeMirrorBinding}
              readOnly={readOnly}
              usersCachedData={usersCachedData}
            />
          )}

          {view === 'history' && (
            <History
              canWriteSnapshots={canWriteSnapshots}
              snapshots={snapshots}
              snapshotMethods={snapshotMethods}
              snapshotData={snapshotData}
              setSnapshotData={setSnapshotData}
              setCreateSnapshotModalOpen={setCreateSnapshotModalOpen}
              usersCachedData={usersCachedData}
              isOffline={isOffline}
              onSnapshotContextMenu={onSnapshotContextMenu}
              onClose={onClose}
              open={!!openWithView}
              userDoc={userDoc}
              docId={docId}
            />
          )}

          {view === 'info' && (
            <Info
              info={info}
              userDataId={userDataId}
              showInfoBar={showInfoBar}
              onToggleInfoBar={onToggleInfoBar}
            />
          )}

          {view === 'plugins' && (
            <Plugins
              availableExports={availableExports}
              activatedPlugins={activatedPlugins}
              onSetPluginActive={onSetPluginActive}
              readOnly={readOnly}
            />
          )}
        </ModalInner>
      </Modal>

      {snapshotContextMenu && (
        <ContextMenu
          top={snapshotContextMenu.top}
          left={snapshotContextMenu.left}
          options={snapshotContextMenu.options}
          title={snapshotContextMenu.title}
          context={snapshotContextMenu.context}
          onClose={closeSnapshotContextMenu}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
  ${p => !p.open && 'opacity: 0; visibility: hidden; transform: translateY(50%);'}
  transform-origin: center;
  @media (min-width: 650px){
    ${p => !p.open && 'transform: translateX(1rem) scale(1.05);'}
    transform-origin: left;
  }
  `
const Modal = styled.div`
  position: relative;
  background: white;
  color: #3d4f50;
  border-radius: 2rem 2rem 0 0;
  @media (min-width: 650px){
    border-radius: 2rem;
  }
  box-shadow: 0 0.4rem 4rem rgba(61, 79, 80, 0.1);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  .darkMode & {
    color: #f3f9fe;
    background: #1d232b;
    box-shadow: 0 0.4rem 4rem rgba(0, 0, 0, 0.15);
  }
  transition: opacity .3s ease, visibility .3s ease, background .3s ease, color .3s ease;

  ${p => p.hideOnMobile && `
    @media screen and (max-width: 649px) {
      opacity: 0;
      visibility: hidden;
    }
  `}
`
const ModalHeader = styled.div`
  padding: 2rem;
  margin: 0 -2rem 2rem;
  box-shadow: none;
  position: sticky;
  top: 0;
  z-index: 5;
  background: inherit;

  &:after {
    content: '';
    height: 0.1rem;
    transition: background .3s ease;
    background: rgba(61, 79, 80, 0.1);
    position: absolute;
    bottom: 0;
    left: 2rem;
    right: 2rem;
  }
  .darkMode &:after { background: rgba(255, 255, 255, 0.1); }

  ${p => p.shadow && `
  &:after, .darkMode &:after { background: transparent; }
  box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
  .darkMode & { box-shadow: 0 -0.4rem 2rem rgba(255, 255, 255, 0.1); }
`}
`
const ModalInner = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`
const ModalTitle = styled.h2`
  font-size: 1.6rem;
  line-height: 2rem;
`
const CloseButton = styled.button`
  border: none;
  appearance: none;
  background: transparent;
  display: block;
  position: absolute;
  padding: 0.1rem;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #3d4f50;
  svg { display: block; }

  .darkMode & { color: white; }
`
const ToggleViewButtons = styled(BigRadioButtons)`margin-bottom: 2rem;`
