import * as Y from 'yjs'
import fixMissingItem from 'utils/fixMissingItem'

// Check if selected text in comment is deleted by looking at codemirror content
export default function isCommentSelectedTextDeleted (textType, codeMirror, anchor, head) {
  const _anchor = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(anchor), textType.doc)
  const _head = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(head), textType.doc)

  if (_anchor !== null && _head !== null && _anchor.type === textType && _head.type === textType) {
    const headpos = codeMirror.posFromIndex(_head.index)
    const anchorpos = codeMirror.posFromIndex(_anchor.index)

    // If line and character is equal, it means the text is removed, since no text is inside selection anymore
    return (headpos.line === anchorpos.line && headpos.ch === anchorpos.ch)
  }

  return false
}
