import styled from 'styled-components'
import * as Y from 'yjs'
import { debounce } from 'throttle-debounce'
import { useState, useMemo, useEffect, useCallback } from 'preact/hooks'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import Comment from 'components/Comment'
import EmptyViewNotice from 'components/EmptyViewNotice'
import formatDate from 'utils/formatDate'
import fixMissingItem from 'utils/fixMissingItem'
import CustomRange from 'helpers/CustomRange'

const sortOnCreatedLatestFirst = (a, b) => b.created.timestamp - a.created.timestamp

export default function Comments ({
  comments,
  unfilteredComments,
  selectedComment,
  setSelectedComment,
  includeResolvedComments,
  setIncludeResolvedComments,
  setIncludeResolvedCommentsFrom,
  onResolveComment,
  onUnresolveComment,
  onReplyToComment,
  onAddComment,
  onCancelAddComment,
  addCommentData,
  editorMethodsRef,
  codeMirrorBinding,
  readOnly,
  usersCachedData
}) {
  // Hash is updated when text changes, debounced. Use to trigger rerenders in comments.
  const [updateFromChangedTextHash, setUpdateFromChangedTextHash] = useState(0)

  const commentsWithAddingComment = useMemo(() => {
    if (addCommentData) {
      return [
        {
          id: 'adding-comment',
          isAddingComment: true,
          ...addCommentData
        }
      ].concat(comments)
    }

    return [].concat(comments)
  }, [comments, addCommentData])

  const onShowResolved = useCallback((checked) => {
    setIncludeResolvedComments(checked)

    // Update timestamp when toggling resolved
    setIncludeResolvedCommentsFrom(Date.now())
  }, [setIncludeResolvedComments])

  // Categorize comments based on created date
  // Comments are assumed to be in order
  const categorizedComments = useMemo(() => {
    const dates = commentsWithAddingComment
      .sort(sortOnCreatedLatestFirst)
      .reduce((result, comment) => {
        if (!comment.created) return result

        const date = formatDate(comment.created.timestamp, 'YYYYMMDD')
        if (!result[date]) result[date] = []
        result[date].push(comment)
        return result
      }, {})

    return Object.keys(dates)
      .reduce((result, key) => {
        result.push({ key, date: new Date(key), comments: dates[key] })
        return result
      }, [])
  }, [commentsWithAddingComment])

  const onSelectComment = useCallback((comment) => () => {
    const didSelect = !(selectedComment && comment.id === selectedComment)
    setSelectedComment(didSelect ? comment.id : null)

    // Scroll to comment
    if (didSelect) {
      const codeMirror = codeMirrorBinding.cm
      const textType = codeMirrorBinding.doc.getText('content')
      const anchor = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(comment.anchor), textType.doc)
      const head = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(comment.head), textType.doc)

      if (anchor !== null && head !== null && anchor.type === textType && head.type === textType) {
        const headpos = codeMirror.posFromIndex(head.index)
        const anchorpos = codeMirror.posFromIndex(anchor.index)
        const isSelectedTextDeleted = (headpos.line === anchorpos.line && headpos.ch === anchorpos.ch)

        // Don't scroll if selected text is deleted
        if (!isSelectedTextDeleted) {
          const range = CustomRange(headpos, anchorpos)
          const coords = codeMirror.cursorCoords(range.from(), 'local')
          const forceScroll = true
          const activityFromUserAction = true
          const forceSmoothScroll = true

          editorMethodsRef.current.onCursorCoords(coords, forceScroll, activityFromUserAction, forceSmoothScroll)
        }
      }
    }
  }, [editorMethodsRef, codeMirrorBinding, selectedComment])

  // Check if selected text for a comment has been removed when codemirror updates
  useEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm) return

    const codeMirror = codeMirrorBinding.cm

    // Debounce to not check too often
    const onUpdate = debounce(1000, () => {
      // Update hash to trigger new check in comments
      setUpdateFromChangedTextHash(Math.random())
    })
    codeMirror.on('change', onUpdate)

    return () => {
      onUpdate.cancel()
      codeMirror.off('change', onUpdate)
    }
  }, [codeMirrorBinding])

  const showEmptyState = !unfilteredComments.length && !addCommentData
  const showEmptyUnresolvedState = !comments.length && !addCommentData

  return (
    <>
      {!!unfilteredComments.length && (
        <Header>
          <CheckboxWithLabel
            checked={includeResolvedComments}
            onChecked={onShowResolved}
            label='Show resolved comments'
          />
        </Header>
      )}

      {categorizedComments.map(cat => (
        <Category key={cat.key}>
          <CategoryHeader><span><span>{formatDate(cat.date, 'DAY')}</span></span></CategoryHeader>
          {cat.comments.map(comment => (
            <CommentWithMargin
              key={comment.id}
              onSelect={onSelectComment(comment)}
              onResolve={() => onResolveComment(comment.id)}
              onUnresolve={() => onUnresolveComment(comment.id)}
              onReply={(message) => onReplyToComment(comment.id, message)}
              onAddComment={onAddComment}
              onCancelAddComment={onCancelAddComment}
              updateFromChangedTextHash={updateFromChangedTextHash}
              codeMirrorBinding={codeMirrorBinding}
              readOnly={readOnly}
              isSelected={comment.id === selectedComment}
              usersCachedData={usersCachedData}
              {...comment}
            />
          ))}
        </Category>
      ))}

      {showEmptyState
        ? (
          <EmptyViewNotice
            icon='add-comment'
            message={<Message>There are no comments in this kludd yet</Message>}
          />
          )
        : showEmptyUnresolvedState && (
          <EmptyViewNotice
            icon='add-comment'
            message={<Message>There are no unresolved comments in this kludd</Message>}
          />
        )}
    </>
  )
}

const Header = styled.div`
  border-bottom: 1px solid rgba(61, 79, 80, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`
const Category = styled.section``
const CategoryHeader = styled.h3`
  position: relative;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;

  > span {
    display: inline-block;
    padding: 0 1rem;
    position: relative;
    transition: background .3s ease, color .3s ease;
    background: white;
    .darkMode & { background: #1d232b; }
  }
  > span > span { opacity: 0.5; }

  &:before {
    content: '';
    border-top: 1px dashed;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    opacity: 0.1;
  }
`
const CommentWithMargin = styled(Comment)`
  margin-bottom: 1rem;
`
const Message = styled.p`
  max-width: 15rem;
`
