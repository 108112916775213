import * as Y from 'yjs'
import * as time from 'lib0/dist/time.cjs'

// Counts amount of undos to do while Y.UndoManager never merges transactions
const CustomUndoManager = (typeScope, trackedOrigins) => {
  const history = []
  let historyIndex = -1
  let lastChange = 0

  const undoManager = new Y.UndoManager(typeScope, { captureTimeout: 0, trackedOrigins })
  const captureTimeout = 500
  const scope = [typeScope]

  typeScope.doc.on('afterTransaction', transaction => {
    if (!scope.some(type => transaction.changedParentTypes.has(type)) || (!trackedOrigins.has(transaction.origin) && (!transaction.origin || !trackedOrigins.has(transaction.origin.constructor)))) {
      return
    }

    if (!undoManager.undoing && !undoManager.redoing) {
      const now = time.getUnixTime()
      if (historyIndex < history.length - 1) {
        history.splice(historyIndex + 1)
      }
      if (now - lastChange < captureTimeout && history.length > 0) {
        history[history.length - 1] += 1
      } else {
        history.push(1)
        historyIndex = history.length - 1
      }
      lastChange = now
    }
  })

  return {
    undoManager,
    undo: () => {
      if (historyIndex >= 0) {
        const undos = history[historyIndex]
        for (let i = 0; i < undos; i++) undoManager.undo()
        historyIndex -= 1
      }
    },
    redo: () => {
      const redos = history[historyIndex + 1]
      if (redos != null) {
        for (let i = 0; i < redos; i++) undoManager.redo()
        historyIndex += 1
      }
    },
    destroy: () => {
      undoManager.destroy()
    }
  }
}

export default CustomUndoManager
