/* global IDBFactory, IDBDatabase */
// From https://gist.github.com/rmehner/b9a41d9f659c9b1c3340#gistcomment-3449418
/**
 * Polyfill for indexedDB.databases()
 * Firefox and some other older browsers that support indexedDB do NOT
 * Support enumerating existing databases. This is problematic when it
 * comes time to cleanup, otherwise we could litter their device with
 * unreferenceable database handles forcing a nuclear browser clear all history.
 */

// Stores databases in localStorage as { key: version + ':' + latestOpenedTimestamp }

export default () => {
  // if (window.indexedDB && typeof window.indexedDB.databases === 'undefined') {

  // Always apply polyfill to fix issue in Safari Incognito reporting empty databases()
  if (window.indexedDB) {
    console.log('Apply indexedDB.databases() polyfill')

    const LOCALSTORAGE_CACHE_KEY = 'indexedDBDatabases'

    const currenlyOpenDatabases = new Set()

    // Store a key value map of databases
    const getFromStorage = () =>
      JSON.parse(window.localStorage[LOCALSTORAGE_CACHE_KEY] || '{}')

    // Write the database to local storage
    const writeToStorage = value =>
      (window.localStorage[LOCALSTORAGE_CACHE_KEY] = JSON.stringify(value))

    // Keep reference to real databases if it exists
    const databases = IDBFactory.prototype.databases

    IDBFactory.prototype.databases = function () {
      return (
        Promise.all([
          Object.entries(getFromStorage()).reduce((acc, [name, versionAndTimestamp]) => {
            // Make sure versionAndTimestamp is a string and split
            const [versionString] = (versionAndTimestamp + '').split(':')
            const version = parseInt(versionString)
            acc.push({ name, version })
            return acc
          }, []),
          // Append actual databases if available
          typeof databases === 'undefined' ? [] : databases.apply(this)
        ])
          .then(([customStorage, nativeStorage]) => {
            // Merge customStorage and nativeStorage and remove duplicates (prefer nativeStorage if available)
            return customStorage.reduce((total, db) => (
              // Only include if it doesn't exist already
              total.find(db2 => db2.name === db.name)
                ? total
                : total.concat(db)
            ), nativeStorage)
          })
      )
    }

    // Custom method to fetch databases with latest opened timestamp
    IDBFactory.prototype.databasesWithTimestamp = function () {
      return (
        Object.entries(getFromStorage()).reduce((acc, [name, versionAndTimestamp]) => {
          // Make sure versionAndTimestamp is a string and split
          const [versionString, timestampString] = (versionAndTimestamp + '').split(':')
          const version = parseInt(versionString)
          const timestamp = parseInt(timestampString) || 0
          acc.push({ name, version, timestamp })
          return acc
        }, [])
      )
    }

    // Intercept the existing open handler to write our DBs names
    // and versions to localStorage
    const open = IDBFactory.prototype.open

    IDBFactory.prototype.open = function (...args) {
      const dbName = args[0]
      currenlyOpenDatabases.add(dbName)
      const version = args[1] || 1
      const existing = getFromStorage()
      const latestOpenedTimestamp = Date.now()
      writeToStorage({ ...existing, [dbName]: version + ':' + latestOpenedTimestamp })
      return open.apply(this, args)
    }

    // Intercept the existing close handler to remember what tables are currently open
    const close = IDBDatabase.prototype.close
    IDBDatabase.prototype.close = function (...args) {
      currenlyOpenDatabases.delete(this.name)
      return close.apply(this, args)
    }

    IDBFactory.prototype.currenlyOpenDatabases = currenlyOpenDatabases

    // Intercept the existing deleteDatabase handler remove our
    // dbNames from localStorage
    const deleteDatabase = IDBFactory.prototype.deleteDatabase

    IDBFactory.prototype.deleteDatabase = function (...args) {
      const dbName = args[0]
      const existing = getFromStorage()
      delete existing[dbName]
      writeToStorage(existing)
      return deleteDatabase.apply(this, args)
    }
  }
}
