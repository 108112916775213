import { useEffect, useState } from 'preact/hooks'
import { WebsocketProvider } from 'y-websocket'
import * as encoding from 'lib0/dist/encoding.cjs'
import * as decoding from 'lib0/dist/decoding.cjs'
import * as syncProtocol from 'y-protocols/sync.js'
import { ANONYMOUS_USER_ID, SOCKET_SERVER, WEBSOCKET_AUTH_NO_READ, WEBSOCKET_AUTH_DELETED_DOCUMENT, WEBSOCKET_AUTH_INVALID_TOKEN, MESSAGE_PERMISSIONS, MESSAGE_SYNCED_WITH_DATABASE, MESSAGE_SNAPSHOTS, MESSAGE_SNAPSHOTS_DIFF, MESSAGE_SNAPSHOTS_MESSAGE } from '@constants'
import isValidDocId from 'utils/isValidDocId'

const resyncProvider = (provider) => {
  try {
    if (provider.ws) {
      const messageSync = 0
      const encoder = encoding.createEncoder()
      encoding.writeVarUint(encoder, messageSync)
      syncProtocol.writeSyncStep1(encoder, provider.doc)
      provider.ws.send(encoding.toUint8Array(encoder))
    }
  } catch (_) {
    // Error can happen on .send() if websocket is not connected yet but it's harmless and happens due to timer intervals being fired before connected
    // Catch it here to avoid noise in error logs
  }
}

const compileUsers = (provider, onUsers) => {
  const users = Array.from(provider.awareness.getStates())
    .map(([clientId, { user }]) => ({
      clientId,
      me: clientId === provider.doc.clientID,
      ...user
    }))
    .sort((a, b) => a.clientId < b.clientId ? -1 : 1)

  if (onUsers) onUsers(users)
}

export default function useWebsocketProvider (doc, roomName, docId, userToken, userData, generateNewIdToken, ignoreCheckingValidDocId, onProvider, setSnapshots, setSnapshotData) {
  const [_provider, setProvider] = useState()
  const [isConnected, setIsConnnected] = useState(false)
  const [authRejectionCode, setAuthRejectionCode] = useState(0)
  const [users, setUsers] = useState([])
  const [permissions, setPermissions] = useState('rw')
  const [isSynced, setIsSynced] = useState(false)
  const [snapshotMethods, setSnapshotMethods] = useState({})
  const [_latestClientIdForUserId, setLatestClientIdForUserId] = useState({})

  useEffect(() => {
    // Check that docId is valid if provided
    if (docId && !ignoreCheckingValidDocId && !isValidDocId(docId)) return

    // No need to sync anonymous users, will fail anyway without token
    if (roomName === 'user:' + ANONYMOUS_USER_ID) return
    if (roomName === 'kludd_user:' + ANONYMOUS_USER_ID) return

    if (doc) {
      // Make sure docId matches document docId, to avoid syncing with wrong document
      if (docId && docId !== doc.docId) return

      const provider = new WebsocketProvider(
        SOCKET_SERVER,
        roomName,
        doc.doc,
        {
          params: userToken ? { token: userToken } : undefined,
          // resyncInterval is still needed even though we resync after 1s and 5s, sometimes that's not enough
          resyncInterval: 20000
        }
      )
      if (onProvider) { onProvider(provider) }
      const myUserId = userData && userData.userId
      let latestClientIdForUserId = {}

      setIsConnnected(false)
      setProvider(provider)
      setAuthRejectionCode(0)
      setPermissions('rw') // default to 'rw' for newly created documents

      // Handle readOnly message
      provider.messageHandlers[MESSAGE_PERMISSIONS] = (encoder, decoder, provider, emitSynced, messageType) => {
        setPermissions(decoding.readVarString(decoder))
      }

      // Handle custom synced message to handle i.e. user terms
      provider.messageHandlers[MESSAGE_SYNCED_WITH_DATABASE] = (encoder, decoder, provider, emitSynced, messageType) => {
        setIsSynced(decoding.readUint8(decoder) === 1)
      }

      // Handle snapshots
      provider.messageHandlers[MESSAGE_SNAPSHOTS] = (encoder, decoder, provider, emitSynced, messageType) => {
        const snapshots = Array.from({ length: decoding.readVarUint(decoder) }, () => ({
          id: decoding.readVarString(decoder),
          name: decoding.readVarString(decoder),
          description: decoding.readVarString(decoder),
          userId: decoding.readVarString(decoder),
          timestamp: decoding.readFloat64(decoder),
          autosave: decoding.readVarUint(decoder) === 1,
          restored: decoding.readVarUint(decoder) === 1
        }))

        console.log('Got snapshots', snapshots)
        setSnapshots(snapshots)
      }

      // Handle snapshot diffs
      provider.messageHandlers[MESSAGE_SNAPSHOTS_DIFF] = (encoder, decoder, provider, emitSynced, messageType) => {
        const id = decoding.readVarString(decoder)
        const name = decoding.readVarString(decoder)
        // const content = decoding.readVarString(decoder)
        const diffs = Array.from({ length: decoding.readVarUint(decoder) }, () => ({
          type: decoding.readVarUint(decoder),
          // id: new Y.ID(decoding.readVarUint(decoder), decoding.readVarUint(decoder)),
          content: decoding.readVarString(decoder)
        }))

        setSnapshotData({ snapshot: { id, name, diffs } })
      }

      // Handle snapshot error message
      provider.messageHandlers[MESSAGE_SNAPSHOTS_MESSAGE] = (encoder, decoder, provider, emitSynced, messageType) => {
        const nonce = decoding.readVarString(decoder)
        const error = decoding.readVarString(decoder)

        setSnapshotData({ nonce, error })
      }

      const createSnapshot = (name, description, nonce) => {
        if (!name) return

        try {
          const messageSnapshotCreate = 3
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotCreate)
          encoding.writeVarString(encoder, name)
          encoding.writeVarString(encoder, description || '')
          encoding.writeVarString(encoder, nonce || '')
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }
      }
      const updateSnapshot = (snapshotId, name, description, nonce) => {
        if (!snapshotId || !name) return

        try {
          const messageSnapshotUpdate = 8
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotUpdate)
          encoding.writeVarString(encoder, snapshotId)
          encoding.writeVarString(encoder, name)
          encoding.writeVarString(encoder, description || '')
          encoding.writeVarString(encoder, nonce || '')
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }
      }
      const getSnapshotList = () => {
        try {
          const messageSnapshotList = 4
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotList)
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }
      }
      const getSnapshotDiffs = (snapshotId) => {
        try {
          const messageSnapshotDiffs = 5
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotDiffs)
          encoding.writeVarString(encoder, snapshotId)
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }
      }
      const removeSnapshot = (snapshotId) => {
        try {
          const messageSnapshotRemove = 6
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotRemove)
          encoding.writeVarString(encoder, snapshotId)
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }
      }
      const restoreSnapshot = (snapshotId) => {
        try {
          const messageSnapshotRestore = 7
          const encoder = encoding.createEncoder()
          encoding.writeVarUint(encoder, messageSnapshotRestore)
          encoding.writeVarString(encoder, snapshotId)
          provider.ws.send(encoding.toUint8Array(encoder))
        } catch (_) {
        }

        setSnapshotData(null)
      }

      setSnapshotMethods({
        createSnapshot,
        updateSnapshot,
        getSnapshotList,
        getSnapshotDiffs,
        removeSnapshot,
        restoreSnapshot
      })

      // Monkey patch websocket provider to prevent reconnection on auth reject
      const monkeyPatchWebsocket = () => {
        const providerOnClose = provider.ws.onclose
        provider.ws.onclose = (ev) => {
          if (ev.code === WEBSOCKET_AUTH_NO_READ || ev.code === WEBSOCKET_AUTH_DELETED_DOCUMENT || ev.code === WEBSOCKET_AUTH_INVALID_TOKEN) {
            // Calling disconnect prevents reconnect attempts
            provider.disconnect()
            setAuthRejectionCode(ev.code)

            if (ev.code === WEBSOCKET_AUTH_NO_READ) {
              console.log('No read!')
            } else if (ev.code === WEBSOCKET_AUTH_DELETED_DOCUMENT) {
              console.log('Document deleted!')
            } else if (ev.code === WEBSOCKET_AUTH_INVALID_TOKEN) {
              generateNewIdToken(true, false)
            }
          }

          providerOnClose(ev)
        }
      }

      provider.on('status', (ev) => {
        switch (ev.status) {
          case 'connecting':
            monkeyPatchWebsocket()
            break

          case 'connected':
            setIsConnnected(true)
            compileUsers(provider, setUsers)

            // Trigger resync some time after connection to fix issue where first sync message is sometimes lost, see
            // https://github.com/yjs/y-websocket/issues/19
            // https://github.com/yjs/y-websocket/issues/31#issuecomment-706562929
            // Code is cloned from resyncInterval, but add here also to react a little bit faster
            setTimeout(() => resyncProvider(provider), 1000)
            setTimeout(() => resyncProvider(provider), 5000) // Do it again after 5s just to make sure, probably not needed but whatever
            setTimeout(() => resyncProvider(provider), 10000)
            break

          case 'disconnected':
            setIsConnnected(false)
            break
        }
      })

      // Update connected users when awareness changes
      provider.awareness.on('change', (ev, origin) => {
        const awarenessStates = provider.awareness.getStates()
        for (const updatedClientId of ev.added.concat(ev.updated)) {
          const state = awarenessStates.get(updatedClientId)
          const userId = state && state.user && state.user.userId

          if (updatedClientId && userId) {
            // My local clientId should always be considered my latest client id locally
            const clientId = userId === myUserId ? provider.doc.clientID : updatedClientId

            latestClientIdForUserId = {
              ...latestClientIdForUserId,
              [userId]: clientId
            }
            setLatestClientIdForUserId(latestClientIdForUserId)
          }
        }

        if (ev.added.length || ev.updated.length || ev.removed.length) {
          compileUsers(provider, setUsers)
        }
      })

      return () => {
        setIsConnnected(false)
        setIsSynced(false)
        setProvider(null)
        setSnapshots(null)

        provider.awareness.destroy() // Memory leak. https://github.com/yjs/y-websocket/issues/68#issuecomment-846410518
        provider.destroy()
      }
    } else {
      setIsConnnected(false)
      setIsSynced(false)
      setProvider(null)
    }
  }, [doc, roomName, userToken])

  useEffect(() => {
    if (_provider && userData) {
      _provider.awareness.setLocalStateField('user', userData)
      compileUsers(_provider, setUsers)
    }
  }, [_provider, userData])

  return [_provider, isConnected, users, _latestClientIdForUserId, authRejectionCode, permissions, isSynced, snapshotMethods]
}
