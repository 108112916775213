import styled from 'styled-components'

export default ({ autoExpand, className, value, ...props }) => {
  if (autoExpand) {
    return (
      <Wrapper className={className}>
        <Input autoExpand value={value} {...props} />
        <ResizeText>{value}</ResizeText>
      </Wrapper>
    )
  }

  return <Input className={className} value={value} {...props} />
}

const Input = styled.input`
  display: block;
  appearance: none;
  padding: 0 0 0.5rem;
  background: inherit;
  border: 0;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.2);
  font: inherit;
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  transition: border .2s ease;
  ${p => p.autoExpand && 'position: absolute; top: 0; left: 0; width: 100%; text-align: center;'}

  .darkMode & {
    border-bottom-color: rgba(243, 249, 254, 0.1);
  }

  &:focus {
    outline: none;
    border-bottom-color: rgba(61, 79, 80, 0.8);
  }
  .darkMode &:focus {
    border-bottom-color: rgba(243, 249, 254, 0.8);
  }
`
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  font-size: 0;
  overflow: hidden;
`
const ResizeText = styled.p`
  font: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 0 0 0.5rem;
  display: inline-block;
  white-space: pre;
  min-width: 15rem;
  height: 2.6rem;
  pointer-events: none;
  opacity: 0;
`
