import { useState, useLayoutEffect, useEffect, useRef } from 'preact/hooks'
import { debounce } from 'throttle-debounce'
import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'
import Loader from 'components/Loader'

export default function ContextMenu ({ top, left, options, title, context, onClose, positionedToTheRight }) {
  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth)
  const [screenHeight, setScreenHeight] = useState(document.documentElement.clientHeight)
  const modalRef = useRef()
  const modalWidth = 270
  const [modalHeight, setModalHeight] = useState(100)
  const isPositioned = top != null && left != null
  const cappedTop = Math.min(
    positionedToTheRight
      ? top || 0
      : top + 10 || 0,
    screenHeight - modalHeight - 20
  )
  const cappedLeft = Math.min(
    positionedToTheRight
      ? left + 10 || 0
      : left - (modalWidth / 2) || 0,
    screenWidth - modalWidth - 20
  )

  // Calculate modal height on mount
  useLayoutEffect(() => {
    setModalHeight(modalRef.current.getBoundingClientRect().height)
  }, [])

  // Calculate client size on resize
  useEffect(() => {
    const onResize = debounce(500, () => {
      setScreenWidth(document.documentElement.clientWidth)
      setScreenHeight(document.documentElement.clientHeight)
    })
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  // Capture clicks outside menu
  useEffect(() => {
    if (!onClose) return

    const onMouseDown = (ev) => {
      if (!modalRef.current.contains(ev.target)) {
        onClose()
      }
    }

    window.addEventListener('mousedown', onMouseDown)
    window.addEventListener('touchstart', onMouseDown)

    return () => {
      window.removeEventListener('mousedown', onMouseDown)
      window.removeEventListener('touchstart', onMouseDown)
    }
  }, [onClose])

  return (
    <ModalWrapper isPositioned={isPositioned}>
      <Modal
        isPositioned={isPositioned}
        style={isPositioned && { top: cappedTop, left: cappedLeft }}
        ref={modalRef}
      >
        {!!title && (
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>

            <CloseButton onClick={onClose}>
              <SvgIcon icon='exit' />
            </CloseButton>
          </ModalHeader>
        )}
        {!!context && (
          <ContextWrapper>
            {context}
          </ContextWrapper>
        )}
        <TabContent>
          {options.map(option => (
            option.divider
              ? <Divider onlyVisibleOnMobile={option.onlyVisibleOnMobile} />
              : (
                <Option
                  onClick={() => {
                    if (!option.loading && !option.disabled) {
                      option.action()
                      if (onClose) onClose()
                    }
                  }}
                  loading={!!option.loading}
                  disabled={!!option.disabled}
                  isDeleteAction={!!option.deleteDocumentOption}
                  onlyVisibleOnMobile={option.onlyVisibleOnMobile}
                >
                  {!!option.loading && <OptionLoader fadeIn secondsBeforeRotating={1} />}
                  {!!option.icon && <OptionIcon icon={option.icon} />}
                  {!!option.iconSrc && <OptionIconSrc src={option.iconSrc} />}
                  <OptionLabel>{option.label}</OptionLabel>
                  {!!option.disabled && !!option.disabledMessage && (
                    <OptionDisabledMessage>{option.disabledMessage}</OptionDisabledMessage>
                  )}
                </Option>
                )
          ))}
        </TabContent>
      </Modal>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  ${p => p.isPositioned && `
    @media screen and (max-width: 649px) {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      padding: env(safe-area-inset-top) 0 0 0;

      background: rgba(248, 248, 249, 0.8);
      .darkMode & { background: rgba(48, 56, 64, 0.8); }
    }
  `}
`
const Modal = styled.div`
  width: 27rem;
  padding: 1rem;
  background: white;
  color: #3d4f50;
  box-shadow: 0 .4rem 2rem rgba(61, 79, 80, 0.15);
  box-sizing: border-box;
  border-radius: 2rem;
  z-index: 12;

  .darkMode & {
    color: #f3f9fe;
    background: #1d232b;
    box-shadow: 0 .4rem 2rem rgba(0, 0, 0, 0.15);
  }

  ${p => p.isPositioned && `
    position: fixed;

    @media screen and (max-width: 649px) {
      margin: auto 0 0;
      padding: 2rem;
      padding-bottom: calc(env(safe-area-inset-bottom) + 2rem);
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`
const ModalHeader = styled.div`
  @media screen and (min-width: 650px) {
    display: none;
  }

  padding: 0 0 2rem;
  margin: 0 0 1rem;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.1);
  box-shadow: none;
  transition: box-shadow .2s ease;
  text-align: center;
  z-index: 1;
`
const ModalTitle = styled.h2`
  font-size: 1.8rem;
  line-height: 2rem;

  @media screen and (max-width: 649px) {
    font-size: 1.6rem;
  }
`
const CloseButton = styled.button`
  border: none;
  appearance: none;
  background: transparent;
  display: block;
  position: absolute;
  padding: 0.1rem;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #3d4f50;
  svg { display: block; }

  .darkMode & { color: white; }
`
const TabContent = styled.div``
const OptionIcon = styled(SvgIcon)`
  margin-right: 1rem;
  transition: opacity 0.2s ease;
`
const OptionIconSrc = styled.img`
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  transition: opacity 0.2s ease;
`
const OptionLabel = styled.p`
  font-size: 1.3rem;
  line-height: 1.6rem;
  transition: opacity 0.2s ease;
`
const OptionDisabledMessage = styled.p`
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.6rem;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;
`
const OptionLoader = styled(Loader)`
  position: absolute;
  top: calc(50% - 1.2rem);
  left: 0.6rem;
  width: 3rem;
`
const Option = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
  transition: background .2s ease;
  &:hover { background: #F8F8F9; }
  .darkMode &:hover { background: #181d23; }

  &:hover ${OptionDisabledMessage} { opacity: 0.6; visibility: visible; }

  ${p => p.isDeleteAction && `
    color: #ff6e6e;
    &:hover, .darkMode &:hover { background: rgba(255, 110, 110, 0.05); }
  `}
  ${p => p.loading && 'pointer-events: none;'}
  ${p => p.disabled && 'cursor: not-allowed;'}
  ${p => (p.loading || p.disabled) && `${OptionLabel} { opacity: 0.4; }`}
  ${p => (p.loading || p.disabled) && `${OptionIcon} { opacity: 0.4; }`}
  ${p => p.onlyVisibleOnMobile && '@media screen and (min-width: 650px) { display: none; }'}
`
const Divider = styled.div`
  height: 0.1rem;
  background: rgba(151, 151, 151, 0.2);
  margin: 1rem 0;

  ${p => p.onlyVisibleOnMobile && '@media screen and (min-width: 650px) { display: none; }'}
`
const ContextWrapper = styled.div`
  @media screen and (min-width: 650px) {
    display: none;
  }

  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
`
