export default (id) => {
  if (!id) return false

  const validLength = 1 + 9 + 4 + 4 + 8

  if (id.length !== validLength) return false
  if (!id.startsWith('c')) return false

  return true
}
