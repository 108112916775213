import { useState, useCallback } from 'preact/hooks'
import Snapshot from 'components/Snapshot'

export default function useSnapshotContextMenu (snapshotMethods, usersCachedData, setCreateSnapshotModalOpen, snapshotData, setSnapshotData, canWriteSnapshots, onCloseAboutSidebar) {
  const [contextMenu, setContextMenu] = useState(null)

  const showSnapshot = useCallback((snapshot) => {
    // Select snapshot
    snapshotMethods.getSnapshotDiffs(snapshot.id)

    // Close modal to allow mobile users to see the snapshot result
    onCloseAboutSidebar()
  }, [setCreateSnapshotModalOpen, onCloseAboutSidebar])

  const editSnapshot = useCallback((snapshot) => {
    setCreateSnapshotModalOpen({ snapshotId: snapshot.id, title: snapshot.name, description: snapshot.description })
  }, [setCreateSnapshotModalOpen])

  const deleteSnapshot = useCallback((snapshotId) => {
    const isDeletingSelectedSnapshot = snapshotId === snapshotData?.snapshot?.id

    // Deselect if deleting
    if (isDeletingSelectedSnapshot) {
      setSnapshotData(null)
    }

    snapshotMethods.removeSnapshot(snapshotId)
  }, [snapshotMethods, snapshotData])

  const onContextMenu = useCallback(async (snapshot, top, left) => {
    const options = (
      snapshot.autosave || !canWriteSnapshots
        ? [
            {
              icon: 'binoculars',
              label: 'Show snapshot',
              action: () => showSnapshot(snapshot)
            }
          ]
        : [
            {
              icon: 'binoculars',
              label: 'Show snapshot',
              action: () => showSnapshot(snapshot),
              onlyVisibleOnMobile: true
            },
            { divider: true, onlyVisibleOnMobile: true },
            {
              icon: 'edit-text',
              label: 'Edit snapshot',
              action: () => editSnapshot(snapshot)
            },
            { divider: true },
            {
              icon: 'delete',
              label: 'Delete snapshot',
              action: () => deleteSnapshot(snapshot.id),
              deleteDocumentOption: true
            }
          ]
    )

    setContextMenu({
      title: 'Manage snapshot',
      top,
      left,
      options,
      context: (
        <Snapshot
          user={usersCachedData.get(snapshot.userId)}
          isPreview
          {...snapshot}
        />
      )
    })
  }, [canWriteSnapshots, showSnapshot, editSnapshot, usersCachedData, deleteSnapshot])

  const close = useCallback(() => {
    setContextMenu(null)
  }, [])

  return [contextMenu, onContextMenu, close]
}
