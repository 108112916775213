import { useRef, useEffect } from 'preact/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { debounce } from 'throttle-debounce'
import isValidDocId from 'utils/isValidDocId'

export default function useRememberOpenedKluddDocument (docId, kluddDoc, userDoc, userData, codeMirrorBinding, memoryLeftDocuments, memoryDeletedDocuments, hasReadAccess) {
  const userDataRef = useRef()
  const newDataRef = useRef({})
  const updateDataRef = useRef(() => {})

  // Set as ref in userdata to prevent useEffect change below
  useEffect(() => { userDataRef.current = userData }, [userData])

  useEffect(() => {
    // Don't remember if it's an invalid doc id (i.e. manually entered by user)
    if (!isValidDocId(docId)) return

    // If remembered in memory as just left, we don't want to remember document
    if (memoryLeftDocuments[docId] || memoryDeletedDocuments[docId]) return

    if (kluddDoc) {
      newDataRef.current = {}

      const updateData = debounce(500, () => {
        const existing = userDoc.documentsMapType.get(docId) || {}
        const data = {
          ...existing,
          ...newDataRef.current
        }

        // If we have absolutely zero data (no preview, no update date, etc)
        // It probably means we don't have access to the document so no need to save
        if (Object.keys(data).length === 0) {
          return
        }

        userDoc.documentsMapType.set(docId, data)
      })

      const onUpdate = (ev) => {
        // ev.transaction.origin = WebsocketProvider | CodemirrorBinding | null
        const now = Date.now()

        // Store full string in preview to allow full text searching (previously .substr(0, 200))
        newDataRef.current.preview = ev.currentTarget.toString()
        newDataRef.current.latestReadTimestamp = now

        // If transaction came from codemirror, it means user changed the text
        if (codeMirrorBinding && ev.transaction.origin === codeMirrorBinding) {
          const latestUpdateTimestamp = kluddDoc.metadataType.setLatestUpdateTimestamp(now, userDataRef.current)
          newDataRef.current.latestUpdateTimestamp = latestUpdateTimestamp
          newDataRef.current.myLatestUpdateTimestamp = latestUpdateTimestamp
          newDataRef.current.isHidden = false // Unhide when writing
        }

        updateData()
      }

      // When document metadata updates, update our user doc bookmark
      const onUpdateMetadata = () => {
        const latestUpdateTimestamp = kluddDoc.metadataType.getLatestUpdateTimestamp()
        newDataRef.current.latestUpdateTimestamp = latestUpdateTimestamp
        newDataRef.current.createdTimestamp = kluddDoc.metadataType.getCreatedTimestamp()
        updateData()
      }

      // Check with timeout if read has been set (i.e. any data has been loaded)
      // If latestRead has not been set, we can regard it as seen anyway, so user can remove dot even if no new data is found
      const latestReadTimeout = setTimeout(() => {
        const existing = userDoc.documentsMapType.get(docId) || {}
        if (!newDataRef.current.latestReadTimestamp && !existing.latestReadTimestamp) {
          newDataRef.current.latestReadTimestamp = Date.now()
          updateData()
        }
      }, 1000)

      kluddDoc.contentType.observe(onUpdate)
      kluddDoc.metadataType.observe(onUpdateMetadata)
      updateData()
      updateDataRef.current = updateData

      return () => {
        updateData.cancel()
        clearTimeout(latestReadTimeout)
        kluddDoc.contentType.unobserve(onUpdate)
        kluddDoc.metadataType.unobserve(onUpdateMetadata)
      }
    }
  }, [docId, kluddDoc, userDoc, codeMirrorBinding, memoryLeftDocuments, memoryDeletedDocuments])

  // Debounce auth code to make sure we're using latest docId
  const onAuthRejection = useDebouncedCallback(() => {
    newDataRef.current.hasAccess = hasReadAccess
    updateDataRef.current()
  }, 300)
  useEffect(onAuthRejection, [hasReadAccess, userDoc, docId])
}
