import { useEffect, useState, useCallback } from 'preact/hooks'
import styled from 'styled-components'
import firebase from 'firebase/app'
import Button from 'components/Button'
import SubtleTextInput from 'components/SubtleTextInput'
import Modal from 'components/Modal'
import ImageInput from 'components/ImageInput'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import SvgIcon from 'components/SvgIcon'
import getProviderName from 'utils/getProviderName'
import takeOwnershipOfDocuments from 'utils/takeOwnershipOfDocuments'
import useCloseOnEsc from 'hooks/useCloseOnEsc'

export default ({
  user,
  userData,
  providerId,
  open,
  onClose,
  logout,
  updateProfile,
  uploadAvatarImage,
  isSigningIn,
  hasAcceptedToS,
  acceptToS,
  anonymousDocuments,
  generateNewIdToken,
  userDoc,
  ...props
}) => {
  const providerName = getProviderName(providerId)
  const [displayName, setDisplayName] = useState('')
  const [acceptedToS, setAcceptedToS] = useState(false)
  const [photoURL, setPhotoURL] = useState('')
  const [avatarFile, setAvatarFile] = useState(null) // type: File
  const [hasUserChanges, setHasUserChanges] = useState(false)
  const [takeAnonymousDocuments, setTakeAnonymousDocuments] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSigningOut, setIsSigningOut] = useState(false)
  const [hasSentResetPasswordMail, setHasSentResetPasswordMail] = useState(false)

  const handleClose = useCallback((success) => {
    setHasSentResetPasswordMail(false)
    onClose(success)
  }, [onClose])

  useCloseOnEsc(handleClose, open)

  useEffect(() => {
    // Reset form on open
    if (open && userData) {
      setDisplayName(userData.name)
      setPhotoURL(userData.avatar)
      setHasUserChanges(false)
      setTakeAnonymousDocuments(true)
    }
  }, [open, userData])

  const handleResetPassword = useCallback(() => {
    if (user && user.email) {
      firebase.auth().sendPasswordResetEmail(user.email)
        .then(() => console.log('Sent reset mail!'))
      setHasSentResetPasswordMail(true)
    }
  }, [user])

  const onSubmit = (ev) => {
    ev.preventDefault()

    // Everything async except preventDefault
    ;(async function () {
      setIsLoading(true)

      if (acceptedToS && acceptToS) {
        acceptToS()
      }

      if (isSigningIn && anonymousDocuments && takeAnonymousDocuments) {
        await takeOwnershipOfDocuments(anonymousDocuments, generateNewIdToken, userDoc)
      }

      // Only update user changes if any change is made
      if (hasUserChanges) {
        setHasUserChanges(false)

        let _photoURL = photoURL

        if (avatarFile) {
          try {
            _photoURL = await uploadAvatarImage(avatarFile)
          } catch (_) {}
        }

        updateProfile({ displayName, photoURL: _photoURL })
      }

      if (isSigningIn && displayName && (hasAcceptedToS ? true : acceptedToS)) {
        handleClose(true)
      }

      setIsLoading(false)
    }())
  }

  const showTosCheckbox = !hasAcceptedToS
  const showAnonymousDocumentsCheckbox = isSigningIn && anonymousDocuments && anonymousDocuments.length > 0

  return (
    <Modal
      open={open}
      title={isSigningIn ? 'One more thing' : 'Profile settings'}
      onClose={handleClose}
      maxWidth='50.5rem'
      {...props}
    >
      <ModalInnerForm onSubmit={onSubmit}>
        <ImageInputWithMargin
          value={photoURL}
          onChange={url => {
            setPhotoURL(url)
            setHasUserChanges(true)
          }}
          onFileChange={file => {
            setAvatarFile(file)
            setHasUserChanges(true)
          }}
        />

        <NameInputWrapper>
          <SubtleTextInput
            required
            autoExpand
            placeholder="What's your name?"
            value={displayName}
            onChange={ev => {
              setDisplayName(ev.target.value)
              setHasUserChanges(true)
            }}
          />
          <SvgIcon icon='edit-text' />
        </NameInputWrapper>

        <AccountWrapper>
          {providerName && <Provider>{isSigningIn ? 'Signing' : 'Signed'} in with: <strong>{providerName}</strong></Provider>}

          {!isSigningIn &&
            <SignOutButton
              icon={<SvgIcon icon='sign-out' />}
              label='Sign out!'
              textLink
              loading={isSigningOut}
              onClick={() => {
                setIsSigningOut(true)

                logout()
                  .then(() => setIsSigningOut(false))
                  .then(() => handleClose())
              }}
            />}
        </AccountWrapper>

        {hasSentResetPasswordMail && (
          <MessageBox>
            <p>We've sent you a link to reset your password!</p>
          </MessageBox>
        )}

        {!isSigningIn && providerId === 'password' && (
          <EmailAuthButtons>
            <Button
              label='Reset password'
              black
              onClick={handleResetPassword}
              disabled={hasSentResetPasswordMail}
            />
          </EmailAuthButtons>
        )}

        {(showTosCheckbox || showAnonymousDocumentsCheckbox) && (
          <CheckboxSection>
            {showTosCheckbox && (
              <CheckboxWithLabelMargin
                checked={acceptedToS}
                onChecked={setAcceptedToS}
                label={<span>I accept the Kludd’s <a href='/privacy' target='_blank'>privacy policy</a> and <a href='/terms' target='_blank'>terms of service</a></span>}
              />
            )}

            {showAnonymousDocumentsCheckbox && (
              <CheckboxWithLabelMargin
                checked={takeAnonymousDocuments}
                onChecked={setTakeAnonymousDocuments}
                label={`Add kludds created without logging in to my account (we found ${anonymousDocuments?.length || 0})`}
              />
            )}
          </CheckboxSection>
        )}

        <ModalFooter>
          {
            isSigningIn
              ? (
                <ModalFooterButtons>
                  <SignInButton
                    icon={<SvgIcon icon='sign-in' />}
                    label='Finish setting up account'
                    type='submit'
                    loading={isLoading}
                    disabled={!displayName || (hasAcceptedToS ? false : !acceptedToS)}
                  />
                </ModalFooterButtons>
                )
              : (
                <ModalFooterButtons>
                  <SubmitButton
                    icon={<SvgIcon icon='check' />}
                    tighterIconMargin
                    label='Save changes'
                    type='submit'
                    loading={isLoading}
                    disabled={!hasUserChanges}
                  />
                </ModalFooterButtons>
                )
          }
        </ModalFooter>
      </ModalInnerForm>
    </Modal>
  )
}

const ModalFooter = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  margin-top: 3rem;
  padding-top: 2rem;
`
const ModalFooterButtons = styled.div`
  display: flex;
  > a, > button {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
  }
`
const ModalInnerForm = styled.form`
  padding: 3rem 4rem 0 3rem;
  text-align: center;

  @media (max-width: 649px) { padding: 2rem; padding-bottom: 0 }
`
const Provider = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: center;

  > strong { font-weight: 500; }
`
const SignInButton = styled(Button)`max-width: 25rem; margin: 0 auto;`
const SubmitButton = styled(Button)`margin-right: 2rem;`
const SignOutButton = styled(Button)`
  &.is-text-link { 
    font-size: 1.3rem; 
    text-decoration-color: rgba(255, 110, 110, 1);
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.3rem;
    color: #ff6e6e;
    & > svg {
      margin-right: .5rem;
    }
  }
`
const ImageInputWithMargin = styled(ImageInput)`margin: 0 auto 2rem;`
const NameInputWrapper = styled.div`
  position: relative;
  max-width: 30rem;
  display: inline-block;
  margin-bottom: 3rem;
  font-size: 0;

  > svg {
    position: absolute;
    top: 0;
    left: calc(100% + 1rem);
    opacity: 0.5;
  }
`
const EmailAuthButtons = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  justify-content: center;

  > a {
    flex: 1;
    max-width: 17.3rem;
  }
`
const MessageBox = styled.div`
  background: rgba(54, 102, 104, 0.1);
  border-radius: 1.5rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 3rem;
`
const CheckboxSection = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  margin-top: 3rem;
`
const CheckboxWithLabelMargin = styled(CheckboxWithLabel)`
  margin-top: 2rem;
`
const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`
