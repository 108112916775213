import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'

export default function BottomBar ({
  show,
  sidebarOpen,
  bigSidebarOpen,
  className,
  icon,
  options,
  closeAction,
  concernsFullPage,
  ...props
}) {
  return (
    <>
      {!!concernsFullPage && <Border show={show} />}
      <Container
        show={show}
        className={className}
        sidebarOpen={sidebarOpen}
        bigSidebarOpen={bigSidebarOpen}
        concernsFullPage={concernsFullPage}
      >
        <Wrapper>
          {icon && <Icon>{icon}</Icon>}
          {props.children}
          {!!options && options.length > 0 && (
            <Options>
              {options.map(({ label, ...o }, i) => (
                <OptionButton key={i} {...o}>{label}</OptionButton>
              ))}
            </Options>
          )}
          {!!closeAction && (
            <CloseActionWrapper>
              <CloseAction onClick={closeAction}><SvgIcon icon='exit' /></CloseAction>
            </CloseActionWrapper>
          )}
        </Wrapper>
      </Container>
    </>
  )
}

const Icon = styled.div`
  background: inherit;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  border-radius: 50%;
  padding: 0.6rem;
  margin-right: 1rem;
  transition: box-shadow .2s ease;

  .darkMode & {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
  }

  > svg { display: block; }
`
const Wrapper = styled.div`
  border-radius: 1rem;
  background: white;
  padding: 1rem 1.5rem;
  box-shadow: 0 0 3rem rgba(61, 79, 80, 0.05);
  transition: transform .2s ease-in-out, background .2s ease, box-shadow .2s ease;
  max-width: min(47.5rem, 100%);
  width: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 auto;
  pointer-events: auto;

  a {
    text-decoration: underline;
    text-decoration-color: rgba(61, 79, 80, 0.2);
    text-underline-offset: 0.3rem;
  }

  .darkMode & {
    background: #1d232b;
    box-shadow: 0 .4rem 2rem rgba(0, 0, 0, 0.15);
  }
`
const Options = styled.div`
  display: flex;
  margin-left: 1rem;
`
const OptionButton = styled.a`
  border-radius: 5rem;
  min-width: 3rem;
  height: 3rem;
  background: rgba(54, 102, 104, 0.1);
  color: #366668;
  border: 0.1rem solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding: 0 1rem;
  html & { text-decoration: none; }

  > svg { display: block; }
  > svg:first-child { margin-left: -0.6rem; }
  > svg:last-child { margin-right: -0.6rem; }
`
const CloseActionWrapper = styled.div`
  display: flex;
  margin-left: 1.5rem;
  border-left: 0.1rem solid rgba(61, 79, 80, 0.1);
  .darkMode & { border-color: rgba(255, 255, 255, 0.1); }
`
const CloseAction = styled.a`
  background: inherit;
  width: ${1.8 + 3}rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1.5rem;
  text-decoration: none;

  > svg { display: block; }
`
const Container = styled.div`
  position: fixed;
  bottom: 3rem;
  left: 0;
  right: 0;
  padding: 0 1rem;
  display: flex;
  z-index: 10;
  pointer-events: none;

  transition: transform .2s ease-in-out, opacity .2s ease, visibility .2s ease, left .2s ease;

  @media (max-width: 779px) {
    bottom: 1rem;
  }

  ${p => !p.show && `
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(50%);
  `}

  ${p => (p.sidebarOpen || p.bigSidebarOpen) && `
    @media (min-width: 780px) {
      left: ${p.bigSidebarOpen ? '36.5rem' : '27rem'};
    }
  `}

  ${p => p.concernsFullPage && `
    bottom: 0;
    padding: 0;

    @media (max-width: 779px) {
      bottom: 0rem;
    }

    ${Wrapper} {
      border: 0.2rem solid #6ed0ff;
      background: #e2f6ff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .darkMode & {
        color: white;
        background: #2d4655;
      }
    }

    ${Icon} {
      background: white;

      .darkMode & { background: #22282f; }
    }

    ${OptionButton} {
      color: white;
      background: #46b7ed;
      border-color: #46b7ed;
    }
  `}
`
const Border = styled.div`
  pointer-events: none;
  position: fixed;
  border: 0.2rem solid #6ed0ff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  transition: opacity .2s ease, visibility .2s ease;

  ${p => !p.show && 'opacity: 0;'}
`
