/* global FileReader */
import { useEffect, useState, useRef } from 'preact/hooks'
import SvgIcon from 'components/SvgIcon'
import styled from 'styled-components'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

// onChange returns new url
// onFile returns file when input change
export default ({ value, onChange, onFileChange, ...props }) => {
  const [image, setImage] = useState('')
  const [showDropZone, setShowDropZone] = useState(false)
  const inputRef = useRef()
  const onRemove = () => {
    onChange(null)
    setImage(null)
    inputRef.current.value = null
  }
  const onInputChange = (ev) => {
    const file = ev.target.files[0]
    if (file) {
      const isAllowedType = ['image/png', 'image/jpeg'].includes(file.type)
      if (!isAllowedType) {
        onFileChange(null)
        window.alert('We currently only support PNG and JPEG files for avatars.')
      } else {
        onFileChange(file)
        toBase64(file).then(setImage)
      }
    } else {
      onFileChange(null)
    }
  }

  useEffect(() => {
    let latestElement
    window.addEventListener('dragenter', (ev) => {
      latestElement = ev.target
      setShowDropZone(true)
    }, { capture: true })
    window.addEventListener('drop', (ev) => { setShowDropZone(false) }, { capture: true })
    window.addEventListener('dragleave', (ev) => {
      if (ev.target === latestElement) {
        setShowDropZone(false)
      }
    }, { capture: true })
  }, [])

  useEffect(() => {
    setImage(value)
  }, [value])

  return (
    <Wrapper showDropZone={showDropZone} {...props}>
      {!image && (
        <AddImage>
          <SvgIcon icon='add' />
        </AddImage>
      )}
      {image && <Image src={image} />}

      {showDropZone && <DropZone>Drop your avatar here</DropZone>}

      <Input
        type='file'
        accept='image/*'
        onChange={onInputChange}
        ref={inputRef}
        showDropZone={showDropZone}
      />

      {image && !showDropZone && (
        <RemoveButton onClick={onRemove}>
          <SvgIcon icon='edit-text' />
        </RemoveButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 11rem;
  height: 11rem;
  ${p => !p.showDropZone && 'position: relative;'}
`
const Image = styled.img`
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  display: block;
  border: 0.2rem solid #ffffff;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);

  .darkMode & {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
`
const RemoveButton = styled.button`
  border: none;
  appearance: none;
  background: white;
  display: block;
  position: absolute;
  padding: 0.6rem;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  color: #3d4f50;
  svg { display: block; }
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  .darkMode & {
    background: #22282f;
    color: white;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
`
const AddImage = styled.div`
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  border: 0.2rem dashed rgba(61, 79, 80, 0.2);
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;

  .darkMode & {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    border-color: rgba(243, 249, 254, 0.2);
  }
`
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  ${p => p.showDropZone && 'z-index: 2;'}
`
const DropZone = styled.div`
  font-size: 1.4rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(254, 254, 254, 1);
  border: 0.2rem dashed rgba(61, 79, 80, 0.2);
  border-radius: 1rem;
  z-index: 1;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .darkMode & {
    background: #181d23;
    border-color: rgba(243, 249, 254, 0.2);
  }
`
