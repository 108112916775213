export default function findIndicesOf (query, text) {
  query = query.toLowerCase()
  text = text.toLowerCase()

  const searchStrLen = query.length
  if (searchStrLen === 0) {
    return []
  }
  let startIndex = 0; let index; const indices = []
  while ((index = text.indexOf(query, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}
