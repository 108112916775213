import { useState, useCallback } from 'preact/hooks'
import styled, { css } from 'styled-components'
import SvgIcon from 'components/SvgIcon'
import Button from 'components/Button'
import TextButton from 'components/TextButton'
import useCloseOnEsc from 'hooks/useCloseOnEsc'

const ModalFooterComponent = ({
  submitButton,
  altButton,
  textButton,
  loading,
  error,
  includeCancelButton = true,
  onClose,
  bottomFixed
}) => (
  <ModalFooter bottomFixed={bottomFixed}>
    <ModalFooterButtons>
      <ModalFooterButtonsRow>
        {!!altButton && (
          <AltButton
            black
            {...altButton}
          />
        )}
        <SubmitButton
          label='Submit'
          type='submit'
          loading={loading}
          noAltButton={!altButton}
          {...submitButton}
        />
      </ModalFooterButtonsRow>

      {!!error && <ErrorText>{error}</ErrorText>}

      {includeCancelButton && (
        <CancelButton
          label='Cancel'
          type='button'
          onClick={onClose}
        />
      )}

      {textButton && (
        <CancelButton
          {...textButton}
          type='button'
        />
      )}
    </ModalFooterButtons>
  </ModalFooter>
)

export default ({
  title,
  open,
  onClose,
  onSubmit,
  onBack,
  maxWidth,
  innerStyle,
  centered,
  submitButton,
  isFooterBottomFixed,
  className,
  style,
  ...props
}) => {
  useCloseOnEsc(onClose, open)
  const [useHeaderShadow, setUseHeaderShadow] = useState(false)
  const Modal = onSubmit ? ModalForm : ModalDiv

  const onScroll = useCallback((el) => {
    if (!el || !el.target) return
    const { scrollTop } = el.target
    setUseHeaderShadow(scrollTop > 10)
  }, [])

  return (
    <ModalWrapper open={open} className={className} style={style}>
      <Modal
        onSubmit={onSubmit}
        maxWidth={maxWidth}
      >
        <ModalHeader shadow={useHeaderShadow}>
          {!!onBack && (
            <BackButton type='button' onClick={onBack}>
              <SvgIcon icon='arrow-left' />
            </BackButton>
          )}

          <ModalTitle>{title}</ModalTitle>

          <CloseButton type='button' onClick={onClose}>
            <SvgIcon icon='exit' />
          </CloseButton>
        </ModalHeader>
        <ModalInner
          centered={centered}
          innerStyle={innerStyle}
          onScroll={(el) => onScroll(el)}
        >
          {props.children}

          {!!submitButton && !isFooterBottomFixed && (
            <ModalFooterComponent
              submitButton={submitButton}
              onClose={onClose}
              {...props}
            />
          )}
        </ModalInner>
        {!!submitButton && isFooterBottomFixed && (
          <ModalFooterComponent
            submitButton={submitButton}
            onClose={onClose}
            {...props}
            bottomFixed
          />
        )}
      </Modal>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.section`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(248, 248, 249, 0.8);
  padding: 2rem;
  @media (min-width: 1000px) { padding: 9rem 2rem; }
  .darkMode & { background: rgba(48, 56, 64, 0.8); }

  @media screen and (max-width: 600px) {
    padding: env(safe-area-inset-top) 0 0 0;
  }

  transition: opacity .3s ease, visibility .3s ease;
  ${p => !p.open && `
    transition: none;
    opacity: 0;
    visibility: hidden;
  `}
`
const ModalSharedStyle = css`
  background: white;
  color: #3d4f50;
  border-radius: 2rem;
  box-shadow: 0 0.4rem 4rem rgba(61, 79, 80, 0.1);
  width: 100%;
  max-width: ${p => p.maxWidth || '38rem'};
  max-height: 100%;
  overflow: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  .darkMode & {
    color: #f3f9fe;
    background: #1d232b;
    box-shadow: 0 0.4rem 4rem rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 0;
    padding-bottom: calc(env(safe-area-inset-bottom) + 2rem);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const ModalDiv = styled.div`
  ${ModalSharedStyle}
`
const ModalForm = styled.form`
  ${ModalSharedStyle}
`
const ModalHeader = styled.div`
  padding: 2rem 0;
  margin: 0;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.1);
  box-shadow: none;
  transition: box-shadow .2s ease;
  text-align: center;
  z-index: 1;

  ${p => p.shadow && `
    border-bottom-width: 0;
    box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
  `}
`
const ModalInner = styled.div`
  display: flex;
  background: inherit;
  flex-direction: column;
  overflow: auto;
  justify-content: flex-start;
  font-size: 1.3rem;
  ${p => p.centered && `
    align-items: center;
  `}
  ${p => p.innerStyle || 'padding: 3rem;'}
`
const ModalTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2rem;

  @media screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`
const CloseButton = styled.button`
  border: none;
  appearance: none;
  background: transparent;
  display: block;
  position: absolute;
  padding: 0.1rem;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #3d4f50;
  svg { display: block; }

  .darkMode & { color: white; }
`
const BackButton = styled(CloseButton)`
  right: auto;
  left: 2rem;
`
const ModalFooter = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  margin-top: 3rem;
  padding-top: 3rem;

  ${p => p.bottomFixed && `
    margin-top: 0;
    border-top: none;
    padding: 2rem 3rem;
    box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
  `}
`
const ModalFooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ModalFooterButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  gap: 2.5rem;
`
const SubmitButton = styled(Button)`
  width: 100%;
  ${p => p.noAltButton && `
    max-width: 20rem;
    margin: 0 auto;
  `}
`
const AltButton = styled(Button)`
  width: 100%;
`
const CancelButton = styled(TextButton)`
  margin: 2rem auto 0;
`
const ErrorText = styled.p`
  font-size: 1.4rem;
  margin: 1rem 0 0 0;
  text-align: center;
  color: red;
`
