import { useState, useEffect, useCallback, useMemo, useRef } from 'preact/hooks'
import styled from 'styled-components'
import formatDate from 'utils/formatDate'
import formatClock from 'utils/formatClock'
import Avatar from 'components/Avatar'
import SvgIcon from 'components/SvgIcon'
import Button from 'components/Button'
import TextButton from 'components/TextButton'
import TextInput from 'components/TextInput'
import getRandomUserColor from 'utils/getRandomUserColor'
import getUserFromCache from 'utils/getUserFromCache'
import isCommentSelectedTextDeleted from 'utils/isCommentSelectedTextDeleted'
import urlRegEx from 'utils/urlRegEx'
import encodeHtml from 'utils/encodeHtml'

const MAX_URL_LENGTH = 35
const urlReplacer = (match, p1, p2) => (
  `<a href="${match}" target="_blank" rel="noreferrer">${match.length > MAX_URL_LENGTH ? match.substr(0, MAX_URL_LENGTH - 3) + '...' : match}</a>`
)
const formatMessage = (text) => (
  encodeHtml(text).replace(urlRegEx, urlReplacer)
)
// Check if element should take mouse event
const isElementOrAnyParentMouseEventWanters = (element) => {
  const mouseEventWanters = ['a', 'button', 'input', 'textarea']

  while (element) {
    const nodeName = element.nodeName.toLowerCase()

    if (mouseEventWanters.includes(nodeName)) {
      return true
    }

    element = element.parentNode
  }

  return false
}

export default function Comment ({
  id,
  message,
  selectedText,
  created,
  resolved,
  replies,
  onSelect,
  onResolve,
  onUnresolve,
  onReply,
  onAddComment,
  onCancelAddComment,
  onShowWriteMessage,
  isAddingComment,
  isSelected,
  anchor,
  head,
  className,
  updateFromChangedTextHash,
  codeMirrorBinding,
  readOnly,
  usersCachedData,
  ...props
}) {
  const baseRef = useRef()
  const messageInputRef = useRef()
  const [_showWriteMessage, setShowWriteMessage] = useState(false)
  const [writtenMessage, setWrittenMessage] = useState('')
  const [repliesLinkHeight, setRepliesLinkHeight] = useState(0)
  const hasReplies = !!(replies && replies.length > 0)
  const showWriteMessage = _showWriteMessage || isAddingComment
  const _isSelected = isSelected || isAddingComment
  const formattedMessage = useMemo(() => formatMessage(message), [message])
  const formattedReplies = useMemo(() => (
    replies ? replies.map(r => ({ ...r, message: formatMessage(r.message) })) : replies
  ), [replies])

  // Check if selected text in comment is deleted when text changes
  const isSelectedTextDeleted = useMemo(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm || !codeMirrorBinding.doc || !anchor || !head) return false

    const codeMirror = codeMirrorBinding.cm
    const textType = codeMirrorBinding.doc.getText('content')

    return isCommentSelectedTextDeleted(textType, codeMirror, anchor, head)
  }, [updateFromChangedTextHash, anchor, head])

  const { avatar, name } = useMemo(
    () => getUserFromCache(created.user, usersCachedData)
    , [created, usersCachedData])

  const onMessageSubmit = useCallback((ev) => {
    ev.preventDefault()

    if (writtenMessage.length > 0) {
      if (isAddingComment) {
        onAddComment({ message: writtenMessage, selectedText, anchor, head, created })
      } else {
        onReply(writtenMessage)
      }

      setWrittenMessage('')
      setShowWriteMessage(false)
    }
  }, [isAddingComment, writtenMessage, onReply, onAddComment])

  useEffect(() => {
    if (showWriteMessage) {
      if (onShowWriteMessage) onShowWriteMessage()
    }
  }, [showWriteMessage])

  useEffect(() => {
    if (showWriteMessage) {
      if (messageInputRef.current) messageInputRef.current.base.focus()
    } else {
      setWrittenMessage('')
    }
  }, [showWriteMessage])

  useEffect(() => {
    if (replies && replies.length > 0) {
      const parts = Array.from(baseRef.current.querySelectorAll('.part'))
      let height = 0
      for (let i = 0; i < parts.length - 1; i++) {
        const bounds = parts[i].getBoundingClientRect()
        const margin = 20
        height += bounds.height + margin
      }
      setRepliesLinkHeight(height)
    } else {
      setRepliesLinkHeight(0)
    }
  }, [id, replies])

  const toggleDotColorClass = useCallback(
    (ev) => {
      const dot = document.querySelector(`.comment-dot[data-comment-id='${id}']`)

      if (dot) {
        const colorClassName = `color-${getRandomUserColor(created.user.userId).background.replace('#', '')}`

        if (ev.type === 'mouseover') {
          dot.classList.add(colorClassName)
        } else {
          dot.classList.remove(colorClassName)
        }
      }
    },
    [id]
  )

  const handleClick = useCallback((ev) => {
    // Only select comment if no link was clicked
    if (!isElementOrAnyParentMouseEventWanters(ev.target) && onSelect) {
      onSelect()
    }
  }, [onSelect])

  return (
    <Wrapper
      ref={baseRef}
      isSelected={_isSelected}
      className={(_isSelected ? 'comment-selected ' : '') + className}
      isResolved={!!resolved}
      onMouseOver={toggleDotColorClass}
      onMouseOut={toggleDotColorClass}
      onClick={handleClick}
      {...props}
    >
      <Part className='part' lastPart={!hasReplies} resolveIconsVisible={!readOnly || resolved}>
        <AvatarWithMargin
          avatar={avatar}
          seed={created.user.userId}
          size={25}
          isResolved={!!resolved}
        />
        <Content>
          <TitleTextWrapper>
            <p>{name}</p>
            <time>{formatClock(created.timestamp)}</time>
          </TitleTextWrapper>
          <SelectedTextWrapper>
            <SelectedTextInner>
              “
              <SelectedText>{selectedText}</SelectedText>
              ”
            </SelectedTextInner>
            {isSelectedTextDeleted && <SelectedTextDeleted>*Text deleted*</SelectedTextDeleted>}
          </SelectedTextWrapper>
          {!!formattedMessage && <Message dangerouslySetInnerHTML={{ __html: formattedMessage }} />}
        </Content>
      </Part>

      {hasReplies && formattedReplies.map((reply, i) => (
        <Part key={i} className='part' lastPart={i === formattedReplies.length - 1} resolveIconsVisible={!readOnly || resolved}>
          <AvatarWithMargin
            avatar={getUserFromCache(reply.created.user, usersCachedData).avatar}
            seed={reply.created.user.userId}
            size={25}
            isResolved={!!resolved}
          />
          <Content>
            <TitleTextWrapper>
              <p>{getUserFromCache(reply.created.user, usersCachedData).name}</p>
              <time>{formatClock(reply.created.timestamp)}</time>
            </TitleTextWrapper>
            <Message dangerouslySetInnerHTML={{ __html: reply.message }} />
          </Content>
        </Part>
      ))}

      {hasReplies && (
        <RepliesLink style={{ height: repliesLinkHeight }} />
      )}

      {showWriteMessage && !readOnly && (
        <MessageForm onSubmit={onMessageSubmit}>
          <MessageInput
            required
            placeholder='Write a reply...'
            value={writtenMessage}
            onChange={ev => setWrittenMessage(ev.target.value)}
            name='writtenMessage'
            multiline
            rows={3}
            ref={messageInputRef}
          />

          <MessageFormButtons>
            <SubmitButton
              label='Post comment'
              type='submit'
              disabled={writtenMessage.length === 0}
              tighterIconMargin
              icon={<SvgIcon icon='check' />}
            />
            <CancelButton
              label='Cancel'
              type='button'
              onClick={() => {
                setShowWriteMessage(false)
                if (onCancelAddComment) onCancelAddComment()
              }}
            />
          </MessageFormButtons>
        </MessageForm>
      )}

      {!showWriteMessage && (!readOnly || resolved) && (
        <Icons isSelected={_isSelected}>
          {!readOnly && <IconButton onClick={() => setShowWriteMessage(true)}><SvgIcon icon='replies' /></IconButton>}
          {
            resolved
              ? <ResolvedText>Resolved {formatDate(resolved.timestamp, 'DAY')} {formatClock(resolved.timestamp)}</ResolvedText>
              : <IconButton onClick={onResolve}><SvgIcon icon='resolved' /></IconButton>
          }
          {!!resolved && !readOnly && (
            <UndoButton onClick={onUnresolve}>Undo</UndoButton>
          )}
        </Icons>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.article`
  padding: 1rem;
  border-radius: 1.5rem;
  transition: background .1s ease;
  position: relative;
  cursor: pointer;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  &:hover { background: rgba(241, 241, 241, 0.5); }
  .darkMode &:hover { background: rgba(12, 14, 17, 0.3); }

  ${p => p.isSelected && `
    &, &:hover, .darkMode &:hover { background: rgba(110, 208, 255, 0.2); }
  `}
  ${p => p.isResolved && `
    color: rgba(61, 79, 80, 0.6);
    .darkMode & { color: rgba(243, 249, 254, 0.6); }
  `}
`
const Part = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  &:not(:first-child) { margin-top: 2rem; }

  ${p => p.lastPart && !p.resolveIconsVisible && 'margin-bottom: 0;'}
`
const RepliesLink = styled.div`
  opacity: 0.1;
  background: #3d4f50;
  position: absolute;
  top: 2.25rem;
  left: 2.1rem;
  width: 0.3rem;
`
const AvatarWithMargin = styled(Avatar)`
  margin-right: 1rem;
  flex: none;
  ${p => p.isResolved && 'opacity: 0.6;'}
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`
const TitleTextWrapper = styled.div`
  margin: 0.4rem 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
  }
  time {
    font-size: 1.2rem;
    line-height: 1.5rem;
    opacity: 0.5;
    letter-spacing: 0.02rem;
  }
`
const SelectedTextWrapper = styled.div`
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.7rem;
  margin-bottom: 1rem;
`
const SelectedTextInner = styled.p`
  display: flex;
  overflow: hidden;
  margin-right: auto;
  opacity: 0.6;
`
const SelectedText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const SelectedTextDeleted = styled.p`
  opacity: 0.6;
  text-transform: uppercase;
  font-size: 1.2rem;
  white-space: nowrap;
  margin-left: 1.5rem;
`
const Message = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;

  a { color: #366668; }
`
const IconButton = styled.a`
  margin-right: 1rem;
  pointer-events: auto;
  transition: opacity 0.1s ease;
`
const Icons = styled.div`
  display: flex;
  padding-left: 3.5rem;
  pointer-events: none;
  z-index: 3;
  position: relative;

  ${IconButton} { opacity: 0.5; }

  ${Wrapper}:hover & ${IconButton} { opacity: 0.7; }
  ${p => p.isSelected && `${IconButton} { opacity: 1; }`}
`
const UndoButton = styled.a`
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.1);
  text-underline-offset: 0.2rem;
  pointer-events: auto;

  .darkMode & { text-decoration-color: rgba(255, 255, 255, 0.3); }
`
const MessageForm = styled.form`
  position: relative;
  z-index: 3;
`
const MessageFormButtons = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SubmitButton = styled(Button)`
  align-self: stretch;
  margin-bottom: 1rem;
`
const CancelButton = styled(TextButton)``
const MessageInput = styled(TextInput)`
  // widths to now allow horizontal resize
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`
const ResolvedText = styled.p`
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin-right: 1rem;
`
