import styled from 'styled-components'

export default ({
  illustration,
  illustrationDarkMode,
  illustrationBottomMargin,
  imageAlt,
  title,
  text,
  addPadding = true,
  ...props
}) => (
  <InformationWithIllustration padding={addPadding} {...props}>
    {illustration && (
      <IllustrationImage
        showOnDarkMode={illustrationDarkMode ? false : null}
        src={illustration}
        alt={imageAlt || 'Illustration image'}
        bottonMargin={illustrationBottomMargin}
      />
    )}
    {illustrationDarkMode && (
      <IllustrationImage
        showOnDarkMode
        src={illustrationDarkMode}
        alt={imageAlt || 'Illustration image'}
        bottonMargin={illustrationBottomMargin}
      />
    )}
    {title && <Title>{title}</Title>}
    {text && <Text>{text}</Text>}
  </InformationWithIllustration>
)

const InformationWithIllustration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  ${p => p.padding && 'padding: 2rem;'}
`
const IllustrationImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: ${p => p.bottonMargin || '5rem'};

  ${p => p.showOnDarkMode === true && `
    display: none;
    .darkMode & { display: block; }
  `}
  ${p => p.showOnDarkMode === false && `
    display: block;
    .darkMode & { display: none; }
  `}
`
const Title = styled.h1`
  font-size: 3rem;
  line-height: 3.6rem;
  margin-bottom: 2rem;
`
const Text = styled.p`
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 3rem;
  white-space: pre-line;
`
