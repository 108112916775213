/* global fetch */
import { API_SERVER } from '@constants'
import { getAnonymousUserDoc } from 'utils/getAnonymousDocuments'

// documents = [[key, {data}]]
export default async function takeOwnershipOfDocuments (documents, generateNewIdToken, userDoc) {
  const userToken = await generateNewIdToken(false, true) // Generate id token to make sure we get valid token
  const docIds = documents.map(([key]) => key)

  // Call backend with docIds to set new owner
  return fetch(API_SERVER + '/take-ownership-of-documents', {
    method: 'POST',
    body: JSON.stringify(docIds),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken
    }
  })
    .then(r => r.json())
    .then(async ({ userId, movedDocIds }) => {
      // Make sure we're updating correct user
      if (userId === userDoc.docId) {
        // Fetch anonymous user doc, with indexeddb persistence, to remove documents from its map
        const { doc: anonymousUserDoc, persistence: anonymousUserPersistence } = await getAnonymousUserDoc()
        const anonymousUserDocumentsMapType = anonymousUserDoc.getMap('documents')

        // Add ALL documents to userDoc.metadataType
        // Not just documents that changed owners, to remember opened kludds
        for (const [docId, data] of documents) {
          const alreadyExists = userDoc.documentsMapType.has(docId)

          if (!alreadyExists) {
            userDoc.documentsMapType.set(docId, data)
          }

          // Remove document from anonymous user doc list
          anonymousUserDocumentsMapType.delete(docId)
        }

        // Clean up anonymous user indexeddb
        // crdtmap-indexeddb stores updates after 1000ms, so destroy a bit after that to make sure it's stored
        setTimeout(() => anonymousUserPersistence.destroy(), 1000 + 5000)
      }
    })
    .catch(err => {
      console.log('Error taking ownership of documents', err)
    })
}
