import { CONTACT_MAIL } from '@constants'
import Modal from 'components/Modal'
import SvgIcon from 'components/SvgIcon'
import Button from 'components/Button'
import useCloseOnEsc from 'hooks/useCloseOnEsc'
import styled from 'styled-components'

const PressKitModal = ({ open, onClose, ...props }) => {
  useCloseOnEsc(onClose, open)

  return (
    <Modal
      title='Download brand assets'
      open={open}
      onClose={onClose}
      innerStyle='padding: 3rem;'
      maxWidth='50.5rem'
      includeCancelButton={false}
      submitButton={{
        type: false, // creates a link button <a>,
        label: 'Download ZIP-file',
        icon: <SvgIcon icon='download' />,
        href: 'kludd-press-kit.zip',
        download: true
      }}
      {...props}
    >
      <Message>
        By using Kludd brand assets you agree to follow our guidelines packaged
        with the media kit. For further information about use of the Kludd brand
        assets, please contact us at{' '}
        <Link label={CONTACT_MAIL} textLink href={CONTACT_MAIL} />
      </Message>
    </Modal>
  )
}

const Message = styled.p`
  text-align: center;
  margin: 0 auto;
  line-height: 1.8rem;
`

const Link = styled(Button)`
  &.is-text-link { font-size: inherit; }
`

export default PressKitModal
