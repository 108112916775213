import { useEffect, useState } from 'preact/hooks'
import { createIndexedDBHandler } from 'crdtmap-indexeddb'

export default function useCrdtMapIndexeddbProvider (doc, roomName, docId, isValid) {
  const [isSynced, setIsSynced] = useState(false)

  useEffect(() => {
    setIsSynced(false)

    if (doc && isValid) {
      // If docId is provided, compare with document docId, to not bind to wrong document
      if (docId && docId !== doc.docId) return

      const handler = createIndexedDBHandler(roomName, doc.doc)
      handler.whenSynced.then(() => setIsSynced(true))

      return () => handler.destroy()
    }
  }, [doc, roomName, isValid])

  return [isSynced]
}
