import styled from 'styled-components'
import { useState, useCallback } from 'preact/hooks'
import SvgIcon from 'components/SvgIcon'

export default ({
  message,
  submessage,
  actionLabel,
  actionCallback,
  actionCalledLabel,
  ...props
}) => {
  const [actionCalled, setActionCalled] = useState(false)
  const handleAction = useCallback(() => {
    if (actionCallback) actionCallback()
    setActionCalled(true)
  }, [actionCallback])

  return (
    <Wrapper {...props}>
      <IconWrapper>
        <SvgIcon icon='alert' />
      </IconWrapper>

      <Text>
        <Message>{message}</Message>
        <Submessage>{submessage}</Submessage>
      </Text>

      {
        actionCalled
          ? <ActionCalled>{actionCalledLabel}</ActionCalled>
          : <Action onClick={handleAction}>{actionLabel}</Action>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  border-radius: 6rem;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  color: #3d4f50;
  background: white;
  transition: background .3s ease, color .3s ease;
  font-size: 1.2rem;
  line-height: 1.5rem;
  .darkMode & { color: white; background: #22282f; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
`
const IconWrapper = styled.div`
  color: #f59169;
  padding: 0.6rem;
  border-radius: 50%;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  background: inherit;
  .darkMode & { box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  svg { display: block; }
  margin-right: 1rem;
`
const Text = styled.div`
  border-right: 1px solid rgba(61, 79, 80, 0.1);
  padding-right: 1.5rem;
  margin-right: 1.5rem;
`
const Message = styled.p``
const Submessage = styled.p`
  font-size: 1rem;
  line-height: 1.2rem;
  opacity: 0.6;
  margin-top: 0.3rem;
`
const Action = styled.a`
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  display: flex;
  align-self: stretch;
  align-items: center;

  text-decoration-color: rgba(61, 79, 80, 0.2);
  .darkMode & { text-decoration-color: rgba(255, 255, 255, 0.2);
`
const ActionCalled = styled.p`
`
