import styled from 'styled-components'

export default function KeyboardKey ({ children, ...props }) {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;

  border: 0.2rem solid currentColor;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 1px rgba(255, 255, 255, 0.15);
  border-radius: 0.6rem;
  padding: 0 0.5rem;

  font-size: 1.6rem;
  line-height: 2rem;

  ${p => p.square && 'padding: 0; width: 2.4rem;'}
`
