import amplitude from 'amplitude-js'
import queryString from 'query-string'

const amplitudeKey = process.env.AMPLITUDE_API_KEY

if (amplitudeKey) {
  amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY)
}

if (typeof window !== 'undefined' && window.location.search) {
  const source = queryString.parse(window.location.search).s
  if (source) {
    console.log('Source', source)
    if (!amplitudeKey) {
      console.log('identify.setOnce', 'source', source)
    } else {
      const identify = new amplitude.Identify().setOnce('source', source)
      amplitude.getInstance().identify(identify)
    }
  }
}

let lastUserId = null

export function updateTrackingUserId (userId) {
  if (!amplitudeKey) {
    console.log('updateTrackingUserId', userId)

    if (!userId && userId != lastUserId) { // eslint-disable-line eqeqeq
      console.log('regenerateDeviceId')
    }
  } else {
    // https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users
    amplitude.getInstance().setUserId(userId)

    if (!userId && userId != lastUserId) { // eslint-disable-line eqeqeq
      // Generate new device if user id changed to null (i.e. signed out) and wasn't null from start
      amplitude.getInstance().regenerateDeviceId()
    }
  }

  lastUserId = userId
}

export function logTrackingEvent (eventName, eventProperties) {
  if (!amplitudeKey) {
    console.log('logTrackingEvent', eventName, eventProperties)
  } else {
    amplitude.getInstance().logEvent(eventName, eventProperties)
  }
}

let gtmDidInit = false
export function loadGoogleTagManager () {
  if (process.env.NODE_ENV === 'development') return

  // https://www.sean-lloyd.com/post/delay-google-analytics-improve-pagespeed-insights-score/
  const GTM_ID = 'G-WLFY9W584C'

  // Load after 2s to avoid interfering with first page load, i.e. PageSpeed Insights score
  document.addEventListener('DOMContentLoaded', () => { setTimeout(initGTM, 2000) })

  function initGTM () {
    // Only load once
    if (gtmDidInit) return false
    gtmDidInit = true

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag () { window.dataLayer.push(arguments) }
      gtag('js', new Date())
      gtag('config', GTM_ID)
    }
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GTM_ID
    script.defer = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }
}
