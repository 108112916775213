import styled, { keyframes } from 'styled-components'

export default ({ secondsBeforeRotating, fadeIn, ...props }) => {
  const _secondsBeforeRotating = typeof secondsBeforeRotating !== 'number' ? 2 : secondsBeforeRotating

  return (
    <Svg
      width='200'
      height='160'
      viewBox='0 0 200 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fadeIn={fadeIn}
      secondsBeforeRotating={_secondsBeforeRotating}
      {...props}
    >
      <Second x='80' y='77' width='50' height='13' fill='currentColor' />
      <Hour x='69' y='30' width='13' height='100' fill='currentColor' secondsBeforeRotating={_secondsBeforeRotating} />
      <Minute x='72' y='14.5' width='13' height='66' fill='currentColor' secondsBeforeRotating={_secondsBeforeRotating} />

      {/* <circle cx='80.5' cy='83.5' r='50' stroke='blue' /> */}
    </Svg>
  )
}
// <Minute>
//   <rect width='13' height='66' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 130.02 39)' fill='currentColor' />
// </Minute>

// <circle cx='80' cy='83.5' r='1' fill='green' />
// <circle cx='78.5' cy='80.5' r='1' fill='blue' />

// <rect width='13' height='66' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 130.02 39)' fill='currentColor' />
// <circle cx='78' cy='80' r='1' fill='blue' />
// <circle cx='78.5' cy='80.5' r='1' fill='blue' />
// <circle cx='75.5' cy='80' r='1' fill='green' />
const rotateHourFirst = keyframes`
  from {}
  to {}
`
const rotateHourSecond = keyframes`
  from { transform: translate(0px, 0px) rotate(0deg); }
  to { transform: translate(4.5px, 3.5px) rotate(45deg); }
`
const rotateHour = keyframes`
  from { transform: translate(4.5px, 3.5px) rotate(45deg); }
  to { transform: translate(4.5px, 3.5px) rotate(405deg); }
`
const rotateMinuteFirst = keyframes`
  from {}
  to {}
`
const rotateMinuteSecond = keyframes`
  from { transform: translate(0px, 0px) rotate(45deg); }
  to { transform: translate(1.5px, 3px) rotate(90deg); }
`
const rotateMinute = keyframes`
  from { transform: translate(1.5px, 3px) rotate(90deg); }
  to { transform: translate(1.5px, 3px) rotate(450deg); }
`
const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Svg = styled.svg`
  display: block;
  width: 7.2rem;
  height: auto;
  opacity: 0;
  animation: ${fadeInAnimation} 0.5s ${p => p.secondsBeforeRotating * 0.8}s ease forwards;
  ${p => !p.fadeIn && 'opacity: 1; animation: none;'}
`
const Hour = styled.rect`
  transform-origin: 75.5px 80px;
  // transform: translate(4.5px, 3.5px) rotate(0deg);
  animation: ${rotateHourFirst} ${p => p.secondsBeforeRotating}s linear, ${rotateHourSecond} 1s ${p => p.secondsBeforeRotating}s ease-in, ${rotateHour} 4s ${p => p.secondsBeforeRotating + 1}s linear infinite;
`
const Minute = styled.rect`
  transform-origin: 78.5px 80.5px;
  transform: rotate(45deg);
  // transform: translate(1.5px, 3px) rotate(90deg);
  animation: ${rotateMinuteFirst} ${p => p.secondsBeforeRotating}s linear, ${rotateMinuteSecond} 0.5s ${p => p.secondsBeforeRotating}s ease-in, ${rotateMinute} 2s ${p => p.secondsBeforeRotating + 0.5}s linear infinite;
`
const Second = styled.rect``
