import { useEffect, useRef } from 'preact/hooks'
import * as Y from 'yjs'
// import { IndexeddbPersistence } from 'y-indexeddb'
import { WebsocketProvider } from 'y-websocket'
import { ANONYMOUS_USER_ID, SOCKET_SERVER, WEBSOCKET_AUTH_NO_READ, WEBSOCKET_AUTH_DELETED_DOCUMENT, WEBSOCKET_AUTH_INVALID_TOKEN, MESSAGE_PERMISSIONS, MESSAGE_SYNCED_WITH_DATABASE } from '@constants'

const CONNECT_TO_OLD_WEBSOCKET_UNTIL = new Date(2022, 0, 1)

// Load old user document to sync with new user doc
// Loads both websocket and indexeddb to make sure we get latest data
export default function useOldUserDoc (userId, userDoc, userToken) {
  const currentUserId = useRef()

  useEffect(() => {
    // After this date, do not load old user docs. We assume everyone has synced old user docs by now so no need for the extra connections and memory used.
    if (new Date() >= CONNECT_TO_OLD_WEBSOCKET_UNTIL) {
      return
    }

    if (userDoc.docId === userId) {
      currentUserId.current = userId

      const oldDoc = new Y.Doc()
      const oldDocuments = oldDoc.getMap('documents')
      const oldMetadata = oldDoc.getMap('metadata')

      // IndexeddbPersistence caused major performance issue (maybe because both CrdtMap and yjs read from indexeddb or something)
      // So this is commented out and hopefully websocket sync will be enough
      // new IndexeddbPersistence('user:' + userId, oldDoc) // eslint-disable-line no-new
      let websocketProvider

      // No need to sync anonymous users with websocket, will fail anyway without token
      if (userId !== ANONYMOUS_USER_ID) {
        websocketProvider = new WebsocketProvider(
          SOCKET_SERVER,
          'user:' + userId,
          oldDoc,
          {
            params: userToken ? { token: userToken } : undefined,
            // resyncInterval is still needed even though we resync after 1s and 5s, sometimes that's not enough
            resyncInterval: 20000
          }
        )

        // Handle messages to avoid errors
        websocketProvider.messageHandlers[MESSAGE_PERMISSIONS] = () => {}
        websocketProvider.messageHandlers[MESSAGE_SYNCED_WITH_DATABASE] = () => {}

        // Monkey patch websocket provider to prevent reconnection on auth reject
        const monkeyPatchWebsocket = () => {
          const providerOnClose = websocketProvider.ws.onclose
          websocketProvider.ws.onclose = (ev) => {
            if (ev.code === WEBSOCKET_AUTH_NO_READ || ev.code === WEBSOCKET_AUTH_DELETED_DOCUMENT || ev.code === WEBSOCKET_AUTH_INVALID_TOKEN) {
              // Calling disconnect prevents reconnect attempts
              websocketProvider.disconnect()
            }

            providerOnClose(ev)
          }
        }

        websocketProvider.on('status', (ev) => {
          switch (ev.status) {
            case 'connecting':
              monkeyPatchWebsocket()
              break
          }
        })
      }

      const applyOldDocToNewDoc = (type, prefix) => {
        const snapshot = {}

        for (const [key, value] of type.entries()) {
          // Add to snapshot with low timestamp to make sure it's considered as entered earlier than in new user doc (which will have timestamps of Date.now()) to make sure new user doc data is prioritized.
          // This way, if new user docs syncs LATER than old user doc, we still get latest new user doc data
          // Set timestamp to 1 instead of 0 to make sure we don't accidentally trigger any "|| 0" checks.
          snapshot[prefix + ':' + key] = { timestamp: 1, data: value, clientId: 1 }
        }

        userDoc.doc.applySnapshot(snapshot)
      }

      oldDocuments.observe(() => {
        if (currentUserId.current === userId) {
          applyOldDocToNewDoc(oldDocuments, 'documents')
        }
      })

      oldMetadata.observe(() => {
        if (currentUserId.current === userId) {
          applyOldDocToNewDoc(oldMetadata, 'metadata')
        }
      })

      return () => {
        // oldDoc.destroy() destroys indexeddb provider
        oldDoc.destroy()
        if (websocketProvider) websocketProvider.destroy()
      }
    }
  }, [userId, userDoc, userToken])
}
