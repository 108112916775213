import { useCallback, useState, useMemo } from 'preact/hooks'
import { isWithinView, useScrollPosition } from 'within-view/preact'
import styled from 'styled-components'
import goToNewDoc from 'utils/goToNewDoc'
import SvgIcon from 'components/SvgIcon'
import PressKitModal from 'components/PressKitModal'

export default ({
  switchToDarkModeRef,
  isLoggedIn,
  docs,
  userData,
  userDoc,
  alwaysVisible,
  titleComponent,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [pressKitModalActive, setPressKitModalActive] = useState(false)
  const [headerStyle, setHeaderStyle] = useState(alwaysVisible ? 'always-visible' : 'transparent')
  const links = useMemo(() => (
    isLoggedIn
      ? [
          { label: 'Add to Slack', href: 'https://futurememories.slack.com/apps/A02GLEG6PQR-kludd', target: '_blank', rel: 'noreferrer', icon: 'slack-color' },
          { label: 'My workspace', href: '/workspaces', promoted: true }
        ]
      : [
          { label: 'Add to Slack', href: 'https://futurememories.slack.com/apps/A02GLEG6PQR-kludd', target: '_blank', rel: 'noreferrer', icon: 'slack-color' },
          { label: 'Sign in', href: '/workspaces' },
          { label: 'Try Kludd', onClick: () => goToNewDoc(docs.length === 0, userData, userDoc), promoted: true }
        ]
  ), [isLoggedIn, docs])

  useScrollPosition(({ position }) => {
    if (alwaysVisible) setHeaderStyle('always-visible')

    if (!switchToDarkModeRef || alwaysVisible) return

    const useDarkmode = isWithinView({
      y: position.y,
      addParentOffset: true,
      ref: switchToDarkModeRef,
      offset: window.innerHeight
    })

    if (position.y > 75) {
      setHeaderStyle(useDarkmode ? 'dark' : 'light')
    } else {
      setHeaderStyle('transparent')
    }
  }, [switchToDarkModeRef, alwaysVisible], 100)

  const toggleMenu = () => {
    if (!open) {
      document.body.classList.add('lockScroll')
    } else {
      document.body.classList.remove('lockScroll')
    }
    setOpen(!open)
  }

  const handleLogoRightClick = useCallback(
    (event) => {
      event.preventDefault()
      setPressKitModalActive(true)
    }, [])

  const handleCloseModal = useCallback(
    () => setPressKitModalActive(false)
    , [])

  return (
    <>
      <Header headerStyle={headerStyle}>
        <Logo href='/' onContextMenu={handleLogoRightClick}>
          <img
            src={
              headerStyle === 'dark'
                ? require('images/logo-white.svg').default
                : require('images/logo.svg').default
            }
            alt='Kludd'
          />
        </Logo>

        {titleComponent && (
          <TitleComponent>
            {titleComponent}
          </TitleComponent>
        )}

        <Links
          opened={open}
          headerStyle={headerStyle}
        >
          {links.map(({ label, promoted, icon, ...link }) => (
            <Link key={'menu-item_' + link.href} promoted={promoted}>
              <a {...link}>
                {label}
                {icon && <SvgIcon icon={icon} width={24} />}
              </a>
            </Link>
          ))}
        </Links>

        <HamburgerMenu aria-label='Navigation Menu' onClick={() => toggleMenu()}>
          <HamburgerLine opened={open} headerStyle={headerStyle} />
          <HamburgerLine opened={open} headerStyle={headerStyle} />
        </HamburgerMenu>
      </Header>
      <PressKitModal
        open={pressKitModalActive}
        onClose={handleCloseModal}
      />
    </>
  )
}

const Header = styled.header`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
  color: #3d4f50;
  border-bottom: transparent solid .1rem;
  transition: background .2s ease, border-bottom .2s ease, padding .2s ease, margin .2s ease;
  width: 100%;
  z-index: 10;

  @media (max-width: 999px) {
    background: #F8F8F9;
    border-bottom-color: #E1DEDE;
    padding: 0;

    ${p => p.headerStyle === 'dark' && `
      background: #22282F;
      border-bottom-color: #161B22;
      color: #FFFFFF;
    `}
  }

  @media (min-width: 1000px) {
    padding: 5rem;
    margin-bottom: -13.8rem; // height of header, to show lines behind it
  }

  ${p => p.headerStyle === 'always-visible' && `
    @media (min-width: 1000px) {
      padding: 2.6rem 4rem;
      margin-bottom: 0;
    }
  `}

  ${p => p.headerStyle !== 'transparent' && p.headerStyle !== 'always-visible' && `
    @media (min-width: 1000px) {
      padding: 2.8rem 4rem;
      margin-bottom: -9.4rem; // height of header, to show lines behind it
    }
  `}

  ${p => (p.headerStyle === 'light' || p.headerStyle === 'always-visible') && `
    background: #F8F8F9;
    border-bottom-color: #E1DEDE;
  `}

  ${p => p.headerStyle === 'dark' && `
    background: #22282F;
    border-bottom-color: #161B22;
    color: #FFFFFF;
  `}
`

const Logo = styled.a`
  display: block;
  z-index: 1;

  img {
    display: block;
    width: 11.5rem;
    height: 3.7rem;

    @media (max-width: 999px) {
      padding: 2rem;
    }
  }
`

const Links = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  transition: opacity 0s ease, visibility 0s ease;

  @media (max-width: 999px) {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: auto;

    ${p => p.opened && `
      position: fixed;
      bottom: 0;
      left: 0;
      visibility: visible;
      margin-left: 0;
      background: ${p.headerStyle === 'dark' ? 'rgba(34, 40, 47, 1)' : 'rgba(255, 255, 255, 1)'} ;
      opacity: 1;
      transition: opacity .2s ease, visibility .2s ease, background .2s ease;
      height: calc(100vh - 7.8rem);
      width: 100vw;
    `}
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    opacity: 1;
    visibility: visible;
  }
`

const Link = styled.li`
  display: block;

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    ${p => p.promoted && `
      color: #366668;
      font-weight: 500;
      padding-bottom: .2rem;
      border-bottom: .2rem solid rgba(54, 102, 104, 0.2);
    `}

    > svg { margin-left: 1rem; }
  }

  @media (max-width: 999px) {
    a {
      font-size: 2.2rem;
      > svg { width: 3.6rem !important; height: 3.6rem !important; }
    }
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  @media (min-width: 1000px) {
    a {
      font-size: 1.4rem;
    }
    &:not(:last-child) {
      margin-right: 4rem;
    }
  }
`

const HamburgerMenu = styled.button`
  cursor: pointer;
  outline: none;
  border: 0;
  background: transparent;
  padding: 2rem;

  @media (min-width: 1000px) {
    display: none;
  }
`

const HamburgerLine = styled.span`
  display: block;
  background: #3d4f50;
  opacity: 1;
  height: .2rem;
  width: 2.4rem;
  transition: transform .1s ease-in-out, margin .1s ease;


  &:not(:last-child) {
    margin-bottom: .6rem;
  }

  ${p => p.headerStyle === 'dark' && `
    background: #FFFFFF;
  `}

  ${p => p.opened && `
    margin-right: .4rem;
    &:nth-child(1) {
      transform: rotate(-45deg) translateY(.55rem);
    }
    &:nth-child(2) {
      transform: rotate(45deg) translateY(-.55rem)
    }
  `}
`

const TitleComponent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
