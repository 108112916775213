import { useState, useCallback, useEffect, useMemo } from 'preact/hooks'
import { throttle } from 'throttle-debounce'

export default function useKluddPlugins (kluddDoc, codeMirror, activatePlugin, deactivatePlugin, availableExports, kluddDocTitle) {
  const [activatedPlugins, setActivatedPlugins] = useState({})

  const onSetKluddPluginActive = useCallback((plugin, active) => {
    kluddDoc.pluginsType.set(plugin, active)
  }, [kluddDoc])

  // Extract activated plugins from kludd
  useEffect(() => {
    if (!kluddDoc) return

    const extractPlugins = throttle(100, () => {
      setActivatedPlugins(kluddDoc.pluginsType.toJSON())
    })

    extractPlugins()
    kluddDoc.pluginsType.observe(extractPlugins)

    return () => {
      extractPlugins.cancel()
      kluddDoc.pluginsType.unobserve(extractPlugins)
    }
  }, [kluddDoc, activatePlugin, deactivatePlugin])

  // Active/deactive plugins when activated plugins for kludd doc changes
  useEffect(() => {
    // Remember activated here to deactivate on unmount
    const activatedInThisHook = Object.keys(activatedPlugins)
      .reduce((res, plugin) => {
        if (activatedPlugins[plugin]) {
          // Activate and add to activated array
          activatePlugin(plugin)

          return res.concat(plugin)
        }
        return res
      }, [])

    return () => {
      // Deactivate plugins that was activated in this hook to clean up
      for (const plugin of activatedInThisHook) {
        deactivatePlugin(plugin)
      }
    }
  }, [JSON.stringify(activatedPlugins)]) // Use JSON.stringify on dependencies to only update on value change as opposed to reference change

  const availableExportsBinded = useMemo(() => {
    const result = {}

    for (const key of Object.keys(availableExports)) {
      result[key] = () => availableExports[key]({
        codeMirror,
        kluddDocTitle
      })
    }

    return result
  }, [availableExports, codeMirror, kluddDocTitle])

  return [activatedPlugins, onSetKluddPluginActive, availableExportsBinded]
}
