/* global ResizeObserver */
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'preact/hooks'

const TOP_OFFSET = 56
const AMOUNT_INSIDE_LAST_ELEMENT = 21 // just enough to reach avatar dot in autosaves

export default function ConnectedLine () {
  const baseRef = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (!baseRef.current.parentNode || typeof ResizeObserver === 'undefined') return

    // Check resize observer because if some snapshot is hidden or opened/closed we need to recalculate line
    const ro = new ResizeObserver(([parent]) => {
      // We want the line to extend to beginning of last element, so find position of that
      // console.log(parent.height)
      const lastElement = parent.target.querySelector('article:last-child')

      if (lastElement) {
        setHeight(lastElement.offsetTop - TOP_OFFSET + AMOUNT_INSIDE_LAST_ELEMENT)
      } else {
        setHeight(0)
      }
    })

    ro.observe(baseRef.current.parentNode)

    return () => ro.disconnect()
  }, [])

  return <Line style={{ height }} ref={baseRef} />
}

const Line = styled.div`
  background: currentColor;
  opacity: 0.1;
  width: 0.3rem;
  position: absolute;
  left: 1.85rem;
  top: ${TOP_OFFSET / 10}rem;
`
