import { useCallback } from 'preact/hooks'
import * as Y from 'yjs'
import { getCursorCoords } from 'helpers/codemirror'
import fixMissingItem from 'utils/fixMissingItem'

export default function useScrollToUserCursor (
  userDataId,
  latestClientIdForUserId,
  codeMirror,
  editorMethodsRef,
  kluddDoc,
  websocketProvider
) {
  const scrollToUserCursor = useCallback((scrollToUserId) => {
    const forceScroll = true
    const activityFromUserAction = true
    const forceSmoothScroll = true
    const isMe = userDataId === scrollToUserId

    // If local client, just scroll to local codemirror cursor
    if (isMe) {
      const coords = getCursorCoords(codeMirror)
      if (coords) {
        editorMethodsRef.current.onCursorCoords(coords, forceScroll, activityFromUserAction, forceSmoothScroll)
      }
      return
    }

    const awarenessStates = websocketProvider.awareness.getStates()
    // Find cursors for user, could be multiple if multiple client connections
    const cursors = Array.from(awarenessStates)
      .filter(([clientId, state]) => (
        state.user && state.user.userId === scrollToUserId && state.cursor && state.cursor.head
      ))

    if (cursors.length > 0) {
      // Look for latest client update, to scroll to latest active cursor in case there's more than one
      const latestClientId = latestClientIdForUserId && latestClientIdForUserId[scrollToUserId]
      // Select cursor with latest client id, fallback to first
      const latestCursor = cursors.find(([id]) => id == latestClientId) // eslint-disable-line
      const cursor = (latestCursor && latestCursor[1].cursor) || cursors[0][1].cursor

      const head = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(JSON.parse(cursor.head)), kluddDoc.doc)
      if (head !== null) {
        const headPos = codeMirror.posFromIndex(head.index)
        const coords = codeMirror.cursorCoords(headPos, 'local')
        // Scroll to user cursor
        if (editorMethodsRef.current) {
          editorMethodsRef.current.onCursorCoords(coords, forceScroll, activityFromUserAction, forceSmoothScroll)
        }
      }
    }
  }, [userDataId, latestClientIdForUserId, codeMirror, kluddDoc, websocketProvider])

  return [scrollToUserCursor]
}
