import { TITLE_FONT_FAMILY } from '@constants'
import { useRef } from 'preact/hooks'
import styled from 'styled-components'
import authorStories from '../author-stories.json'
import Metadata from 'components/Metadata'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Grid from 'components/Grid'
import Button from 'components/Button'
import SvgIcon from 'components/SvgIcon'
import goToNewDoc from 'utils/goToNewDoc'

export default function Landing ({ isLoggedIn, docs, userData, userDoc }) {
  const pickedAuthorStories = authorStories.filter(authorStory => authorStory.showOnHome)
  const firstDarkChild = useRef(null)

  return (
    <View>
      <Header
        switchToDarkModeRef={firstDarkChild}
        isLoggedIn={isLoggedIn}
        docs={docs}
        userData={userData}
        userDoc={userDoc}
      />

      <Metadata
        title='Kludd'
        description='Write together. When we write, we sometimes benefit from an extra pair of eyes or two. An editor, a publisher, a client or a friend. In Kludd, you write, share and create together. Your script, novel, story, essay or poem.'
        image='https://kludd.co/ogimage.jpg'
      />

      <Grid
        topBorderDesktop={false}
        items={[null,
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner>
                <HeroTitle>Write together.</HeroTitle>
                <HeroText>When we write, we sometimes benefit from an extra pair of eyes or two. An editor, a publisher, a client or a friend. In Kludd, you write, share and create together. Your script, novel, story, essay or poem.</HeroText>
                {
                  isLoggedIn
                    ? (
                      <WorkspaceButton
                        href='/workspaces'
                        label='Show my workspace'
                        icon={<SvgIcon icon='workspace' />}
                      />
                      )
                    : (
                      <>
                        <WorkspaceButton
                          icon={<SvgIcon icon='edit-text' />}
                          label='Try it out!'
                          onClick={() => goToNewDoc(docs.length === 0, userData, userDoc)}
                        />
                        <SmallText>No signup required</SmallText>
                      </>
                      )
                }
              </GridChildInner>
            )
          },
          null, null, null
        ]}
      />

      <Grid
        items={[null, null, {
          column: 2,
          row: 2,
          mobileOrder: 2,
          right: true,
          bottom: true,
          content: (
            <MockedImageFirst
              alt='Mocked desktop image of kludd'
              width='3837'
              height='3014'
              src='/images/kludd-mockup-desktop-1.png'
              srcset={`
                /images/kludd-mockup-desktop-1@1944w.png 1944w,
                /images/kludd-mockup-desktop-1@2538w.png 2538w,
                /images/kludd-mockup-desktop-1.png 3837w
              `}
              sizes={`
                (max-width: 414px) 1944px,
                (max-width: 1350px) 2538px,
                3837px
              `}
            />
          )
        },
        {
          column: 2,
          mobileOrder: 1,
          centered: true,
          content: (
            <GridChildInner style={{ maxWidth: '48rem' }}>
              <Title>Sharing is caring.</Title>
              <Text>Invite anyone to view your writing, at any time. Work on a new draft together, edit an existing one or just throw in comments for later.</Text>
            </GridChildInner>
          )
        }]}
      />

      <Grid
        items={[
          {
            column: 2,
            row: 2,
            mobileOrder: 3,
            right: true,
            bottom: true,
            content: (
              <MockedImageSecond
                src='/images/kludd-mockup-desktop-1.png'
                alt='Mocked desktop image of kludd'
                width='3837'
                height='3014'
                loading='lazy'
                srcset={`
                  /images/kludd-mockup-desktop-1@1944w.png 1944w,
                  /images/kludd-mockup-desktop-1@2538w.png 2538w,
                  /images/kludd-mockup-desktop-1.png 3837w
                `}
                sizes={`
                  (max-width: 414px) 1944px,
                  (max-width: 1350px) 2538px,
                  3837px
                `}
              />
            )
          },
          { mobileOrder: 1, keepOnMobile: true }, { mobileOrder: 4, keepOnMobile: true },
          {
            column: 2,
            mobileOrder: 2,
            centered: true,
            content: (
              <GridChildInner style={{ maxWidth: '48rem' }}>
                <Title>Collaborate.</Title>
                <Text>Screenplay writers. Novelists. Journalists. Students. Copywriters. Or anyone who writes, really. Together, you elaborate, edit and rewrite whenever and wherever. Or withdraw, work on your edits alone and invite anyone else to help when it's time.</Text>
              </GridChildInner>
            )
          }
        ]}
      />

      <Grid
        items={[
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner style={{ maxWidth: '57rem' }}>
                <Title>Anytime from anywhere.</Title>
                <Text>Kludd doesn't care about platforms or devices. Jump straight in, from any device and any location. Work on your own stuff or review and edit something you're already collaborating on. Your library is with you wherever you go.</Text>
                <ConnectionSection>
                  <ConnectionSectionIcons>
                    <ConnectionSectionIcon>
                      <SvgIcon icon='logo-small' width={48} />
                    </ConnectionSectionIcon>
                    <ConnectionSectionPlus>+</ConnectionSectionPlus>
                    <ConnectionSectionIcon>
                      <SvgIcon icon='slack-color' width={48} />
                    </ConnectionSectionIcon>
                  </ConnectionSectionIcons>
                  <p>Invite collaborators from Slack. <strong>/kludd</strong></p>
                  <ConnectionSectionButtons>
                    <Button
                      label='Add to Slack'
                      href='https://futurememories.slack.com/apps/A02GLEG6PQR-kludd'
                      target='_blank'
                      rel='noreferrer'
                      icon={<SvgIcon icon='slack' width={18} />}
                    />
                    <Button
                      label='More info'
                      href='/slack'
                      textLinkMedium
                    />
                  </ConnectionSectionButtons>
                </ConnectionSection>
              </GridChildInner>
            )
          },
          null, null,
          {
            column: 2,
            row: 2,
            content: (
              <PhoneMockupRow>
                <PhoneMockupImage src='/images/kludd-mockup-phone-1.png' alt='Mocked phone image of kludd' width='1257' height='1861' loading='lazy' />
                <PhoneMockupImage hideOnMobile src='/images/kludd-mockup-phone-2.png' alt='Mocked phone image of kludd' width='1257' height='1861' loading='lazy' />
              </PhoneMockupRow>
            )
          },
          {
            column: 2,
            row: 2,
            content: (
              <PhoneMockupRow leftAligned>
                <PhoneMockupImage hideOnMobile src='/images/kludd-mockup-phone-3.png' alt='Mocked phone image of kludd' width='1257' height='1861' loading='lazy' />
                <PhoneMockupImage src='/images/kludd-mockup-phone-4.png' alt='Mocked phone image of kludd' width='1257' height='1861' loading='lazy' />
              </PhoneMockupRow>
            )
          },
          null, { keepOnMobile: true }
        ]}
      />

      <Grid
        items={[
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner style={{ maxWidth: '47rem' }}>
                <Title>Who is Kludd for?</Title>
                <Text>Anyone who writes, really. You don't need all that many tools to do good work. You just need the right tools. We got along fine with only pen and paper and then the typewriter for quite some time, so Kludd just bridges the gap where you can get more people involved, when it's that time. Until then, writing is as serene as efficient in Kludd as it should be.</Text>
              </GridChildInner>
            )
          },
          {
            column: 2,
            row: 2,
            centered: true,
            content: (
              <GridChildInner style={{ maxWidth: '56rem' }}>
                <AuthorImageWrapper>
                  <AuthorImage src={pickedAuthorStories[0].image} alt={`Photo of ${pickedAuthorStories[0].name}`} loading='lazy' />
                </AuthorImageWrapper>
                <AuthorName>{pickedAuthorStories[0].name}</AuthorName>
                <AuthorTitle>{pickedAuthorStories[0].title}</AuthorTitle>
                <AuthorQuote>{pickedAuthorStories[0].quote}</AuthorQuote>
                {pickedAuthorStories[0].cta && (
                  <AuthorButton
                    {...pickedAuthorStories[0].cta}
                    textLinkBig
                  />
                )}
              </GridChildInner>
            )
          }
        ]}
      />

      <Grid
        twoGridMobile
        customStyle={`
          background: linear-gradient(180deg, #E1DEDE calc(50% + .1rem), #161B22 calc(50% + .2rem));
          @media (min-width: 1000px) {
            background: linear-gradient(90deg, #E1DEDE 50%, #161B22 50%);
          }
        `}
        items={[
          { style: { background: '#F8F8F9' }, centered: true, content: <GridChildInner><BasicImage src='/images/crafted.svg' alt='crafted' width='97' height='24' loading='lazy' /></GridChildInner> },
          { style: { background: '#E4E5E7' }, centered: true, content: <GridChildInner><BasicImage src='/images/for.svg' alt='for' width='40' height='24' loading='lazy' /></GridChildInner> },
          { style: { background: '#303840' }, centered: true, content: <GridChildInner ref={firstDarkChild}><BasicImage src='/images/every.svg' alt='every' width='73' height='22' loading='lazy' /></GridChildInner> },
          { style: { background: '#22282F' }, centered: true, content: <GridChildInner><BasicImage src='/images/occasion.svg' alt='occasion' width='120' height='23' loading='lazy' /></GridChildInner> }
        ]}
      />

      <Grid
        shorterFirstRow
        darkerGrid
        items={[
          { row: 2 }, null, null, { row: 2 },
          {
            column: 2,
            centered: true,
            content: (
              <GridChildInner style={{ maxWidth: '53rem' }}>
                <Title light>Optimize your experience.</Title>
                <Text light small>Enhance your experience by trying out the smooth dark mode for late sessions or the focus mode to keep your eyes at the right place at the right time. It’s all up to you.</Text>
              </GridChildInner>
            )
          }
        ]}
      />

      <Grid
        darkerGrid
        shorterFirstRow
        items={[{
          column: 4,
          row: 3,
          centered: true,
          content: <MockedImageThird src='/images/kludd-mockup-desktop-2.png' alt='Mocked desktop image of kludd' width='3957' height='2216' loading='lazy' />
        }]}
      />
      <Footer />
    </View>
  )
}

const View = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 999px) {
    margin-top: -.1rem;
  }
`

const GridChildInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;

  @media (min-width: 1000px) {
    padding: 2rem;
  }
`

const HeroTitle = styled.h1`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 4rem;
  line-height: 4.8rem;
  color: #3d4f50;
  font-weight: 600;
  margin-top: 2rem;

  @media (min-width: 1000px) {
    font-size: 6rem;
    line-height: 6.8rem;
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
    line-height: 9rem;
  }
`

const HeroText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 50rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  color: #3d4f50;
  opacity: .6;

  @media (min-width: 1000px) {
    font-size: 2rem;
    line-height: 3.2rem;
    margin-top: 2rem;
  }
`

const WorkspaceButton = styled(Button)`
  font-weight: 500;
  padding: 1.1rem 2.5rem;
`

const SmallText = styled.p`
  font-size: 1.2rem;
  color: #3d4f50;
  opacity: 0.5;
  margin-top: 1rem;
`

const Title = styled.h2`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 600;
  color: ${p => p.light ? '#F8F8F9' : '#3d4f50'};

  @media (min-width: 1000px) {
    font-size: 4rem;
    line-height: 5.4rem;
  }
`

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  opacity: .6;
  margin-top: 1rem;
  color: ${p => p.light ? '#F8F8F9' : '#3d4f50'};
  ${p => p.small && 'max-width: 43rem;'}

  @media (min-width: 1000px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: 2rem;
  }
`

const MockupImage = styled.img`
  position: relative;
  height: auto;
  width: 235vw;

  @media (min-width: 1000px) {
    position: absolute;
    width: 94vw;
  }
`

const MockedImageFirst = styled(MockupImage)`
  @media (max-width: 999px) {
    margin-bottom: -87vw;
    margin-right: -135vw;
  }

  @media (min-width: 1000px) {
    top: 1rem;
    left: 1rem;
  }
`

const MockedImageSecond = styled(MockupImage)`
  @media (max-width: 999px) {
    margin-bottom: -87vw;
  }

  @media (min-width: 1000px) {
    top: 1rem;
    right: 1rem;
  }
`

const MockedImageThird = styled.img`
  position: relative;
  display: block;
  height: auto;

  @media (max-width: 999px) {
    margin-bottom: -30%;
    margin-left: -135vw;
    width: 250vw;
    top: -2rem;
  }

  @media (min-width: 1000px) {
    top: 4rem;
    width: 92%;
  }
`

const PhoneMockupRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3rem .5rem 0;
  ${p => p.leftAligned && 'justify-content: flex-end;'}

  @media (max-width: 999px) {
    height: 100vw;
  }

  @media (min-width: 1000px) {
    padding: 10rem 7.5rem 0;
  }
`

const PhoneMockupImage = styled.img`
  padding: 0 2.5rem;
  height: auto;
  width: 100%;
  max-width: 55rem;

  @media (max-width: 500px) {
    width: 100%;
    ${p => p.hideOnMobile && `
        display: none;
    `}
  }
`

const BasicImage = styled.img``

const AuthorName = styled.h3`
  font-family: ${TITLE_FONT_FAMILY};
  color: #3d4f50;
  font-size: 3rem;
  line-height: 4.1rem;
  font-weight: 600;
  margin-top: 1rem;
`

const AuthorTitle = styled.p`
  font-family: ${TITLE_FONT_FAMILY};
  font-size: 1.6rem;
  color: #3d4f50;
  line-height: 2.15rem;
  margin-top: .5rem;
  max-width: 25rem;
`

const AuthorQuote = styled.blockquote`
  color: #3d4f50;
  font-size: 1.4rem;
  line-height: 2.2rem;
  opacity: .6;
  margin-top: 3rem;

  @media (min-width: 1000px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &:before, &:after {
    content: '”'
  }
`

const AuthorButton = styled(Button)`
  margin-top: 5rem;
`

const AuthorImageWrapper = styled.div`
  display: block;
  height: 7rem;
  width: 7rem;
  border: .15rem solid white;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 .2rem 1rem rgba(61, 79, 80, .15);
`

const AuthorImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const ConnectionSection = styled.div`
  margin-top: 5rem;

  > p {
    margin-bottom: 3rem;
    color: rgba(61, 79, 80, 0.6);
    font-size: 1.8rem;
    line-height: 2.8rem;

    strong { color: rgba(61, 79, 80, 1); font-weight: 600; }
  }
`
const ConnectionSectionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2rem;
`
const ConnectionSectionIcon = styled.div`
  border: 1px solid #e1dede;
  border-radius: 1.5rem;
  padding: 0.9rem;
  color: #3d4f50;

  svg { display: block; }
`
const ConnectionSectionPlus = styled.span`
  font-size: 2.4rem;
  opacity: 0.6;
  margin: auto 1.7rem;
  color: #3d4f50;
`
const ConnectionSectionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > a:first-child { margin-bottom: 2rem; }
`
