import styled from 'styled-components'
import { useState, useMemo, useEffect } from 'preact/hooks'
import { throttle } from 'throttle-debounce'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import Button from 'components/Button'
import SvgIcon from 'components/SvgIcon'
import Snapshot from 'components/Snapshot'
import ConnectedLine from 'components/AboutSidebar/History/ConnectedLine'
import formatDate from 'utils/formatDate'

const sortOnCreatedLatestFirst = (a, b) => b.timestamp - a.timestamp

export default function History ({
  snapshots,
  snapshotMethods,
  snapshotData,
  setSnapshotData,
  setCreateSnapshotModalOpen,
  usersCachedData,
  canWriteSnapshots,
  isOffline,
  onClose,
  onSnapshotContextMenu,
  open,
  docId,
  userDoc
}) {
  const [showAutosaved, setShowAutosaved] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [currentStateDescription, setCurrentStateDescription] = useState(null)
  const [currentStateTimestamp, setCurrentStateTimestamp] = useState(null)

  // Fetch snapshots on mount
  useEffect(() => {
    if (open && snapshotMethods.getSnapshotList) {
      snapshotMethods.getSnapshotList()
    }
  }, [open, snapshotMethods.getSnapshotList])

  const categorizedSnapshots = useMemo(() => {
    const dates = (snapshots || [])
      .filter(snapshot => showAutosaved ? true : !snapshot.autosave)
      .sort(sortOnCreatedLatestFirst)
      .reduce((result, snapshot) => {
        const date = formatDate(snapshot.timestamp, 'YYYYMMDD')
        if (!result[date]) result[date] = []
        result[date].push(snapshot)
        return result
      }, {})

    return Object.keys(dates)
      .reduce((result, key) => {
        result.push({ key, date: new Date(key), snapshots: dates[key] })
        return result
      }, [])
  }, [snapshots, showAutosaved])

  // Update isMobile on resize
  useEffect(() => {
    const onResize = throttle(100, () => {
      setIsMobile(typeof window !== 'undefined' && window.innerWidth < 650) // Uses same as sidebarOpen padding
    })

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  // Extract current snapshot from latest stored userDoc (list of documents) value
  useEffect(() => {
    if (!userDoc) return

    const onUpdate = throttle(500, () => {
      const doc = userDoc.documentsMapType.get(docId)
      setCurrentStateDescription(doc.preview)
      setCurrentStateTimestamp(doc.latestUpdateTimestamp)
    })

    userDoc.doc.on('update', onUpdate)
    userDoc.doc.on('snapshot', onUpdate)
    onUpdate()

    return () => {
      onUpdate.cancel()
      userDoc.doc.off('update', onUpdate)
      userDoc.doc.off('snapshot', onUpdate)
    }
  }, [userDoc, docId])

  return (
    <>
      <Header>
        <CheckboxWithLabel
          checked={showAutosaved}
          onChecked={setShowAutosaved}
          label='Show autosaved snapshots'
        />
      </Header>
      {isOffline && (
        <ErrorMessage>Snapshot history only works while online.<br />It looks like you're currently offline.</ErrorMessage>
      )}
      {!isOffline && currentStateTimestamp != null && (
        <Category>
          <ConnectedLine />
          <CategoryHeader><span><span>Current version</span></span></CategoryHeader>
          <SnapshotWithMargin
            onClick={() => { setSnapshotData(null) }}
            timestamp={currentStateTimestamp}
            description={currentStateDescription}
            hideAvatar
            hideShowMore
          />
          {canWriteSnapshots && (
            <CreateButton
              label='Create snapshot'
              black
              icon={<SvgIcon icon='snapshot-create' />}
              onClick={() => setCreateSnapshotModalOpen(true)}
            />
          )}
        </Category>
      )}
      {categorizedSnapshots.map(cat => (
        <Category key={cat.key}>
          <CategoryHeader><span><span>{formatDate(cat.date, 'DAY')}</span></span></CategoryHeader>
          {cat.snapshots.map(snapshot => {
            const isSelected = !!(snapshotData && snapshotData.snapshot && snapshotData.snapshot.id === snapshot.id)

            return (
              <SnapshotWithMargin
                key={snapshot.id}
                user={usersCachedData.get(snapshot.userId)}
                isSelected={isSelected}
                onClick={() => {
                  if (isSelected) {
                    setSnapshotData(null)
                  } else {
                    snapshotMethods.getSnapshotDiffs(snapshot.id)
                  }
                }}
                onContextMenu={(canWriteSnapshots && !snapshot.autosave) || isMobile ? onSnapshotContextMenu : null}
                showContextMenuOnClick={isMobile} // On mobile, click is always for just context menu
                {...snapshot}
                snapshot={snapshot}
              />
            )
          })}
        </Category>
      ))}
    </>
  )
}

const Header = styled.div`
  border-bottom: 1px solid rgba(61, 79, 80, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`
const Category = styled.section`
  position: relative;
  padding-bottom: 1rem;
`
const CategoryHeader = styled.h3`
  position: relative;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;

  > span {
    display: inline-block;
    padding: 0 1rem;
    position: relative;
    transition: background .3s ease, color .3s ease;
    background: white;
    .darkMode & { background: #1d232b; }
  }
  > span > span { opacity: 0.5; }

  &:before {
    content: '';
    border-top: 1px dashed;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    opacity: 0.1;
  }
`
const SnapshotWithMargin = styled(Snapshot)`
  margin: 0 -1rem 1rem;
`
const ErrorMessage = styled.p`
  font-size: 1.4rem;
  text-align: center;
`
const CreateButton = styled(Button)`
  width: 100%;
  margin-bottom: 1rem;
`
