/* global requestAnimationFrame */
import { useEffect, useCallback, useState } from 'preact/hooks'
import * as Y from 'yjs'
import { getCursor } from 'helpers/codemirror'
import CustomUndoManager from 'helpers/CustomUndoManager'
import fixMissingItem from 'utils/fixMissingItem'

export default function useCustomUndoManager (doc, codeMirror, codeMirrorBinding) {
  const [_customUndoManager, setCustomUndoManager] = useState()
  const onUndo = useCallback(() => { console.log('Undo.'); _customUndoManager.undo() }, [_customUndoManager])
  const onRedo = useCallback(() => { console.log('Redo.'); _customUndoManager.redo() }, [_customUndoManager])

  useEffect(() => {
    if (doc && codeMirrorBinding) {
      const customUndoManager = CustomUndoManager(doc.contentType, new Set([codeMirrorBinding]))

      customUndoManager.undoManager.on('stack-item-added', event => {
        // Remember relative cursor position in document
        if (!event.stackItem.meta.has('relative-cursor-location')) {
          const cursor = getCursor(codeMirror)
          const index = codeMirror.indexFromPos(cursor)
          const relativeCursorPos = Y.createRelativePositionFromTypeIndex(doc.contentType, index)

          event.stackItem.meta.set('relative-cursor-location', relativeCursorPos)
        }
      })

      customUndoManager.undoManager.on('stack-item-popped', event => {
        // Restore the current cursor location on the stack-item
        const relPos = event.stackItem.meta.get('relative-cursor-location')

        if (relPos) {
          // Async to let codemirror update text before our cursor
          requestAnimationFrame(() => {
            const pos = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(relPos), doc.doc)
            const cursor = codeMirror.posFromIndex(pos.index)
            codeMirror.setCursor(cursor)
          })
        }
      })

      setCustomUndoManager(customUndoManager)

      return () => {
        setCustomUndoManager(null)
        customUndoManager.destroy()
      }
    } else {
      setCustomUndoManager(null)
    }
  }, [doc, codeMirrorBinding])

  return { onUndo, onRedo }
}
