import { useState, useEffect } from 'preact/hooks'
import { debounce } from 'throttle-debounce'
import formatSeconds from 'utils/formatSeconds'
import isWord from 'utils/isWord'

export default function useExtractKluddDocInfo (kluddDoc) {
  const [info, setInfo] = useState({})

  useEffect(() => {
    if (!kluddDoc || !kluddDoc.contentType || !kluddDoc.metadataType) return

    const updateData = debounce(1000, () => {
      const text = kluddDoc.contentType.toString().trim()
      const amountWords = text ? text.split(/\s+/).filter(isWord).length : 0
      const amountParagraphs = text.split('\n').filter(row => /[^\s]/.test(row)).length
      const amountCharacters = text.length
      const wpm = 225 // average adult reading speed (words per minute).
      const readTimeMinutes = amountWords / wpm
      const readTime = formatSeconds(Math.ceil(readTimeMinutes * 60))

      setInfo({
        created: kluddDoc.metadataType.get('created'),
        latestUpdate: kluddDoc.metadataType.getLatestUpdateTimestamp(true, true),
        amountWords,
        amountParagraphs,
        amountCharacters,
        readTime
      })
    })

    kluddDoc.contentType.observe(updateData)
    kluddDoc.metadataType.observe(updateData)
    updateData()

    return () => {
      kluddDoc.contentType.unobserve(updateData)
      kluddDoc.metadataType.unobserve(updateData)
      updateData.cancel()
    }
  }, [kluddDoc])

  return info
}
