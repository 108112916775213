import styled from 'styled-components'
import Modal from 'components/Modal'
import SvgIcon from 'components/SvgIcon'
import useCloseOnEsc from 'hooks/useCloseOnEsc'

export default ({
  open,
  onClose,
  onDeleteKludd,
  ...props
}) => {
  useCloseOnEsc(onClose, open)

  const onSubmit = (ev) => {
    ev.preventDefault()

    if (onDeleteKludd) {
      onDeleteKludd()
    }

    onClose()
  }

  return (
    <Modal
      title='Delete kludd'
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      innerStyle='padding: 3rem;'
      maxWidth='50.5rem'
      submitButton={{
        label: 'Delete kludd',
        red: true,
        icon: <SvgIcon icon='delete' />
      }}
      {...props}
    >
      <Message>
        If you delete this kludd you remove access for all collaborators.
      </Message>
    </Modal>
  )
}

const Message = styled.p`
  text-align: center;
  max-width: 23.5rem;
  margin: 0 auto;
  font-size: 1.3rem;
  line-height: 1.8rem;
`
