import { useEffect } from 'preact/hooks'
import { debounce } from 'throttle-debounce'

// Partially from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export default () => {
  useEffect(() => {
    const hasVisualViewport = !!window.visualViewport
    let height = 0
    let offset = 0 // offsetTop to compensate for clients that move the viewport when keyboard is opened

    const updateProperty = (e) => {
      if (hasVisualViewport) { // Use visualViewport if available as it is more consistent.
        height = window.visualViewport.height
        offset = window.visualViewport.offsetTop
      } else { // Fallback to window.innerHeight if visualViewport isn't available.
        height = window.innerHeight
      }

      const vh = (height + offset) * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    // Debounce to prevent lag and to make sure we get latest value
    const updatePropertyDebounced = debounce(50, updateProperty)

    // Update without debounce to get immediate variable
    updateProperty()

    if (hasVisualViewport) {
      window.visualViewport.addEventListener('resize', updatePropertyDebounced)
    } else {
      window.addEventListener('resize', updatePropertyDebounced)
      window.addEventListener('orientationchange', updatePropertyDebounced)
    }

    return () => {
      if (hasVisualViewport) {
        window.visualViewport.removeEventListener('resize', updatePropertyDebounced)
      } else {
        window.removeEventListener('resize', updatePropertyDebounced)
        window.removeEventListener('orientationchange', updatePropertyDebounced)
      }
    }
  }, [])
}
