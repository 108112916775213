/* global indexedDB */

export default async function getDocumentExistsOffline (docId) {
  if (typeof indexedDB === 'undefined') return false

  // Use databases() if available (i.e. not Firefox)
  // databases() currently not supported in Firefox. Follow these for updates:
  // https://developer.mozilla.org/en-US/docs/Web/API/IDBFactory/databases
  // https://bugzilla.mozilla.org/show_bug.cgi?id=934640
  // We have polyfill for Firefox for now
  if (indexedDB.databases) {
    try {
      return (await window.indexedDB.databases()).map(db => db.name).includes('kludd:' + docId)
    } catch (_) {
      return false
    }
  }
}
