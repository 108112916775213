import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'preact/hooks'
import cuid from 'cuid'
import TextInput from 'components/TextInput'
import Label from 'components/Label'
import SvgIcon from 'components/SvgIcon'
import Modal from 'components/Modal'
import formatFullDate from 'utils/formatFullDate'

export default ({
  open,
  onClose,
  snapshotMethods,
  snapshotData,
  predefinedData,
  ...props
}) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [nonce, setNonce] = useState('') // nonce is used to check response to message
  const [error, setError] = useState(null)
  const isEdit = !!(predefinedData && predefinedData.snapshotId)

  useEffect(() => {
    if (predefinedData) {
      setTitle(predefinedData.title || formatFullDate(new Date()))
      setDescription(predefinedData.description || '')
    }
  }, [predefinedData])

  useEffect(() => {
    if (snapshotData && snapshotData.nonce === nonce) {
      setError(snapshotData.error)
      setLoading(false)

      if (!snapshotData.error) {
        onClose()
      }
    }
  }, [snapshotData, nonce])

  useEffect(() => {
    if (!open) {
      setError(null)
      setTitle('')
      setDescription('')
    }
  }, [open])

  const onSubmit = useCallback((ev) => {
    ev.preventDefault()
    setLoading(true)

    const _nonce = cuid()
    setNonce(_nonce)

    if (isEdit) {
      snapshotMethods.updateSnapshot(predefinedData.snapshotId, title, description, _nonce)
    } else {
      snapshotMethods.createSnapshot(title, description, _nonce)
    }
  }, [snapshotMethods, title, description, isEdit])

  return (
    <Modal
      title={isEdit ? 'Edit snapshot' : 'Create snapshot'}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      innerStyle='padding: 3rem;'
      submitButton={{
        label: 'Save',
        icon: <SvgIcon icon='snapshot-save' />
      }}
      loading={loading}
      error={error}
      {...props}
    >
      <Label for='snapshot-title'>Title (*):</Label>
      <TextInputWithMargin
        required
        placeholder='Title...'
        value={title}
        onChange={ev => setTitle(ev.target.value)}
        name='title'
        id='snapshot-title'
        autoComplete='off'
        disabled={loading}
      />

      <Label for='snapshot-description'>Description:</Label>
      <TextInput
        multiline
        rows={5}
        ignoreValid
        placeholder='Description...'
        value={description}
        onChange={ev => setDescription(ev.target.value)}
        name='description'
        id='snapshot-description'
        autoComplete='off'
        disabled={loading}
      />
    </Modal>
  )
}

const TextInputWithMargin = styled(TextInput)`
  margin-bottom: 2rem;
`
