import { useRef, useState, useEffect, useCallback } from 'preact/hooks'
import getAnonymousDocuments from 'utils/getAnonymousDocuments'

export default function useHandleFirstTimeSignIn (userData, userToken, hasAcceptedToS, justSignedIn, isUserSynced, userEmailVerified) {
  const timer = useRef()
  const [isSigningIn, _setIsSigningIn] = useState(false)
  const [anonymousDocuments, setAnonymousDocuments] = useState([])

  // Debounce on true to avoid flickering modal
  // But set to false immediately to avoid delay
  const setIsSigningIn = useCallback((value) => {
    if (timer.current) clearTimeout(timer.current)

    if (value) {
      timer.current = setTimeout(() => _setIsSigningIn(value), 1000)
    } else {
      _setIsSigningIn(false)
    }
  }, [timer, isUserSynced])

  // Handle just signed in but missing info
  useEffect(() => {
    if (!userToken || !isUserSynced) {
      setIsSigningIn(false)
      return
    }

    if (userData && isUserSynced) {
      setIsSigningIn(
        !userData.name || !hasAcceptedToS
          ? true
          : anonymousDocuments.length > 0
            ? 'anonymousOnly'
            : false
      )
    }
  }, [userData, userToken, hasAcceptedToS, setIsSigningIn, anonymousDocuments, isUserSynced])

  // Handle fetching anonymous documents
  const lastFetchUpdateId = useRef(0)
  useEffect(() => {
    if (!userToken) return

    const updateId = lastFetchUpdateId.current + 1
    lastFetchUpdateId.current = updateId

    if (justSignedIn && userEmailVerified) {
      // If user actively signed in, look up if there are anonymous documents to ask to move
      // We don't want to ask for anonymous documents until email is verified to prevent moving to accidental email address
      getAnonymousDocuments()
        .then(docs => {
          // To fix race condition:
          if (lastFetchUpdateId.current === updateId) {
            setAnonymousDocuments(
              docs
                // Don't include documents without access rights
                .filter(([, d]) => d.hasAccess !== false)
            )
          }
        })
    } else {
      setAnonymousDocuments([])
    }
  }, [userToken, justSignedIn, userEmailVerified])

  return [isSigningIn, setIsSigningIn, anonymousDocuments, setAnonymousDocuments]
}
