import { useEffect, useState } from 'preact/hooks'
import { createWebsocketClient } from 'crdtmap-websocket'
import * as decoding from 'lib0/dist/decoding.cjs'
import * as url from 'lib0/url'
import { ANONYMOUS_USER_ID, SOCKET_SERVER, WEBSOCKET_AUTH_NO_READ, WEBSOCKET_AUTH_DELETED_DOCUMENT, WEBSOCKET_AUTH_INVALID_TOKEN, MESSAGE_PERMISSIONS, MESSAGE_SYNCED_WITH_DATABASE } from '@constants'
import isValidDocId from 'utils/isValidDocId'

export default function useCrdtMapWebsocketProvider (doc, roomName, docId, userToken, userData, generateNewIdToken, ignoreCheckingValidDocId) {
  // const [authRejectionCode, setAuthRejectionCode] = useState(0)
  // const [permissions, setPermissions] = useState('rw')
  const [isSynced, setIsSynced] = useState(false)

  useEffect(() => {
    // Check that docId is valid if provided
    if (docId && !ignoreCheckingValidDocId && !isValidDocId(docId)) return

    // No need to sync anonymous users, will fail anyway without token
    if (roomName === 'user:' + ANONYMOUS_USER_ID) return
    if (roomName === 'kludd_user:' + ANONYMOUS_USER_ID) return

    if (doc) {
      // Make sure docId matches document docId, to avoid syncing with wrong document
      if (docId && docId !== doc.docId) return

      const encodedParams = url.encodeQueryParams(userToken ? { token: userToken } : {})

      // setAuthRejectionCode(0)
      // setPermissions('rw') // default to 'rw' for newly created documents

      // Don't reconnect if closed due to auth
      const shouldReconnectOnDisconnect = (ev) => {
        if (ev.code === WEBSOCKET_AUTH_NO_READ || ev.code === WEBSOCKET_AUTH_DELETED_DOCUMENT || ev.code === WEBSOCKET_AUTH_INVALID_TOKEN) {
          // setAuthRejectionCode(ev.code)

          if (ev.code === WEBSOCKET_AUTH_NO_READ) {
            console.log('No read!')
          } else if (ev.code === WEBSOCKET_AUTH_DELETED_DOCUMENT) {
            console.log('Document deleted!')
          } else if (ev.code === WEBSOCKET_AUTH_INVALID_TOKEN) {
            generateNewIdToken(true, false)
          }

          return false
        }

        return true
      }

      const client = createWebsocketClient(
        SOCKET_SERVER + '/' + roomName + (encodedParams.length === 0 ? '' : '?' + encodedParams),
        doc.doc,
        {
          // resyncInterval is still needed even though we resync after 1s and 5s, sometimes that's not enough
          resyncInterval: 20000,
          shouldReconnectOnDisconnect,
          messageHandlers: {
            // Handle readOnly message
            [MESSAGE_PERMISSIONS]: (encoder, decoder) => {
              // setPermissions(decoding.readVarString(decoder))
            },

            // Handle custom synced message to handle i.e. user terms
            [MESSAGE_SYNCED_WITH_DATABASE]: (encoder, decoder) => {
              setIsSynced(decoding.readUint8(decoder) === 1)
            }
          }
        }
      )

      client.on('status', (ev) => {
        switch (ev.status) {
          case 'connected':
            // Trigger resync some time after connection to fix issue where first sync message is sometimes lost, see
            // https://github.com/yjs/y-websocket/issues/19
            // https://github.com/yjs/y-websocket/issues/31#issuecomment-706562929
            // Code is cloned from resyncInterval, but add here also to react a little bit faster
            setTimeout(client.resync, 1000)
            setTimeout(client.resync, 5000) // Do it again after 5s just to make sure, probably not needed but whatever
            setTimeout(client.resync, 10000)
            break
        }
      })

      return () => {
        setIsSynced(false)
        client.destroy()
      }
    } else {
      setIsSynced(false)
    }
  }, [doc, roomName, userToken])

  return [isSynced]
}
