/* global requestAnimationFrame */
import { useRef, useLayoutEffect, useEffect, useState, useMemo, useCallback } from 'preact/hooks'
import { startMarkActiveLine } from 'helpers/codemirror'
import { FONT_FAMILY_CHOICES, BASE_FONT_FAMILY, USER_COLORS } from '@constants'
import styled, { keyframes } from 'styled-components'
import convertRemToPixels from 'utils/convertRemToPixels'
import handleRemovingWeirdCharactersBeforeCodeMirrorChange from 'utils/handleRemovingWeirdCharactersBeforeCodeMirrorChange'
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/addon/display/placeholder.js'
import SvgIcon from './SvgIcon'
import { updateFloatingAnchorOnCursorActivity } from 'helpers/floating-anchor'
import calculateMarkdownKeyboardToggleResult from 'utils/calculateMarkdownKeyboardToggleResult'

if (typeof window !== 'undefined') {
  require('codemirror/mode/gfm/gfm.js')
}

if (typeof window !== 'undefined') {
  function KluddMode (config, modeConfig) {
    const kluddOverlay = {
      startState: () => ({
        inlineComment: false,
        codeBlock: false,
        customHeader: 0
      }),
      copyState: (s) => ({
        inlineComment: s.inlineComment,
        codeBlock: s.codeBlock,
        customHeader: s.customHeader
      }),
      token: (stream, state) => {
        if (state.customHeader) {
          const size = state.customHeader
          state.customHeader = 0

          if (!stream.sol()) {
            stream.skipToEnd()
            return 'line-custom-header line-custom-header--' + size
          }
        }

        if (state.inlineComment) {
          state.inlineComment = false

          if (!stream.sol()) {
            stream.skipToEnd()
            return 'line-inline-comment'
          }
        }

        if (state.codeBlock) {
          if (stream.sol() && stream.match(/^```/)) {
            state.codeBlock = false
            stream.skipToEnd()
            return 'line-code-block inline-comment-start'
          }

          stream.skipToEnd()
          return 'line-code-block'
        }

        // Start of line
        if (stream.sol()) {
          if (stream.match(/^%%/)) {
            state.inlineComment = true
            return 'inline-comment-start'
          }

          if (stream.match(/^```/)) {
            state.codeBlock = true
            return 'line-code-block inline-comment-start'
          }

          const headerMatch = stream.match(/^(#+) /)
          if (headerMatch) {
            const size = headerMatch[1].length
            state.customHeader = size
            // stream.skipToEnd()
            return 'custom-header-hash line-custom-header line-custom-header--' + size
          }
        }

        stream.next()
        return null
      }
    }

    const gfmConfig = { name: 'gfm' }
    return CodeMirror.overlayMode(CodeMirror.getMode(config, gfmConfig), kluddOverlay)
  }

  const formatKeyMap = (symbol) => (cm) => {
    // Get all lines that can potentially affect markdown formatting so calculateMarkdownKeyboardToggleResult can handle the rest
    // Empty newlines clears formatting, so get all lines until empty newline in both directions
    const head = cm.getCursor('head')
    const anchor = cm.getCursor('anchor')
    let lowestLineNumber = Math.min(head.line, anchor.line)
    const highestLineNumber = Math.max(head.line, anchor.line)
    const startingLowestLineNumber = lowestLineNumber
    const lines = []

    let lineValue = cm.getLine(lowestLineNumber)
    do {
      lines.unshift(lineValue)
      lineValue = cm.getLine(lowestLineNumber - 1)
      if (lineValue) lowestLineNumber -= 1
    } while (lineValue)

    let lineNumber = startingLowestLineNumber + 1
    lineValue = cm.getLine(lineNumber)
    while (lineValue || lineNumber <= highestLineNumber) {
      // Check to avoid adding same line twice
      lines.push(lineValue)
      lineValue = cm.getLine(lineNumber + 1)
      lineNumber += 1
    }

    const symbolsMap = {
      '**': 'strong',
      '*': 'em',
      '~~': 'del'
    }

    const keyboardToggleResult = calculateMarkdownKeyboardToggleResult(lines, symbol, head, anchor, symbolsMap, lowestLineNumber)

    cm.replaceRange(keyboardToggleResult.replacement, keyboardToggleResult.from, keyboardToggleResult.to)

    // If no selection, place cursor in middle of newly created symbols
    if (
      keyboardToggleResult.from.ch === keyboardToggleResult.to.ch &&
      keyboardToggleResult.from.line === keyboardToggleResult.to.line
    ) {
      cm.setSelection(CodeMirror.Pos(keyboardToggleResult.to.line, keyboardToggleResult.to.ch + symbol.length))
    } else {
      // Select replaced text
      cm.setSelection(
        CodeMirror.Pos(keyboardToggleResult.from.line, keyboardToggleResult.from.ch),
        CodeMirror.Pos(keyboardToggleResult.to.line, keyboardToggleResult.to.ch + keyboardToggleResult.addedCharsOnLastLine)
      )
    }
  }
  const boldKeyMap = formatKeyMap('**')
  const italicKeyMap = formatKeyMap('*')
  const strikethroughKeyMap = formatKeyMap('~~')

  KluddMode.keyMapMac = {
    'Cmd-B': boldKeyMap,
    'Cmd-I': italicKeyMap,
    'Shift-Cmd-X': strikethroughKeyMap
  }

  KluddMode.keyMapWin = {
    'Ctrl-B': boldKeyMap,
    'Ctrl-I': italicKeyMap,
    'Shift-Ctrl-X': strikethroughKeyMap
  }

  CodeMirror.defineMode('kludd', KluddMode, 'gfm')
  CodeMirror.defineMIME('text/x-kludd', 'kludd')
}

const LINE_HEIGHT = 3

// Handle disabling focus mode on scroll
const useShowFocusMode = (focusMode) => {
  const [showFocusMode, setShowFocusMode] = useState(false)

  // Update on focus mode change
  useEffect(() => {
    setShowFocusMode(focusMode)
  }, [focusMode])

  // Disable on mouse wheel
  useEffect(() => {
    const onWheel = () => setShowFocusMode(false)
    window.addEventListener('wheel', onWheel)
    return () => window.removeEventListener('wheel', onWheel)
  }, [])

  // Enable on mouse click or keyboard
  useEffect(() => {
    const onAction = () => setShowFocusMode(focusMode)

    window.addEventListener('mousedown', onAction)
    window.addEventListener('touchstart', onAction)
    window.addEventListener('keydown', onAction)

    return () => {
      window.removeEventListener('mousedown', onAction)
      window.removeEventListener('touchstart', onAction)
      window.removeEventListener('keydown', onAction)
    }
  }, [focusMode])

  return showFocusMode
}

// Used to measure line heights
const LineHeightMeasurement = ({ divRef, ...props }) => {
  return (
    <LineHeightMeasurementWrapper
      sidebarOpen={props.sidebarOpen}
      bigSidebarOpen={props.bigSidebarOpen}
    >
      <LineHeightMeasurementRef
        ref={divRef}
        {...props}
      >
        <LineHeightMeasurementRefSpan />
      </LineHeightMeasurementRef>
    </LineHeightMeasurementWrapper>
  )
}

// Used to calculate header width in order to have negative margin
const HeaderSymbolWidth = ({ size, fontFamily, fontSize, fontExtraCss }) => {
  const ref = useRef()

  // Calculate header margins
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width / 10)
    }

    // Update again with timeouts incase font wasn't loaded
    setTimeout(() => ref.current && setWidth(ref.current.getBoundingClientRect().width / 10), 200)
    setTimeout(() => ref.current && setWidth(ref.current.getBoundingClientRect().width / 10), 1000)
  }, [fontFamily, fontSize])

  return (
    <>
      <SymbolWidthRef
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontExtraCss={fontExtraCss}
      >
        <span ref={ref}>{Array.from({ length: size }, _ => '#')}{' '}</span>
        <span>E</span>{/* Extra span to get correct width when font has letter-spacing */}
      </SymbolWidthRef>
      <style>{`
        @media (min-width: 1000px) {
          .CodeMirror pre.CodeMirror-line.custom-header--${size} {
            padding-left: ${10 - width}rem;
          }
          .CodeMirror pre.CodeMirror-line.custom-header--${size} .bookmark-dot-wrapper {
            left: ${10 - 0.6 - 2 - width}rem;
          }
        }
      `}
      </style>
    </>
  )
}

export default ({
  view,
  onCodeMirror,
  darkMode,
  focusMode,
  onSave,
  onUndo,
  onRedo,
  readOnly,
  isHidden,
  onFocus,
  focusOnMount,
  editorMethodsRef,
  fontFamilyId,
  docId,
  codeMirrorMode,
  ...props
}) => {
  const wrapperRef = useRef()
  const editorRef = useRef()
  const showFocusMode = useShowFocusMode(focusMode)
  const isSelectingWithMouse = useRef(false)
  const awaitingScrollAfterMouse = useRef()
  const automaticScrollDisabled = useRef(false)
  const lastCursorCoordWhenNotTypewriter = useRef(null)
  const fontFamily = useMemo(() => (FONT_FAMILY_CHOICES.find(f => f.id === fontFamilyId) || FONT_FAMILY_CHOICES[0]).fontFamily, [fontFamilyId])
  const fontExtraCss = useMemo(() => (FONT_FAMILY_CHOICES.find(f => f.id === fontFamilyId) || FONT_FAMILY_CHOICES[0]).fontExtraCss, [fontFamilyId])

  // top is relative distance to document top
  const onCursorCoords = useCallback(({ top }, forceScroll, activityFromUserAction, forceSmoothScroll) => {
    // Enable automatic scroll if initiated from user action
    if (activityFromUserAction) {
      automaticScrollDisabled.current = false
    }

    if (!automaticScrollDisabled.current) {
      if (forceScroll) {
        const doScroll = (smooth) => {
          const paddingTop = window.innerHeight / 2 - convertRemToPixels(LINE_HEIGHT / 2)
          const scrollView = document.querySelector('.CodeMirror-scroll')

          scrollView.scroll({
            top: top - paddingTop,
            left: 0,
            behavior: (smooth || forceSmoothScroll) ? 'smooth' : undefined
          })
        }

        // If selecting with mouse, wait for mouseup
        if (isSelectingWithMouse.current) {
          awaitingScrollAfterMouse.current = () => doScroll(true)
        } else {
          doScroll()
          // Do scroll again on next render to fix issue with hitting ENTER on last line
          requestAnimationFrame(() => doScroll())
          awaitingScrollAfterMouse.current = null
        }
      } else {
        // Scroll when not typewriter
        if (!activityFromUserAction && lastCursorCoordWhenNotTypewriter.current != null && !isSelectingWithMouse.current) {
          // Only do this if change is from remote
          const scrollView = document.querySelector('.CodeMirror-scroll')
          if (scrollView) scrollView.scrollTop += top - lastCursorCoordWhenNotTypewriter.current
        }
        lastCursorCoordWhenNotTypewriter.current = top
      }
    }
  }, [])

  useEffect(() => {
    if (editorMethodsRef) {
      editorMethodsRef.current = { onCursorCoords, editorRef }
    }
  }, [editorMethodsRef])

  const editorSettingsRef = useRef({})
  const lineHeightMeasurementRef = useRef()
  useEffect(() => {
    editorSettingsRef.current.lineHeight = props.lineHeight
    editorSettingsRef.current.paragraphSpacing = props.paragraphSpacing
  }, [props.lineHeight, props.paragraphSpacing])

  // Initiate CodeMirror
  useEffect(() => {
    const editor = CodeMirror(wrapperRef.current, {
      placeholder: 'Write something...',
      theme: 'kludd',
      mode: { name: 'kludd' },
      smartIndent: true, // To use kludd mode indent
      lineWrapping: true,
      singleCursorHeightPerLine: false,
      cursorBlinkRate: 0,
      viewportMargin: 40,
      estimateHeight: (cm) => {
        return function (line) {
          if (lineHeightMeasurementRef.current) {
            lineHeightMeasurementRef.current.innerText = line.text
            const bounds = lineHeightMeasurementRef.current.getBoundingClientRect()
            return Math.max(editorSettingsRef.current.lineHeight + editorSettingsRef.current.paragraphSpacing, bounds.height)
          } else {
            // Fallback to line height, falling back to 30
            return editorSettingsRef.current.lineHeight || 30
          }
        }
      }
    })

    startMarkActiveLine(editor, onCursorCoords)

    if (onCodeMirror) onCodeMirror(editor)

    editorRef.current = editor

    editor.on('cursorActivity', updateFloatingAnchorOnCursorActivity)
    editor.on('beforeChange', handleRemovingWeirdCharactersBeforeCodeMirrorChange)
  }, [])

  /**
   * This effect was introduced to update cm whilst highlighting a selection. It is meant to re-measure
   * the linewidth and correct the selection highlighting. This is affected by max-width transitions
   * such as opening side bars etc.
   */
  useEffect(() => {
    // This refresh is also needed to estimate correct line heights when sidebar toggles
    const refresh = (e) => {
      if (e.propertyName === 'max-width') { // To Prevent refreshing on other transitions which might cause CM to "jump".
        editorRef.current.refresh()
      }
    }

    wrapperRef.current.addEventListener('transitionend', refresh)
    return () => {
      wrapperRef.current.removeEventListener('transitionend', refresh)
    }
  }, [])

  // Update codemirror mode, usually set from plugin
  useEffect(() => {
    const name = codeMirrorMode || 'kludd'

    // Recreate Mac check from CodeMirror
    const userAgent = navigator.userAgent
    const platform = navigator.platform
    const safari = /Apple Computer/.test(navigator.vendor)
    const ios = safari && (/Mobile\/\w+/.test(userAgent) || navigator.maxTouchPoints > 2)
    const mac = ios || /Mac/.test(platform)

    editorRef.current.setOption('mode', { name })
    editorRef.current.setOption('extraKeys', (mac ? CodeMirror.modes[name]?.keyMapMac : CodeMirror.modes[name]?.keyMapWin) || null)
  }, [codeMirrorMode])

  useEffect(() => {
    if (focusOnMount) {
      // Give editor time to load
      setTimeout(() => {
        if (editorRef.current) {
          const lineNumber = editorRef.current.lastLine()
          const line = editorRef.current.getLine(lineNumber)

          editorRef.current.focus()
          editorRef.current.setCursor({
            line: lineNumber,
            ch: line.length
          })
        }
      }, 500)
    }
  }, [focusOnMount, docId])

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setOption('readOnly', !!readOnly)
    }
  }, [readOnly])

  // Disable automatic scroll on wheel, until user action, to prevent jumping on remote activity
  useEffect(() => {
    const onWheel = () => { automaticScrollDisabled.current = true }
    window.addEventListener('wheel', onWheel)
    return () => window.removeEventListener('wheel', onWheel)
  }, [])

  // Set selecting with mouse
  useEffect(() => {
    const onMouseUp = () => {
      isSelectingWithMouse.current = false

      // Check if should scroll after mouse up
      if (awaitingScrollAfterMouse.current) {
        awaitingScrollAfterMouse.current()
        awaitingScrollAfterMouse.current = null
      }
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('touchend', onMouseUp)
      window.removeEventListener('touchcancel', onMouseUp)
    }
    const onMouseDown = (ev) => {
      isSelectingWithMouse.current = true
      window.addEventListener('mouseup', onMouseUp)
      window.addEventListener('touchend', onMouseUp)
      window.addEventListener('touchcancel', onMouseUp)
    }

    // Capture to get event before selection
    wrapperRef.current.addEventListener('mousedown', onMouseDown, { capture: true })
    wrapperRef.current.addEventListener('touchstart', onMouseDown, { capture: true })

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('mousedown', onMouseDown, { capture: true })
        wrapperRef.current.removeEventListener('touchstart', onMouseDown, { capture: true })
      }
    }
  }, [])

  // Lock body to prevent iOS issues with scroll
  useEffect(() => {
    document.documentElement.classList.add('lockScroll')
    return () => document.documentElement.classList.remove('lockScroll')
  }, [])

  // Close sidebar on focus on mobile, to prevent cursor appearing above sidebar
  useEffect(() => {
    if (onFocus) {
      editorRef.current.on('focus', onFocus)
      return () => editorRef.current.off('focus', onFocus)
    }
  }, [onFocus])

  // Setup keyboard commands
  useEffect(() => {
    CodeMirror.commands.save = onSave
    CodeMirror.commands.undo = onUndo
    CodeMirror.commands.redo = onRedo
  }, [onSave, onUndo, onRedo])

  return (
    <>
      <Wrapper
        ref={wrapperRef}
        darkMode={darkMode}
        focusMode={showFocusMode}
        fontFamily={fontFamily}
        fontExtraCss={fontExtraCss}
        readOnly={readOnly}
        isHidden={isHidden} // Hide component when showing diffs because another editor component is visible then
        {...props}
      />
      {Array.from({ length: 6 }, (_, i) => (
        <HeaderSymbolWidth
          key={i}
          fontFamily={fontFamily}
          fontSize={props.fontSize}
          fontExtraCss={fontExtraCss}
          size={i + 1}
        />
      ))}
      <LineHeightMeasurement
        fontFamily={fontFamily}
        fontSize={props.fontSize}
        fontExtraCss={fontExtraCss}
        paragraphSpacing={props.paragraphSpacing}
        lineHeight={props.lineHeight}
        lineWidth={props.lineWidth}
        sidebarOpen={props.sidebarOpen}
        bigSidebarOpen={props.bigSidebarOpen}
        divRef={lineHeightMeasurementRef}
      />

      {/* An icon to be picked up by code-mirrors drag n drop. Offset enough to hide it but still be "visible" */}
      <SvgIcon
        id='CodeMirror-drag-drop-text-icon'
        icon='dragDropText'
        style={{
          position: 'fixed',
          top: -23,
          left: -35
        }}
      />
    </>
  )
}

const darkModeStyle = `
  .CodeMirror {background: #181d23; color: #f3f9fe;}
  .CodeMirror-cursor {border-color: white;}
  .CodeMirror pre.CodeMirror-line.inline-comment,
  .CodeMirror pre.CodeMirror-line.code-block {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(243, 249, 254, 0.6);
  }
  .cm-inline-comment-start { color: rgba(243, 249, 254, 0.2); }
  .CodeMirror-selected {
    opacity: 0.15;
    mix-blend-mode: normal;
  }
`

const focusModeStyle = `
  .CodeMirror {color: rgba(0, 0, 0, 0.3);}
  .active-line {color: #3d4f50;}
`

const darkModeFocusModeStyle = `
  ${focusModeStyle}
  .CodeMirror {color: rgba(255, 255, 255, 0.3);}
  .active-line {color: white;}
`

const RIGHT_SIDEBAR_SIZE = 6
// 0.01rem is used on bigSidebarOpen to trigger max-width transitionend event
const maxWidth = (p) => (
  p.bigSidebarOpen
    ? `
      @media (min-width: 650px) {
        .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p.lineWidth / 10 + 4 * 2 + RIGHT_SIDEBAR_SIZE + 0.01}rem; }
        .CodeMirror pre.CodeMirror-line.inline-comment:before,
        .CodeMirror pre.CodeMirror-line.code-block:before {
          width: calc((100vw - 36.5rem - ${RIGHT_SIDEBAR_SIZE}rem) / 2 - ${(p.lineWidth / 10 + 10 * 2) / 2}rem);
        }
        .CodeMirror pre.CodeMirror-line.inline-comment:after,
        .CodeMirror pre.CodeMirror-line.code-block:after {
          width: calc((100vw - 36.5rem - ${RIGHT_SIDEBAR_SIZE}rem) / 2 - ${(p.lineWidth / 10 + 10 * 2) / 2}rem + ${RIGHT_SIDEBAR_SIZE}rem);
          min-width: ${RIGHT_SIDEBAR_SIZE}rem;
        }
      }
      @media (min-width: 1000px) {
        .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p.lineWidth / 10 + 10 * 2 + RIGHT_SIDEBAR_SIZE + 0.01}rem; }
      }
    `
    : p.sidebarOpen
      ? `
        @media (min-width: 650px) {
          .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p.lineWidth / 10 + 4 * 2 + RIGHT_SIDEBAR_SIZE}rem; }
          .CodeMirror pre.CodeMirror-line.inline-comment:before,
          .CodeMirror pre.CodeMirror-line.code-block:before {
            width: calc((100vw - 27rem - ${RIGHT_SIDEBAR_SIZE}rem) / 2 - ${(p.lineWidth / 10 + 10 * 2) / 2}rem);
          }
          .CodeMirror pre.CodeMirror-line.inline-comment:after,
          .CodeMirror pre.CodeMirror-line.code-block:after {
            width: calc((100vw - 27rem - ${RIGHT_SIDEBAR_SIZE}rem) / 2 - ${(p.lineWidth / 10 + 10 * 2) / 2}rem + ${RIGHT_SIDEBAR_SIZE}rem);
            min-width: ${RIGHT_SIDEBAR_SIZE}rem;
          }
        }
        @media (min-width: 1000px) {
          .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p.lineWidth / 10 + 10 * 2 + RIGHT_SIDEBAR_SIZE}rem; }
        }
      `
      : `
        @media (min-width: 650px) {
          .CodeMirror-lines, .add-comment-button-wrapper-inner {
            max-width: ${p.lineWidth / 10 + 4 * 2}rem;
          }
          .CodeMirror pre.CodeMirror-line.inline-comment:before,
          .CodeMirror pre.CodeMirror-line.code-block:before,
          .CodeMirror pre.CodeMirror-line.inline-comment:after,
          .CodeMirror pre.CodeMirror-line.code-block:after {
            width: calc(50vw - ${(p.lineWidth / 10 + 4 * 2) / 2}rem);
          }
        }
        @media (min-width: 1000px) {
          .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p.lineWidth / 10 + 10 * 2}rem; }
          .CodeMirror pre.CodeMirror-line.inline-comment:before,
          .CodeMirror pre.CodeMirror-line.code-block:before,
          .CodeMirror pre.CodeMirror-line.inline-comment:after,
          .CodeMirror pre.CodeMirror-line.code-block:after {
            width: calc(50vw - ${(p.lineWidth / 10 + 10 * 2) / 2}rem);
          }
        }
      `
)

const dotsStyle = (p) => `
  .bookmark-dot-wrapper {
    position: absolute;
    left: 0.7rem;
    height: ${(p.lineHeight / 10)}rem;
    display: inline-flex;
    align-items: center;
    transition: transform .2s ease;

    @media (min-width: 650px) { left: ${4 - 0.6 - 2}rem; }
    @media (min-width: 1000px) { left: ${10 - 0.6 - 2}rem; }
  }
  .bookmark-dot {
    border: 0.1rem solid;
    width: 0.6rem; // 0.4rem but adjusted for border
    height: 0.6rem;
    border-radius: 50%;
    transition: background .3s ease;

    @media (min-width: 650px) {
      width: 0.8rem; // 0.6rem but adjusted for border
      height: 0.8rem;
    }
  }
  .comment-dot {
    position: relative;
    ${USER_COLORS.map(c => '&.color-' + c.background.replace('#', '') + '{background:' + c.background + '}')}
  }
  ${p.darkMode
    ? '.comment-dot { border-color: #181d23; background: rgba(255, 255, 255, 0.2); }'
    : '.comment-dot { border-color: #f8f8f9; background: rgba(0, 0, 0, 0.2); }'
  }
  .comment-dot .avatar-container {
    cursor: pointer;
    position: absolute;
    top: ${0.3 - 1.5}rem;
    left: ${0.3 - 1.5}rem;
    z-index: 999;
  }
  .comment-dot .avatar {
    width: 3rem;
    height: 3rem;
    border: 2px solid;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .2s ease;

    &.-is-missing-avatar {
      background: white;
      padding: ${(30 / 60) - 0.2}rem; // mimic padding from Avatar component
    }

    @media screen and (max-width: 649px) {
      opacity: 0 !important;
    }
  }

  .add-comment-button-wrapper {
    z-index: 2;
    pointer-events: none;
    transition: opacity .2s ease, visibility .2s ease;

    @media (max-width: 649px) {
      margin-top: -3rem;
    }

    @media (min-width: 650px) {
      left: 0 !important;
      width: 100%;
    }
  }
  .add-comment-button-wrapper.hidden {
    opacity: 0;
    visibility: hidden;
  }
  .add-comment-button-wrapper-inner {
    margin: 0 auto;

    @media (min-width: 650px) { padding: 0 4rem; }
    @media (min-width: 1000px) { padding: 0 10rem; }
  }
  .add-comment-button {
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background .3s ease;

    @media (min-width: 650px) { margin-left: -3.5rem; }
    @media (min-width: 1000px) { margin-left: -4.5rem; }
  }
  ${p.darkMode
    ? '.add-comment-button { background: #22282f; box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15); }'
    : '.add-comment-button { background: white; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }'
  }

  .search-marker {
    border: 1px solid;
    transition: background .2s ease, border .2s ease;
    border-radius: 0.5rem;
    // TODO: Maybe &:before
  }
  ${p.darkMode
    ? '.search-marker { background: rgba(255, 255, 255, 0.1); border-color: rgba(255, 255, 255, 0.5); }'
    : '.search-marker { background: rgba(61, 79, 80, 0.1); border-color: rgba(61, 79, 80, 0.5); }'
  }

  .comment-marker { transition: background .2s ease; }
  ${p.darkMode
    ? '.comment-marker { background: rgba(255, 255, 255, 0.05); }'
    : '.comment-marker { background: rgba(0, 0, 0, 0.05); }'
  }
`

const SymbolWidthRef = styled.pre`
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  font-weight: bold;
  font-variant-ligatures: contextual;
  font-size: ${p => p.fontSize / 10}rem;
  font-family: ${p => p.fontFamily};
  ${p => p.fontExtraCss}
  pointer-events: none;
  opacity: 0;
`
const LineHeightMeasurementWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0 2rem;
  opacity: 0;
  pointer-events: none;

  @media (min-width: 650px) {
    padding-left: ${p => p.bigSidebarOpen ? (35.5 + 4) + 'rem' : p.sidebarOpen ? (26 + 4) + 'rem' : '4rem'};
    padding-right: ${p => p.bigSidebarOpen || p.sidebarOpen ? (6 + 4) + 'rem' : '4rem'};
  }

  @media (min-width: 1000px) {
    padding-left: ${p => p.bigSidebarOpen ? (36.5 + 10) + 'rem' : p.sidebarOpen ? (27 + 10) + 'rem' : '10rem'};
    padding-right: ${p => p.bigSidebarOpen || p.sidebarOpen ? (6 + 10) + 'rem' : '10rem'};
  }
`
const LineHeightMeasurementRef = styled(SymbolWidthRef)`
  position: static;
  max-width: ${p => p.lineWidth / 10}rem;
  line-height: ${p => p.lineHeight / 10}rem;
  font-weight: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  background: rgba(0, 0, 0, 0.1);
  padding-bottom: ${p => p.paragraphSpacing / 10}rem;

  // opacity: 1;
  // pointer-events: auto;
`
const LineHeightMeasurementRefSpan = styled.span`
  padding-right: 0.1px; // mimic codemirror
`
const blink = keyframes`
  from { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0; }
  to { opacity: 0; }
`
const Wrapper = styled.div`
  cursor: text;
  font-size: ${p => p.fontSize / 10}rem;
  font-family: ${p => p.fontFamily};
  ${p => p.fontExtraCss}
  line-height: ${p => p.lineHeight / 10}rem;
  height: 100%;

  ${p => p.isHidden && `
    position: absolute;
    top: 0;
    left: 0;
    width: 30rem;
    height: 30rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  `}

  pre { font-family: ${p => p.fontFamily}; ${p => p.fontExtraCss} }

  ${p => p.readOnly && '.CodeMirror-cursor { display: none; }'}
  ${dotsStyle}

  .diff-added {
    color: #329459;
    background: rgba(50, 148, 89, 0.1);
  }
  .diff-removed {
    color: #ff6e6e;
    text-decoration: line-through;
    text-decoration-color: rgba(255, 110, 110, 0.5);
    text-decoration-thickness: 0.2rem;
  }

  .CodeMirror-cursors { visibility: visible; }
  .CodeMirror-cursor:not(.remote-cursor) { animation: ${blink} ${530 * 2}ms linear infinite; visibility: hidden; }
  .CodeMirror-focused .CodeMirror-cursor:not(.remote-cursor) { visibility: visible; }
  .CodeMirror-cursor.remote-cursor { border-left-width: 2px; }
  .CodeMirror-cursor.remote-cursor .remote-cursor-name {
    position: absolute;
    bottom: 100%;
    font: normal 400 1rem/1.2rem ${BASE_FONT_FAMILY};
    letter-spacing: 0.05rem;
    padding: 0.1rem 0.2rem;
    white-space: nowrap;
  }
  .CodeMirror-cursor.remote-cursor.start .remote-cursor-name { left: -2px; }
  .CodeMirror-cursor.remote-cursor.end .remote-cursor-name { right: 0; }
  @media (max-width: 999px) {
    // Here we have 50px left padding and cursor close to the left edge, count as start
    .CodeMirror-cursor.remote-cursor.less-than-40 .remote-cursor-name,
    .CodeMirror-cursor.remote-cursor.less-than-40.end .remote-cursor-name {
      left: -2px;
      right: auto;
    }
  }

  .CodeMirror-selected {
    opacity: 0.34;
    mix-blend-mode: multiply;
  }
  ${p => p.readOnly
    ? '.CodeMirror-selected { background: #8e9aa7; }'
    : '.CodeMirror-selected { background: #6ed0ff; }'
  }

  ${p => p.darkMode
    ? '.CodeMirror-selected.color-comment { background: white; opacity: 0.05; }'
    : '.CodeMirror-selected.color-comment { background: black; opacity: 0.05; }'
  }

  .CodeMirror pre.CodeMirror-line.inline-comment,
  .CodeMirror pre.CodeMirror-line.code-block {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(61, 79, 80, 0.6);
    position: relative;
  }
  .cm-inline-comment-start { color: rgba(61, 79, 80, 0.2); }
  .CodeMirror pre.CodeMirror-line.inline-comment:before,
  .CodeMirror pre.CodeMirror-line.code-block:before,
  .CodeMirror pre.CodeMirror-line.inline-comment:after,
  .CodeMirror pre.CodeMirror-line.code-block:after {
    content: '';
    background: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    transition: width .2s ease, min-width .2s ease;
  }
  .CodeMirror pre.CodeMirror-line.inline-comment:before,
  .CodeMirror pre.CodeMirror-line.code-block:before {
    right: 100%;
  }
  .CodeMirror pre.CodeMirror-line.inline-comment:after,
  .CodeMirror pre.CodeMirror-line.code-block:after {
    left: 100%;
  }

  .CodeMirror-lines, .add-comment-button-wrapper-inner { max-width: ${p => p.lineWidth / 10 + 4 * 2}rem; }
  ${maxWidth}

  .CodeMirror { height: 100%; background: #f8f8f9; color: #3d4f50; }
  .CodeMirror, .active-line { transition: background .3s ease, color .3s ease; }
  .CodeMirror-lines {
    margin: 0 auto;
    padding: 10rem 0;
    // Don't transition padding, it messes with scroll when toggling typewriter
    transition: max-width .2s ease;
  }
  .floatingAnchorContainer {
    position: relative;
  }
  .floatingAnchor {
    display: inline-flex;
    position: absolute;
    left: 0.1rem;
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize / 10}rem;
    line-height: ${p => p.fontSize / 10}rem;
    border-radius: 0.8rem;
    padding: 0.6rem;
    background: white;
    box-shadow: 0px 0.2rem 1rem rgba(61, 79, 80, 0.15);
    color: #208AED;

    svg {
      width: 1em;
      height: 1em;
      display: block;
    }
  }
  .cm-underline { text-decoration: underline; }
  .custom-header { font-weight: bold; }
  span.cm-formatting-symbol { font-weight: normal; font-style: normal; text-decoration: none; opacity: 0.2; }
  pre.CodeMirror-line.custom-header--1, span.cm-setext.cm-header-1 { font-size: 1.555em; }
  pre.CodeMirror-line.custom-header--2, span.cm-setext.cm-header-2 { font-size: 1.333em; }
  pre.CodeMirror-line.custom-header--3 { font-size: 1.111em; }
  pre.CodeMirror-line.custom-header .cm-custom-header-hash { font-size: ${p => p.fontSize / 10}rem; opacity: 0.2; }
  span.cm-setext.cm-setext-line { opacity: 0.2; }
  .CodeMirror-placeholder { opacity: 0.5; }
  .CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
    font-family: ${p => p.fontFamily};
    ${p => p.fontExtraCss}
    padding: 0 2rem;
    padding-bottom: ${p => p.paragraphSpacing / 10}rem;
  }
  ${p => (p.sidebarOpen || p.bigSidebarOpen) && '@media (min-width: 650px) { .CodeMirror-lines, .add-comment-button-wrapper { padding-right: 6rem; } }'}
  @media (min-width: 650px) {
    .CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
      padding: 0 4rem;
    }
  }
  @media (min-width: 1000px) {
    .CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
      padding: 0 10rem;
      padding-bottom: ${p => p.paragraphSpacing / 10}rem;
    }
  }

  // Fixes issue https://app.clubhouse.io/kludd/story/13650
  .CodeMirror-gutters { width: 0px; border-right: 0; }

  ${p => p.darkMode && darkModeStyle}
  ${p => p.focusMode && (p.darkMode ? darkModeFocusModeStyle : focusModeStyle)}
  ${USER_COLORS.map(c => '.CodeMirror-selected.color-' + c.background.replace('#', '') + '{background:' + c.background + '}')}
`
