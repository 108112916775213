import { useRef, useState, useLayoutEffect } from 'preact/hooks'
import { WEBSOCKET_AUTH_NO_READ } from '@constants'

// Calculate if user should have no read access based on authRejectionCode if connected to server or stored sidebar value if not connected
export default function useReadAccess (isSocketConnected, authRejectionCode, docId, docs) {
  const debounceTimerRef = useRef()
  const [readAccess, setReadAccess] = useState(true)
  const [debouncedReadAccess, setDebouncedReadAccess] = useState(true)

  useLayoutEffect(() => {
    const doc = docs.find(d => d[0] === docId)

    if (authRejectionCode === WEBSOCKET_AUTH_NO_READ) {
      return setReadAccess(false)
    }

    // If connected to server and it deems us has having access, then it overrides sidebar permissions
    if (isSocketConnected) {
      return setReadAccess(true)
    }

    if (doc && doc[1].hasAccess === false) {
      return setReadAccess(false)
    }

    setReadAccess(true)
  }, [isSocketConnected, authRejectionCode, docs, docId])

  useLayoutEffect(() => {
    clearTimeout(debounceTimerRef.current)

    if (readAccess) {
      // Set true with timeout to prevent flash
      debounceTimerRef.current = setTimeout(() => setDebouncedReadAccess(true), 100)
    } else {
      // Set false immediately to prevent flash
      setDebouncedReadAccess(false)
    }
  }, [readAccess])

  return debouncedReadAccess
}
