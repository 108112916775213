/* global indexedDB */
import { useEffect } from 'preact/hooks'
import { debounce } from 'throttle-debounce'

const ONE_MINUTE_IN_MS = 60000
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60
const INTERVAL_BETWEEN_CLEAR_CHECKS = ONE_HOUR_IN_MS

// Databases that are never removed, to prevent weird state with databases we don't directly control
const PROTECTED_DATABASES = ['firebaseLocalStorageDb', 'localforage']

// Makes sure to clean up old stored indexeddb documents that might not be in use
// Needed to make sure we don't go over allowed indexeddb limit
export default function useClearOldIndexedDB () {
  useEffect(() => {
    let timeoutId

    // Debounce just in case hook mounts unmounts quickly
    const clearOld = debounce(1000, async () => {
      console.log('Check if we should clear old IndexedDB database to free up space...')

      const databases = (await indexedDB.databasesWithTimestamp())
      let shouldClearSomething = false
      let databaseDeleted = false

      // If 'estimate' is available (all modern browsers except for Safari) we can guess current usage and determine remove from that
      if ('storage' in navigator && 'estimate' in navigator.storage) {
        const { quota, usage } = await navigator.storage.estimate()
        const percentageQuotaUsed = usage / quota

        // Make sure we have huge margins on quota, to avoid big data stored
        shouldClearSomething = percentageQuotaUsed >= 0.7
      } else {
        // If 'estimate' is NOT available, we have no way of knowing IndexedDB size so just determine based on arbitrary amount of IndexedDB databases
        // Let's assume a kludd document database is approx. average 1 mb. Safari max is probably 0.5gb (not confirmed). So we could probably allow 500 databases. But keep some margins just in case.
        const MAX_DATABASES = 200
        shouldClearSomething = databases.length > MAX_DATABASES
      }

      if (shouldClearSomething) {
        const filteredDatabases = databases
          .filter(db => (
            // Filter out databases that is currenly open. For example, if a user is logged in for a very long time, the userdoc database (kludd_user:) will probably not have been opened for a long time but is currently open. We don't want to remove that.
            !indexedDB.currenlyOpenDatabases.has(db.name) &&
            // Prevent removing certain protected databases
            !PROTECTED_DATABASES.includes(db.name)
          ))

          // Sort on oldest first. 0 means we don't have a stored timestamp, so assume they are old (since newer should always get a timestamp).
          .sort((a, b) => a.timestamp - b.timestamp)

        // Remove oldest
        const oldestDatabaseNotCurrenlyOpen = filteredDatabases[0]
        if (oldestDatabaseNotCurrenlyOpen && oldestDatabaseNotCurrenlyOpen.name) {
          console.log('Delete database:', oldestDatabaseNotCurrenlyOpen.name)
          indexedDB.deleteDatabase(oldestDatabaseNotCurrenlyOpen.name)
          databaseDeleted = true
        }
      }

      if (!databaseDeleted) {
        console.log('No IndexedDB database deleted.')
      }

      // Set timeout for next clear.
      // Usually we only need to call clearOld once on mount but if user is active in same tab for a long time it's probably better to check again from time to time, so have a long interval
      timeoutId = setTimeout(clearOld, INTERVAL_BETWEEN_CLEAR_CHECKS)
    })

    clearOld()

    return () => {
      clearTimeout(timeoutId)
      clearOld.cancel()
    }
  }, [])
}
