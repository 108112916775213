import { useState } from 'preact/hooks'
import styled from 'styled-components'
import Button from 'components/Button'
import Cookies from 'js-cookie'
import Modal from 'components/Modal'
import Checkbox from 'components/Checkbox'
import SvgIcon from 'components/SvgIcon'

const cookieOptions = [
  {
    id: 'analytics-and-advertising',
    title: 'Analytics & advertising cookies',
    text: 'These cookies allow us to recognize and count the number of visitors to our website and identify how to improve the website when necessary. They also allow us to see what pages you have visited so that we can provide more relevant ads.'
  },
  {
    id: 'necessary-cookies',
    title: 'Necessary',
    text: 'These cookies are essential for you to browse the website and use its features. The website cannot function optimally without these cookies.',
    required: true
  }
]

export default function CookieCompliance ({ onAcceptCookies, hide }) {
  const [error, setError] = useState(false)
  const [showConfig, setShowConfig] = useState(false)
  const [checked, setChecked] = useState({ 'necessary-cookies': true, 'analytics-and-advertising': true })

  const onSubmit = (ev, acceptAll) => {
    if (ev) { ev.preventDefault() }

    let cookieComplianceCookie = {}

    if (acceptAll) {
      cookieComplianceCookie = { 'necessary-cookies': true, 'analytics-and-advertising': true }
    } else {
      if (!checked['necessary-cookies']) {
        return setError('Necessary cookies must be checked.')
      }
      cookieComplianceCookie = JSON.stringify(checked)
    }

    Cookies.set('cookieCompliance', cookieComplianceCookie, { expires: 30 })
    setShowConfig(false)
    onAcceptCookies()
  }

  return (
    <>
      <Modal
        title='Manage cookies'
        open={showConfig}
        onClose={() => setShowConfig(false)}
        onSubmit={ev => onSubmit(ev)}
        maxWidth='57rem'
        innerStyle={`
          padding: 3rem;
          text-align: center;
        `}
      >
        {cookieOptions.map(option => (
          <OptionWrapper key={`option_${option.id}`}>
            <Option>
              <OptionTitle>{option.title}</OptionTitle>
              {option.required
                ? <OptionRequiredText>Always on</OptionRequiredText>
                : (
                  <Checkbox
                    displayAsBoolean
                    checked={checked[option.id]}
                    defaultChecked
                    onChecked={value => setChecked(v => ({ ...v, [option.id]: value }))}
                  />
                  )}
            </Option>
            <OptionText>{option.text}</OptionText>
          </OptionWrapper>
        ))}
        {error && <ErrorText>{error}</ErrorText>}
        <Options topPadding='3rem'>
          <Button
            white
            outline
            type='button'
            label='Cancel'
            onClick={() => setShowConfig(false)}
          />
          <Button
            type='submit'
            label='Save changes'
            icon={<SvgIcon icon='check' />}
          />
        </Options>
      </Modal>
      <CookieBar hide={hide}>
        <Content>
          <CookieWrapper>
            <Cookie src={require('images/cookie.png').default} alt='Image of a cookie' width='18' height='18' />
          </CookieWrapper>
          <Text>We use cookies to give you a more personalized experience on this website. Click “Manage cookies” to change your options.</Text>
        </Content>
        <Options>
          <Button
            black
            type='button'
            label='I accept!'
            icon={<SvgIcon icon='thumbs-up' />}
            onClick={() => onSubmit(null, true)}
          />
          <Button
            white
            outline
            type='button'
            label='Manage cookies'
            onClick={() => setShowConfig(true)}
          />
        </Options>
      </CookieBar>
    </>
  )
}

const CookieBar = styled.div`
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem;
  background: white;
  padding: 2rem;
  box-shadow: 0 .4rem 2rem rgba(61, 79, 80, 0.15);
  transition: transform .2s ease-in-out, opacity .2s .2s ease, visibility .2s .2s ease;
  max-width: 47.5rem;
  width: 100%;
  z-index: 10;

  .darkMode & {
    background: #1d232b;
    box-shadow: 0 .4rem 2rem rgba(0, 0, 0, 0.15);
  }

  ${p => p.hide && `
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateX(-50%) translateY(100%);
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
`

const CookieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background: #F8F8F9;
  border: .15rem solid #fff;
  box-shadow: 0 .2rem 1rem rgba(61, 79, 80, 0.15);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;

  .darkMode & {
    border-color: #3d4f50;
    background: #181D23;
  }
`

const Cookie = styled.img`
  height: auto;
  width: 1.8rem;
  margin-top: -.1rem;
`

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
`

const Options = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${p => p.topPadding || '2rem'};

  button { flex: 1; }
  button:not(:last-of-type) {
    margin-right: 2rem;
  }
`

const OptionWrapper = styled.div`
  position: relative;
  padding-bottom: 3rem;
  max-width: 49rem;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1rem;
    height: 0.1rem;
    width: calc(100% + 4rem);
    background: #3d4f50;
    opacity: 0.1;
  }

  &:not(:first-of-type) {
    margin-top: 3rem;
  }
`

const Option = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`

const OptionTitle = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: left;
  flex: 1;
`

const OptionRequiredText = styled.p`
  font-size: 1.3rem;
  opacity: 0.5;
`

const OptionText = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
`

const ErrorText = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 2rem;
`
