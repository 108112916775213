import { useRef, useState, useEffect } from 'preact/hooks'
import countNewlines from 'utils/countNewlines'
import getLastLineCharacter from 'utils/getLastLineCharacter'

const DIFF_TYPE_ADDED_IN_CURRENT_VERSION = 0
const DIFF_TYPE_REMOVED_IN_CURRENT_VERSION = 1

export default function useExtractSnapshotDiffsIntoCodeMirror (snapshotData, kluddDoc, docId, snapshots) {
  const [codeMirror, setCodeMirror] = useState()
  const [justAddedSnapshot, setJustAddedSnapshot] = useState()

  useEffect(() => {
    if (kluddDoc && snapshotData && snapshotData.snapshot && codeMirror) {
      let text = ''
      let ch = 0
      let line = 0
      const markers = []

      for (const diff of snapshotData.snapshot.diffs) {
        const nextLine = line + countNewlines(diff.content)
        const nextCh = getLastLineCharacter(text + diff.content)

        if (diff.type === DIFF_TYPE_ADDED_IN_CURRENT_VERSION) {
          // Text added in newer snapshot (type 0) should be added and marked with GREEN
          markers.push({
            from: { line, ch },
            to: { line: nextLine, ch: nextCh },
            type: DIFF_TYPE_ADDED_IN_CURRENT_VERSION
          })
        } else if (diff.type === DIFF_TYPE_REMOVED_IN_CURRENT_VERSION) {
          // Text removed in newer snapshot (type 1) should be marked with RED (it's already part of the text)
          markers.push({
            from: { line, ch },
            to: { line: nextLine, ch: nextCh },
            type: DIFF_TYPE_REMOVED_IN_CURRENT_VERSION
          })
        }

        text += diff.content
        line = nextLine
        ch = nextCh
      }

      codeMirror.setValue(text)

      for (const marker of markers) {
        codeMirror.markText(marker.from, marker.to, {
          className: marker.type === DIFF_TYPE_ADDED_IN_CURRENT_VERSION ? 'diff-added' : 'diff-removed'
        })
      }
    }
  }, [codeMirror, snapshotData, kluddDoc])

  const lastSnapshotsHash = useRef()
  const lastSnapshotsLength = useRef(0)
  useEffect(() => {
    lastSnapshotsHash.current = undefined
    lastSnapshotsLength.current = 0
  }, [docId])
  useEffect(() => {
    if (!snapshots) return

    const hash = JSON.stringify(snapshots)
    let timeout
    if (lastSnapshotsHash.current && lastSnapshotsHash.current !== hash && snapshots.length > lastSnapshotsLength.current) {
      const latest = snapshots[snapshots.length - 1]

      if (!latest.autosave) {
        setJustAddedSnapshot(latest)

        setTimeout(() => { setJustAddedSnapshot(null) }, 5000)
      }
    }
    lastSnapshotsHash.current = hash
    lastSnapshotsLength.current = snapshots.length

    return () => {
      clearTimeout(timeout)
      setJustAddedSnapshot(null)
    }
  }, [snapshots])

  return [setCodeMirror, justAddedSnapshot]
}
