import styled from 'styled-components'
import Button from 'components/Button'

export default function Message ({ image, title, text, actionButton, ...props }) {
  return (
    <View {...props}>
      <Inner>
        {!!image && <MessageImage {...image} />}
        {!!title && <Title>{title}</Title>}
        {!!text && <Text>{text}</Text>}
        {!!actionButton && <ActionButton {...actionButton} />}
      </Inner>
    </View>
  )
}

const View = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  transition: padding .2s ease;
  @media (min-width: 650px) {
    padding-left: ${p => p.sidebarOpen && !p.hideSidebar ? '26rem' : '0rem'};
  }
  @media (min-width: 1000px) {
    padding-left: ${p => p.sidebarOpen && !p.hideSidebar ? '27rem' : '0rem'};
  }
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65rem;
  margin: auto;
  padding: 6rem 2rem;
  text-align: center;

  @media (min-width: 1000px) {
    padding: 6rem 4rem;
  }
`
const MessageImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0 auto 5rem;
  display: block;
`
const Title = styled.h1`
  font-size: 3rem;
  line-height: 3.6rem;
  margin-bottom: 2rem;
`
const Text = styled.p`
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 3rem;
  white-space: pre-wrap;
`
const ActionButton = styled(Button)`
  align-self: center;
`
