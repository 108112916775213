import { useMemo } from 'preact/hooks'
import styled from 'styled-components'
import getRandomUserColor from 'utils/getRandomUserColor'
import getAnonymousAvatar from 'utils/getAnonymousAvatar'

export default ({ avatar: _avatar, alternativeAvatar, seed, color: _color, size = 30, ...props }) => {
  const color = _color || useMemo(() => getRandomUserColor(seed).background, [seed])
  const avatar = _avatar || alternativeAvatar || useMemo(() => getAnonymousAvatar(seed), [seed])

  return (
    <AvatarImage
      src={avatar}
      style={{ borderColor: color, borderWidth: size >= 40 ? 3 : 2, height: size, width: size }}
      background={_avatar && color}
      addExtraPadding={!_avatar}
      size={size}
      {...props}
    />
  )
}

const AvatarImage = styled.img`
  display: block;
  border: 2px solid;
  border-radius: 50%;
  background: ${p => p.background || 'white'};
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  ${p => p.addExtraPadding && `padding: ${(p.size / 60) - 0.2}rem;`}

  .darkMode & {
    background: ${p => p.background || '#23282e'};
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
  }
`
