import { useMemo, useState, useLayoutEffect, useEffect } from 'preact/hooks'
import * as Y from 'yjs'
import { throttle, debounce } from 'throttle-debounce'
import hexToRgba from 'hex-to-rgba'
import CustomRange, { getLowestRangePos, getHighestRangePos } from 'helpers/CustomRange'
import getUserFromCache from 'utils/getUserFromCache'
import getRandomUserColor from 'utils/getRandomUserColor'
import getAnonymousAvatar from 'utils/getAnonymousAvatar'
import fixMissingItem from 'utils/fixMissingItem'

const updateRemoteSelection = (y, cm, type, cursors, clientId, awareness) => {
  // redraw caret and selection for clientId
  const aw = awareness.getStates().get(clientId)

  if (aw === undefined) {
    return
  }

  const cursor = aw.cursor
  if (cursor == null || cursor.anchor == null || cursor.head == null) {
    return
  }

  const remoteCursor = aw.user || {}
  if (remoteCursor.color == null) {
    remoteCursor.color = '#000000'
  }
  if (remoteCursor.name == null) {
    remoteCursor.name = `User: ${clientId}`
  }

  const anchor = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(JSON.parse(cursor.anchor)), y)
  const head = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(JSON.parse(cursor.head)), y)

  if (anchor !== null && head !== null && anchor.type === type && head.type === type) {
    const headPos = cm.posFromIndex(head.index)
    const anchorPos = cm.posFromIndex(anchor.index)
    remoteCursor.range = CustomRange(headPos, anchorPos)
    cursors.set(clientId, remoteCursor)
  }
}

export default function useRemoteCursors (awareness, codeMirrorBinding, comments, selectedComment, userData, permissions, userSelection, setAboutModalOpen, usersCachedData) {
  const [remoteCursors, setRemoteCursors] = useState([])
  const [hoveredCommentId, setHoveredCommentId] = useState()

  useLayoutEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm) return

    const codeMirror = codeMirrorBinding.cm
    const doc = codeMirrorBinding.doc
    const textType = codeMirrorBinding.doc.getText('content')

    const cursors = new Map()
    const updateChangedCursors = throttle(10, () => {
      if (awareness) {
        // First remove all old cursors
        cursors.clear()

        awareness.getStates().forEach((_, clientId) => {
          // Only update remote cursors
          if (doc.clientID !== clientId) {
            updateRemoteSelection(textType.doc, codeMirror, textType, cursors, clientId, awareness)
          }
        })

        // Update remote cursors from map
        setRemoteCursors(Array.from(cursors.values()))
      }
    })

    // Check both awareness and textType update to get updated cursors
    // Awareness is changed when cursor changes but not when it's due to content change
    // textType update handles content change
    if (awareness) { awareness.on('change', updateChangedCursors) }
    textType.observe(updateChangedCursors)

    return () => {
      if (awareness) { awareness.off('change', updateChangedCursors) }
      textType.unobserve(updateChangedCursors)
    }
  }, [awareness, codeMirrorBinding])

  // Calculate comments hash to make sure comments have actually changed and not just a new array
  const [commentsHash, setCommentsHash] = useState('')
  useEffect(() => {
    setCommentsHash(JSON.stringify(comments))
  }, [comments])

  // Create marks from comments
  useEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm) {
      return
    }

    const codeMirror = codeMirrorBinding.cm
    const textType = codeMirrorBinding.doc.getText('content')

    let markers = []

    const getCommentPositions = (comment) => {
      let anchor, head

      try {
        anchor = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(comment.anchor), textType.doc)
        head = Y.createAbsolutePositionFromRelativePosition(fixMissingItem(comment.head), textType.doc)
      } catch (_) {}

      if (anchor != null && head != null && anchor.type === textType && head.type === textType) {
        const headPos = codeMirror.posFromIndex(head.index)
        const anchorPos = codeMirror.posFromIndex(anchor.index)
        const fromPos = getLowestRangePos(headPos, anchorPos)
        const toPos = getHighestRangePos(headPos, anchorPos)

        return { headPos, anchorPos, fromPos, toPos }
      }
    }

    // By running this in a batch .operation() we increase performance by a lot (keeps it to just 1 DOM operation instead of once per marker)
    codeMirror.operation(() => {
      // const dots = new Map()

      markers = (
        comments
          .map((comment, index) => {
            if (!comment.created) return null
            const pos = getCommentPositions(comment)

            if (pos) {
              // If line and character is equal, it means the text is removed, since no text is inside selection anymore
              const isSelectedTextDeleted = (pos.headPos.line === pos.anchorPos.line && pos.headPos.ch === pos.anchorPos.ch)
              if (isSelectedTextDeleted) return null

              const user = getUserFromCache(comment.created.user, usersCachedData)
              const seed = user.userId
              const userColor = getRandomUserColor(seed)

              const marker = codeMirror.markText(
                pos.fromPos,
                pos.toPos,
                {
                  className: 'comment-marker comment-marker--' + comment.id
                }
              )

              const dot = document.createElement('div')
              dot.setAttribute('data-comment-id', comment.id)
              dot.className = 'bookmark-dot comment-dot'

              // Mimic <Avatar> component
              const avatarElement = document.createElement('img')
              const isMissingAvatar = !user.avatar
              avatarElement.className = 'avatar' + (isMissingAvatar ? ' -is-missing-avatar' : '')
              avatarElement.src = isMissingAvatar ? getAnonymousAvatar(user.userId) : user.avatar
              avatarElement.style.color = userColor.background

              // Container element for avatar for increased trigger area for hover effect
              const avatarContainerElement = document.createElement('div')
              avatarContainerElement.className = 'avatar-container'
              avatarContainerElement.append(avatarElement)

              // Hover effect for container
              avatarContainerElement.addEventListener('mouseover', () => setHoveredCommentId(comment.id))
              avatarContainerElement.addEventListener('mouseout', () => setHoveredCommentId(undefined))

              // Add avatar to dot
              dot.appendChild(avatarContainerElement)

              // Add dot click action
              const onOpenComment = (ev) => {
                // Prevent default and propagation to stop CodeMirror from swallowing event prior to click
                ev.preventDefault()
                ev.stopPropagation()

                setAboutModalOpen({ view: 'comments', selectedComment: comment.id })
              }
              dot.addEventListener('mousedown', onOpenComment)
              dot.addEventListener('touchstart', onOpenComment)

              const bookmark = codeMirror.markText(
                pos.fromPos,
                pos.fromPos,
                {
                  type: 'bookmark',
                  replacedWith: dot,
                  clearWhenEmpty: false,
                  insertLeft: true, // This is to prevent loss if backspacing to previous line and comment is at start of line
                  className: 'bookmark-dot-wrapper'
                }
              )

              // This is a hack patch on CodeMirror. To prevent our absolute positioned widgets to affect cursor placements
              dot.parentNode.ignoreWhenMeasuringChars = true

              return [marker, bookmark]
            }

            return null
          })
          .filter(marker => !!marker)
      )
    })

    const updateDotOffsets = debounce(1000, () => {
      const positions = new Map()

      // Find positions
      markers.forEach(([_, bookmark]) => {
        // try...catch here in case something has happened with editor so bookmarks are no longer valid
        try {
          const top = Math.round(codeMirror.cursorCoords(bookmark.find(-1)).top)
          if (positions.has(top)) {
            positions.get(top).push(bookmark)
          } else {
            positions.set(top, [bookmark])
          }
        } catch (_) {
        }
      })

      // Set offsets
      positions.forEach((bookmarks) => {
        for (let i = 0; i < bookmarks.length; i++) {
          bookmarks[i].widgetNode.style.transform = `translateY(${i * 0.6}rem)`
        }
      })
    })

    codeMirror.on('refresh', updateDotOffsets)
    codeMirror.on('change', updateDotOffsets)
    updateDotOffsets()

    return () => {
      markers.forEach(([marker, bookmark]) => {
        marker.clear()
        bookmark.clear()
      })
      updateDotOffsets.cancel()
      codeMirror.off('refresh', updateDotOffsets)
      codeMirror.off('change', updateDotOffsets)
    }
  }, [codeMirrorBinding, commentsHash])

  // Show 'Add comment' on user selection
  const [addCommentButtonWidget, setAddCommentButtonWidget] = useState()
  const [addCommentButton, setAddCommentButton] = useState()
  useEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm) return

    // Only allow adding comment if user has write access
    if (!permissions || !permissions.includes('w')) return

    const codeMirror = codeMirrorBinding.cm

    const widget = document.createElement('div')
    widget.className = 'add-comment-button-wrapper hidden'
    const inner = document.createElement('div')
    inner.className = 'add-comment-button-wrapper-inner'

    const addCommentButton = document.createElement('a')
    addCommentButton.className = 'add-comment-button'
    addCommentButton.innerHTML = `
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00001 16H2.58333C2.42862 16 2.28025 15.9385 2.17085 15.8291C2.06146 15.7198 2 15.5714 2 15.4167V8.99998C2 7.14347 2.7375 5.363 4.05025 4.05025C5.363 2.7375 7.14348 2 8.99999 2H9C10.8565 2 12.637 2.7375 13.9497 4.05025C15.2625 5.36301 16 7.14348 16 9V9.00001C16 10.8565 15.2625 12.637 13.9498 13.9498C12.637 15.2625 10.8565 16 9.00001 16Z" fill="currentColor" fill-opacity="0.2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.48587 11.2377C7.15552 11.5684 6.72763 11.7792 6.26979 11.8421C6.33265 11.3841 6.54327 10.9558 6.87363 10.6252L10.2579 7.23784C10.3555 7.14013 10.5139 7.14009 10.6115 7.23776L10.8704 7.49661C10.968 7.59421 10.968 7.75244 10.8705 7.85008L7.48587 11.2377Z" fill="currentColor" stroke="currentColor" stroke-width="1.5"/>
      </svg>
    `

    widget.appendChild(inner)
    inner.appendChild(addCommentButton)

    codeMirror.addWidget({ line: 0, ch: 0 }, widget, false)
    setAddCommentButtonWidget(widget)
    setAddCommentButton(addCommentButton)

    return () => {
      if (widget.parentNode) widget.parentNode.removeChild(widget)
      setAddCommentButtonWidget(null)
      setAddCommentButton(null)
    }
  }, [codeMirrorBinding, permissions])

  // Move 'add comment' button
  useEffect(() => {
    if (!addCommentButtonWidget) return
    if (!codeMirrorBinding || !codeMirrorBinding.cm) return

    const codeMirror = codeMirrorBinding.cm
    const textType = codeMirrorBinding.doc.getText('content')

    if (userSelection) {
      const update = throttle(100, () => {
        const lowestUserSelection = getLowestRangePos(userSelection.anchor, userSelection.head)
        const coords = codeMirror.cursorCoords(lowestUserSelection, 'local')

        addCommentButtonWidget.style.left = coords.left + 'px'
        addCommentButtonWidget.style.top = coords.top + 'px'
      })

      update()

      // Refresh is called on resize + on modal open/close
      codeMirror.on('refresh', update)

      // Show widget
      addCommentButtonWidget.classList.remove('hidden')

      // Add comment onClick here instead of when creating to allow updating to latest selection
      const onComment = (ev) => {
        // Prevent default and propagation to stop CodeMirror from swallowing event prior to click
        ev.preventDefault()
        ev.stopPropagation()

        const user = {
          userId: userData.userId,
          avatar: userData.avatar,
          name: userData.name
        }

        let anchor = Y.relativePositionToJSON(Y.createRelativePositionFromTypeIndex(textType, codeMirror.indexFromPos(userSelection.anchor)))
        let head = Y.relativePositionToJSON(Y.createRelativePositionFromTypeIndex(textType, codeMirror.indexFromPos(userSelection.head)))

        // Fix to avoid relative position anchored at end of file. If it's anchored at end of file, without any relative item, it will always be end of file even if new characters are added after it. So we supply assoc: -1 to associate it with the character before last
        if (!anchor.item) {
          anchor = Y.relativePositionToJSON(Y.createRelativePositionFromTypeIndex(textType, codeMirror.indexFromPos(userSelection.anchor), -1))
        }
        if (!head.item) {
          head = Y.relativePositionToJSON(Y.createRelativePositionFromTypeIndex(textType, codeMirror.indexFromPos(userSelection.anchor), -1))
        }

        setAboutModalOpen({
          view: 'comments',
          addComment: {
            selectedText: userSelection.selectedText,
            anchor,
            head,
            created: { user, timestamp: Date.now() }
          }
        })
      }
      addCommentButton.addEventListener('mousedown', onComment)
      addCommentButton.addEventListener('touchstart', onComment)

      return () => {
        update.cancel()
        codeMirror.off('refresh', update)
        addCommentButton.removeEventListener('mousedown', onComment)
        addCommentButton.removeEventListener('touchstart', onComment)
      }
    } else {
      // Hide widget
      addCommentButtonWidget.classList.add('hidden')
    }
  }, [codeMirrorBinding, addCommentButton, addCommentButtonWidget, userData, userSelection])

  const selectedCommentCss = useMemo(() => {
    if (!selectedComment && !hoveredCommentId) return ''
    const _selectedComment = comments.find(c => c.id === selectedComment)
    const _hoveredComment = comments.find(c => c.id === hoveredCommentId)
    if (!_selectedComment && !_hoveredComment) return ''

    const selectedSeed = _selectedComment && _selectedComment.created.user.userId
    const selectedUserColor = getRandomUserColor(selectedSeed)

    const hoveredSeed = _hoveredComment && _hoveredComment.created.user.userId
    const hoveredUserColor = getRandomUserColor(hoveredSeed)

    return `
      .comment-marker--${selectedComment} {
        background: ${hexToRgba(selectedUserColor.background, 0.3)} !important;
      }
      .comment-marker--${hoveredCommentId} {
        background: ${hexToRgba(hoveredUserColor.background, 0.3)} !important;
      }
      .comment-dot[data-comment-id='${selectedComment}'] {
        background: ${selectedUserColor.background};
      }
      .comment-dot[data-comment-id='${hoveredCommentId}'] {
        background: ${hoveredUserColor.background};
      }
      .comment-dot[data-comment-id='${selectedComment}'] .avatar,
      .comment-dot[data-comment-id='${hoveredCommentId}'] .avatar {
        opacity: 1;
      }
    `
  }, [selectedComment, hoveredCommentId])

  useLayoutEffect(() => {
    if (!codeMirrorBinding || !codeMirrorBinding.cm) return

    const codeMirror = codeMirrorBinding.cm

    codeMirror.remoteCursors = remoteCursors
    codeMirror.updateSelection()
  }, [remoteCursors])

  return [selectedCommentCss, hoveredCommentId]
}
