import { useEffect, useState } from 'preact/hooks'

export default function useIsSyncedWithServer (kluddDoc, docId, websocketProvider) {
  const [isSyncedWithServer, setIsSyncedWithServer] = useState(false)

  useEffect(() => {
    if (!kluddDoc || !kluddDoc.metadataType) return

    // Make sure docId matches document docId, to avoid syncing with wrong document
    if (docId && docId !== kluddDoc.docId) return

    const onUpdateMetadata = () => {
      const hasUpdateOrCreatedKey = !!Array.from(kluddDoc.metadataType.keys()).find(k => k === 'created' || k.startsWith('updated:'))

      // If we have updated key, we are enough synced (even if offline we have some data)
      if (hasUpdateOrCreatedKey) {
        setIsSyncedWithServer(true)
      }
    }

    setIsSyncedWithServer(false)

    // If websocket provider says we are synced, assume we are
    // IMPORTANT! Currently not used to avoid user creating document by just entering URL. Might be worth to add this code in case we still get issues with recognizing isSyncedWithServer
    // if (websocketProvider) {
    //   setIsSyncedWithServer(websocketProvider.synced)
    //   websocketProvider.on('synced', (synced) => {
    //     if (synced) setIsSyncedWithServer(true)
    //   })
    // }

    onUpdateMetadata()
    kluddDoc.metadataType.observe(onUpdateMetadata)

    return () => {
      kluddDoc.metadataType.unobserve(onUpdateMetadata)
    }
  }, [kluddDoc, docId, websocketProvider])

  return isSyncedWithServer
}
