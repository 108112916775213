import styled, { css } from 'styled-components'

export default ({ className, ignoreValid, multiline, ...props }) => {
  const Element = multiline ? TextArea : Input

  return (
    <Element
      className={[className, props.valid && 'is-valid'].filter(v => v).join(' ')}
      ignoreValid={ignoreValid}
      {...props}
    />
  )
}

const sharedStyle = css`
  display: block;
  width: 100%;
  appearance: none;
  padding: 1.1rem 1.5rem;
  background: white;
  border: 0.1rem solid rgba(61, 79, 80, 0.2);
  border-radius: 0.6rem;
  font: inherit;
  color: #3d4f50;
  font-size: 1.4rem;
  line-height: 2rem;
  transition: background .2s ease, border .2s ease;

  .darkMode & {
    border-color: rgba(243, 249, 254, 0.1);
    color: white;
    background: #1d232b;
  }

  ${p => !p.ignoreValid && p.valid != null && `
    &.is-valid {
      background: #ecf9ff;
      border-color: #366668;
      color: #3d4f50;
    }
  `}
  ${p => !p.ignoreValid && p.valid == null && `
    &:valid {
      background: #ecf9ff;
      border-color: #366668;
      color: #3d4f50;
      box-shadow: 0 0 0 0.2rem rgba(54, 102, 104, 0.2);
    }
    .darkMode &:valid {
      background: #1d232b;
      color: white;
    }
  `}

  // 16px on touch device to avoid zoom-in on iOS
  @media (-moz-touch-enabled:1), (pointer:coarse) {
    font-size: 1.6rem;
  }
`
const Input = styled.input`
  ${sharedStyle}
`
const TextArea = styled.textarea`
  ${sharedStyle}
`
