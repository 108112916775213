import firebase from 'firebase/app'

export default function getProviderName (providerId) {
  switch (providerId) {
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID: return 'Google'
    case 'apple.com': return 'Apple ID'
    case 'password': return 'Email/Password'
    default: return ''
  }
}
