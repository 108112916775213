import styled from 'styled-components'

export default ({ name, value, options, onChange, required, fadeNotSelected, hideLabels, ...props }) => (
  <Wrapper {...props}>
    {options.map(o => (
      <Option
        key={o.value}
        value={o.value}
        selected={value ? value === o.value : undefined}
        disabled={!!o.disabled}
        className={(value ? value === o.value : undefined) && 'is-selected'}
      >
        <RadioInput
          name={name}
          type='radio'
          checked={value === o.value}
          value={o.value}
          onChange={ev => onChange(ev.target.value)}
          required={required}
          disabled={!!o.disabled}
        />
        <OptionText
          fadeNotSelected={fadeNotSelected}
          selected={value ? value === o.value : undefined}
          hideLabels={hideLabels}
        >
          {o.icon}
          {o.label}
        </OptionText>
      </Option>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  transition: border .2s ease;
  padding: 0.5rem;
  border: 1px solid rgba(61, 79, 80, 0.1);
  border-radius: 2.1rem;
  .darkMode & { border-color: rgba(243, 249, 254, 0.1); }
`
const Option = styled.label`
  flex: 1;
  transition: background .2s ease, color .2s ease;
  cursor: pointer;
  position: relative;
  border-radius: 10rem;

  &.is-selected { background: #f1f1f1; }
  .darkMode &.is-selected { background: #181d23; }

  &:hover { background: rgba(241, 241, 241, 0.5); }
  .darkMode &:hover { background: rgba(24, 29, 35, 0.5); }

  &:not(:first-child):before {
    content: '';
    width: 0.1rem;
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0;
    background: #f1f1f1;
    pointer-events: none;
    transition: opacity .2s ease;
  }
  .darkMode &:not(:first-child):before { background: #181d23; }

  // Hide border, either by selection or hover
  &.is-selected:before,
  &.is-selected + &:before,
  &:hover + &:before,
  &:hover:before {
    opacity: 0;
  }

  ${p => p.disabled && 'pointer-events: none; opacity: 0.3;'}
`
const OptionText = styled.span`
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  justify-content: center;
  padding: 0.6rem 0 0.6rem;
  transition: opacity .2s ease;

  ${p => (p.selected === false && p.fadeNotSelected) && 'opacity: 0.5;'}
  ${p => !p.hideLabels && 'svg { margin-right: 0.5rem; }'}
  ${p => p.hideLabels && 'font-size: 0;'}
`
const RadioInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`
