import styled from 'styled-components'

export default function NumberButton ({ number, active, className, ...props }) {
  return (
    <Button className={[className, active && 'is-active']} {...props}>
      {number + ''}
    </Button>
  )
}

const Button = styled.a`
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  width: 3.6rem;
  height: 3.6rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: rgba(61, 79, 80, 0.6);
  background: transparent;
  border: 1px solid rgba(61, 79, 80, 0);
  transition: color .2s ease, background .2s ease, border .2s ease;

  &.is-active {
    font-weight: 600;
    color: white;
    background: #3d4f50;
    border: 1px solid rgba(61, 79, 80, 0.6);
  }
  .darkMode & {
    color: #3d4f50;
  }
  .darkMode &.is-active {
    color: #f8f8f9;
    background: #181d23;
    border: 1px solid rgba(24, 29, 35, 0.6);
  }
`
