import { route } from 'preact-router'
import * as Y from 'yjs'
import { IndexeddbPersistence } from 'y-indexeddb'
import Cookies from 'js-cookie'
import YKluddMetadataMap from 'helpers/YKluddMetadataMap'
import generateDocId from 'utils/generateDocId'
import { logTrackingEvent } from 'helpers/tracking'
import defaultText from 'static/defaultText.md'

export default async function goToNewDoc (insertDefaultText, userData, userDoc) {
  const docId = generateDocId()

  logTrackingEvent('Document created', { documentId: docId })

  // Create doc with latest update timestamp
  // It's safe to set here manually since we know it's a new Kludd
  const doc = new Y.Doc()
  const metadataType = doc.get('metadata', YKluddMetadataMap)

  metadataType.setCreatedTimestampToNow(userData)
  metadataType.setLatestUpdateTimestampToNow(userData)

  // Add document to userDoc if provided, in order to set latestReadTimestamp
  if (userDoc && userDoc.documentsMapType) {
    userDoc.documentsMapType.set(docId, {
      latestUpdateTimestamp: metadataType.getLatestUpdateTimestamp(false, true),
      createdTimestamp: metadataType.getCreatedTimestamp(false),
      latestReadTimestamp: Date.now(),
      preview: ''
    })
  }

  // Set default text
  if (insertDefaultText) {
    doc.getText('content').insert(0, defaultText)
  }

  // Wait to have it stored in indexeddb before routing to it
  const persistence = new IndexeddbPersistence('kludd:' + docId, doc)

  await persistence.whenSynced
  persistence.destroy()

  // Assume document only exists offline until route has connected and removes cookie
  Cookies.set('onlyExistsOffline:kludd:' + docId, 'true', { expires: 365 })

  route('/' + docId + (insertDefaultText ? '?focus' : ''))
}
