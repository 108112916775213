import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'

export default function EmptyAvatar ({ avatar, seed, size = 30, ...props }) {
  return (
    <Avatar style={{ width: size, height: size }} {...props}>
      <SvgIcon icon='user' />
    </Avatar>
  )
}

const Avatar = styled.div`
  background: white;
  color: #3d4f50;
  border: 2px dashed rgba(61, 79, 80, 0.2);
  border-radius: 50%;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;

  svg { opacity: 0.3; }

  .darkMode & {
    color: white;
    background: #181d23;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
  }
`
