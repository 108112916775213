import styled from 'styled-components'

export default styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  opacity: 0.6;
  ${p => 'margin-bottom: ' + (p.margin || 0) + 'rem;'}
  ${p => p.centered && 'text-align: center;'}
`
