import { useMemo } from 'preact/hooks'
import { useRouter } from 'preact-router'
import { BASE_FONT_FAMILY } from '@constants'
import styled, { keyframes } from 'styled-components'
import formatDate from 'utils/formatDate'
import Loader from 'components/Loader'
import SvgIcon from 'components/SvgIcon'
import encodeHtml from 'utils/encodeHtml'

export default ({
  preview,
  latestUpdateTimestamp,
  href,
  onContextMenu,
  doc,
  isTemp,
  isRemoved,
  tempMessage,
  showDot,
  symbol,
  symbolText,
  symbolAlwaysVisible,
  hideMetadata,
  filled,
  className,
  loading,
  forceMatches,
  onFavorite,
  searchQuery,
  ...props
}) => {
  const router = useRouter()[0]
  const matches = router.path === '/:docId' && router.matches.docId === doc.key
  const previewHtml = useMemo(() => (
    searchQuery
      ? addMarksToPreview(preview, searchQuery)
      : encodeHtml(preview)
  ), [preview, searchQuery])
  const _onContextMenu = (ev) => {
    const rect = ev.target.getBoundingClientRect()
    const top = rect.bottom
    const left = rect.left + rect.width / 2

    onContextMenu(doc, top, left)
  }

  return (
    <Card
      active={matches || forceMatches}
      filled={filled}
      isTemp={isTemp}
      isRemoved={isRemoved}
      className={[className, filled && 'is-filled'].filter(v => v).join(' ')}
      {...props}
    >
      {
        hideMetadata
          ? <NoPreview active={matches || forceMatches} filled={filled} />
          : preview == null
            ? <NoPreview active={matches || forceMatches} filled={filled}>(No preview available)</NoPreview>
            : (
              <Preview
                active={matches || forceMatches}
                filled={filled}
                dangerouslySetInnerHTML={{ __html: previewHtml }}
              />
              )
      }
      <Link href={href} />
      <TempMessage visible={isTemp}>
        {tempMessage && tempMessage.title && <TempMessageTitle>{tempMessage.title}</TempMessageTitle>}
        {tempMessage && tempMessage.subtitle && <TempMessageSubtitle dangerouslySetInnerHTML={{ __html: tempMessage.subtitle }} />}
      </TempMessage>
      <Meta pointerEvents={!isTemp}>
        <LatestChange
          active={matches || forceMatches}
          filled={filled}
        >
          {!hideMetadata && !!latestUpdateTimestamp && formatDate(latestUpdateTimestamp)}
        </LatestChange>
        {onFavorite && (
          <StyledSvgIcon icon='favorite' onClick={onFavorite} />
        )}
        {onContextMenu && (
          <Dots onClick={_onContextMenu} active={matches || forceMatches} filled={filled}>
            <svg width='18' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect class='contextBackground' width='18' height='8' rx='4' fill='currentColor' fill-opacity='.2' />
              <path fill-rule='evenodd' clip-rule='evenodd' d='M3.75 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.75 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' fill='currentColor' />
            </svg>
          </Dots>
        )}
      </Meta>
      {showDot && <Dot title='Has unread changes' />}
      {!!symbol && (
        <SymbolHolder
          showOnHover={!matches && !forceMatches && !symbolAlwaysVisible}
          isActive={!!(matches || forceMatches)}
          className={['symbol-holder', filled && 'is-filled'].filter(v => v).join(' ')}
        >
          <Symbol isActive={!!(matches || forceMatches)}><SvgIcon icon={symbol} /></Symbol>
          {!!symbolText && !!(matches || forceMatches) && <SymbolText>{symbolText}</SymbolText>}
        </SymbolHolder>
      )}
      <LoaderWrapper visible={loading}>
        <SmallLoader secondsBeforeRotating={0} />
      </LoaderWrapper>
    </Card>
  )
}

const showCard = keyframes`
  from { max-height: 0; padding-top: 0; padding-bottom: 0; opacity: 0; margin-bottom: -0.2rem !important; }
  to { max-height: 14.5rem; padding: 1.5rem; opacity: 1; }
`
// -0.2rem for isRemoved is border width (0.1rem * 2)
const Card = styled.article`
  background: #f8f8f9;
  border: 1px dashed rgba(61, 79, 80, 0.2);
  border-radius: 0.6rem;
  font-family: ${BASE_FONT_FAMILY};
  padding: 1.5rem;
  color: #3d4f50;
  display: block;
  position: relative;
  transition: background .3s ease, color .3s ease, border .2s ease, max-height .2s ease, opacity .2s ease, padding .2s ease, margin .2s ease;
  box-shadow: 0 0.4rem 2rem rgba(61, 79, 80, 0.15);
  overflow: hidden;
  animation: ${showCard} .2s ease;
  .darkMode & {
    background: #181d23;
    border-color: rgba(61, 79, 80, 0.6);
    color: #f3f9fe;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.2);
  }
  &.is-filled {
    background: white;
    border-color: white;
  }
  .darkMode &.is-filled {
    background: #1d232b;
    border-color: #1d232b;
    color: #f3f9fe;
  }
  ${p => (p.active || p.isTemp) && 'border-style: solid;'}

  ${p => !p.active && !p.filled && `
    box-shadow: none !important;
    &:hover {
      border-color: rgba(61, 79, 80, 0.3);
      border-style: solid;
    }
    .darkMode &:hover {
      border-color: rgba(61, 79, 80, 0.6);
    }
  `}

  max-height: 14.5rem;
  ${p => p.isTemp && 'max-height: 6.5rem;'}
  ${p => p.isRemoved && 'max-height: 0rem; padding-top: 0; padding-bottom: 0; opacity: 0; margin-bottom: -0.2rem !important;'}
`
const Preview = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  height: 7.5rem;
  transition: opacity .2s ease;

  ${p => !p.active && !p.filled && `
    opacity: 0.4;

    @media (min-width: 650px) {
      -webkit-line-clamp: 2;
      height: 3rem;
    }
  `}

  ${Card}:hover & {
    ${p => !p.active && !p.filled && 'opacity: 0.8;'}
  }

  mark {
    color: inherit;
    background: rgba(110, 208, 255, 0.34);
  }
`
const NoPreview = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  height: 7.5rem;
  opacity: 0.5;
  ${p => !p.active && !p.filled && '-webkit-line-clamp: 2; height: 3rem;'}
`
const Meta = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  ${p => !p.pointerEvents && 'pointer-events: none;'}
`
const LatestChange = styled.time`
  font-size: 1.2rem;
  transition: color .3s ease;
  color: inherit;
  opacity: 0.4;
  display: block;
  flex: 1;
  transition: opacity .2s ease;
  ${p => (p.active || p.filled) && 'opacity: 1;'}

  ${Card}:hover & {
    ${p => !p.active && !p.filled && 'opacity: 0.8;'}
  }
`
const Dots = styled.a`
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  position: relative;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  z-index: 2;
  .contextBackground { opacity: 0; transition: opacity 0.2s ease; }
  &:hover {
    .contextBackground { opacity: 1; }
    opacity: 1;
  }
  ${p => (p.active || p.filled) && 'opacity: 1;'}
  ${Card}:hover & {
    ${p => !p.active && !p.filled && 'opacity: 0.8;'}
  }
`
const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  &:hover ~ .symbol-holder { opacity: 1; }
`
const TempMessage = styled.div`
  position: absolute;
  top: -0.1rem;
  left: -0.1rem;
  bottom: -0.1rem;
  right: -0.1rem;
  padding: 1.5rem;
  font-family: ${BASE_FONT_FAMILY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 0.6rem;
  background: white;
  z-index: 1;
  transition: opacity .2s ease, visibility .2s ease, background .2s ease;
  .darkMode & { background: #181d23; }
  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}
`
const TempMessageTitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
`
const TempMessageSubtitle = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.2rem;
  opacity: 0.6;

  a { color: inherit }
`
const Dot = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: #6ed0ff;
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const SymbolHolder = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.6rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  transition: opacity .2s ease;
  ${p => p.showOnHover && 'opacity: 0;'}

  background: rgba(248, 248, 249, 0.85);
  .darkMode & { background: rgba(34, 40, 47, 0.85); }

  ${p => !p.isActive && `
    left: auto;
    bottom: auto;
    width: 10rem;
    height: 5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background: linear-gradient(269.19deg, #f8f8f9 0.71%, #f8f8f9 35.3%, rgba(248, 248, 249, 0) 99.31%);
    padding: 1.4rem 1.4rem 0 0;

    &.is-filled { background: linear-gradient(269.19deg, #fff 0.71%, #fff 35.3%, rgba(255, 255, 255, 0) 99.31%); }
    .darkMode & { background: linear-gradient(269.19deg, #181d23 0.71%, #181d23 35.3%, rgba(24, 29, 35, 0) 99.31%); }
    .darkMode &.is-filled { background: linear-gradient(269.19deg, #1d232b 0.71%, #1d232b 35.3%, rgba(29, 35, 43, 0) 99.31%); }
  `}
`
const Symbol = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: white;
  .darkMode & { background: black; }
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2rem 1rem rgba(61, 79, 80, 0.15);
  .darkMode & { box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2); }

  ${p => !p.isActive && `
    background: transparent;
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;

    .darkMode & { background: transparent; box-shadow: none; }
  `}
`
const SymbolText = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
`
const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  transition: opacity .2s ease, visibility .2s ease, background .2s ease;

  background: rgba(255, 255, 255, 0.5);
  .darkMode & { background: rgba(24, 29, 35, 0.5); }

  ${p => !p.visible && 'opacity: 0; visibility: hidden;'}
`
const SmallLoader = styled(Loader)`
  width: 5rem;
`

const StyledSvgIcon = styled(SvgIcon)`
  cursor: pointer;
  margin-right: 10px;
  z-index: 2;
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`

const addMarksToPreview = (text, query) => {
  if (!text || !query) return ''

  // Find all occurences of query
  // Use regular string indexes instead of regexp to perfectly mimic actual search (to avoid regexp special characters)
  const queryLowercase = query.toLowerCase()
  const textLowercase = text.toLowerCase()

  let result = ''

  let indexOffset = 0
  let index

  while ((index = textLowercase.indexOf(queryLowercase, indexOffset)) > -1) {
    result += encodeHtml(text.substring(indexOffset, index))
    result += '<mark>' + encodeHtml(text.substr(index, query.length)) + '</mark>'

    indexOffset = index + query.length
  }

  // Add rest of text
  if (text.length > indexOffset) {
    result += encodeHtml(text.substr(indexOffset))
  }

  return result
}
