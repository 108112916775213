import styled from 'styled-components'

export default ({ icon, hideLabel, label, ...props }) => (
  <Button title={label} {...props}>
    {icon}
    <Label>{label}</Label>
  </Button>
)

const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #3d4f50;
  box-shadow: 0 0.2rem 2rem rgba(61, 79, 80, 0.05);
  padding: 1.1rem 3rem;
  border-radius: 3rem;
  margin: 0 1rem;
  min-width: 14rem;
`
const Label = styled.span`
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: 1rem;
`
