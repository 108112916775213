/* global fetch */
import { useEffect, useMemo, useCallback, useState } from 'preact/hooks'
import { route } from 'preact-router'
import firebase from 'firebase/app'
import styled from 'styled-components'
import { API_SERVER } from '@constants'
import Modal from 'components/Modal'
import SvgIcon from 'components/SvgIcon'
import TextInput from 'components/TextInput'
import Label from 'components/Label'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import ImageInput from 'components/ImageInput'
import isValidEmail from 'utils/isValidEmail'

export default ({
  open,
  onClose,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  uploadAvatarImage,
  acceptToSForUserId,
  ...props
}) => {
  const [view, setView] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [avatarFile, setAvatarFile] = useState()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  // Clear state when opened
  useEffect(() => {
    if (open) {
      setView(null)
      setLoading(false)
      setError('')
    }
  }, [open])

  // Clear error when view changes because not related anymore
  useEffect(() => {
    setError('')
  }, [view])

  const onSelectSignIn = useCallback((ev) => {
    ev.preventDefault()
    setView('signin')
  }, [])

  const onSignIn = useCallback((ev) => {
    ev.preventDefault()

    setLoading(true)
    setError('')

    signInWithEmailAndPassword(email, password)
      .then(user => {
        setLoading(false)

        // If we got a user when signing in, we can safely close modal
        if (user) onClose()
      })
      .catch(err => setError((err && err.message) || 'Something went wrong.'))
      .then(() => setLoading(false))
  }, [onClose, email, password])

  const onSignUp = useCallback((ev) => {
    ev.preventDefault()

    setLoading(true)
    setError('')

    createUserWithEmailAndPassword(email, password)
      .then(async userCredential => {
        const user = userCredential?.user

        if (user) {
          // If we got a user when creating, set name and upload avatar
          let photoURL
          if (avatarFile) {
            try {
              photoURL = await uploadAvatarImage(avatarFile)
            } catch (_) {}
          }

          await updateProfile({ displayName, photoURL })

          // Send email verification mail
          await fetch(API_SERVER + '/send-email-verification', {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: { 'Content-Type': 'application/json' }
          })

          // Accept terms of service in user doc (called for specific user id because userDoc hook is not updated yet here)
          acceptToSForUserId(user.uid)

          // Redirect to verification message page
          route('/message/awaiting-verification')

          onClose()
        }

        setLoading(false)
      })
      .catch(err => setError((err && err.message) || 'Something went wrong.'))
      .then(() => setLoading(false))
  }, [email, password, displayName, avatarFile, acceptedTerms, acceptToSForUserId])

  const onResetPassword = useCallback((ev) => {
    ev.preventDefault()

    setLoading(true)
    setError('')

    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        console.log('Sent reset mail!')
        setView('forgotPasswordDone')
      })
      .catch(err => setError((err && err.message) || 'Something went wrong.'))
      .then(() => setLoading(false))
  }, [email])

  const onForgotPasswordDone = useCallback((ev) => {
    ev.preventDefault()
    onClose()
  }, [onClose])

  const title = useMemo(() => {
    switch (view) {
      case 'signin': return 'Sign in with email'
      case 'signup': return 'Create account'
      case 'forgotPassword': return 'Forgot password'
      case 'forgotPasswordDone': return 'Forgot password'
      default: return 'Use email address'
    }
  }, [view])

  const submitButton = useMemo(() => {
    if (!view) {
      return {
        label: 'Sign in',
        icon: <SvgIcon icon='sign-in' />,
        disabled: !isValidEmail(email)
      }
    } else if (view === 'signin') {
      return {
        label: 'Sign in',
        icon: <SvgIcon icon='sign-in' />,
        disabled: !isValidEmail(email) || !password
      }
    } else if (view === 'signup') {
      return {
        label: 'Create account',
        icon: <SvgIcon icon='add' />,
        disabled: !isValidEmail(email) || !password || !displayName || !acceptedTerms
      }
    } else if (view === 'forgotPassword') {
      return {
        label: 'Send reset link',
        icon: <SvgIcon icon='link' />,
        disabled: !isValidEmail(email)
      }
    } else if (view === 'forgotPasswordDone') {
      return {
        label: 'Close',
        onClick: onForgotPasswordDone,
        black: true
      }
    }
  }, [view, email, password, displayName, acceptedTerms, onForgotPasswordDone])

  const textButton = useMemo(() => {
    if (view === 'signin') {
      return {
        label: 'Forgot password?',
        onClick: () => setView('forgotPassword')
      }
    }
  }, [view])

  const altButton = useMemo(() => {
    if (!view) {
      return {
        label: 'Create account',
        icon: <SvgIcon icon='add' />,
        disabled: !isValidEmail(email),
        onClick: () => setView('signup')
      }
    }
  }, [view, email])

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={
        view === 'signup'
          ? onSignUp
          : view === 'signin'
            ? onSignIn
            : view === 'forgotPassword'
              ? onResetPassword
              : view === 'forgotPasswordDone'
                ? onForgotPasswordDone
                : onSelectSignIn
      }
      includeCancelButton={false}
      maxWidth='50.5rem'
      onBack={view === 'forgotPassword' && onSelectSignIn}
      submitButton={submitButton}
      altButton={altButton}
      textButton={textButton}
      isFooterBottomFixed={view === 'signup'}
      loading={loading}
      error={error}
      {...props}
    >
      {(!view || view === 'forgotPassword') && (
        <EnterEmailView
          email={email}
          setEmail={setEmail}
        />
      )}

      {view === 'signin' && (
        <SignInView
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
        />
      )}

      {view === 'signup' && (
        <SignUpView
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          displayName={displayName}
          setDisplayName={setDisplayName}
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          avatarFile={avatarFile}
          setAvatarFile={setAvatarFile}
        />
      )}

      {view === 'forgotPasswordDone' && (
        <ForgotPasswordDoneView email={email} />
      )}
    </Modal>
  )
}

const EnterEmailView = ({ email, setEmail }) => {
  return (
    <View>
      <Label for='email-auth-email'>Email address:</Label>
      <TextInput
        required
        value={email}
        valid={isValidEmail(email)}
        onChange={ev => setEmail(ev.currentTarget.value)}
        type='email'
        name='email'
        id='email-auth-email'
        autoComplete='username'
      />
    </View>
  )
}

const SignInView = ({ email, setEmail, password, setPassword }) => {
  return (
    <View>
      <Label for='email-auth-email'>Email address:</Label>
      <TextInput
        required
        value={email}
        valid={isValidEmail(email)}
        onChange={ev => setEmail(ev.currentTarget.value)}
        type='email'
        name='email'
        id='email-auth-email'
        autoComplete='username'
      />

      <SecondaryLabel for='email-auth-password'>Password:</SecondaryLabel>
      <TextInput
        required
        value={password}
        onChange={ev => setPassword(ev.currentTarget.value)}
        type='password'
        name='password'
        id='email-auth-password'
        autoComplete='current-password'
      />
    </View>
  )
}

const SignUpView = ({
  email,
  setEmail,
  password,
  setPassword,
  displayName,
  setDisplayName,
  acceptedTerms,
  setAcceptedTerms,
  avatarFile,
  setAvatarFile
}) => {
  return (
    <View>
      <ImageInputWithMargin onFileChange={setAvatarFile} />

      <Divider />

      <Label for='email-auth-email'>Email address:</Label>
      <TextInput
        required
        value={email}
        valid={isValidEmail(email)}
        onChange={ev => setEmail(ev.currentTarget.value)}
        type='email'
        name='email'
        id='email-auth-email'
        autoComplete='username'
      />

      <SecondaryLabel for='email-auth-name'>Name:</SecondaryLabel>
      <TextInput
        required
        value={displayName}
        onChange={ev => setDisplayName(ev.currentTarget.value)}
        type='text'
        name='name'
        id='email-auth-name'
        autoComplete='name'
      />

      <SecondaryLabel for='email-auth-password'>Password:</SecondaryLabel>
      <TextInput
        required
        value={password}
        onChange={ev => setPassword(ev.currentTarget.value)}
        type='password'
        name='password'
        id='email-auth-password'
        autoComplete='new-password'
      />

      <Divider />

      <CheckboxWithLabel
        label={<span>I accept Kludd’s <a href='/privacy' target='_blank'>Privacy Policy</a> and <a href='/terms' target='_blank'>Terms of Service</a>.</span>}
        checked={acceptedTerms}
        onChecked={setAcceptedTerms}
      />
    </View>
  )
}

const ForgotPasswordDoneView = ({ email }) => {
  return (
    <View>
      <Message>We've sent a verification link to your email address</Message>
      <MessageValue>{email}</MessageValue>
    </View>
  )
}

const View = styled.div``
const SecondaryLabel = styled(Label)`margin-top: 2rem;`
const Divider = styled.div`
  height: 0.1rem;
  background: rgba(61, 79, 80, 0.1);
  margin: 3rem 0;
`
const ImageInputWithMargin = styled(ImageInput)`margin: 0 auto;`
const Message = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
`
const MessageValue = styled.p`
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  text-align: center;
`
