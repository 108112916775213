import styled from 'styled-components'
import SvgIcon from 'components/SvgIcon'

const icons = ['logo', 'public', 'private', 'delete', 'locked', 'unlocked', 'knock', 'sync', 'not-synced', 'link', 'settings', 'edit-text', 'notification', 'check', 'user', 'users', 'user-settings', 'add-user', 'remove-user', 'favorite', 'search', 'workspace', 'alert', 'type-settings', 'context', 'sign-in', 'sign-out', 'add', 'exit', 'exit-small', 'undo', 'redo', 'duplicate', 'save', 'sidebar-out', 'light', 'dark', 'focused', 'typewriter', 'comments', 'history', 'information', 'information-2', 'offline', 'arrow', 'arrow-back', 'thumbs-up', 'email', 'typewriting', 'minimalist']

export default function SvgIcons () {
  return (
    <View>
      <Icons>
        {icons.map(icon => (
          <Icon key={icon}>
            <SvgIcon icon={icon} />
            <p>{icon}</p>
          </Icon>
        ))}
      </Icons>
    </View>
  )
}

const View = styled.div`
  padding: 8rem;
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  flex: 1;
  white-space: nowrap;

  p { margin-top: 0.8rem; }
`
