import { route } from 'preact-router'
import { IndexeddbPersistence } from 'y-indexeddb'
import * as Y from 'yjs'

import YKluddMetadataMap from 'helpers/YKluddMetadataMap'
import canCloneDocument from 'utils/canCloneDocument'
import generateDocId from 'utils/generateDocId'

export default async function cloneDocument (userId, docId, userData) {
  if (!(await canCloneDocument(userId, docId))) return

  // Fetch text into new document
  const doc = new Y.Doc()
  let persistence = new IndexeddbPersistence('kludd:' + docId, doc)

  await persistence.whenSynced
  persistence.destroy()

  // Update metadata with new timestamp
  const metadataType = doc.get('metadata', YKluddMetadataMap)
  metadataType.setLatestUpdateTimestampToNow(userData)

  // TODO: Remove permissions metadata here

  const newDocId = generateDocId()

  // Create new doc with same text
  persistence = new IndexeddbPersistence('kludd:' + newDocId, doc)

  await persistence.whenSynced
  persistence.destroy()

  // Will automatically be added to docs when routing to it
  route(`/${newDocId}`)
}
