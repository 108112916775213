import { useEffect } from 'preact/hooks'
import Cookies from 'js-cookie'

export default function useRememberModes (darkMode, setDarkMode, focusMode, setFocusMode, minimalistMode, setMinimalistMode, isInApp) {
  // Load modes from cookies
  useEffect(() => {
    setDarkMode(Cookies.get('darkMode') === '1')
    setFocusMode(Cookies.get('focusMode') === '1')
    setMinimalistMode(Cookies.get('minimalistMode') !== '0') // true by default.
  }, [setDarkMode, setFocusMode, setMinimalistMode])

  // Store modes with cookies
  useEffect(() => {
    Cookies.set('darkMode', darkMode ? '1' : '0', { expires: 365 })
    Cookies.set('focusMode', focusMode ? '1' : '0', { expires: 365 })
    Cookies.set('minimalistMode', minimalistMode ? '1' : '0', { expires: 365 })
  }, [darkMode, focusMode, minimalistMode])

  // Set darkmode on html
  useEffect(() => {
    document.documentElement.classList.toggle('darkMode', darkMode && isInApp)
  }, [darkMode, isInApp])
}
