import styled from 'styled-components'

export default styled.label`
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: inherit;
  opacity: 0.6;
  margin-bottom: 1rem;
`
