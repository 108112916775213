/* global fetch */
import { useState, useEffect } from 'preact/hooks'
import { route } from 'preact-router'
import styled from 'styled-components'
import firebase from 'firebase/app'
import Cookies from 'js-cookie'
import LoginButton from 'components/LoginButton'
import Loader from 'components/Loader'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import AddDocCard from 'components/AddDocCard'
import DocCardWithOfflineCheck from 'components/DocCardWithOfflineCheck'
import FeedbackMessage from 'components/FeedbackMessage'
import SvgIcon from 'components/SvgIcon'
import shouldShowDot from 'utils/shouldShowDot'
import goToNewDoc from 'utils/goToNewDoc'
import useSortedDocs from 'hooks/useSortedDocs'
import { useFavoriteFilteredDocs, useFavoriteToggle } from 'hooks/useFavoriteDocs'
import { API_SERVER } from '@constants'

export default function Home ({
  userEmailVerified,
  isLoggedIn,
  isLoggingIn,
  login,
  sidebarOpen,
  docs,
  returnUrl,
  onDocContextMenu,
  sidebarRefreshHash,
  userData,
  userDoc,
  setMyProfileOpen,
  setHideSidebar,
  wasInvitedToAuthor,
  tempDocs,
  setShowCookieBar
}) {
  const sortedDocs = useSortedDocs(docs, tempDocs)
  const [favoriteKeys] = useFavoriteFilteredDocs(sortedDocs, userDoc)
  const onFavorite = useFavoriteToggle(userDoc)
  const [hasResentInvite, setHasResentInvite] = useState(false)

  useEffect(() => {
    // Hide sidebar if unauthorized.
    setHideSidebar(wasInvitedToAuthor != null && !isLoggedIn)

    // Redirect to / if user is logged in.
    if (isLoggedIn && returnUrl) route(returnUrl)

    return () => setHideSidebar(false)
  }, [isLoggedIn, returnUrl])

  if (isLoggedIn) {
    return (
      <View sidebarOpen={sidebarOpen}>
        <Inner>
          <Header>
            <Title>Workspace</Title>
            <Button
              label='Profile settings'
              white
              icon={<SvgIcon icon='user-settings' />}
              onClick={() => setMyProfileOpen(true)}
            />
          </Header>
          {!userEmailVerified && (
            <NotVerifiedSection>
              <NotVerifiedSectionInner>
                <NotVerifiedTitle>Verify your email adress</NotVerifiedTitle>
                <p>Check your inbox and click the link.</p>
                {
                  hasResentInvite
                    ? <p>A new link has been sent!</p>
                    : (
                      <Button
                        label='Send a new link'
                        icon={<SvgIcon icon='alert' />}
                        orange
                        onClick={() => {
                          const email = firebase.auth().currentUser?.email
                          if (email) {
                            fetch(API_SERVER + '/send-email-verification', {
                              method: 'POST',
                              body: JSON.stringify({ email }),
                              headers: { 'Content-Type': 'application/json' }
                            })
                          }
                          setHasResentInvite(true)
                        }}
                      />
                      )
                }
              </NotVerifiedSectionInner>
            </NotVerifiedSection>
          )}
          <WorkspaceHeader>
            {userData && (
              <UserAvatar avatar={userData.avatar} seed={userData.userId} size={40} />
            )}
            <div>
              <WorkspaceTitle>{(userData && userData.name) || 'My space'}</WorkspaceTitle>
              <WorkspaceAmount>{sortedDocs.length} kludds</WorkspaceAmount>
            </div>
          </WorkspaceHeader>
          <DocGrid>
            <AddDocCard onClick={() => goToNewDoc(sortedDocs.length === 0, userData, userDoc)} />
            {sortedDocs
              .map(([key, doc]) => (
                <DocCardWithOfflineCheck
                  key={key}
                  href={`/${key}`}
                  doc={{ key, ...doc }}
                  onContextMenu={onDocContextMenu}
                  showDot={isLoggedIn && shouldShowDot(doc)}
                  sidebarRefreshHash={sidebarRefreshHash}
                  filled
                  onFavorite={favoriteKeys.has(key) && (() => onFavorite(key))}
                  {...doc}
                />
              ))}
          </DocGrid>
          <MarginedFeedbackMessage />
        </Inner>
      </View>
    )
  }

  const isFromInvitation = wasInvitedToAuthor != null

  // The cookie bar covers the login buttons so hide on Home page
  useEffect(() => {
    if (!isLoggedIn) {
      setShowCookieBar(false)

      return () => {
        // Show cookie bar when leaving Home page if not already accepted
        let cookieCompliance = {}
        try { cookieCompliance = JSON.parse(Cookies.get('cookieCompliance')) } catch (_) {}
        setShowCookieBar(!cookieCompliance['necessary-cookies'])
      }
    }
  }, [isLoggedIn])

  return (
    <View sidebarOpen={sidebarOpen}>
      {isFromInvitation && (
        <HomeLink href='/'>
          <SvgIcon icon='logo' />
        </HomeLink>
      )}

      <InnerSignedOut>
        <WritingEliteImage
          showOnDarkMode={false}
          src={require(`images/illustration-${isFromInvitation ? 'access-denied.svg' : 'writing-elite.svg'}`).default}
          width={366}
          height={301}
        />
        <WritingEliteImage
          showOnDarkMode
          src={require(`images/illustration-${isFromInvitation ? 'access-denied-darkmode.svg' : 'writing-elite.svg'}`).default}
          width={366}
          height={301}
        />
        {isFromInvitation
          ? (
            <>
              <WritingEliteTitle>You’ve been invited to a locked kludd</WritingEliteTitle>
              <WritingEliteText>You need to sign in to gain access.</WritingEliteText>
            </>
            )
          : (
            <>
              <WritingEliteTitle>Join the writing elite</WritingEliteTitle>
              <WritingEliteText>Reach your kludds from any device and collaborate with others.</WritingEliteText>
            </>
            )}
        {!isLoggedIn && isLoggingIn && <LoginButtons><Loader /></LoginButtons>}
        {!isLoggedIn && !isLoggingIn && (
          <LoginButtons>
            <LoginButton
              icon={
                <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fill-rule='evenodd' clip-rule='evenodd' d='M11.1905 2.92219C11.7776 2.16439 12.2226 1.09331 12.0616 0C11.1022 0.0666779 9.98067 0.680538 9.3263 1.48067C8.72979 2.20566 8.23954 3.28415 8.43101 4.33089C9.4799 4.3637 10.5625 3.73502 11.1905 2.92219ZM16.375 13.2063C15.9552 14.1419 15.7532 14.56 15.2125 15.3887C14.4582 16.5455 13.3946 17.986 12.0753 17.9965C10.9044 18.0092 10.6024 17.2292 9.01279 17.2387C7.42316 17.2472 7.09176 18.0114 5.91873 17.9997C4.60052 17.9881 3.59266 16.6884 2.83835 15.5316C0.727945 12.2993 0.505964 8.50499 1.80734 6.48666C2.73314 5.05361 4.19338 4.21537 5.56525 4.21537C6.96131 4.21537 7.83976 4.98587 8.99596 4.98587C10.1174 4.98587 10.8002 4.21326 12.4151 4.21326C13.6376 4.21326 14.9326 4.88321 15.8542 6.03896C12.8328 7.70485 13.322 12.0453 16.375 13.2063Z' fill='#0B0B0A' />
                </svg>
              }
              label='Apple ID'
              onClick={() => login('apple')}
            />
            <LoginButton
              icon={
                <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clip-path='url(#clip0)'>
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M3.68705 8.9997C3.68705 8.4151 3.78414 7.85465 3.95743 7.32897L0.92422 5.0127C0.333063 6.21297 0 7.56542 0 8.9997C0 10.4327 0.332654 11.7844 0.922991 12.9838L3.95456 10.6631C3.78291 10.1398 3.68705 9.58142 3.68705 8.9997Z' fill='#FBBC05' />
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M9.01278 3.68182C10.2828 3.68182 11.4298 4.13182 12.3311 4.86818L14.953 2.25C13.3553 0.859091 11.3069 0 9.01278 0C5.45109 0 2.39002 2.03686 0.924217 5.013L3.95743 7.32927C4.65633 5.20773 6.64856 3.68182 9.01278 3.68182Z' fill='#EA4335' />
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M9.01278 14.3184C6.64856 14.3184 4.65633 12.7924 3.95743 10.6709L0.924217 12.9868C2.39002 15.9633 5.45109 18.0002 9.01278 18.0002C11.2111 18.0002 13.3098 17.2196 14.885 15.7571L12.0058 13.5313C11.1935 14.043 10.1705 14.3184 9.01278 14.3184Z' fill='#34A853' />
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M17.6159 8.99965C17.6159 8.46783 17.534 7.8951 17.4111 7.36328H9.01279V10.8406H13.8469C13.6052 12.0261 12.9473 12.9376 12.0059 13.5307L14.885 15.7566C16.5397 14.2209 17.6159 11.9332 17.6159 8.99965Z' fill='#4285F4' />
                  </g>
                  <defs>
                    <clipPath id='clip0'>
                      <rect width='18' height='18' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              }
              label='Google'
              onClick={() => login('google')}
            />
            <LoginButton
              icon={
                <svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <mask id='email-icon-mask-a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'>
                    <path fill='#A5A5A5' d='M0 0h18v18H0z' />
                  </mask>
                  <g mask='url(#email-icon-mask-a)'>
                    <mask id='email-icon-mask-b' fill='#fff'>
                      <path fill-rule='evenodd' clip-rule='evenodd' d='M4.5 2.25a3 3 0 0 0-3 3V12a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-9ZM4.288 3.8C3.577 3.8 3 4.377 3 5.088c0 .234.127.45.331.563l4.212 2.34a3 3 0 0 0 2.914 0l4.212-2.34A.644.644 0 0 0 15 5.088c0-.711-.577-1.288-1.288-1.288H4.288Z' />
                    </mask>
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M4.5 2.25a3 3 0 0 0-3 3V12a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-9ZM4.288 3.8C3.577 3.8 3 4.377 3 5.088c0 .234.127.45.331.563l4.212 2.34a3 3 0 0 0 2.914 0l4.212-2.34A.644.644 0 0 0 15 5.088c0-.711-.577-1.288-1.288-1.288H4.288Z' fill='#3d4f50' fill-opacity='.2' />
                    <path d='m3.331 5.65-.728 1.312.728-1.311Zm4.212 2.34-.728 1.312.728-1.311Zm2.914 0-.729-1.31.729 1.31Zm4.212-2.34-.729-1.31.729 1.31ZM3 5.25a1.5 1.5 0 0 1 1.5-1.5v-3A4.5 4.5 0 0 0 0 5.25h3ZM3 12V5.25H0V12h3Zm1.5 1.5A1.5 1.5 0 0 1 3 12H0a4.5 4.5 0 0 0 4.5 4.5v-3Zm9 0h-9v3h9v-3ZM15 12a1.5 1.5 0 0 1-1.5 1.5v3A4.5 4.5 0 0 0 18 12h-3Zm0-6.75V12h3V5.25h-3Zm-1.5-1.5a1.5 1.5 0 0 1 1.5 1.5h3a4.5 4.5 0 0 0-4.5-4.5v3Zm-9 0h9v-3h-9v3Zm0 1.338a.212.212 0 0 1-.212.212v-3A2.788 2.788 0 0 0 1.5 5.088h3Zm-.44-.749c.271.151.44.438.44.749h-3c0 .778.422 1.496 1.103 1.874L4.06 4.339Zm4.212 2.34L4.06 4.34 2.603 6.962l4.212 2.34 1.457-2.623Zm1.456 0a1.5 1.5 0 0 1-1.456 0L6.815 9.302a4.5 4.5 0 0 0 4.37 0L9.728 6.679Zm4.212-2.34L9.728 6.68l1.457 2.623 4.212-2.34-1.457-2.623Zm-.44.749c0-.311.169-.598.44-.749l1.457 2.623A2.144 2.144 0 0 0 16.5 5.088h-3Zm.212.212a.212.212 0 0 1-.212-.212h3A2.788 2.788 0 0 0 13.712 2.3v3Zm-9.424 0h9.424v-3H4.288v3Z' fill='#3d4f50' mask='url(#email-icon-mask-b)' />
                  </g>
                </svg>
              }
              label='Email'
              onClick={() => login('email')}
            />
          </LoginButtons>
        )}
      </InnerSignedOut>
    </View>
  )
}

const View = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;

  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  transition: padding .2s ease;
  @media (min-width: 650px) {
    padding-left: ${p => p.sidebarOpen ? '27rem' : '0rem'};
  }
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 97rem;
  margin: 0 auto;
  padding: 6rem 2rem;
  @media (min-width: 1000px) {
    padding: 6rem 4rem;
  }
`
const HomeLink = styled.a`
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: block;
  color: inherit;
  z-index: 1;

  @media (min-width: 1000px) {
    position: fixed;
    top: 4rem;
    left: 4rem;
  }
`
const InnerSignedOut = styled(Inner)`
  max-width: 65rem;
  margin: auto;
  text-align: center;
`
const WritingEliteImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0 auto 5rem;
  transform: translateX(1.2rem);

  ${p => p.showOnDarkMode && `
    display: none;
    .darkMode & { display: block; }
  `}
  ${p => !p.showOnDarkMode && `
    display: block;
    .darkMode & { display: none; }
  `}
`
const Title = styled.h1`
  font-size: 3rem;
  line-height: 3.6rem;
`
const WritingEliteTitle = styled(Title)`
  margin-bottom: 2rem;
`
const WritingEliteText = styled.p`
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 3rem;
`
const LoginButtons = styled.div`display: flex; justify-content: center;`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 3rem;
  margin: 0 0 3rem;
  border-bottom: 1px solid rgba(61, 79, 80, 0.1);
  .darkMode & { border-bottom-color: rgba(255, 255, 255, 0.1); }
`
const DocGrid = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  margin-bottom: auto;
`
const UserAvatar = styled(Avatar)`margin-right: 1rem;`
const WorkspaceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`
const WorkspaceTitle = styled.h2`
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 2.3rem;
  margin-bottom: 0.2rem;
`
const WorkspaceAmount = styled.p`
  font-size: 1.1rem;
  line-height: 1.3rem;
  opacity: 0.5;
`
const MarginedFeedbackMessage = styled(FeedbackMessage)`
  margin-top: 3rem;
  margin-bottom: -6rem;
`
const NotVerifiedSection = styled.div`
  padding: 0 0 3rem;
  margin: 0 0 3rem;
  border-bottom: 1px solid rgba(61, 79, 80, 0.1);
  .darkMode & { border-bottom-color: rgba(255, 255, 255, 0.1); }
`
const NotVerifiedSectionInner = styled.div`
  background: #fdf6ef;
  background-image: url('${require('images/verify-email-bg@2x.png').default}');
  background-size: cover;
  background-position: center;
  border: 0.2rem solid rgba(245, 145, 105, 0.4);
  border-radius: 1rem;
  padding: 3rem 2rem;
  color: #f59169;
  margin: 1rem auto;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.8rem;

  margin: 0 auto;
  @media (min-width: 1000px) { margin: 1rem auto; }

  > p:not(:last-child) {
    margin-bottom: 2rem;
  }
`
const NotVerifiedTitle = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
`
