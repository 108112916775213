import { useState, useEffect, useRef } from 'preact/hooks'

const mouseMovementToCancelHide = 15

export default function useHideUiWhileWriting (deviceHasMouse, amountChangesBeforeHide, disabled) {
  const [hideUiWhileWriting, setHideUiWhileWriting] = useState(false)
  const [codeMirror, setCodeMirror] = useState()
  const textChanges = useRef(0)
  const mouseMovement = useRef(0)

  useEffect(() => {
    if (hideUiWhileWriting) {
      const onMouseMove = (ev) => {
        if (ev.movementX == null) {
          // handle old browsers without movementX
          mouseMovement.current += 1
        } else {
          mouseMovement.current += Math.abs(ev.movementX) + Math.abs(ev.movementY)
        }

        if (mouseMovement.current >= mouseMovementToCancelHide) {
          textChanges.current = 0
          setHideUiWhileWriting(false)
        }
      }

      window.addEventListener('mousemove', onMouseMove)
      return () => window.removeEventListener('mousemove', onMouseMove)
    }
  }, [hideUiWhileWriting])

  useEffect(() => {
    if (!disabled && deviceHasMouse && codeMirror && !hideUiWhileWriting) {
      const onChange = (_, change) => {
        // setValue is initial value, so means new doc, i.e. reset count
        if (change.origin === 'setValue') {
          textChanges.current = 0
          setHideUiWhileWriting(false)
          return
        }

        // prosemirror-binding == yjs remote sync
        const isLocalUserChange = change.origin !== 'prosemirror-binding'
        if (isLocalUserChange) {
          textChanges.current += 1
          mouseMovement.current = 0

          if (textChanges.current >= amountChangesBeforeHide) {
            setHideUiWhileWriting(true)
          }
        }
      }

      codeMirror.on('change', onChange)
      return () => codeMirror.off('change', onChange)
    }
  }, [deviceHasMouse, codeMirror, amountChangesBeforeHide, disabled])

  return {
    hideUiWhileWriting,
    setCodeMirror
  }
}
