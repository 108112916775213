import styled from 'styled-components'
import { useMemo } from 'preact/hooks'
import Metadata from 'components/Metadata'
import { MONOSPACE_FONT_FAMILY, MONOSPACE_FONT_EXTRA_CSS } from '@constants'
import urlRegEx from 'utils/urlRegEx'

const content = {
  '/terms': require('content/terms.md').default,
  '/privacy': require('content/privacy.md').default,
  '/cookies': require('content/cookies.md').default,
  '/subprocessors': require('content/subprocessors.md').default
}

const headerRegex = /(^#+) /
const italicBoldRegex = /([*_]{1,2})[^*]*[*_]{1,2}/g
const italicBoldReplacer = (match, p1) => (
  p1.length === 1
    ? `<em>${match}</em>`
    : `<strong>${match}</strong>`
)
const urlReplacer = (match, p1, p2) => (
  `<a href="${match}" target="_blank" rel="noreferrer">${match}</a>`
)
const simpleMarkdown = (content) => (
  content
    .split('\n')
    .map(row => {
      const header = row.match(headerRegex)
      if (header) {
        return `<h${header[1].length}>${row}</h${header[1].length}>`
      }

      return (
        '<p>' +
        row
          .replace(italicBoldRegex, italicBoldReplacer)
          .replace(urlRegEx, urlReplacer) +
        '</p>'
      )
    })
    .join('')
)

export default function ContentPage ({ title, path, sidebarOpen }) {
  const html = useMemo(() => (
    simpleMarkdown(content[path] || '', {})
  ), [path])

  return (
    <Wrapper sidebarOpen={sidebarOpen}>
      <Metadata title={title} />
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  transition: padding .2s ease, background .3s ease, color .3s ease;
  color: #3d4f50;
  .darkMode & { color: #f3f9fe; }

  @media (min-width: 650px) {
    padding-left: ${p => p.sidebarOpen ? '27rem' : '0rem'};
    ${p => p.sidebarOpen && 'padding-right: 6rem;'}
  }
`
const Content = styled.div`
  transition: max-width .2s ease, padding .2s ease;

  padding: 10rem 4rem;
  max-width: ${p => 78 + 4 * 2}rem;
  margin: 0 auto;

  font-size: 1.8rem;
  line-height: 3rem;
  font-family: ${MONOSPACE_FONT_FAMILY};
  ${MONOSPACE_FONT_EXTRA_CSS}

  p { min-height: 3rem; }
  a { color: inherit; }

  @media (min-width: 650px) {
    max-width: ${78 + 4 * 2}rem;
  }
  @media (min-width: 1000px) {
    max-width: ${78 + 10 * 2}rem;
    padding-left: 10rem;
    padding-right: 10rem;

    h1 { margin-left: -2.161rem; }
    h2 { margin-left: -3.241rem; }
    h3 { margin-left: -4.322rem; }
    h4 { margin-left: -5.402rem; }
    h5 { margin-left: -6.481rem; }
    h6 { margin-left: -7.563rem; }
  }
`
