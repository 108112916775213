import styled from 'styled-components'
import { useState } from 'preact/hooks'
import Toast from 'components/Toast'
import SvgIcon from 'components/SvgIcon'

export default function TooltipIcon ({ icon, message, ...props }) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Wrapper {...props}>
      <Icon onClick={() => setShowTooltip(!showTooltip)}>
        {icon || <SvgIcon icon='information' />}
      </Icon>
      <Tooltip
        icon={icon || <SvgIcon icon='information' />}
        message={message}
        visible={showTooltip}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Icon = styled.a`
  display: block;

  > svg { display: block; }
`
const Tooltip = styled(Toast)`
  position: absolute;
  bottom: calc(100% + 1rem);
  right: 0;
  width: 100vw;
  max-width: 28rem;
`
