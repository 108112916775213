/* global location */
import styled from 'styled-components'
import { useRef, useState, useEffect, useMemo, useCallback } from 'preact/hooks'
import { logTrackingEvent } from 'helpers/tracking'
import AvatarComponent from 'components/Avatar'
import EmptyAvatarComponent from 'components/EmptyAvatar'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import CopyTextButton from 'components/CopyTextButton'
import TooltipIcon from 'components/TooltipIcon'
import SmallTitle from 'components/SmallTitle'
import SvgIcon from 'components/SvgIcon'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import isValidEmail from 'utils/isValidEmail'
import getUserFromCache from 'utils/getUserFromCache'
import { memo } from 'preact/compat'

// Option is memoized to prevent weird glitch where an opened <select> dropdown would flash when rerendering
const Option = memo(props => <option {...props} />)

const UserItem = ({ docId, type, currentUser, user, onUpdateUserPermissions, onDeleteUserCallback, accessLevel, accessPermission, canUpdateOwner, canEdit, usersCachedData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeletingUser, setIsDeletingUser] = useState(false)

  const onDeleteUser = () => {
    setIsLoading(true)

    onDeleteUserCallback()
      .then(() => setIsLoading(false))
  }

  const userId = type === 'invite' ? user.inviteCode : user.userId
  const invitedByUser = useMemo(() => (
    getUserFromCache(user.invitedBy, usersCachedData)
  ), [user, usersCachedData])

  return (
    <UserWrapper isDeletingUser={isDeletingUser}>
      <User>
        {type === 'invite' && <EmptyAvatar />}
        {type === 'user' && <Avatar seed={user.userId} avatar={user.avatar} />}
        <UserNameWrapper flex={type === 'user' || isDeletingUser}>
          <UserName>{user.displayName}</UserName>
          {isDeletingUser && <DeletionWarning>will be removed from this kludd, are you sure?</DeletionWarning>}
        </UserNameWrapper>
        {type === 'user' && accessLevel === 'ANYONE' && accessPermission === 'rw' && !user.permissions.includes('w') && (
          <HigherPermission>
            Can edit
            <HigherPermissionTooltipIcon message='The settings for this kludd is set to Anyone with the link can edit' />
          </HigherPermission>
        )}
        {!isDeletingUser && type === 'invite' && user.inviteCode && (
          <UserCopyInviteButton
            text={`${location.origin}/invite/${docId}/${user.inviteCode}`}
            label='Invite link'
            copiedLabel='Link copied!'
            inverted
            small
          />
        )}
        {!isDeletingUser && type === 'user' && (
          // Can't update myself so just show value
          !canEdit || currentUser === user.userId || (!canUpdateOwner && user.permissions.includes('o'))
            ? (
              <PermissionValue>
                {
                  user.permissions === 'rwo'
                    ? 'Owner'
                    : user.permissions === 'rw'
                      ? 'Editor'
                      : 'Viewer'
                }
              </PermissionValue>
              )
            : (
              <PermissionSelect
                rightAligned
                value={user.permissions}
                onChange={e => onUpdateUserPermissions(userId, e.currentTarget.value)}
              >
                <Option value='rwo' disabled={!canUpdateOwner}>Owner</Option>
                <Option value='rw' disabled={!canUpdateOwner && user.permissions.includes('o')}>Editor</Option>
                <Option value='r' disabled={!canUpdateOwner && user.permissions.includes('o')}>Viewer</Option>
              </PermissionSelect>
              )
        )}

        {!isDeletingUser && type === 'invite' && (
          <PermissionSelect
            rightAligned
            value={user.permissions}
            onChange={e => onUpdateUserPermissions(userId, e.currentTarget.value)}
          >
            <Option value='rw' disabled={type === 'user' && user.permissions.includes('o')}>Editor</Option>
            <Option value='r' disabled={type === 'user' && user.permissions.includes('o')}>Viewer</Option>
          </PermissionSelect>
        )}

        {(type === 'invite' || (currentUser !== userId && !user.permissions.includes('o'))) && (
          <DeleteUserWrapper>
            <DeleteConfirmButtons show={isDeletingUser}>
              <Button red loading={isLoading} label='Remove' onClick={() => onDeleteUser()} />
              <Button black label='Cancel' onClick={() => setIsDeletingUser(false)} />
            </DeleteConfirmButtons>
            <DeleteUser hide={isDeletingUser} onClick={() => setIsDeletingUser(true)} isLoading={isLoading}>
              <SvgIcon icon='delete' />
            </DeleteUser>
          </DeleteUserWrapper>
        )}
      </User>

      {!!invitedByUser?.name && (
        <InvitedBy>
          <span>Invited by</span>
          <Avatar seed={invitedByUser.userId} avatar={invitedByUser.avatar} size={18} />
          <UserName>{invitedByUser.name}</UserName>
        </InvitedBy>
      )}
    </UserWrapper>
  )
}

export default ({
  open,
  onClose,
  isLoading,
  isError,
  metadata,
  callbacks,
  docId,
  userId,
  canEdit,
  isLoggedIn,
  userPermissions,
  usersCachedData,
  ...props
}) => {
  const hasLoadedSoCanSave = useRef(false)
  const [workspaceRights, setWorkspaceRights] = useState('rw')
  const [accessLevel, setAccessLevel] = useState('ANYONE')
  const [accessPermission, setAccessPermission] = useState('rw')
  const [name, setName] = useState('')
  const [users, setUsers] = useState(null)
  const [invitedUsers, setInvitedUsers] = useState(null)
  const [isInviting, setIsInviting] = useState(false)
  const [inviteInfo, setInviteInfo] = useState(null)
  const hasOwner = useMemo(() => !!(
    metadata.users && metadata.users.find(u => u.permissions && u.permissions.includes('o'))
  ), [metadata])
  const showMailInvite = useMemo(() => !!(
    workspaceRights && name && isValidEmail(name)
  ), [workspaceRights, name])
  // Only owners can set/remove other owners
  const canUpdateOwner = useMemo(() => (userPermissions || '').includes('o'), [userPermissions])

  // On loaded metadata
  useEffect(() => {
    hasLoadedSoCanSave.current = false

    setIsInviting(false)
    setUsers(metadata.users)
    setInvitedUsers(metadata.invitedUsers)
    setAccessLevel(metadata.accessLevel)
    setAccessPermission(metadata.accessPermission)

    // Allow save after load
    setTimeout(() => { hasLoadedSoCanSave.current = true }, 500)
  }, [metadata])

  // On new metadata to save
  useEffect(() => {
    if (hasLoadedSoCanSave.current) {
      if (callbacks && callbacks.onSaveMetadata) {
        logTrackingEvent('Document visibility changed', { accessLevel, accessPermission })
        callbacks.onSaveMetadata({ accessLevel, accessPermission })
      }
    }
  }, [accessLevel, accessPermission])

  // Clear data if modal is closed
  useEffect(() => {
    if (!open) {
      // Do with timeout to avoid quick flicker on close
      setTimeout(() => {
        setInviteInfo(null)
      }, 500)
    }
  }, [open])

  // Update user access
  const updateUserPermissions = useCallback((userId, permissions) => {
    if (callbacks && callbacks.onUpdateUser) {
      callbacks.onUpdateUser(userId, { permissions })
    }
  }, [callbacks])

  const updateInvitedUserPermissions = useCallback((inviteCode, permissions) => {
    if (callbacks && callbacks.onUpdateUser) {
      callbacks.onUpdateInvitedUser(inviteCode, { permissions })
    }
  }, [callbacks])

  const onInviteUser = useCallback((ev, inviteBy = 'link') => {
    ev.preventDefault()

    if (callbacks && callbacks.onInviteUser) {
      setIsInviting(inviteBy)

      callbacks.onInviteUser(
        workspaceRights,
        name,
        inviteBy === 'email' ? name : undefined // if inviting by mail, treat name as mail
      )
        .then(inviteCode => {
          logTrackingEvent('Document invite created', {
            documentId: docId,
            accessRight: workspaceRights,
            invitationCode: inviteCode,
            inviteBy
          })

          setInviteInfo({
            docId,
            inviteCode,
            name,
            inviteBy
          })

          // Add to user array. Server updates invites object so should be safe most times.
          setInvitedUsers(_users => (_users || []).concat({
            displayName: name,
            permissions: workspaceRights,
            inviteCode,
            invitedBy: users?.find(u => u.userId === userId) || { userId }
          }))
        })
        .then(() => {
          setIsInviting(false)
          setWorkspaceRights('rw')
          setName('')
        })
    }
  }, [docId, workspaceRights, name, callbacks, users, userId])

  return (
    <Modal
      title='Share this kludd'
      open={open}
      onClose={onClose}
      maxWidth='61rem'
      centered={isLoading || isError}
      innerStyle={`
          padding: ${(isLoading || isError) ? '2rem 2rem' : '0 2rem'};
          @media (min-width: 1000px) {
            padding: ${(isLoading || isError) ? '2rem 4rem' : '0 4rem'};
          }
        `}
    >
      {isLoading
        ? <Loader />
        : isError
          ? <ErrorMessage>Oops, something went wrong fetching permissions.</ErrorMessage>
          : (
            <>
              {!hasOwner && (
                <Section centeredRow>
                  <SvgIcon icon='unlocked' style={{ marginRight: '0.5rem' }} />
                  <BelongsToText>This kludd is public.</BelongsToText>
                </Section>
              )}

              <Section centeredRow noWrap>
                <SvgIcon icon='unlocked' style={{ marginRight: '1rem', flex: 'none' }} />

                {canEdit
                  ? (
                    <LargeSelect
                      large
                      value={accessLevel}
                      onChange={e => setAccessLevel(e.currentTarget.value)}
                    >
                      <Option value='ANYONE'>Anyone with the link</Option>
                      <Option value='INVITED'>Only people invited to this kludd</Option>
                    </LargeSelect>
                    )
                  : (
                    <LargeSelectValue>
                      {accessLevel === 'ANYONE' && 'Anyone with the link'}
                      {accessLevel === 'INVITED' && 'Only people invited to this kludd'}
                    </LargeSelectValue>
                    )}
                {
              accessLevel === 'INVITED'
                ? <SelectValue>Can access</SelectValue>
                : canEdit
                  ? (
                    <CanEditSelect
                      rightAligned
                      value={accessPermission}
                      onChange={e => setAccessPermission(e.currentTarget.value)}
                    >
                      <Option value='rw'>Can edit</Option>
                      <Option value='r'>Can view</Option>
                    </CanEditSelect>
                    )
                  : (
                    <SelectValue>
                      {accessPermission === 'rw' && 'Can edit'}
                      {accessPermission === 'r' && 'Can view'}
                    </SelectValue>
                    )
            }
              </Section>

              {canEdit && (
                inviteInfo
                  ? (
                      inviteInfo.inviteBy === 'email'
                        ? (
                          <Section>
                            <InviteInfo>
                              <InviteInfoText>We’ve sent your invite to:</InviteInfoText>
                              <InviteInfoMail>
                                <InviteInfoMailValue>{inviteInfo.name}</InviteInfoMailValue>
                                <InviteInfoMailIcon>🎉</InviteInfoMailIcon>
                              </InviteInfoMail>
                              <InviteInfoButton label='Done' onClick={() => setInviteInfo(null)} white />
                            </InviteInfo>
                          </Section>
                          )
                        : (
                          <Section>
                            <InviteInfo>
                              <InviteInfoText>Copy this link and share it with {inviteInfo.name}:</InviteInfoText>
                              <CopyInviteButton
                                text={`${location.origin}/invite/${inviteInfo.docId}/${inviteInfo.inviteCode}`}
                                label='Copy link'
                                copiedLabel='Link copied!'
                                withConfetti
                              />
                              <InviteInfoButton label='Done' onClick={() => setInviteInfo(null)} white />
                            </InviteInfo>
                          </Section>
                          )
                    )
                  : (
                    <FormSection onSubmit={ev => onInviteUser(ev, showMailInvite ? 'email' : 'link')}>
                      <SmallTitle margin={1}>Collaborator name or email:</SmallTitle>
                      <FormInputs>
                        <NameInput>
                          <NameTextInput
                            required
                            placeholder='e.g. Jenny Svensson'
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                            disabled={!!isInviting}
                            name='name'
                            autoComplete='off'
                          />

                          <NameInputSelect
                            rightAligned
                            value={workspaceRights}
                            onChange={e => setWorkspaceRights(e.currentTarget.value)}
                            disabled={!!isInviting}
                          >
                            <Option value='rw'>Can edit</Option>
                            <Option value='r'>Can view</Option>
                          </NameInputSelect>
                        </NameInput>

                        <AddCollaboratorButton
                          type={showMailInvite ? 'button' : 'submit'}
                          label='Create link'
                          icon={<SvgIcon icon='link' />}
                          tighterIconMargin
                          loading={isInviting === 'link'}
                          disabled={!!(
                            !workspaceRights ||
                            !name ||
                            isInviting === 'email'
                          )}
                          onClick={ev => onInviteUser(ev, 'link')}
                        />

                        {showMailInvite && (
                          <AddCollaboratorButton
                            type='submit'
                            label='Send invite'
                            icon={<SvgIcon icon='email' />}
                            tighterIconMargin
                            loading={isInviting === 'email'}
                            disabled={isInviting === 'link'}
                            onClick={ev => onInviteUser(ev, 'email')}
                          />
                        )}
                      </FormInputs>
                    </FormSection>
                    )
              )}

              {(((users && users.length) || 0) + ((invitedUsers && invitedUsers.length) || 0)) <= 1 && (
                <Section>
                  <InviteInfo>
                    <InviteInfoText>Copy the address and send this link to anyone{'\n'}you want to collaborate with.</InviteInfoText>
                    <CopyTextButton
                      text={`${location.origin}/${docId}`}
                      label='Copy link'
                      copiedLabel='Link copied!'
                      withConfetti
                    />
                  </InviteInfo>
                </Section>
              )}

              {canEdit && invitedUsers && invitedUsers.length > 0 && (
                <Section extraPadding>
                  <SmallTitle margin={1}>Invited users:</SmallTitle>
                  {invitedUsers.map(user => (
                    <UserItem
                      key={user.inviteCode}
                      docId={docId}
                      type='invite'
                      user={user}
                      onUpdateUserPermissions={updateInvitedUserPermissions}
                      onDeleteUserCallback={() => callbacks.onDeleteInvitedUser(user.inviteCode)}
                      usersCachedData={usersCachedData}
                    />
                  ))}
                </Section>
              )}

              {users && users.length > 0 && (
                <Section extraPadding>
                  <SmallTitle margin={1}>Collaborators:</SmallTitle>
                  {users.map(user => (
                    <UserItem
                      key={user.inviteCode}
                      type='user'
                      canEdit={canEdit}
                      currentUser={userId}
                      user={user}
                      onUpdateUserPermissions={updateUserPermissions}
                      onDeleteUserCallback={() => callbacks.onDeleteUser(user.userId)}
                      accessLevel={accessLevel}
                      accessPermission={accessPermission}
                      canUpdateOwner={canUpdateOwner}
                      usersCachedData={usersCachedData}
                    />
                  ))}
                </Section>
              )}
            </>
            )}

      {!isLoggedIn && (
        <Section extraPadding centeredColumn>
          <SignInButton
            icon={<SvgIcon icon='sign-in' />}
            label='Sign in to kludd'
            href='/workspaces'
          />
          <SignInText>Reach kludds from any device, set access rights & invite collaborators.</SignInText>
        </Section>
      )}

      {!isLoading && !isError && (
        <Footer>
          <CopyTextButton
            text={`${location.origin}/${docId}`}
            label='Copy link to this kludd'
            copiedLabel='Link to this kludd copied!'
            textLink
          />
        </Footer>
      )}
    </Modal>
  )
}

const Section = styled.div`
  padding: 2rem 0;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.1);
  &:last-child { border-bottom: 0 }
  @media (min-width: 1000px) {
    padding: ${p => p.extraPadding ? '3rem 0' : '2rem 0'};
  }
  ${p => !p.noWrap && 'flex-wrap: wrap;'}
  ${p => p.centeredRow && 'display: flex; align-items: center; justify-content: center;'}
  ${p => p.centeredColumn && 'display: flex; flex-direction: column; align-items: center; '}
`
const FormSection = styled.form`
  padding: 2rem 0;
  border-bottom: 0.1rem solid rgba(61, 79, 80, 0.1);
  &:last-child { border-bottom: 0 }
  @media (min-width: 1000px) { padding: 3rem 0; }
  flex-wrap: wrap;
  ${p => p.centeredRow && 'display: flex; align-items: center; justify-content: center;'}
`
const Footer = styled.div`
  position: sticky;
  bottom: 0;
  margin: -0.1rem -4rem 0; // small negative margin to hide border of previous section
  padding: 2rem;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);

  .darkMode & { box-shadow: 0 -0.4rem 2rem rgba(0, 0, 0, 0.1); }

  @media screen and (max-width: 999px) {
    margin-left: -2rem;
    margin-right: -2rem;
  }
`
const UserWrapper = styled.div`
  border-radius: 1.5rem;
  margin: 0.1rem 0 0.1rem -1.2rem;
  transition: background .2s ease, margin .1s ease;
  padding: 1rem;
  width: calc(100% + 2.4rem);

  ${p => p.isDeletingUser && `
    background: rgba(255, 110, 110, 0.1);
    margin: 0.3rem 0 0.3rem -1.2rem;
  `}
`
const User = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const Avatar = styled(AvatarComponent)`
  margin-right: 1rem;
  flex: none;
`
const EmptyAvatar = styled(EmptyAvatarComponent)`
  margin-right: 1rem;
  flex: none;
`
const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.flex && 'flex: 1;'}
`
const UserName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
`
const InvitedBy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  > span {
    opacity: 0.6;
    margin-right: 1rem;
  }

  & ${UserName} {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  & ${Avatar} {
    margin-right: 0.5rem;
  }
`
const DeletionWarning = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  margin-top: 0.2rem
`
const HigherPermission = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(61, 79, 80, 0.5);
  display: flex;
  align-items: center;

  .darkMode & { color: rgba(255, 255, 255, 0.5); }
`
const HigherPermissionTooltipIcon = styled(TooltipIcon)`
  margin-left: 1rem;
`
// const BelongsToText = styled.p`
//   font-size: 1.4rem;
//   line-height: 2rem;
//   margin: 0 1rem 0 0;
// `
// const BelongsToName = styled(BelongsToText)`
//   font-weight: bold;
//   margin: 0;
//   display: flex;
//   align-items: center;
//
//   > img, > p { margin: 0 0.5rem 0 0; }
// `
// const You = styled.span`
//   opacity: 0.5;
//   font-weight: 400;
//   display: inline-block;
//   margin-left: 0.3em;
// `
const LargeSelect = styled(Select)`
  flex-shrink: 1;
  min-width: 0;
  @media (min-width: 420px) { margin-right: auto; }
`
const CanEditSelect = styled(Select)`
  flex: none;
  margin-left: 2rem;
`
const NameInput = styled.div`
  position: relative;
  @media (max-width: 999px) { margin-bottom: 1rem; }
  @media (min-width: 1000px) { flex: 1; margin-right: 1rem; }
`
const NameInputSelect = styled(Select)`
  position: absolute;
  top: 1.3rem;
  right: 1rem;
`
const FormInputs = styled.div`
  display: flex;

  @media (max-width: 999px) { flex-direction: column; }
`
const AddCollaboratorButton = styled(Button)`
  flex: none;
  padding: 0.9rem 1.4rem;
  @media (min-width: 1000px) { margin-left: 1rem; }
`
const ErrorMessage = styled.p`font-size: 1.6rem;`
const InviteInfo = styled.div`
  background: #ecf9ff;
  background-image: url('${require('images/invite-info-bg@2x.png').default}');
  background-size: cover;
  background-position: center;
  border: 0.1rem solid #366668;
  border-radius: 1rem;
  padding: 3rem 2rem;
  color: #366668;
  max-width: 53rem;
  margin: 1rem auto;
  text-align: center;

  margin: 0 auto;
  @media (min-width: 1000px) { margin: 1rem auto; }

  .darkMode & {
    background-color: #1d2c37;
    background-image: url('${require('images/invite-info-bg-darkmode@2x.png').default}');
  }
`
const InviteInfoText = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 auto 1.5rem;
  white-space: pre-line;
`
const InviteInfoMail = styled.p`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InviteInfoMailValue = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`
const InviteInfoMailIcon = styled.span`
  flex: none;
  margin-left: 1rem;
`
const CopyInviteButton = styled(CopyTextButton)`
  margin: 0 auto 2rem;
`
const UserCopyInviteButton = styled(CopyTextButton)`
  margin: 0 auto 0 2rem;
  flex: none;
`
const InviteInfoButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 16.5rem;
  margin: 0 auto;
`
const SelectValue = styled.span`
  flex: none;
  margin-left: 2rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
`
const LargeSelectValue = styled(SelectValue)`
  font-size: 1.4rem;
  line-height: 2rem;
  @media (min-width: 420px) { margin-right: auto; }
`
const PermissionValue = styled.span`
  flex: none;
  margin-left: 2rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
`
const SignInButton = styled(Button)`
  font-weight: 500;
  margin-top: 1rem;
  max-width: 20rem;
  width: 100%;
`
const SignInText = styled.p`
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;
  max-width: 23rem;
  margin-top: 1rem;
`
const PermissionSelect = styled(Select)`
  flex: none;
  margin-left: 2rem;
`
const DeleteUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`
const DeleteUser = styled.button`
  color: inherit;
  appearance: none;
  border: none;
  background: transparent;
  margin-left: 2rem;
  position: relative;
  opacity: 1;

  ${p => p.isLoading && '> svg:first-child { opacity: 0.2 } pointer-events: none;'}
  ${p => p.hide && ' display: none; '}
`
const BelongsToText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 1rem 0 0;
`
const DeleteConfirmButtons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  opacity: 0;
  transform: translateX(2rem) scale(0.8);
  transition: none;
  pointer-events: none;

  & a:not(:last-child) {
    margin-right: 1rem;
  }

  ${p => p.show && `
    position: static;
    opacity: 1;
    transform: none;
    transition: opacity .2s ease, transform .2s ease;
    pointer-events: auto;
`}
`
const NameTextInput = styled(TextInput)`
  padding-right: ${7.1 + 1 + 0.5}rem; // padding to not overlap "Can edit"/"Can view" dropdown
`
