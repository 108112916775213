import { useState, useCallback } from 'react'
import styled from 'styled-components'
import CheckboxWithLabel from 'components/CheckboxWithLabel'
import SvgIcon from 'components/SvgIcon'
import ContextMenu from 'components/ContextMenu'
import { PLUGINS, EXPORTS } from '@constants'

export default function Plugins ({
  availableExports,
  activatedPlugins,
  onSetPluginActive,
  readOnly
}) {
  const [exportsContextMenu, setExportsContextMenu] = useState(null)

  const onExportsContextMenu = useCallback(async (ev) => {
    const rect = ev.currentTarget.getBoundingClientRect()
    const top = rect.top
    const left = rect.right + 20

    setExportsContextMenu({
      top,
      left,
      options: Object.keys(EXPORTS).map(key => {
        const plugin = EXPORTS[key]

        return {
          iconSrc: plugin.icon,
          label: plugin.label,
          action: availableExports[key],
          disabled: !availableExports[key]
        }
      })
    })
  }, [availableExports])

  return (
    <>
      <List>
        {Object.keys(PLUGINS).map(key => {
          const plugin = PLUGINS[key]

          return (
            <Plugin key={key}>
              <CheckboxWithLabel
                label={
                  <PluginLabel>
                    {plugin.label}
                    {!!plugin.beta && <BetaLabel>BETA</BetaLabel>}
                  </PluginLabel>
                }
                displayAsBoolean
                reverseOrder
                icon={<Icon src={plugin.icon} />}
                checked={activatedPlugins[key]}
                onChecked={checked => onSetPluginActive(key, checked)}
                disabled={readOnly}
              />
            </Plugin>
          )
        })}
      </List>
      <ExportButton onClick={onExportsContextMenu}>
        <ExportIcon icon='download' />
        Download this kludd as...
        <ExportIcon icon='caret-right' right />
      </ExportButton>
      {exportsContextMenu && (
        <ContextMenu
          top={exportsContextMenu.top}
          left={exportsContextMenu.left}
          options={exportsContextMenu.options}
          onClose={() => setExportsContextMenu(null)}
          positionedToTheRight
        />
      )}
    </>
  )
}

const List = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  padding-top: 2rem;
`
const ExportButton = styled.a`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 2rem;
  padding: 2rem 0;
`
const Plugin = styled.div`
  margin-bottom: 2rem;
  transition: border .3s ease;
  .darkMode & { border-top-color: rgba(255, 255, 255, 0.1); }
`
const ExportIcon = styled(SvgIcon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  ${p => p.right && 'margin-left: auto; margin-right: 0;'}
`
const Icon = styled.img`
  width: 1.8rem;
  height: 1.8rem;
`
const PluginLabel = styled.span`
  display: flex;
  align-items: center;
`
const BetaLabel = styled.span`
  display: block;
  background: #366668;
  color: white;
  border-radius: 0.6rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
`
