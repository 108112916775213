import { useLayoutEffect, useState } from 'preact/hooks'
import { CodemirrorBinding } from 'y-codemirror'

export default function useCodemirrorBinding (docId, doc, codeMirror, provider) {
  const [_binding, setBinding] = useState()

  useLayoutEffect(() => {
    // Make sure docId is same as doc, to not update incorrect document
    if (doc && doc.docId === docId && codeMirror) {
      const awareness = provider && provider.awareness
      const textType = doc.contentType
      const binding = new CodemirrorBinding(textType, codeMirror, awareness)

      setBinding(binding)

      return () => {
        try { binding.destroy() } catch (_) {}
      }
    } else {
      setBinding(null)
    }
  }, [docId, doc, codeMirror, provider])

  return _binding
}
