import { useState, useEffect } from 'preact/hooks'

export default ({ canHide }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (canHide) {
      const onLoad = () => {
        setHidden(true)
      }

      if (document.readyState === 'complete') {
        onLoad()
      } else {
        window.addEventListener('load', onLoad)
      }
    }
  }, [canHide])

  return (
    <div class={['splashScreen', hidden ? 'hidden' : ''].join(' ')}>
      <svg width='115' height='37' viewBox='0 0 115 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M50.1907 30.6064L42.975 21.0085L50.0526 13.9999H45.5644L38.3832 21.3537V5.61035H35.1724V30.6064H38.3832V25.6003L40.6964 23.2871L46.1168 30.6064H50.1907Z' fill='#3d4f50' />
        <path d='M72.3545 28.6302C72.3545 28.9754 72.3545 30.6326 72.3545 30.6326H75.4272V27.5599V14.0261H72.1818V24.0384C72.1818 25.3158 71.8711 26.317 71.2151 27.1111C70.5592 27.9052 69.5579 28.2849 68.246 28.2849C67.5555 28.2849 66.9686 28.1814 66.5197 27.9397C66.0364 27.698 65.6566 27.3873 65.3459 27.0075C65.0352 26.6277 64.828 26.1444 64.6899 25.6265C64.5518 25.1086 64.4828 24.5908 64.4828 24.0038V14.0261H61.2374V24.4872C61.2374 25.4194 61.3755 26.317 61.6863 27.1111C61.9625 27.9052 62.3767 28.6302 62.8946 29.2171C63.4125 29.804 64.0685 30.2874 64.8626 30.6326C65.6221 30.9779 66.4852 31.1505 67.4519 31.1505C68.4186 31.1505 69.3508 30.9434 70.283 30.5291C71.2151 30.1493 71.9056 29.4933 72.3545 28.6302Z' fill='#3d4f50' />
        <path d='M111.858 28.0864V28.3626V30.6412H114.977L115 5.6106H111.789V16.3479C111.651 16.0026 111.444 15.6574 111.168 15.3121C110.892 15.0014 110.546 14.6907 110.132 14.4145C109.718 14.1728 109.234 13.9656 108.682 13.793C108.095 13.6204 107.439 13.5513 106.714 13.5513C105.54 13.5513 104.47 13.793 103.503 14.2418C102.537 14.7252 101.708 15.3466 101.017 16.1407C100.327 16.9348 99.8091 17.867 99.4293 18.9027C99.0496 19.973 98.8424 21.0778 98.8424 22.2516C98.8424 23.4945 99.015 24.6339 99.3948 25.7041C99.7746 26.7744 100.292 27.7066 100.983 28.5007C101.639 29.2947 102.468 29.9162 103.434 30.365C104.401 30.8484 105.506 31.0555 106.714 31.0555C108.026 31.0555 109.131 30.7793 109.994 30.1579C110.857 29.5709 111.479 28.8804 111.858 28.0864ZM102.157 22.2516C102.157 21.3885 102.26 20.629 102.502 19.9039C102.744 19.1789 103.054 18.5575 103.503 18.0396C103.918 17.5217 104.435 17.1419 105.057 16.8312C105.644 16.555 106.334 16.4169 107.059 16.4169C108.544 16.4169 109.718 16.9348 110.581 18.0051C111.409 19.0753 111.824 20.4563 111.824 22.1826C111.824 23.0457 111.72 23.8398 111.513 24.5648C111.271 25.2898 110.961 25.9458 110.546 26.4637C110.132 26.9816 109.614 27.3959 109.027 27.7066C108.44 28.0173 107.75 28.1899 107.025 28.1899C106.231 28.1899 105.54 28.0518 104.953 27.7411C104.366 27.4304 103.849 27.0161 103.434 26.4982C103.02 25.9803 102.709 25.3244 102.502 24.5993C102.26 23.8743 102.157 23.1148 102.157 22.2516Z' fill='#3d4f50' />
        <path d='M92.173 28.0864V28.3626V30.6412H95.2916L95.3148 5.6106H92.104V16.3479C91.9659 16.0026 91.7587 15.6574 91.4825 15.3121C91.2063 15.0014 90.8611 14.6907 90.4468 14.4145C90.0325 14.1728 89.5491 13.9656 88.9967 13.793C88.4098 13.6204 87.7538 13.5513 87.0288 13.5513C85.8549 13.5513 84.7847 13.793 83.818 14.2418C82.8513 14.7252 82.0227 15.3466 81.3322 16.1407C80.6417 16.9348 80.1238 17.867 79.744 18.9027C79.3643 19.973 79.1571 21.0778 79.1571 22.2516C79.1571 23.4945 79.3297 24.6339 79.7095 25.7041C80.0893 26.7744 80.6071 27.7066 81.2976 28.5007C81.9536 29.2947 82.7822 29.9162 83.7489 30.365C84.7156 30.8484 85.8204 31.0555 87.0288 31.0555C88.3407 31.0555 89.4455 30.7793 90.3087 30.1579C91.1718 29.5709 91.7932 28.8804 92.173 28.0864ZM82.4715 22.2516C82.4715 21.3885 82.5751 20.629 82.8168 19.9039C83.0584 19.1789 83.3692 18.5575 83.818 18.0396C84.2323 17.5217 84.7501 17.1419 85.3716 16.8312C85.9585 16.555 86.649 16.4169 87.374 16.4169C88.8586 16.4169 90.0325 16.9348 90.8956 18.0051C91.7242 19.0753 92.1385 20.4563 92.1385 22.1826C92.1385 23.0457 92.0349 23.8398 91.8278 24.5648C91.5861 25.2898 91.2754 25.9458 90.8611 26.4637C90.4468 26.9816 89.9289 27.3959 89.342 27.7066C88.755 28.0173 88.0645 28.1899 87.3395 28.1899C86.5454 28.1899 85.855 28.0518 85.268 27.7411C84.6811 27.4304 84.1632 27.0161 83.7489 26.4982C83.3346 25.9803 83.0239 25.3244 82.8168 24.5993C82.5751 23.8743 82.4715 23.1148 82.4715 22.2516Z' fill='#3d4f50' />
        <path d='M53.3451 5.6106V30.6067H56.5904V5.6106H53.3451Z' fill='#3d4f50' />
        <rect width='4.74719' height='36.2512' transform='matrix(1 0 0 -1 0 36.2786)' fill='#3d4f50' />
        <rect width='4.74719' height='17.2625' transform='matrix(-4.37114e-08 1 1 4.37114e-08 4.74731 17.2896)' fill='#3d4f50' />
        <rect width='4.74719' height='23.9209' transform='matrix(0.707107 0.707107 0.707107 -0.707107 1.76526 16.9148)' fill='#3d4f50' />
      </svg>
    </div>
  )
}
