import { useMemo } from 'preact/hooks'
import styled from 'styled-components'
import Avatar from 'components/Avatar'
import getUserFromCache from 'utils/getUserFromCache'
import formatClock from 'utils/formatClock'

export default function CommentPreview ({ message, created, usersCachedData, ...props }) {
  const { avatar, name } = useMemo(() => getUserFromCache(created.user, usersCachedData), [created, usersCachedData])

  return (
    <Preview {...props}>
      <TitleTextWrapper>
        <AvatarWithMargin
          avatar={avatar}
          seed={created.user.userId}
          size={25}
        />
        <Name>{name}</Name>
        <Time>{formatClock(created.timestamp)}</Time>
      </TitleTextWrapper>

      {!!message && <Message>{message}</Message>}
    </Preview>
  )
}

const Preview = styled.div`
  transition: background .3s ease, color .3s ease, transform .3s ease;
  color: #3f4852;
  background: white;
  box-shadow: 0 0.4rem 2rem rgba(63, 72, 82, 0.15);
  padding: 2rem;
  border-radius: 2rem 2rem 2rem 1rem;
  max-width: 27.5rem;

  .darkMode & { background: #1d232b; color: #f3f9fe; }

  .title-text-wrapper {
    display: flex;
    align-items: center;
  }
`
const TitleTextWrapper = styled.div`
  display: flex;
  align-items: center;
`
const AvatarWithMargin = styled(Avatar)`
  margin-right: 1rem;
  flex: none;
`
const Name = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  margin-right: 0.5rem;
`
const Time = styled.time`
  font-size: 1.2rem;
  line-height: 1.5rem;
  opacity: 0.5;
  letter-spacing: 0.02rem;
  padding-top: 0.2rem;
`
const Message = styled.p`
  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 2rem;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  a { color: #46b7ed; }
`
