import { useState } from 'preact/hooks'
import { FONT_FAMILY_CHOICES } from '@constants'
import { defaultValues as editorSettingsDefaultValues } from 'hooks/useEditorSettings'
import styled from 'styled-components'
import Button from 'components/Button'
import SmallTitle from 'components/SmallTitle'
import FontButton from 'components/FontButton'
import NumberButton from 'components/NumberButton'
import Slider from 'components/Slider'
import SvgIcon from 'components/SvgIcon'
import BigRadioButtons from 'components/BigRadioButtons'
import Checkbox from 'components/Checkbox'
import useCloseOnEsc from 'hooks/useCloseOnEsc'
import preventDefaultEvent from 'utils/preventDefaultEvent'
import preventLabelClickForCheckboxesToPreventFocus from 'utils/preventLabelClickForCheckboxesToPreventFocus'

const fontSizes = [14, 16, 18, 20, 22]

export default ({
  userData,
  open,
  onClose,
  editorSettings,
  setEditorSettings,
  darkMode,
  setDarkMode,
  focusMode,
  setFocusMode,
  minimalistMode,
  setMinimalistMode,
  ...props
}) => {
  const [view, setView] = useState('view')
  const [useHeaderShadow, setUseHeaderShadow] = useState(false)

  useCloseOnEsc(onClose, open)

  const onEditorValue = (property) => (value) => {
    setEditorSettings(s => ({ ...s, [property]: value }))
  }

  const onScroll = (el) => {
    if (!el || !el.target) return
    const { scrollTop } = el.target
    setUseHeaderShadow(scrollTop > 10)
  }

  return (
    <Modal open={open} {...props} onScroll={onScroll}>
      <ModalHeader shadow={useHeaderShadow}>
        <ModalTitle>Type settings</ModalTitle>

        <CloseButton onClick={onClose}>
          <SvgIcon icon='exit' />
        </CloseButton>
      </ModalHeader>

      <ModalInner>
        <ToggleViewButtons
          fadeNotSelected
          name='view'
          value={view}
          onChange={setView}
          options={[{ value: 'view', label: 'View' }, { value: 'text', label: 'Text' }]}
        />

        {view === 'view' && (
          <>
            <Section openTop>
              <CheckboxLabel
                onClick={preventLabelClickForCheckboxesToPreventFocus}
                onMouseDown={preventDefaultEvent}
              >
                <SvgIcon icon='light' />
                Light / Dark
                <MarginedCheckbox displayAsBoolean checked={darkMode} onChecked={setDarkMode} isWrappedByLabel />
              </CheckboxLabel>
            </Section>

            <Section>
              <CheckboxLabel
                onClick={preventLabelClickForCheckboxesToPreventFocus}
                onMouseDown={preventDefaultEvent}
              >
                <SvgIcon icon='focused' />
                Focused
                <MarginedCheckbox displayAsBoolean checked={focusMode} onChecked={setFocusMode} isWrappedByLabel />
              </CheckboxLabel>
            </Section>

            <Section>
              <CheckboxLabel
                onClick={preventLabelClickForCheckboxesToPreventFocus}
                onMouseDown={preventDefaultEvent}
              >
                <SvgIcon icon='minimalist' />
                Minimalist
                <MarginedCheckbox displayAsBoolean checked={minimalistMode} onChecked={setMinimalistMode} isWrappedByLabel />
              </CheckboxLabel>
            </Section>
          </>
        )}

        {view === 'text' && (
          <>
            <Section openTop>
              <SmallTitle margin={1}>Typeface</SmallTitle>
              <FontButtonWrapper>
                {FONT_FAMILY_CHOICES.map((f, i) => (
                  <FontButtonFlex
                    {...f}
                    key={f.id}
                    active={editorSettings.fontFamilyId === f.id}
                    onClick={() => onEditorValue('fontFamilyId')(f.id)}
                  />
                ))}
              </FontButtonWrapper>
            </Section>

            <Section>
              <SmallTitle margin={1}>Font size</SmallTitle>
              <FontSizeWrapper>
                {fontSizes.map(size => (
                  <NumberButton
                    key={size}
                    number={size}
                    active={editorSettings.fontSize === size}
                    onClick={() => onEditorValue('fontSize')(size)}
                  />
                ))}
              </FontSizeWrapper>
            </Section>

            <Section>
              <SmallTitle margin={1}>Paragraph spacing</SmallTitle>
              <Slider
                min={0}
                max={40}
                value={editorSettings.paragraphSpacing}
                onValue={onEditorValue('paragraphSpacing')}
                step={10}
              />
            </Section>

            <Section>
              <SmallTitle margin={1}>Line height</SmallTitle>
              <Slider
                min={20}
                max={40}
                value={editorSettings.lineHeight}
                onValue={onEditorValue('lineHeight')}
                step={5}
              />
            </Section>

            <Section>
              <SmallTitle margin={1}>Line width</SmallTitle>
              <Slider
                min={580}
                max={780 + 200 * 4}
                value={editorSettings.lineWidth}
                onValue={onEditorValue('lineWidth')}
                step={200}
              />
            </Section>

            <Section>
              <ResetButton
                label='Reset to default'
                outline
                onClick={() => {
                  setEditorSettings(editorSettingsDefaultValues)
                }}
              />
            </Section>
          </>
        )}
      </ModalInner>
    </Modal>
  )
}

const Modal = styled.div`
  position: relative;
  background: white;
  color: #3d4f50;
  border-radius: 2rem 2rem 0 0;
  @media (min-width: 650px){
    border-radius: 2rem;
  }
  box-shadow: 0 0.4rem 4rem rgba(61, 79, 80, 0.1);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .darkMode & {
    color: #f3f9fe;
    background: #1d232b;
    box-shadow: 0 0.4rem 4rem rgba(0, 0, 0, 0.15);
  }
  transition: opacity .3s ease, visibility .3s ease, transform .3s ease, background .3s ease, color .3s ease;
  ${p => !p.open && 'opacity: 0; visibility: hidden; transform: translateY(50%);'}
  transform-origin: center;
  @media (min-width: 650px){
    ${p => !p.open && 'transform: translateX(1rem) scale(1.05);'}
    transform-origin: left;
  }
`
const ModalHeader = styled.div`
  padding: 2rem;
  margin: 0 -2rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background: inherit;

  &:after {
    content: '';
    height: 1px;
    transition: background .3s ease;
    background: rgba(61, 79, 80, 0.1);
    position: absolute;
    bottom: 0;
    left: 2rem;
    right: 2rem;
  }
  .darkMode &:after { background: rgba(255, 255, 255, 0.1); }

  ${p => p.shadow && `
    &:after,.darkMode &:after { background: transparent; }
    box-shadow: 0 -0.4rem 2rem rgba(61, 79, 80, 0.15);
    .darkMode & { box-shadow: 0 -0.4rem 2rem rgba(255, 255, 255, 0.1); }
  `}
`
const ModalInner = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`
const ModalTitle = styled.h2`
  font-size: 1.6rem;
  line-height: 2rem;
`
const CloseButton = styled.button`
  border: none;
  appearance: none;
  background: transparent;
  display: block;
  position: absolute;
  padding: 0.1rem;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #3d4f50;
  svg { display: block; }

  .darkMode & { color: white; }
`
const ResetButton = styled(Button)`width: 100%;`
const Section = styled.div`
  border-top: 1px solid rgba(61, 79, 80, 0.1);
  padding: 2rem 0;
  ${p => p.openTop && 'border: none; padding-top: 0;'}
  .darkMode & { border-top-color: rgba(255, 255, 255, 0.1); }
`
const FontButtonWrapper = styled.div`display: flex;`
const FontSizeWrapper = styled.div`display: flex; justify-content: space-between;`
const FontButtonFlex = styled(FontButton)`
  flex: 1;
  &:not(:last-child) { margin-right: 1rem; }
`
const ToggleViewButtons = styled(BigRadioButtons)`margin-bottom: 2rem;`
const CheckboxLabel = styled.label`
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;

  > svg { margin-right: 0.8rem; }
`
const MarginedCheckbox = styled(Checkbox)`margin-left: auto;`
