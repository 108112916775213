import { useMemo } from 'preact/hooks'

export default function useSortedDocs (docs, tempDocs) {
  const sortedDocs = useMemo(() => {
    const tmp = tempDocs || []
    const arr = (docs || [])
      .filter(d => (
        // Remove docs that also exist in tmp
        !tmp.find(td => td[0] === d[0]) &&
        // Remove hidden docs
        !d[1].isHidden
      ))
      .concat(tmp.filter(d => !d[1].isHidden))

    arr.sort((a, b) => ((b[1].myLatestUpdateTimestamp || b[1].latestUpdateTimestamp || 0) - (a[1].myLatestUpdateTimestamp || a[1].latestUpdateTimestamp || 0)))

    return arr
  }, [docs, tempDocs])

  return sortedDocs
}
