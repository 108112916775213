/* global location */
import { useEffect } from 'preact/hooks'

const isLocalhostRouteRegex = /^([\w\d]+);(http:\/\/localhost:\d{2,5}\/.*)/

export default function useActivatePluginsFromUrl (activatePlugin, deactivatePlugin) {
  useEffect(() => {
    if (typeof URLSearchParams !== 'undefined' && typeof location !== 'undefined') {
      const params = new URLSearchParams(location.search)

      if (params.has('plugins')) {
        const pluginIds = params.get('plugins').split(',')

        for (let id of pluginIds) {
          let url
          const isLocalhostRoute = id.match(isLocalhostRouteRegex)

          if (isLocalhostRoute) {
            id = isLocalhostRoute[1]
            url = isLocalhostRoute[2]
          } else {
            // For now, only accept localhost routes
            continue
          }

          if (url) {
            activatePlugin(id)

            // Load script
            const script = document.createElement('script')
            script.src = url
            script.async = true
            document.body.appendChild(script)
          } else {
            console.log('Plugin', id, 'not found.')
          }
        }
      }
    }
  }, [])
}
